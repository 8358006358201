// import external
import React from "react";
// import internal
import CarOverview from "../../components/CarOverview";

// internal styling
import "./_style.scss";
import {useSelector} from "react-redux";

/**
 * @param {*} props (not used)
 * renders the page to show all invoice positions and invoices for a fleet
 * @returns
 */
const MyCarsPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <CarOverview labels={currentLabels} title={currentLabels?.carsPage?.titles?.fleetOverview}></CarOverview>
            </div>
        </div>
    )
}

export default MyCarsPage
// import external
import { useVariableValue } from "@devcycle/react-client-sdk";


// import internal
import { checkAgentLoginToken } from "../../actions/token.actions";


// import helper
import { useGetUserQuery } from "../../redux/apiSlice/userApiSlice";


// import assets
import { ROLE_CONST } from "../../actions/constants/role.constants";


 
 
  export const useUserPermissions = () => {
    const { data: userData } = useGetUserQuery();
    const hasDamageRole = userData?.roles?.some(role => role.id === ROLE_CONST.DAMAGE);
    const isShowDamagePage = useVariableValue('damage-page', false);
    const isAgentLogin = checkAgentLoginToken();
   
    const allowEdit = hasDamageRole && (isShowDamagePage || isAgentLogin);
 
    return { allowEdit };
  };

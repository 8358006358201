// import styling
import "./_style.scss"
import React, {useContext, useState} from "react";
import FormModal from "../../../components/FormModal";
import {ReactComponent as LinkIcon} from "../../../assets/svg/icon-link.svg";
import {ReactComponent as SuccessIcon} from "../../../assets/svg/icon-check-circle.svg";
import {ReactComponent as ErrorIcon} from "../../../assets/svg/icon-warning.svg";
import FeatureIcon from "../../../components/FeatureIcon";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {addDriverCarRelationSchema} from "../../../components/Form/FormSchemas";
import {useAddDriverToCarMutation} from "../../../redux/apiSlice/driverApiSlice";
import {useGetCarsV2Query} from "../../../redux/apiSlice/carApiSlice";
import {
    FormDescription,
    FormField,
    FormGroup,
    FormItem,
    FormLabel,
    FormMessage,
    Input
} from "../../../components/Form/Form";
import {Combobox, ComboboxInput, ComboboxItem, ComboboxList} from "../../../components/Form/Combobox/Combobox";
import {
    StatusMessage,
    StatusMessageContent,
    StatusMessageIcon,
    StatusMessageTitle
} from "../../../components/StatusMessage";
import {DriverContext} from "../index";


const AddRelationModal = ({lastConnection, hasPermission, labels}) => {
    /**
     Data Loading
     **/
    // Load Driver from Context Provider
    const driver = useContext(DriverContext)
    // Load cars to allow car search
    const {data: carData, isLoading: isCarsLoading, error: carsError} = useGetCarsV2Query()
    // Filter out cars without licence plate
    // Yue: the !! double exclamation mark convert any value to a boolean
    const cars = carData?.cars?.filter(car => !!car?.licencePlate)

    /**
     States
     **/
    // State to control modal visibility
    const [showModal, setShowModal] = useState(false)
    // Save the car selected by user (car to be assigned to driver)
    const [selectedCar, setSelectedCar] = useState(null)
    // Used to handle the logic of whether to show success screen or not
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)

    /**
     API Calls (separated by use case)
     **/
    // Used to assign driver to car (create relation)
    const [assignDriver, {isLoading: isAssigning, error: assignError, reset: resetAssignDriver}] = useAddDriverToCarMutation()
    // Same as above but used specifically to end last connection
    const [endLastConnection, {isSuccess: endLastConnectionSuccess, isLoading: endLastConnectionIsLoading, error: endLastConnectionError, reset: resetEndLastConnection}] = useAddDriverToCarMutation()


    /**
     Rest of the logic/components
     **/
    // Form to add relation
    const addRelationForm = useForm({
        resolver: zodResolver(addDriverCarRelationSchema(labels?.fleetWizard?.form)),
        defaultValues: {
            startDt: null,
            endDt: null,
            carId: null,
            // If the user ticks this, after the API call we execute the onSuccess function to send update API call in parent
            endLastConnection: false
        }
    })

    // Called when form is clean and submitted
    const onSubmitForm = async (data) => {

        await assignDriver({
            organisationCustomerId: driver?.parentCustomer,
            carId: data.carId,
            // If user wants to end last connection, we don't invalidate tags just yet (use custom queryArg for this)
            skipTagInvalidation: data.endLastConnection,
            body: {
                startDt: data.startDt,
                endDt: data.endDt,
                driver: driver?.childCustomer?.id,
                car: data.carId
            }
        }).then(async (result) => {
            if (result?.data) {
                if (data.endLastConnection) {
                    await endLastConnection({
                        organisationCustomerId: driver?.parentCustomer,
                        carId: lastConnection?.id,
                        body: {
                            startDt: lastConnection?.startDt,
                            car: lastConnection?.id,
                            id: lastConnection?.relationshipId,
                            driver: driver?.childCustomer?.id,
                            endDt: data.startDt
                        }
                    })
                }
            }
        })
        // Show success screen, will have different messages depending on the result of the API calls
        setShowSuccessScreen(true)
    }

    // Reset all states and close modal
    function resetStates() {
        addRelationForm.reset();
        setShowSuccessScreen(false);
        setSelectedCar(null);
        resetAssignDriver();
        resetEndLastConnection();
        setShowModal(false);
    }

    // Message that is shown after successful API calls
    const SuccessScreen = () => {
        // If success and user didn't want to end last connection
        let message = labels?.addDriverCarRelation?.messages?.success?.created;
        let title = labels?.fleetWizard?.messages?.success?.title;

        // If user wanted to end last connection and both API calls were successful
        if (endLastConnectionSuccess) message = labels?.addDriverCarRelation?.messages?.success?.createdAndUpdated;
        // If user wanted to end last connection but that second API call failed
        else if (endLastConnectionError) {
            message = labels?.addDriverCarRelation?.messages?.error?.createdButNotUpdated;
            title = labels?.addDriverCarRelation?.messages?.error?.partialSuccessTitle;
        }

        return (
            <>
                <StatusMessage variant={endLastConnectionError ? "warning" : "success"} style={{marginBottom: "1em", marginTop: "1em"}}>
                    <StatusMessageIcon>
                        <SuccessIcon/>
                    </StatusMessageIcon>
                    <StatusMessageTitle>
                        {title}
                    </StatusMessageTitle>
                    <StatusMessageContent>
                        {message}
                    </StatusMessageContent>
                </StatusMessage>


                <button className="btn btn-primary" style={{width:"100%"}} onClick={resetStates}>
                    {labels?.otherElements?.close}
                </button>
            </>

        )
    }


    // If failed to load cars or no permission, return nothing
    if (carsError || !hasPermission) return null

    return (
        <>
            {
                isCarsLoading ? (<button className="btn btn-primary btn-disabled" style={{minWidth:"150px"}}>
                    <div className="dot-falling-container" style={{margin:"auto"}}>
                        <div className="dot-falling"/>
                    </div>
                </button>) : (
                    <button className="btn btn-light sm" onClick={() => setShowModal(true)} disabled={isCarsLoading}>
                        <LinkIcon className="add-relation-icon link"/>
                        {labels?.addDriverCarRelation?.labels?.addCarConnection}
                    </button>
                )
            }

            <FormModal
                show={showModal}
                onClose={resetStates}
                onOutsideClick={() => setShowModal(false)}
                style={{maxWidth: "550px"}}
                title={labels?.addDriverCarRelation?.labels?.connectDriverToCar}
                featuredIcon={<FeatureIcon>
                    <LinkIcon className="add-relation-icon link"  />
            </FeatureIcon>}
            >

                {
                    showSuccessScreen ? (<SuccessScreen/>) : (
                        <div className="add-relation-form-wrapper">
                        <form onSubmit={addRelationForm.handleSubmit(onSubmitForm)} className="add-relation-form">

                            <StatusMessage variant="warning" style={{marginBottom: "0.5em"}} hidden={!assignError}>
                                <StatusMessageIcon>
                                    <ErrorIcon/>
                                </StatusMessageIcon>
                                <StatusMessageTitle>
                                    {labels?.fleetWizard?.messages?.error?.title}
                                </StatusMessageTitle>
                                <StatusMessageContent>
                                    {labels?.addDriverCarRelation?.messages?.error?.notCreated}
                                </StatusMessageContent>
                            </StatusMessage>

                            <FormGroup>
                                <FormField
                                    control={addRelationForm.control}
                                    name="startDt"
                                    render={({field, error}) => (<FormItem style={{justifyContent:"start"}}>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo?.startDate} *
                                        </FormLabel>
                                        <Input {...field} {...error} type="date"/>
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />

                                <FormField
                                    control={addRelationForm.control}
                                    name="endDt"
                                    render={({field, error}) => (<FormItem style={{justifyContent:"start"}}>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo?.endDate}
                                        </FormLabel>
                                        <Input {...field} {...error} type="date"/>
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />
                            </FormGroup>

                            <FormField
                                control={addRelationForm.control}
                                name="carId"
                                render={({field, error}) => (<FormItem>
                                    <FormLabel {...error} >
                                        {labels?.addDriverCarRelation?.labels?.selectVehicle} *
                                    </FormLabel>


                                    <Combobox
                                        selected={selectedCar?.additionalCarInformation?.baseData?.carId}
                                        onSelect={(id) => {
                                            const car = cars.find(car => car?.additionalCarInformation?.baseData?.carId === id);
                                            setSelectedCar(car);
                                            addRelationForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || null);
                                            addRelationForm.trigger(["carId"])
                                        }}
                                    >
                                        <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                       selectedValue={selectedCar?.licencePlate}
                                        />
                                        <ComboboxList
                                            noResults={<ComboboxItem
                                                disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                        >
                                            {cars?.map(car => (
                                                <ComboboxItem key={car?.additionalCarInformation?.baseData?.carId}
                                                              value={car?.additionalCarInformation?.baseData?.carId}>
                                                    {car?.licencePlate}
                                                </ComboboxItem>))}
                                        </ComboboxList>
                                    </Combobox>

                                    <FormMessage message={error?.message}/>

                                    <FormDescription style={{marginBottom: 0}}>
                                        {labels?.fleetWizard?.form?.selectCarDescription}
                                    </FormDescription>

                                </FormItem>)}/>

                            <div hidden={!lastConnection} className="w-100">
                                <FormField
                                    control={addRelationForm.control}
                                    name="endLastConnection"
                                    render={({field, error}) => (<FormItem style={{width:"max-content"}}>
                                        <FormLabel htmlFor="endCurrentDriver" {...error} >
                                            {labels?.addDriverCarRelation?.labels?.endLastConnection}
                                        </FormLabel>
                                        <Input {...field} {...error}
                                                id="endCurrentDriver"
                                               checked={addRelationForm.watch("endLastConnection")}
                                               type="checkbox"
                                               onChange={(e) => addRelationForm.setValue("endLastConnection", e.target.checked)}
                                        />

                                    </FormItem>)}
                                />
                                <FormDescription style={{marginBottom: 0}}>
                                    {labels?.addDriverCarRelation?.messages?.endLastConnectionDescription}
                                </FormDescription>

                            </div>

                            <div className="add-relation-note-section">
                                {labels?.addDriverCarRelation?.messages?.note}
                            </div>


                            <div className="footer-buttons" style={{width: "100%", margin: 0}}>
                                <button className="btn btn-light" type="button" style={{width: "100%"}}
                                        onClick={resetStates}
                                >
                                    {labels?.otherElements?.cancel}

                                </button>

                                {isAssigning || endLastConnectionIsLoading ? (
                                    <button className="btn btn-primary btn-disabled" style={{width: "100%"}}>
                                        <div className="dot-falling-container">
                                            <div className="dot-falling"/>
                                        </div>
                                    </button>) : (
                                    <button className="btn btn-primary" type="submit" style={{width: "100%"}}
                                    >
                                        {labels?.otherElements?.save}
                                    </button>)}
                            </div>

                        </form>
                        </div>
                    )
                }


            </FormModal>
        </>
    )
}


export default AddRelationModal;
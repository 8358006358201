// import external libraries
import React from "react";
//import assets

// import styling
import "./_style.scss"


const GenericInfoTable = (props) => {
    // infoPath is dictionary that has the field name as the key, and a function regarding data path as
    // the value (the function returns an ARRAY of string). See component driverDetailsPage for example
    const infoFields = props.infoPath
    const labels=props.labels

    //TODO: consider the situation where there is no car details information entered
    return (
        //Don't move out the section-container classname. This is needed for the n-child logic and background color
        <div className="section-card">
                <div className="section-container-content">
                    <h2 className={props.titleFontSize}>{props.section_name}</h2>
                    <table className="car-data-table">
                        <tbody>
                        {Object.keys(infoFields).map((key, i) => {
                                        return <tr key={labels[key]}>
                                            <th style={{"width": "50%"}}>{labels[key]}</th>
                                            <td className="generic-info-table-data">
                                                {infoFields[key](props.data)}
                                            </td>
                                        </tr>
                                })}
                        </tbody>
                    </table>
                </div>
        </div>
    )
}

export default GenericInfoTable
import React from 'react';
import {ReactComponent as ClockIcon} from "../../assets/svg/icon-clock.svg";

export const EditPeriodFuc = (props)=>{
    const handleClick=(e)=>{
        props.setModalState({ type: 'OPEN_MODAL', payload: {
            relationshipId:props.data?.relationshipId,
                carId:props.data?.id,
                vin:props.data?.vin,
                driverId:props.driverId,
                startDate:props.data?.startDt,
                endDate:props.data?.endDt
        } })
    }
    return (
        <button className={"btn-cell"} onClick={handleClick}>
            <ClockIcon className={"clock-icon"}/>
            {props?.labels?.otherElements?.edit}
        </button>
    )
}

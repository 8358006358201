// import external
import React, { useState, useCallback } from "react";
import Moment from "moment";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// import internal
import FormModal from "../FormModal";
import SearchForCar from "../../pages/DamageDetailsPage/SearchForCar";
import { createDamageSchema } from "../Form/FormSchemas";
import {FormField, FormGroup, FormItem, FormLabel, FormMessage, Input} from '../Form/Form';
import { FormControlButtons } from "../Form/FormControlButtons";

// import assets
import FeatureIcon from "../FeatureIcon";
import { ReactComponent as ToolIcon } from "../../assets/svg/icon-tool.svg";

const AddDamageModal = (props) => {
    const { onSubmit, onClose, show, labels, isLoading } = props;
    const [damageData, setDamageData] = useState({
        damageDate: Moment().format("YYYY-MM-DDT00:00:00+00:00"),
        vehicleAax: {},
        messageId: "1234",
    });
    const [validSelectedNewVehicle, setValidSelectedNewVehicle] = useState({});


    // Form logic for the vehicle form (resolver and defaultValues)
    const createDamageForm = useForm({
        resolver: zodResolver(createDamageSchema(labels?.fleetWizard?.form)), defaultValues: {
            vin: validSelectedNewVehicle?.vin,
            damageBranche: "",
            insuranceCompanyName: "",
            insurancePolicyNumber: "",
            damageDate: damageData.damageDate,
            description: "",
            carId: null,
        },
    });


    const onValidCarSelected = useCallback((result) => {
        setValidSelectedNewVehicle(result);
        createDamageForm.setValue('carId', result.additionalCarInformation?.baseData?.carId)
        createDamageForm.setValue('vin', result.vin)
        createDamageForm.trigger(["vin"])
        if (result && Object.keys(result).length >= 0) {
            setDamageData(prevState => ({
                ...prevState,
                vehicleAax: {
                    carId: result.additionalCarInformation?.baseData?.carId
                }
            }));
        }
    }, [createDamageForm]);
    const cancelAddNewDamage=()=>{
        onClose()
        createDamageForm.reset()
    }
    return (
        <FormModal
            show={show}
            onClose={onClose}
            onOutsideClick={onClose}
            title={labels?.damagesPage?.damageInfo?.reportDamage}
            featuredIcon={
                <FeatureIcon>
                    <ToolIcon style={{ stroke: "#344054" }} />
                </FeatureIcon>
            }
        >
            <form onSubmit={createDamageForm.handleSubmit(onSubmit)} className="form">

                <div className="add-damage-modal-container">

                    <FormField control={createDamageForm.control}
                        name="vin"
                        render={({ field, error }) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo?.VIN}
                            </FormLabel>
                            <FormMessage message={error?.message} />
                            <SearchForCar
                                validSelectedVehicle={validSelectedNewVehicle}
                                setValidSelectedVehicle={onValidCarSelected}
                            />
                        </FormItem>)}
                    >

                    </FormField>
                    {/* 
                <div className="form-item" style={{ marginTop: "1em" }}>
                    <div className="label">
                        {labels?.carsPage?.carInfo?.VIN}*
                    </div>
                    <SearchForCar
                        validSelectedVehicle={validSelectedNewVehicle}
                        setValidSelectedVehicle={onValidCarSelected}
                    />
                </div> */}


                    <FormField control={createDamageForm.control}
                        name="damageBranche"
                        render={({ field, error }) => (<FormItem>
                            <FormLabel {...error} >
                                {props.labels?.damagesPage?.damageInfo?.damageCause}
                            </FormLabel>
                            <Input {...field} {...error} type="text" />
                            <FormMessage message={error?.message} />
                        </FormItem>)}
                    >
                    </FormField>

                    <FormGroup>
                        <FormField control={createDamageForm.control}
                            name="insuranceCompanyName"
                            render={({ field, error }) => (<FormItem>
                                <FormLabel {...error} >
                                    {props.labels?.damagesPage?.damageInfo?.insuranceCompanyName}
                                </FormLabel>
                                <Input {...field} {...error} type="text" />
                                <FormMessage message={error?.message} />
                            </FormItem>)}
                        >
                        </FormField>
                        <FormField control={createDamageForm.control}
                            name="insurancePolicyNumber"
                            render={({ field, error }) => (<FormItem>
                                <FormLabel {...error} >
                                    {props.labels?.damagesPage?.damageInfo?.insurancePolicyNumber}
                                </FormLabel>
                                <Input {...field} {...error} type="text" />
                                <FormMessage message={error?.message} />
                            </FormItem>)}
                        >
                        </FormField>

                    </FormGroup>

                    <FormField
                        control={createDamageForm.control}
                        name="damageDate"
                        render={({ field, error }) => (<FormItem>
                            <FormLabel {...error} >
                                {props.labels?.damagesPage?.damageInfo?.damageDate} *
                            </FormLabel>
                            <Input {...field} {...error} type="date" value={new Date()} />
                            <FormMessage message={error?.message} />
                        </FormItem>)}
                    />


                    <FormField control={createDamageForm.control}
                        name="description"
                        render={({ field, error }) => (<FormItem>
                            <FormLabel {...error} >
                                {props.labels?.damagesPage?.damageInfo?.description}
                            </FormLabel>
                            <Input {...field} {...error} type="textArea" placeholder={props.labels?.damagesPage?.damageInfo?.exampleDamageDescription} />
                            <FormMessage message={error?.message} />
                        </FormItem>)}
                    >
                    </FormField>
                </div>
                <FormControlButtons
                    labels={labels}
                    onCancel={cancelAddNewDamage}
                    isLoading={isLoading}
                // onSubmit is not needed. Type is already specified as "submit", and form has a onSubmit funciton
                />
            </form>
        </FormModal>
    );
};


export default AddDamageModal;
import {useSelector} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import {useGetBillPositionsQuery, useLazyGetBillQuery} from "../../redux/apiSlice/billsApiSlice";
import LoadingComponent from "../../components/LoadingComponent";
import {createErrorMessage} from "../../components/AlertContainer/alert.utils";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import React from "react";
import FilterButtonGroup, {FilterButtonItem} from "../../components/FilterButtonGroup";
import {ReactComponent as DownloadIcon} from "../../assets/svg/icon-download.svg";
import ButtonWithIcon from "../../components/ButtonWithIcon";


import "./_style.scss"
import {formatDateIfNotEmpty} from "../../actions/date.actions";
import {dateComparator, dateNowComparator} from "../../components/GridHandler/dates.actions";
import {formatNumber} from "../../actions/formateNumbers.actions";
import BillPositionsByType from "./BillPositionCharts/BillPositionsByType";
import BillPositionsByCar from "./BillPositionCharts/BillPositionsByVehicle";
import GridDownloadButtons from "../../components/GridDownloadButtons";
import { triggerEvent } from "../../actions/eventTracking.actions";


const BillDetailsPage = () => {
    let [triggerBill] = useLazyGetBillQuery();

    // get current labels
    const currentLabels = useSelector(state => state.labels.currentLabels)

    const { billNumber } = useParams();
    const [searchParams]=useSearchParams()
    const billId = Number(searchParams.get("id"));

    //When user clicks on a download button, this function is called to trigger the api call for that bill id
    const downloadDocument = async (billId) => {

        let result = await triggerBill(billId, {preferCacheValue: true});
        if(result.isSuccess) {
            openDownloadLink(result.data.billFile.file, result.data.billFile.documentName);

            // add ga 4
            triggerEvent('file_download', 'click', 'success', 'pdf', 'download_button');
        } else {
            // add ga 4
            triggerEvent('file_download', 'click', 'error', 'pdf', 'download_button');
        }
    };

    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = (file, documentName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        a.download = documentName;
        a.click();
    }

    const [selectedFilter, setSelectedFilter] = React.useState(0)

    // api handling logic starts here
    const {
        data: billData,
        isLoading,
        isSuccess,
        error
    } = useGetBillPositionsQuery(billNumber)
    // Yue: change excelStyle from a function to constant
    const excelStyles = [
            {
                id: 'currency',
                dataType: 'number',
                numberFormat: {
                    format: '"CHF "#,##0.00',
                }
            }
        ]


    const columnDefs = [
        {
            headerName: currentLabels?.carsPage?.carInfo?.brand,
            valueGetter: function (params) {
                return params.data.car?.brand;
            },
            colId:"brand"
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.type,
            valueGetter: function (params) {
                return params.data.car?.type;
            },
            colId:"type"
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.plateNumber,
            colId:"plateNumber",
            valueGetter: function (params) {
                return params.data.car?.licensePlate;
            },
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.VIN,
            colId:"VIN",
            valueGetter: function (params) {
                return params.data.car?.vin;
            }
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.contractStart,
            colId:"contractStart",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.car?.contractStart)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.valuta,
            colId:"valuta",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.valuta)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: { 
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.chargeDescription,
            colId:"chargeDescription",
            valueGetter: function (params) {
                return params.data?.chargeDescription;
            }
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.posAmountNet,
            colId:"posAmountNet",
            valueGetter: function (params) {
                return params.data.posAmountNet;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            cellClass: 'currency'
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.taxRate,
            colId:"taxRate",
            valueGetter: function (params) {
                return params.data.taxRate;
            },
            valueFormatter: function (params) {
                return params.value + " %";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.posAmountTax,
            colId:"posAmountTax",
            valueGetter: function (params) {
                return params.data.posAmountTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            cellClass: 'currency'
        },
        {
            headerName: currentLabels?.billsPage?.billsInfo?.posAmount,
            colId:"posAmount",
            valueGetter: function (params) {
                return params.data.posAmount;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            cellClass: 'currency',
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.startDate,
            colId:"startDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data?.startDate)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: currentLabels?.carsPage?.carInfo?.endDate,
            colId:"endDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data?.endDate)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
    ]



    let content;

    const filterButtons = [
        <FilterButtonItem text="Ansicht pro Kostenart" id={0}/>,
        <FilterButtonItem text="Ansicht pro Fahrzeug" id={1}/>,
    ]

    const handleButtonClick = (item) => {
        setSelectedFilter(item.props?.id)
    }
    if (isLoading) {
        content = <LoadingComponent show/>
    }
    else if (isSuccess && billData.billPositions?.length > 0) {
        let {billPositions, uniqueFields, uniqueFieldDescriptions} = billData
        // Find earliest and latest dates to display in bill period
        // We first filter out null date values, otherwise they might cause the first value to be null, even if there are others that are defined
        const earliestDate = [...billPositions].filter((bill) => bill.startDate).sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0]?.startDate
        const latestDate = [...billPositions].filter((bill) => bill.endDate).sort((a, b) => new Date(b.endDate) - new Date(a.endDate))[0]?.endDate


        // add the customer defined (Kundenfelden) columns to ag grid column definition
        uniqueFields.forEach((columnName, index) => {
            let headerName=currentLabels.labels?.carsPage?.carInfo[columnName] || uniqueFieldDescriptions[index]
            let newColum = {
                headerName: headerName,
                colId:columnName,
                valueGetter: function (params) {
                    return params.data?.car?.customerFields[columnName];
                },
            }
            columnDefs.push(newColum)
            ;
        });

        const positionsByType = []

        billPositions.forEach((position) => {
            const index = positionsByType.findIndex((item) => item.description === position.chargeDescription)
            if (index === -1) {
                positionsByType.push({
                    description: position.chargeDescription,
                    amount: position.posAmountNet
                })
            }
            else {
                positionsByType[index].amount += position.posAmountNet
            }
        })

        const positionsByCar = []

        billPositions.forEach((position) => {
            const index = positionsByCar.findIndex((item) => item.id === position?.car?.id)
            //If car doesn't exist in list yet, create an entry
            if (index === -1) {
                positionsByCar.push({
                    id: position.car?.id,
                    vin: position.car?.vin,
                    licensePlate: position.car?.licensePlate,
                    brand: position.car?.brand,
                    carName: position.car?.brand + " " + position.car?.type,
                    type: position.car?.type,
                    totalAmount: position.posAmountNet
                })
                if(!positionsByCar[positionsByCar.length - 1][position.chargeDescription]) {
                    positionsByCar[positionsByCar.length - 1][position.chargeDescription] = 0
                }
                positionsByCar[positionsByCar.length - 1][position.chargeDescription] += position.posAmountNet
            }
            else {
                positionsByCar[index].totalAmount += position.posAmountNet
                if(!positionsByCar[index][position.chargeDescription]) {
                    positionsByCar[index][position.chargeDescription] = 0
                }
                positionsByCar[index][position.chargeDescription] += position.posAmountNet
            }
        })




            content = (
            <div className="bill-details-page-content">
                <h1>{currentLabels?.billsPage?.titles?.billPositions}</h1>


                <div className="bill-info-cards-container">
                    <div className="blank-card bill-info-card">
                        <div className="bill-info-card-item">
                            <div className="bill-info-card-item-title">
                                {currentLabels?.costPage?.costInfo?.invoiceNumber}
                            </div>
                            <div className="bill-info-card-item-value">
                                {billNumber}
                            </div>
                        </div>
                    </div>

                    <div className="blank-card bill-info-card">
                        <div className="bill-info-card-item">
                            <div className="bill-info-card-item-title">
                                {currentLabels?.billsPage?.billsInfo?.billingPeriod}
                            </div>
                            <div className="bill-info-card-item-value">
                                {formatDateIfNotEmpty(earliestDate)} - {formatDateIfNotEmpty(latestDate)}
                            </div>
                        </div>
                    </div>

                    <div className="blank-card bill-info-card">
                        <div className="bill-info-card-item">
                            <div className="bill-info-card-item-title">
                                {currentLabels?.billsPage?.billsInfo?.status}
                            </div>
                            <div className="bill-info-card-item-value">
                                {billPositions[0]?.status.charAt(0).toUpperCase() + billPositions[0]?.status.slice(1)}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="main-container">

                    <div className="grid-download-container">
                        <div className="blank-card grid-download-content">
                            <span className="header">
                                <div className="title">{currentLabels?.billsPage?.titles?.downloadBillDocument}</div>
                            </span>
                            <ButtonWithIcon buttonStyle="btn-primary"
                                            text={currentLabels.otherElements?.downloadPDF}
                                            size="sm"
                                            clickFunc={() => downloadDocument(billId)}

                            >
                                <DownloadIcon className="download-icon"/>
                            </ButtonWithIcon>
                            <span className="header">
                                <div className="title">{currentLabels.billsPage?.titles?.downloadPositionData}</div>
                                <span className="badge-neutral" style={{lineHeight:"1.75"}}>{billPositions.length + " " + currentLabels?.billsPage?.titles?.positions}</span>
                            </span>

                            <GridDownloadButtons data={billPositions} columnDefs={columnDefs} labels={currentLabels} fileName={"upto_bill_" + billNumber} excelStyles={excelStyles} />

                        </div>
                    </div>

                    <div className="chart-container">

                        <div className="header-buttons-container">
                            <FilterButtonGroup items={filterButtons} onFilterChange={handleButtonClick}/>
                        </div>


                        <div className="blank-card chart-card" >
                            {selectedFilter === 0 ?
                                currentLabels?.billsPage?.billsInfo?.totalCostsPerCostType
                                :
                                currentLabels?.billsPage?.billsInfo?.totalCostsPerVehicle
                            }
                            <div className="chart-wrapper">
                                {selectedFilter === 0 ?
                                    <BillPositionsByType
                                        data={positionsByType}
                                        labels={currentLabels}
                                    /> :
                                    <BillPositionsByCar
                                        data={positionsByCar}
                                        positionsData={positionsByType}
                                    />}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    else {
        content = createErrorMessage(error?.message, currentLabels?.billsPage?.titles?.billPositions);
    }

    return (
        <div className="content-side content-side-padding">
            <BreadcrumbNav/>
            {content}
        </div>
    )
}

export default BillDetailsPage;
// import styling
import "./_style.scss";

// import internal and assets
import WarningContainer from "../../WarningContainer";
import {useGetReportStatusQuery} from "../../../redux/apiSlice/reportApiSlice";
import {ReactComponent as CheckIcon} from "../../../assets/svg/icon-check.svg"

// import external
import React, {useState} from "react";

const DocumentGenerationNotification = (props) => {

    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)

    // Don't cache this, so it's re-fetched every time the page is visited
    const {
        data: workflowData,
        refetch,
        isFetching
    } = useGetReportStatusQuery(null, {refetchOnMountOrArgChange: true});

    // If user has just requested a report, the workflow id will be stored here
    const mostRecentWorkflow = sessionStorage.getItem("mostRecentWorkflow");

    // Check if the workflow with that id has already finished
    if(workflowData?.some(workflow => String(workflow?.workflowId) === mostRecentWorkflow && workflow?.workflowStatus === 'Done')){
        // Display the green alert with success message
        setDisplaySuccessMessage(true)
        // Tell parent to re-fetch grid data on document overview page
        props.refetchData()
        // Remove the workflow id from session storage
        sessionStorage.removeItem("mostRecentWorkflow")
    }


    // True if there is currently a workflow in progress
    const workflowInProgress = workflowData?.some(workflow => workflow?.workflowStatus === 'In Progress')

    const handleRefetch = () => {
        // Re-fetch workflow data
        refetch().then((response) => {
            // Check if now all workflows are finished (there are none that are "In Progress")
            if(!response?.data?.some(workflow => workflow?.workflowStatus === 'In Progress')){
                // Display the green alert with success message
                setDisplaySuccessMessage(true)
                // Tell parent to refetch grid data on document overview page
                props.refetchData()
            }

        })
    }

    // If user clicked on "Refresh" and now the workflow is finished, display success message
    if(displaySuccessMessage){
        return (
                <WarningContainer
                    title={props.labels?.documentPage?.documentInfo?.success}
                    className="warning-container success"
                    icon={<CheckIcon style={{stroke:"green",height:"18px",width:"18px"}}/>}
                >
                    {props.labels?.documentPage?.documentInfo?.documentGenerationSuccessMessage}
                </WarningContainer>
            )

    }
    // If there is currently a workflow in Progress, display alert with "Refresh" button
    else if(workflowInProgress){
        return (
            <WarningContainer
                title={props.labels?.warningMessage?.attention}
            >
                <div className="document-generation-content">
                    {props.labels?.documentPage?.documentInfo?.documentGenerationMessage}

                    <div className="button-container">
                        {isFetching ?
                            <button className="btn btn-primary btn-disabled" style={{width:"100%"}}>
                                <div className="dot-falling-container">
                                    <div className="dot-falling"/>
                                </div>
                            </button>
                            :
                            <button onClick={handleRefetch} className="btn btn-orange sm" style={{width:"100%",height:"38px"}}>
                                {props.labels?.documentPage?.documentInfo?.refresh}
                            </button>
                        }
                    </div>
                </div>
            </WarningContainer>
        )
    }
    else {
        return null;
    }

}


export default DocumentGenerationNotification;
// import external
import React from "react";
import { Link } from "react-router-dom";

// import internal slices
import { useGetDriversQuery } from "../../redux/apiSlice/driverApiSlice";

// import internal helper function
import { isCarValidAndUnique } from "../../actions/isCarValid.actions"

// import assets
import {ReactComponent as DriverIcon} from "../../assets/svg/icon-active-driver.svg";
import {ReactComponent as InactiveDriverIcon} from "../../assets/svg/icon-inactive-driver.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/svg/icon-magnifier.svg";
import {ReactComponent as NewDriverIcon} from "../../assets/svg/icon-new-driver.svg";

// import react components
import GridHandler from "../GridHandler";
import LoadingComponent from "../LoadingComponent";
import { createErrorMessage } from "../AlertContainer/alert.utils";
import KeyInfoCard from "../KeyInfoCard";
import { findNewestValidEntry } from "../../actions/date.actions";
import FilterTypes from "../GridHandler/FilterUtil/FilterTypes";
import GridTitle from "../GridHandler/GridTitle";
import {FilterButtonItem} from "../FilterButtonGroup";
import {newStatusCellRenderer} from "../GridHandler/CellRenderers/newStatusCellRenderer";
import {checkAgentLoginToken} from "../../actions/token.actions";

// import internal styles
//import "./_style.scss"

/**
 * loads data for the driver overview and defines what to show
 * @param {props.labels} languageLabels - language labels used for multi-language feature
 * @param {props.title} title - title used for this section
 * @returns
 */
const DriversOverview = (props) => {
    // load cars from endpoint
    const {
        data: drivers,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDriversQuery();
    // this function is needed to convert statud id to text
    let statusIdToText= (params) => {
        const statusId = params.data.childCustomer?.status?.id;
        if (statusId === 1) {
            return props.labels?.statusCode?.active
        }
        else if (statusId === 0 || statusId === 5) {
            return props.labels?.statusCode?.new
        }
        else {
            return props.labels?.statusCode?.inactive
        }
    }

    // the columns in addColDefsForAgent are needed when batch add drivers or link drivers with cars. They are otherwise
    // meaningless for the user. Therefore, they are only displayed for agents.
    const addColDefsForAgent=[
        {
            headerName: "driverCompanyRelationshipId",
            colId:"relationshipId",
            valueGetter: (params) => params.data.id,

        },
        {
            headerName: "driverId",
            colId:"childCustomerId",
            valueGetter: (params) => params.data.childCustomer?.id,

        },
        {
            headerName: "driverCustomerId",
            colId:"childCustomerCustomerId",
            valueGetter: (params) => params.data.childCustomer?.customerId,
        },

        {
            headerName: "carList1stCarId",
            colId:"carList1stCarId",
            valueGetter: (params) => {
                if(params.data?.carList && params.data?.carList?.length>0){
                    return params.data?.carList[0]?.id
                }
                return null
            },
        },
        {
            headerName: "driverCarRelationshipId",
            colId:"driverCarRelationshipId",
            valueGetter: (params) => {
                if(params.data?.carList && params.data?.carList?.length>0){
                    return params.data?.carList[0]?.relationshipId
                }
                return null
            },
        }
    ]
    // At the moment, all cars (with no endDate) are shown to one driver.
    // It is possible
    // each column definition results in one column.
    let columnDefs = [
        {
            cellRenderer: function (params) {
                return (
                    <Link to={"/drivers/" + params.data.id}>
                        <MagnifierIcon className="grid-icon" alt="open row details icon"></MagnifierIcon>
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            width:65,
            suppressMenu: true,
            colId:"icon",
            suppressMovable:true
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.firstName, sort: 'asc',
            valueGetter: (params) => params.data.childCustomer?.firstName,
            colId:"firstName",
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.familyName,
            colId:"familyName",
            valueGetter: (params) => params.data.childCustomer?.lastName,
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.role,
            colId:"role",
            valueGetter: function (params) {
                return params.data.roleName
            }
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.status,
            colId:"status",
            valueGetter: (params) => params.data.childCustomer?.status.id,
            valueFormatter:(params) =>statusIdToText(params),
            filterValueGetter: (params) =>statusIdToText(params),
            cellRenderer: (params) => {
                const statusId = params.data.childCustomer?.status?.id;
                // If statusId 1 then active
                if (statusId === 1) {
                    return newStatusCellRenderer({ value: statusIdToText(params), color: "positive"});
                }
                // If statusId 0 or 5 then new
                else if (statusId === 0 || statusId === 5){
                    return newStatusCellRenderer({ value: statusIdToText(params), color: "orange"});
                }
                // Else inactive
                else {
                    return newStatusCellRenderer({ value: statusIdToText(params), color: "negative"});
                }
            }
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.carBrand,
            colId:"carBrand",
            valueGetter: function (params) {
                let allCarIds = [];
                let value = "";
                // does the driver have associated cars?
                if (params?.data?.carList?.length > 0) {
                    params?.data?.carList?.forEach(element => {
                        // should car be added to the column value?
                        if (isCarValidAndUnique(element, allCarIds)) {
                            // add all distinct cars to string
                            value += value === "" ? element.brand : ", " + element.brand
                            allCarIds.push(element.id);
                        }
                    });
                }
                return value.trim();
            }
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.carType,
            colId:"carType",
            valueGetter: function (params) {
                let allCarIds = [];
                let value = "";
                // does the driver have associated cars?
                if (params?.data?.carList?.length > 0) {
                    params.data.carList.forEach(element => {
                        // should car be added to the column value?
                        if (isCarValidAndUnique(element, allCarIds)) {
                            // add all distinct cars to string
                            value += value === "" ? element.type : ", " + element.type
                            allCarIds.push(element.id);
                        }
                    });
                }
                return value.trim();
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId:"plateNumber",
            valueGetter: function (params) {
                let allCarIds = [];
                let value = "";
                // does the driver have associated cars?
                if (params?.data?.carList?.length > 0) {
                    params?.data?.carList?.forEach(element => {
                        // should car be added to the column value?
                        if (isCarValidAndUnique(element, allCarIds)) {
                            let newestLicencePlateId = findNewestValidEntry(element?.licensePlates);
                            // check if we find a valid (newest) plate
                            if (newestLicencePlateId !== null) {
                                let newestPlate = element.licensePlates[newestLicencePlateId]?.licencePlate
                                // check if the place number exist
                                if (newestPlate) {
                                    // add all distinct car plates to string
                                    value += value === "" ? newestPlate : ", " + newestPlate
                                    allCarIds.push(element.id);
                                }
                            }
                        }
                    });
                }
                return value.trim();
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            colId:"VIN",
            valueGetter: function (params) {
                let allCarIds = [];
                let value = "";
                // does the driver have associated cars?
                if (params?.data?.carList?.length > 0) {
                    params?.data?.carList?.forEach(element => {
                        // should car be added to the column value?
                        if (isCarValidAndUnique(element, allCarIds)) {
                            // add all distinct VINs to string
                            value += value === "" ? element.vin : ", " + element.vin
                            allCarIds.push(element.id);
                        }
                    });
                }
                return value.trim();
            },
            cellRenderer: (params) => {
                // If params.value is empty, show nothing
                if (!params.value || !params.data?.carList?.length) return null
                return (
                    <Link to={"/cars/" + params.data?.carList[0]?.vin}>
                        <span className="link-with-icon">
                            {params.value}
                        </span>
                    </Link>
                )
            }
        },



    ];

    let content;
    // show loading text (spinner) while loading
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) { // handle successful data loading
        let isAgentLogin = checkAgentLoginToken();
        if (isAgentLogin){
            columnDefs=columnDefs.concat(addColDefsForAgent)
        }
        // Filter out drivers with status id 99 ("als gelöscht markiert") and 3 ("storniert")
        const filteredDrivers = drivers.filter(driver => driver?.childCustomer?.status?.id !== 99 && driver?.childCustomer?.status?.id !== 3);

        // show grid with the data
        if (filteredDrivers && filteredDrivers?.length > 0) {
            let numActiveDrivers = filteredDrivers.filter(driver => driver?.childCustomer?.status?.id === 1)?.length;
            let numNewDrivers = drivers.filter(driver => (driver?.childCustomer?.status?.id === 0 || driver?.childCustomer?.status?.id === 5))?.length;
            content = (
                <>
                    <h1>{props.title}</h1>
                    <div className="number-cards-container">
                        <KeyInfoCard title={props.labels?.driverPage?.keyNumbers?.active}
                            number={numActiveDrivers}
                            cardStyle={"card-positive"}
                            unit={""}
                        >
                            <DriverIcon className={"card-icon"}></DriverIcon>
                        </KeyInfoCard>

                        <KeyInfoCard title={props.labels?.driverPage?.keyNumbers?.new}
                                     number={numNewDrivers}
                                     cardStyle={"card-orange"}
                                     unit={""}
                        >
                            <NewDriverIcon className={"card-icon"}></NewDriverIcon>
                        </KeyInfoCard>

                        <KeyInfoCard title={props.labels?.driverPage?.keyNumbers?.nonActive}
                            number={filteredDrivers.length - numActiveDrivers - numNewDrivers}
                            cardStyle={"card-negative"}
                            unit={""}
                        >
                            <InactiveDriverIcon className={"card-icon"}></InactiveDriverIcon>
                        </KeyInfoCard>

                    </div>
                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.driverPage?.titles?.driverTableTitle}
                                subtitle={props.labels?.driverPage?.titles?.driverTableSubtitle}
                                badgeText={filteredDrivers.length + " " + props.labels?.driverPage?.titles?.drivers}
                            />}
                        gridName={"upto_driver_overview"}
                        data={filteredDrivers}
                        columnDefs={columnDefs}
                        downloadable={true}
                        fileName={`upto_${props.title}`}
                        downloadTextCsv={props.labels.otherElements.downloadCsv}
                        downloadTextExcel={props.labels.otherElements.downloadExcel}
                        filterItems={[
                            <FilterButtonItem id={0} text={props.labels.driverPage?.filter?.all} filterType={FilterTypes.ALL}/>,
                            <FilterButtonItem id={1} text={props.labels.driverPage?.filter?.activeOnly} filterType={FilterTypes.DRIVERS_ONLY_ACTIVE}/>,
                        ]}
                        defaultFilterType={FilterTypes.DRIVERS_ONLY_ACTIVE}
                    ></GridHandler>
                </>
            )
        } else {
            content = createErrorMessage(error, props.title, "h1", props.labels);
        }
    } else if (isError) {
        // show different error messages based on status code
        content = createErrorMessage(error, props.title, "h1", props.labels);
    }
    return (
        <>
            {content}
        </>
    )
}

export default DriversOverview
// import external
import React, {useMemo} from "react";

// import internal helper function
import {isRelationshipUnique} from "../../actions/isCarValid.actions"
import {findNewestValidEntry, formatDateIfNotEmpty} from "../../actions/date.actions"

// import styling
import "./_style.scss"

// import internal react components
import GridHandler from "../GridHandler";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import {EditPeriodFuc} from "../EditPeriod";
import PropTypes from "prop-types";
import AddRelationModal from "../../pages/DriverDetailsPage/AddRelationModal";


const DriverCarListTable = (props) => {

    // Find last connection to update if user wants to add a new connection
    const lastConnection = props?.cars_data?.length ? props.cars_data?.reduce((prev, current) => (prev.startDt > current.startDt) ? prev : current) : null;


    DriverCarListTable.propTypes = {
        setModalState: PropTypes.func.isRequired, // array of columns
        labels: PropTypes.object.isRequired, // array of the data that fills the grid
        driverId: PropTypes.number.isRequired, // this id is used for calling api to update driver car link time range
        section_name: PropTypes.string.isRequired,
        cars_data: PropTypes.array.isRequired
    }
    // wrap columnDefs in useMemo to skip the rerendering of the grid when showing Modal of changing driver time span
    const columnDefs = useMemo(
        () => [
            {
                headerName: props.labels?.carsPage?.carInfo?.brand,
                valueGetter: (params) => params.data?.brand,
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.type,
                valueGetter: (params) => params.data?.type,
            },
            {
                headerName: props.labels?.driverPage?.driverInfo?.carModel,
                valueGetter: (params) => params.data?.model,
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.plateNumber,
                valueGetter: (params) => {
                    // licencePlate is an array. We need to identify the newest plate (most recent fromDate)
                    const plateData = params.data.licensePlates
                    const newestPlateIndex = findNewestValidEntry(plateData)
                    const newestPlate = plateData[newestPlateIndex]
                    // if newestPlateIndex is null, newestPlate?.licencePlate is undefined. No error
                    return newestPlate?.licencePlate
                }
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.VIN,
                valueGetter: (params) => params.data?.vin
            },
            {
                headerName: props.labels?.driverPage?.driverInfo?.driverCarLinkStart,
                valueGetter: (params) => formatDateIfNotEmpty(params.data?.startDt),
                filter: 'agDateColumnFilter',
                // sorting related
                comparator: dateComparator,
                // filter related
                filterParams: {
                    // provide comparator function
                    comparator: dateNowComparator
                }
            },
            {
                headerName: props.labels?.driverPage?.driverInfo?.driverCarLinkEnd,
                valueGetter: (params) => formatDateIfNotEmpty(params.data?.endDt),
                filter: 'agDateColumnFilter',
                // sorting related
                comparator: dateComparator,
                // filter related
                filterParams: {
                    // provide comparator function
                    comparator: dateNowComparator
                }
            },
            {
                colId: 'periodEditor',
                minWidth: 100,
                editable: false,
                sortable: false,
                hasPermission: props.hasPermission,
                cellRenderer: EditPeriodFuc,
                cellRendererParams: {
                    setModalState: props.setModalState,
                    driverId: props.driverId,
                    labels: props.labels
                },
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        ], [props.driverId, props.labels, props.setModalState]);

    let content

    // Yue: no long need to check if car is valid because we will add the functionality to make car invalid
    // now only remove duplicates
    let allRelationshipIds = [];
    let validCarIndexList = []


    props.cars_data.forEach((element, i) => {
        // should car be added to the column value?
        if (isRelationshipUnique(element.relationshipId, allRelationshipIds)) {
            allRelationshipIds.push(element.relationshipId);
            validCarIndexList.push(i)
        }
    });

    // wrap filteredCar in useMemo to skip the re-rendering of the grid when showing Modal of changing driver time span
    let filteredCar = useMemo(
        () => props.cars_data.filter((car, i) => validCarIndexList.includes(i))
        , [props.cars_data])

    content = (
        <div className="section-card">
            <div className="section-container-content">
                <h2>{props.section_name}</h2>
                <GridHandler
                    data={filteredCar}
                    gridName={`upto_driver_${props.driverId}_${props.section_name}`}
                    columnDefs={columnDefs}
                    downloadable={true}
                    fileName={`upto_driver_${props.driverId}_${props.section_name}`}
                    downloadTextCsv={props.labels.otherElements.downloadCsv}
                    downloadTextExcel={props.labels.otherElements.downloadExcel}
                    notHideUnusedCol={true}
                    headerContent={<AddRelationModal hasPermission={props?.hasPermission} labels={props?.labels}
                                                     lastConnection={lastConnection}/>}
                ></GridHandler>
            </div>
        </div>
    )

    return (
        <>
            {content}
        </>
    )
}

export default DriverCarListTable
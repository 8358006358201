// define functions needed for sorting date columns in ag grid
import Moment from "moment";

// Converts a date in various formats to a date object
export function convertToDate(date){
    if (!date) return null;

    let dateAsDate;
    // Make sure date is instance of Date object
    if (date instanceof Date) {
        dateAsDate = date; // already a Date object
    }
    else if(date.length === 10 && date.includes('.')){ // dd.mm.yyyy
        const dateParts = date.split('.');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        dateAsDate = new Date(year, month, day);
    }
    else if(date.length === 10 && date.includes('-')) { // yyyy-mm-dd
        const dateParts = date.split('-');
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        dateAsDate = new Date(year, month, day);
    }
    else {
        dateAsDate = Moment(date).toDate();
    }
    return dateAsDate;
}

// Converts a date to unix timestamp so it can be compared as a number
export function dateToComparableNumber(date) {
    if (!date) return null;
    const dateAsDate = convertToDate(date);
    // Return date as unix timestamp
    return dateAsDate.valueOf();
}

/**
 * needed for sorting date columns in ag grid.
 * @param {Date} filterLocalDateAtMidnight
 * @param {string} cellValue
 * @returns {number}
 */
export function dateComparator(date1, date2) {
    const date1Number = dateToComparableNumber(date1);
    const date2Number = dateToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
}



/**
 * needed for date filtering in ag grid. By default, the date filter 'agDateColumnFilter' assumes JavaScript Date objects are used.
 * if not, a function is needed to perform date comparison
 * @param {Date} filterLocalDateAtMidnight
 * @param {string} cellValue
 * @returns {number}
 */
export function dateNowComparator(filterLocalDateAtMidnight, cellValue){
    if (cellValue == null) {
        return 0;
    }
    const dateAsDate = convertToDate(cellValue);

    const dateAsString = Moment(dateAsDate).format("DD.MM.YYYY");

    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('.');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);
    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
}
import Carousel from 'react-bootstrap/Carousel';

// import styling
import "./_style.scss"

// import internal slices


import GeneralCreditCard from "../../pages/FuelCardDetailsPage/GeneralCreditCard";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {useGetFuelCardsQuery} from "../../redux/apiSlice/fuelCardApiSlice";

/**
 * Use AG grid to show the list of fuelcards related to a car
 */


const FuelCardCarousel = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // API handling logic starts here
    const {
        data: fuelCards,
        isSuccess,
    } = useGetFuelCardsQuery({vin: props.vin})

    let content;
    if (isSuccess) {
        if(fuelCards.length === 1){
            content = (
                <div className={"info-card"}>
                 <span className="header" style={{marginBottom: "1em"}}>
                {currentLabels?.carsPage?.titles?.linkedFuelCards}
                            </span>
                    <GeneralCreditCard labels={currentLabels}
                                       data={fuelCards[0]}></GeneralCreditCard>
                </div>
            )
        }else if (fuelCards.length > 1) {
            content = (
                <div className={"info-card"}>
                 <span className="header" style={{marginBottom: "1em"}}>
                                {currentLabels.carsPage?.titles?.linkedFuelCards}
                            </span>
                    <Carousel slide={false} interval={null} indicators={true} activeIndex={index} onSelect={handleSelect}>
                        {fuelCards.map((fuelCard) => {
                            return (
                                <Carousel.Item>
                                    <GeneralCreditCard labels={currentLabels}
                                                       data={fuelCard}></GeneralCreditCard>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </div>
            )
        }
    }
    return (
        <>
            {content}

        </>
    )
}

export default FuelCardCarousel;




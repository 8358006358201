import "./_style.scss"
import React, {useEffect} from "react";
import { createPortal } from 'react-dom'
import {ReactComponent as CheckIcon} from "../../../assets/svg/icon-check.svg";


const Combobox = ({ children, onSelect, closeOnSelect, ...props }) => {

    Combobox.defaultProps = {
        closeOnSelect: true
    }

    // Stores search input value
    const [search, setSearch] = React.useState("");
    // Whether the "select" list is open
    const [open, setOpen] = React.useState(false);
    // The selected item (usually the ID)
    const [selected, setSelected] = React.useState(props.selected || null);

    // Refs to the input and list elements
    const inputRef = React.useRef(null);
    const listRef = React.useRef(null);


    // Close the list when the user scrolls outside of combobox (and combobox list)
    useEffect(() => {
        // Detects if an event occurs outside of the Combobox
        const checkIfEventOutside = (event) => {
            if (open) {
                const target = event.target;

                // Check if the event is outside both the input and the list
                const isOutside = !inputRef.current.contains(target) &&
                    !(listRef.current && listRef.current.contains(target));

                // Close the combobox if the event happened outside
                if (isOutside) {
                    setOpen(false);
                }
            }
        };


        // Listen for scroll events on the document, capturing phase to catch all scrolls
        document.addEventListener('scroll', checkIfEventOutside, true);

        document.addEventListener('mousedown', checkIfEventOutside)

        // Cleanup
        return () => {
            document.removeEventListener('scroll', checkIfEventOutside, true);
            document.removeEventListener('mousedown', checkIfEventOutside)
        };
    }, [open, inputRef, listRef]);


    useEffect(() => {
        // Places the ComboboxList based on input location
        if (open && inputRef.current && listRef.current) {
            const inputRect = inputRef.current.getBoundingClientRect();
            const listRect =
                listRef.current.getBoundingClientRect();
            const spaceBelow = window.innerHeight - inputRect.bottom;
            const spaceAbove =
                inputRect.top;
            // Position the ComboboxList above the input
            if (spaceBelow < listRect.height && spaceAbove > listRect.height) {
                listRef.current.style.bottom = `${window.innerHeight - inputRect.top}px`;
            }
            // Position the ComboboxList below the input
            else {
                listRef.current.style.top = `${inputRect.bottom}px`;
            }
            listRef.current.style.width = `${inputRef.current.offsetWidth + 5}px`;
            listRef.current.style.left = `${inputRect.left + 4}px`;
        }
    }, )

    // When an item is selected, close the list and call the onSelect callback
    const handleSelect = (value) => {
        // If user unselected, we also reset the search field text
        if (value === null) {
            if (inputRef?.current) inputRef.current.value = "";
            setSearch("")
        }
        setSelected(value);
        if (closeOnSelect) setOpen(false);
        onSelect(value)

    }

    // Inject / Pass props to children
    const injectProps = (child) => {
        if (child.type.displayName === 'ComboboxList') {
            return React.cloneElement(child, {
                setSearch,
                search,
                open,
                selected,
                setSelected,
                onSelect: handleSelect,
                ref: listRef,
            })
        }
        if (child.type.displayName === 'ComboboxInput') {
            return React.cloneElement(child, {
                search,
                setSearch,
                open,
                setOpen,
                selected,
                setSelected,
                ref: inputRef,
            })
        }
    }

    let {selectedValue, ...rest}= props;

    const childrenNoList = children.filter((child) => child.type.displayName !== 'ComboboxList')
    const listChild = children.filter((child) => child.type.displayName === 'ComboboxList')


    return (
            <div className="combobox" {...rest}>
                {React.Children.map(childrenNoList, injectProps)}

                {createPortal(
                    React.Children.map(listChild, injectProps),
                    document.body
                )}

            </div>
    )
}


const ComboboxInput = React.forwardRef((props, ref) => {
    const inputRef = ref;
    let {setSearch, setOpen, selectedValue, ...rest} = props
    // If an item is selected, set the input field (and search state) value to the "selected" value
    // (we don't actually set it to the selected value, because we use the item ID as value, so we instead have a
    // "selectedValue" prop that we can use to set the input field value (e.g. the name of the selected item))
    useEffect(() =>{
        if (props.selected) {
            inputRef.current.value = selectedValue || props.selected;
            props.setSearch(selectedValue || props.selected);
        }
        // eslint-disable-next-line
    }, [props.selected])

    return (
        <input {...rest} ref={inputRef} className="input-field-form"
               onChange={(e) => {
                   setSearch(e.target.value);
                   setOpen(true);
               }}
               onFocus={() => setOpen(true)}

               style={{fontWeight: props.search === selectedValue ? 'bold' : 'normal'}}
        />
    )
})

ComboboxInput.displayName = 'ComboboxInput';

const ComboboxList = React.forwardRef(({children, ...props}, ref) => {
    const listRef = ref;

    let {open, onSelect, selected, setSelected, noResults, ...rest} = props;

    if (!props.open) return null;

    // Separate ComboboxItems and ComboboxMenu (if available) from the component children
    const comboboxItems = React.Children.toArray(children).filter((child) => child?.type?.displayName === 'ComboboxItem')

    const comboboxMenu = React.Children.toArray(children).find((child) => child?.type?.displayName === 'ComboboxMenu')

    // Filter combobox items based on search
    const filteredItems = comboboxItems.filter((child) => {
        if (props.search) {
            let searchableValue = child.props?.['search-values'] || child.props?.children;

            // If child props is null or undefined, return empty string
            if (!searchableValue && searchableValue !== 0) return "";
            // If child props children is string, convert it to lower case
            else if (typeof searchableValue === 'string') {
                // Set Search and item searchableValue to lowercase and remove spaces
                searchableValue = searchableValue.replace(/\s/g, '').toLowerCase()

                return searchableValue.includes(String(props?.search).replace(/\s/g, '').toLowerCase());
            }
            else if (typeof child?.props?.children === 'number') {
                return String(searchableValue).toLowerCase().includes(String(props?.search).toLowerCase());
            }
            // If child props children is array, join it to a string
            const lowerCaseChildren = searchableValue?.map((child) => child.toLowerCase());
            const childrenString = lowerCaseChildren.join("");

            return childrenString.includes(String(props.search).toLowerCase());
        }
        return true;
    })

    // If no results, show "No results found" message
    if (filteredItems.length === 0)  {
        return (
            <div className="combobox-list-container" ref={listRef}>
                {noResults || "No results found"}
            </div>
        )
    }

    const injectProps = (child) => {
        if (child.type.displayName === 'ComboboxItem') {
            return React.cloneElement(child, {
                onSelect: onSelect,
                selected: selected,
                setSelected: setSelected,
            })
        }
        else if (child.type.displayName === 'ComboboxMenu') {
            return React.cloneElement(child, {
                selected: selected
            })
        }
    }

    return (
        <div {...rest} className="combobox-list-container" ref={listRef}>
            <div className="combobox-list"  data-menu={!!comboboxMenu && !!selected}>
                {React.Children.map(filteredItems, injectProps)}
            </div>

            {selected ? React.Children.map(comboboxMenu, injectProps) : null}
        </div>
    )
})

ComboboxList.displayName = 'ComboboxList';

// If used, shows a menu for the currently selected ComboboxItem
const ComboboxMenu = ({children, ...props}) => {
    const { selected } = props;

    if (!selected) return null;

    return (
        <div className="combobox-group">
            {children}
        </div>
    )

}

ComboboxMenu.displayName = 'ComboboxMenu'

const ComboboxItem = ({ children, ...props }) => {
    let {selected, setSelected, disabled, onSelect, value, ...rest} = props;
    // If the item is selected, add a checkmark icon
    const isSelected = (selected === value && !disabled && selected !== null);

    return (
        <div className="combobox-item"
             {...rest}
             data-disabled={disabled}
             data-selected={isSelected}
             onClick={() => {
                 // If the item is disabled, do nothing
                if (disabled) return;
                // If the item is already selected, deselect it
                if (selected === value) {
                    setSelected(null);
                    onSelect(null);
                    return;
                }
                // Otherwise, select it
                onSelect(value);


            }}
        >
            {children}

            {isSelected && <CheckIcon className="selected-check" />}
        </div>
    )
}

ComboboxItem.displayName = 'ComboboxItem';





export { Combobox, ComboboxInput, ComboboxList, ComboboxItem, ComboboxMenu };




// import external library
import React, { useState } from "react";
import SearchableDropdown from "../../../components/SearchableDropdown";
import { useLazyGetCarQuery } from "../../../redux/apiSlice/carApiSlice";
import { FormInputItem, InformationItem } from "../index";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// import assets


const SearchForCar = ({ setValidSelectedVehicle, validSelectedVehicle, ...props }) => {
    SearchForCar.propTypes = {
        validSelectedVehicle: PropTypes.object, // used to save the data of selected car
        setValidSelectedVehicle: PropTypes.func,// from parent: pass the data of selected car out
        originalData: PropTypes.object, //
    }
    // states
    // get the labels
    const labels = useSelector(state => state.labels.currentLabels)
    const [searchValue, setSearchValue] = useState(validSelectedVehicle?.vin);
    const [options, setOptions] = useState([{ "warning": labels?.warningMessage?.minLength }])
    const [isLoading, setIsLoading] = useState(false)

    // api for searching
    const [triggerGetCar] = useLazyGetCarQuery(searchValue);
    const [debounceTimer, setDebounceTimer] = useState(null);


    /*
    Purpose: debounce call
    */
    const debounceSearch = (searchTerm) => {
        // Clear the previous timeout if it exists

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        const newTimer = setTimeout(async () => {
            setIsLoading(true)
            let result = await triggerGetCar(searchTerm);
            // inside async function, the code below will wait
            // until the triggerGetCar is finished and the promise is resolved
            setIsLoading(false)
            if (result.isSuccess) {
                setOptions(result?.data?.cars);
                // if(props.onReturnResult){
                //     props.onReturnResult(result.data)
                // }
            }
            if (result.isError) {
                // if(props.onReturnResult){
                //     props.onReturnResult([])
                // }
                setOptions([{ "warning": labels.warningMessage.noResult }])
            }
        }, 1000);
        setDebounceTimer(newTimer);
    };
    // -----------update the attributes needed to update the damage details page-------------//
    // also needed for this page.
    let otherLabels = ["licencePlate", "brand", "type"]
    let keyLabel = "vin"


    // ---------------- handle search value change ---------------//
    // we pass this function to the child searchableDropdown, and it uses this function to modify the state
    // searchValue, which is a state of parent component
    const handleSearchValueChange = (val) => {
        setSearchValue(val)
        if (val.length > 5) {
            debounceSearch(val)
        } else {
            setOptions([{ "warning": labels.warningMessage.minLength }])
        }
    }


    // ------------determine the data displayed in the input fieldfl-----//
    const whatToDisplay = (originalData, updatedData) => {
        // If validSelectedVehicle is not defined, return originalData, if that is not defined either return "-"
        if (!validSelectedVehicle) return originalData || "-";

        // Check that all values of validSelectedVehicle are defined and that it is not an empty object
        const allDefined = Object.values(validSelectedVehicle).every(value => value !== null && value !== undefined) && Object.values(validSelectedVehicle).length !== 0;

        // If allDefined is true and updatedData is defined, return updatedData, else return "-"
        if (allDefined && updatedData) {
            return updatedData
        }
        return "-"
    }
    return (
        <>
            <SearchableDropdown
                options={options}
                keyLabel={keyLabel}
                selectedVal={searchValue}
                handleChange={(value) => handleSearchValueChange(value)}
                isLoading={isLoading}
                // other labels are needed for displaying such attributes in the options
                otherLabels={otherLabels}
                setValidSelectedVehicle={setValidSelectedVehicle}

            />
            <div className="item-group-container">
                <div className="item-group-column">
                    <InformationItem label={labels?.carsPage?.carInfo?.brand}>
                        <FormInputItem
                            name="vehicleAax?.brand"
                            value={whatToDisplay(props.originalData?.brand, validSelectedVehicle?.brand)}
                            disabled={true}
                        />
                    </InformationItem>
                    <InformationItem label={labels?.carsPage?.carInfo?.VIN}
                        editMode={false}>
                        <FormInputItem
                            name="vehicleAax?.vin"
                            value={whatToDisplay(props.originalData?.vin, validSelectedVehicle?.vin)}
                            disabled={true}
                        />
                    </InformationItem>

                </div>
                <div className="item-group-column">
                    <InformationItem label={labels?.carsPage?.carInfo?.type}
                        editMode={false}>
                        <FormInputItem
                            name="vehicleAax?.brand"
                            value={whatToDisplay(props.originalData?.type, validSelectedVehicle?.type)}
                            disabled={true}
                        />
                    </InformationItem>

                    <InformationItem label={labels?.carsPage?.carInfo?.plateNumber}
                        editMode={false}>
                        <FormInputItem
                            name="vehicleAax?.licencePlate"
                            value={whatToDisplay(props.originalData?.licencePlate, validSelectedVehicle?.licencePlate)}
                            disabled={true}
                        />
                    </InformationItem>
                </div>
            </div>
        </>
    )
}

export default SearchForCar
import React from "react";
import PropTypes from "prop-types";

// import styling
import "./_style.scss"

import {ReactComponent as PaymentIcon} from "../../../assets/svg/icon-wireless-payment.svg";
import {ReactComponent as EyeIcon} from "../../../assets/svg/icon-eye.svg";
import {ReactComponent as EyeIconOff} from "../../../assets/svg/icon-eye-off.svg";


import Moment from "moment";


const GeneralCreditCard = (props) => {
    GeneralCreditCard.propTypes = {
        labels : PropTypes.object.isRequired,
        data : PropTypes.object.isRequired,
    }

    const [showPin, setShowPin] = React.useState(false)


    function getPinText() {
        if (!props.data?.pin) return ""

        if (showPin) {
            return props.data?.pin
        } else {
            return props.data?.pin?.replace(/./g, "*")
        }
    }

    return (
        <div className="generic-credit-card">
            <div className="card-content-container">
                <div className="card-upper">
                    <div>{props.data.provider?.companyName?.toUpperCase()}</div>
                    <PaymentIcon></PaymentIcon>
                </div>
                <div className="card-lower">

                    <div className="card-lower-upper">
                        <div>{props.data?.customers[0]?.companyName}</div>
                        <div>{Moment(props.data.expirationDate).format("MM/YY")}</div>
                    </div>
                    <div className="card-lower-lower">
                        {props.data?.cardNumber}
                    </div>
                    {
                        props?.data?.pin && (
                            <div className="pin-section">
                                {(props.labels?.fuelCardPage?.fuelCardInfo?.pin || props.labels?.fuelCardInfo?.pin || "PIN") + ": "}

                                {getPinText()}
                                <div className="pin-icon" onClick={() => setShowPin(!showPin)}>
                                    {showPin ? <EyeIcon style={{width: "15px"}}/> :
                                        <EyeIconOff style={{width: "14px"}}/>}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )

}

export default GeneralCreditCard;
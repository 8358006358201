// import styling
import "./_style.scss"

import React from 'react';

import PropTypes from "prop-types";

export const newStatusCellRenderer = (props) => {
    newStatusCellRenderer.propTypes = {
        value: PropTypes.string, // The value to display: Active, Inactive, etc.
        color: PropTypes.string, // The color of the text: ACTIVE, NEGATIVE, NEUTRAL, GREY, ORANGE
        hideDot: PropTypes.bool, // Hide the dot
    }
    newStatusCellRenderer.defaultProps = {
        value: "",
        color: "neutral",
        hideDot: false,
    }


    const className = props.hideDot ? "badge-" : "status-tag-";

    return (
        <span className={className + props.color}>
            {props.value}
        </span>
    )
}
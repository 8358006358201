// import external
import React from "react";

// import internal
import CostInfoCard from "../../CostInfoCard";
import GridHandler from "../../GridHandler";
import GridTitle from "../../GridHandler/GridTitle";
import LoadingComponent from "../../LoadingComponent";
import DiagramPerCar from "./DiagramPerCar";
import {useGetFleetCostsByCarQuery, useGetFleetCostsByCarV2Query} from "../../../redux/apiSlice/fleetCostSlice";

// import helper
import {formatNumber, thousandsSeparator} from "../../../actions/formateNumbers.actions";
import {createErrorMessage} from "../../AlertContainer/alert.utils";

// assets
import AlertContainer from "../../AlertContainer";
import {useLocation, useNavigate} from "react-router-dom";
import {setCarCostDates} from "../../../redux/stateSlice/dateSlice";
import {useDispatch, useSelector} from "react-redux";

const CostsByCar = (props) => {
    const {startDate, endDate, labels} = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const costApiVersion = useLocation().pathname

    // Load the date picker state from redux
    const fleetCostDatesState = useSelector(state => state.date).fleetCostDates

    const {
        data: dataV1,
        isLoading: isLoadingV1,
        isSuccess: isSuccessV1,
        error: errorV1,
        isFetching: isFetchingV1
    } = useGetFleetCostsByCarQuery({startDt: startDate, endDt: endDate}, {skip:costApiVersion==="/costs-v2"})
    const {
        data:dataV2,
        isLoading: isLoadingV2,
        isSuccess: isSuccessV2,
        error: errorV2,
        isFetching: isFetchingV2
    } = useGetFleetCostsByCarV2Query({startDt: startDate, endDt: endDate}, {skip:costApiVersion!=="/costs-v2"})

    let data, isLoading, isSuccess, error, isFetching;

    if(costApiVersion==="/costs-v2"){
        data=dataV2
        isLoading=isLoadingV2
        isSuccess=isSuccessV2
        error=errorV2
        isFetching=isFetchingV2
    }else{
        data=dataV1
        isLoading=isLoadingV1
        isSuccess=isSuccessV1
        error=errorV1
        isFetching=isFetchingV1

    }
    const columnDefs = [
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            colId: "VIN",
            valueGetter: (params) => params.data?.vin,
            cellRenderer: (params) => {
                if (!params.value) return null;
                const pageName = `/cars/${params.value}?tab=costs${costApiVersion === "/costs-v2" ? "-v2" : ""}`;
                    return (
                        <span
                            className="link-with-icon"
                            onClick={() => {
                                // When user goes to the Costs page of a car, we change the car detail page costs Datepicker
                                // dates to the currently selected one
                                dispatch(setCarCostDates(fleetCostDatesState))
                                navigate(pageName)
                            }}>
                            {params.value}
                        </span>
                    )
            },
            cellStyle: {fontWeight: 500}
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.brand,
            colId: "brand",
            valueGetter: (params) => params.data?.brand,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.type,
            colId: "type",
            valueGetter: (params) => params.data?.type,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId: "licencePlate",
            valueGetter: (params) => params.data?.licencePlate,
        },
        {
            headerName: "TCO",
            colId: "TCO",
            valueGetter: function (params) {
                // if null or undefined (or 0) return 0
                return params.data?.totalTco || 0;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            sort: 'desc',
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.costPage?.costInfo?.chargedByUPTO,
            colId: "chargedByUPTO",
            valueGetter: function (params) {
                // if null or undefined (or 0) return 0
                return params.data?.totalCost || 0;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.costPage?.costInfo?.difference,
            colId: "difference",
            valueGetter: function (params) {
                // Use 0 if totalTco/TotalCost are null, undefined or 0
                return ((params.data?.totalTco || 0) - (params.data?.totalCost || 0));
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',

        },
    ]


    const DiagramContent = ({data}) => {
        const diagramTitle = labels?.costPage?.titles?.totalCostsPerVehicle

        let content;

        if (isLoading) {
            content = "Loading..."
        } else if (isSuccess && data?.length > 0) {
            content = (
                <div className="chart-container">
                    <DiagramPerCar data={data} labels={props.labels}/>
                </div>
            )
        } else {
            content = createErrorMessage(error, "", "h1", props.labels);
        }
        return (
            <>
                {diagramTitle}
                {content}
            </>
        )
    }

    let content;

    if (isLoading || isFetching) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) {
        // Filter out all cost types that have no costs
        const formattedData = data?.cars?.filter((car) => {
            if (car.totalTco > 0 || car.totalCost > 0) {
                return true;
            } else {
                return false;
            }
        }).map((car) => {
            return {
                ...car,
            }
        })
        // If there is no data for the selected time frame, and the selected time frame is the entire time frame, show different error
        if (data?.cars.length === 0 && !startDate && !endDate) {
            content = (
                <AlertContainer key="alertContainerNoData" show={true} alertStyle={"alert-warning"}>
                    {props.labels?.errorMessage?.dataNotAvailable}
                </AlertContainer>
            )
        }
        // If there is no data for the selected time frame, show an error message
        else if (data?.cars.length === 0) {
            content = (
                <AlertContainer key="alertContainerNoDataForTimeFrame" show={true} alertStyle={"alert-warning"}>
                    {props.labels?.errorMessage?.noDataForTimeFrame}
                </AlertContainer>
            )
        } else {
            content = (
                <div className="cost-overview-content">
                    <div className="cost-overview-top">
                        <div className="cost-info-card-container">
                            <CostInfoCard title={"TCO"}
                                          tooltipText={labels?.costPage?.costInfo?.TCOExplanation}
                                          number={thousandsSeparator((Math.round(data.totalTco * 100) / 100).toFixed(2))}
                                          unit={"CHF"}
                            >
                            </CostInfoCard>
                            <CostInfoCard title={props.labels?.costPage?.titles?.totalChargedByUPTO}
                                          tooltipText={labels?.costPage?.costInfo?.chargedByUPTOExplanation}
                                          number={thousandsSeparator((Math.round(data.totalFleet * 100) / 100).toFixed(2))}
                                          unit={"CHF"}
                            >
                            </CostInfoCard>
                        </div>
                        <div className="total-cost-diagram-container">
                            <div className="blank-card">
                                <DiagramContent data={formattedData} labels={labels}/>
                            </div>
                        </div>
                    </div>

                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.carsPage?.titles?.vehicles}
                                subtitle={props.labels?.costPage?.titles?.vehicleCostsGridSubtitle}
                                badgeText={data?.cars?.length + " " + props.labels?.carsPage?.titles?.vehicles}
                            />}
                        downloadable={true}
                        data={data?.cars}
                        columnDefs={columnDefs}
                        gridName="upto_fleetCostsByCar"
                        downloadTextCsv={props.labels?.otherElements?.downloadCsv}
                        downloadTextExcel={props.labels?.otherElements?.downloadExcel}
                        fileName={`upto_fleetCostsByCar`}
                    />
                </div>
            )
        }

    } else {
        content = createErrorMessage(error, "", "h1", props.labels);
    }

    return content;


}

export default CostsByCar;
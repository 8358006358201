import React from "react";
import PropTypes from "prop-types";

import {ReactComponent as CarImage} from "../../../assets/svg/big-car.svg";
import {useVariableValue} from "@devcycle/react-client-sdk";


import "./_style.scss"
import {getContractStatus, getCarStatus} from "../../../actions/magicNumber.actions";
import {determineLanguage} from "../../../actions/language.actions"

const GeneralCarDataCard = (props) => {
    GeneralCarDataCard.propTypes = {
        labels: PropTypes.object.isRequired,
        infoFields: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
    }

    
    const showSpecificCarStatus = useVariableValue("specific-car-status", false)


    const badgeClassName = () => {
        const prefix = "status-tag-";

        if(showSpecificCarStatus) {
            const statusValue = props.data?.status.replace(/\s/g, '').toLowerCase()
            switch (statusValue) {
                case "lager/parkplatz":
                    return prefix + "positive";
                case "eingang":
                    return prefix + "yellow";
                case "inaufbereitung":
                    return prefix + "orange";
                case "schadenreparatur":
                    return prefix + "negative";
                case "aktiv":
                    return prefix + "positive";
                case "verfügbar":
                    return prefix + "positive";
                case "vermietet":
                    return prefix + "positive";
                case "ausgeliefert":
                    return prefix + "darkgreen";
                case "inrücknahme":
                    return prefix + "neutral";
                case "inaktiv":
                    return prefix + "darkred";
                default:
                    return prefix + "grey";
            }
        }else {
            const statusId = props.data?.contractInformation?.contractStatusId;
            if (statusId=== 1) {
                return prefix + "positive";
            } else if (statusId === 0 || statusId === 5) {
                return prefix + "neutral";
            } else if (statusId === undefined) {
                return prefix + "grey";
            }
            else {
                return prefix + "negative";
            }
        }
    }
    const LeftSide = () => {
        return (
            <>
                <div>
                    <CarImage className="car-data-image"/>
                </div>
                <div className="car-data-text-container">
                    <span className="car-data-title">
                        {props.data?.additionalCarInformation?.baseData?.brand + " " + props.data?.additionalCarInformation?.baseData?.type}
                    </span>
                    <span className={badgeClassName()}
                          style={{
                              width: "fit-content",
                              whiteSpace: "nowrap",
                              position: "relative",
                              top: "-3px",
                              marginLeft: "1em"
                          }}>
                        {showSpecificCarStatus ? getCarStatus(props.data?.status, determineLanguage()) : getContractStatus(props.data?.contractInformation?.contractStatusId, props.labels)}
                    </span>
                </div>
                <div className="car-data-subtitle">
                    {props.data?.licencePlate}
                </div>
            </>
        )
    }

    const RightSide = () => {
        return (
            <table className="general-car-data-table">
                <tbody>
                {Object.keys(props.infoFields).map((key, i) => {
                    return <tr key={props.labels?.carsPage?.carInfo[key]}>
                        <th className="labels">{props.labels?.carsPage?.carInfo[key]}</th>
                        <td className="data">
                            {props.infoFields[key](props.data) ? props.infoFields[key](props.data) : "-"}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        )
    }

    return (
        <div className="main-card">
            <div className="left-side-content">
                <LeftSide/>
            </div>
            <div className="right-side-content">
                <RightSide/>
            </div>
        </div>
    )

}

export default GeneralCarDataCard;
/**
 * modify the property value of an object given the path/key to the property key.
 * @param {object} obj - the object to modify
 * @param {string} keys - the path to the key/property
 * @param {string} value - the new property value
 */
export function setNestedPropertyValue(obj, keys, value) {
    // Convert keys to an array if it's not already
    if (!Array.isArray(keys)) {
        keys = keys.split('.');
    }

    // Start with the outermost object
    let currentObj = obj;

    // Iterate through the keys to access the nested property
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        // If the key doesn't exist in the current object, create an empty object for it
        if (!currentObj[key] || typeof currentObj[key] !== 'object') {
            currentObj[key] = {};
        }
        currentObj = currentObj[key];
    }

    // Set the final nested property to the new value
    const lastKey = keys[keys.length - 1];
    currentObj[lastKey] = value;
}
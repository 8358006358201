// import external
import React from "react";

// import assets
import {ReactComponent as PencilIcon} from "../../assets/svg/icon-edit.svg";

// import styling
import "./_style.scss"
import PropTypes from "prop-types";


const EditButtonGroup = (props) => {
    EditButtonGroup.propTypes = {
        isEditing : PropTypes.bool, // used to save the data of selected car
        handleEnableEdit : PropTypes.func,// from parent: pass the data of selected car out
        allowEdit: PropTypes.bool, //
    }

    const {isEditing, handleEnableEdit, allowEdit}=props

    if (!allowEdit) {
        return <></>
    }
    return (
        <span className="button-group" hidden={false}>
                    {props.isLoading ?
                        (<button className="btn btn-primary btn-disabled">
                                <div className="dot-falling-container">
                                    <div className="dot-falling"/>
                                </div>
                            </button>
                        ) :
                        (
                            <>
                                {isEditing ?
                                    <>
                                        <button className="btn btn-light" onClick={() => {props.handleCancelEdit();}}>
                                            {props.labels?.otherElements?.cancel}
                                        </button>
                                        <button className="btn btn-light" onClick={() => {props.handleSave();}}>
                                            {props.labels?.otherElements?.save}
                                        </button>
                                    </>
                                    :
                                    <button className="btn btn-light" onClick={handleEnableEdit} style={{gap:"5px"}}>
                                        <PencilIcon/> {props.labels?.otherElements?.edit}
                                    </button>
                                }
                            </>
                        )

                    }
                </span>
    )

}

export default EditButtonGroup
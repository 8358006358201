import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const reportApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        requestReport: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.REPORTS,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        getReportStatus: builder.query({
            query: (workflowId) => ({
                url: API_FAMILY_CONST.REPORTS_STATUS,
                params: {
                    workflowId: workflowId || ''
                },
                method: 'GET',
            }),
            providesTags: (result, error, arg) => {
                if (error || !result) {
                    return [{type: 'ReportStatus'}]
                }
                return [{type: 'ReportStatus', id: result.map(({workflowId}) => workflowId)}]
            }
        })
    })
})

export const {
    useRequestReportMutation,
    useGetReportStatusQuery,
    useLazyGetReportStatusQuery
} = reportApiSlice
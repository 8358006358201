import {
    FormDescription,
    FormField,
    FormGroup,
    FormItem,
    FormLabel,
    FormMessage,
    FormValue,
    Input,
    Select
} from "../../Form/Form";
import {Combobox, ComboboxInput, ComboboxItem, ComboboxList, ComboboxMenu} from "../../Form/Combobox/Combobox";
import {formatDateIfNotEmpty} from "../../../actions/date.actions";
import React, {useContext, useEffect} from "react";
import {FleetWizardContext} from "../FleetWizardContext";
import {ReactComponent as CarImage} from "../../../assets/svg/big-car.svg";


const FuelCardTab = ({createFuelCardForm, createCarForm, createDriverForm, onSubmit, tab, children, labels}) => {
    const { state, dispatch } = useContext(FleetWizardContext);

    const { selectedObjects, objects, selectEntries } = state;

    const { selectedFuelCard, selectedCar, selectedOrganisation } = selectedObjects;
    const { fuelCards, cars, organisations} = objects;

    const { energyProviders } = selectEntries;

    function onSelectFuelCard(id) {
        const fuelCard = fuelCards.find(fuelCard => fuelCard?.id === id);
        dispatch({
            type: 'UPDATE_SELECTED_FUEL_CARD',
            payload: fuelCard,
        });
        createFuelCardForm.setValue("id", fuelCard?.id || null);
        createFuelCardForm.setValue("cardNumber", fuelCard?.cardNumber || null);
        createFuelCardForm.setValue("cardName", fuelCard?.cardName || null);
        createFuelCardForm.setValue("expirationDate", fuelCard?.expirationDate || null);
        createFuelCardForm.setValue("note", fuelCard?.note || null);
        createFuelCardForm.setValue("provider", fuelCard?.provider || {companyName: null, customerId: null});
        createFuelCardForm.setValue("pin", fuelCard?.pin || null);
        // Check form again for errors (to clear if there were any beforehand without needing user
        // to type in input field
        createFuelCardForm.trigger(["cardNumber", "cardName", "expirationDate", "note", "provider.customerId", "pin"])
    }

    function handleCheckbox(e) {
        createFuelCardForm.setValue("existingFuelCard", e.target.checked);
        // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)
        if (!e.target.checked) {
            dispatch({
                type: 'UPDATE_SELECTED_FUEL_CARD',
                payload: null,
            });
            createFuelCardForm.setValue("id", null);
            createFuelCardForm.setValue("cardNumber", null);
            createFuelCardForm.setValue("cardName",null);
            createFuelCardForm.setValue("expirationDate", null);
            createFuelCardForm.setValue("note", null);
            createFuelCardForm.setValue("provider",{companyName: null, customerId: null});
            createFuelCardForm.setValue("pin", null);
            createFuelCardForm.setValue("companyData", {id: null, customerId: null})
        }
    }

    function onSelectVehicle(id) {
        const car = cars.find(car => car?.additionalCarInformation?.baseData?.carId === id);
        dispatch({
            type:'UPDATE_SELECTED_CAR',
            payload: car
        });
        createFuelCardForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "")
        createDriverForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "")
        createFuelCardForm.trigger(["carId"])
        createDriverForm.trigger(["carId"])
    }

    function onSelectOrganisation(id) {
        const organisation = organisations.find(organisation => organisation?.id === id);
        dispatch({
            type: 'UPDATE_SELECTED_ORGANISATION',
            payload: organisation,
        });
        createFuelCardForm.setValue("companyData", organisation || {
            id: null,
            customerId: null,
        });
        createCarForm.setValue("organisation", organisation?.id || null);
        createDriverForm.setValue("parentCustomer", organisation?.id || null)

        createFuelCardForm.trigger(["companyData.customerId", "companyData"])
    }

    const ConnectedVehicleInformation = ({vehicles}) => {
        const currentCar = vehicles[0]


        return (
            <>
                <label>
                    {labels?.carsPage?.carInfo?.model}
                </label>
                <p className="vehicle-name">
                    {currentCar?.brand || ""} {currentCar?.type || ""}
                </p>

                <div className="d-flex gap-3 align-items-center w-100">
                    <CarImage width={76} height={76}/>

                    <div>
                        <div>
                            <label>
                                {labels?.carsPage?.carInfo?.plateNumber}
                            </label>
                            <p>
                                {currentCar?.licencePlate || "-"}
                            </p>
                        </div>

                        <div>
                            <label>
                                {labels?.fleetWizard?.form?.assignedFrom}
                            </label>
                            <p>
                                {formatDateIfNotEmpty(currentCar?.startDate)}
                            </p>
                        </div>
                    </div>

                </div>


                <label>
                    {labels?.carsPage?.carInfo?.VIN}
                </label>
                <p>
                    {currentCar?.vin || "-"}
                </p>


            </>
        )
    }


    useEffect(() => {
        const custId = createFuelCardForm.getValues("provider.customerId")
        if (custId) {
            createFuelCardForm.setValue("provider.companyName", energyProviders.find((ep) => Number(ep.value) === Number(custId))?.label)
            createFuelCardForm.setValue("provider.id", energyProviders.find((ep) => Number(ep.value) === Number(custId))?.id)
        } else {
            createFuelCardForm.setValue("provider.companyName",null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createFuelCardForm.watch("provider.customerId")])


    if (tab !== 2) return null;
    return (
        <form onSubmit={createFuelCardForm.handleSubmit(onSubmit)} className="form"
        >
            <div className="modal-tab-content"
                 data-selected={tab === 2}
                 id={"modal-tab-2"}>
                {/*Error Message*/}
                {children[0]}

                <FormField
                    control={createFuelCardForm.control}
                    name="companyData.customerId"
                    render={({field, error}) => (
                        <FormItem hidden={organisations?.length === 1 || organisations?.length === 0}>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.organisation} *
                            </FormLabel>

                            <Combobox
                                selected={selectedOrganisation?.id}
                                onSelect={onSelectOrganisation}
                            >
                                <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                               selectedValue={`${selectedOrganisation?.companyName}`}
                                               disabled={organisations?.length === 1 || organisations?.length === 0}
                                               data-invalid={!!error?.message}
                                />
                                <ComboboxList
                                    noResults={<ComboboxItem
                                        disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                >
                                    {organisations?.map(organisation => (<ComboboxItem key={organisation?.id}
                                                                                       value={organisation?.id}>
                                        {`${organisation?.companyName}`}
                                    </ComboboxItem>))}
                                </ComboboxList>
                            </Combobox>

                            <FormMessage message={error?.message}/>

                        </FormItem>)}
                />


                <FormField
                    control={createFuelCardForm.control}
                    name="existingFuelCard"
                    render={({field, error}) => (<FormItem>
                        <FormLabel linkedCheckBoxId="existingFuelCardCheckbox" {...error} >
                            {labels?.fleetWizard?.form?.existingFuelCard}
                        </FormLabel>
                        <Input id="existingFuelCardCheckbox" {...field} {...error}
                               checked={createFuelCardForm.watch("existingFuelCard")}
                               type="checkbox"
                               onChange={handleCheckbox}
                        />

                    </FormItem>)}
                />


                {createFuelCardForm.watch("existingFuelCard") && (
                    <FormField
                        control={createFuelCardForm.control}
                        name="cardNumber"
                        render={({field, error}) => (
                            <FormItem>
                                <Combobox
                                    selected={selectedFuelCard?.id}
                                    onSelect={onSelectFuelCard}
                                    closeOnSelect={false}
                                >
                                    <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                   selectedValue={selectedFuelCard?.cardNumber}
                                                   data-invalid={!!error?.message}
                                    />
                                    <ComboboxList
                                        noResults={<ComboboxItem
                                            disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                    >
                                        {fuelCards?.map(fuelCard => (
                                            <ComboboxItem
                                                key={fuelCard.id}
                                                value={fuelCard.id}
                                                search-values={`${fuelCard.cardNumber} ${fuelCard.cars[0]?.brand} ${fuelCard.cars[0]?.vin} ${fuelCard.cars[0]?.licencePlate}`}
                                            >
                                                {fuelCard.cardNumber} ({fuelCard.cars[0]?.brand})
                                            </ComboboxItem>

                                        ))}


                                        <ComboboxMenu>
                                            <div className="fuel-card-combobox-menu">
                                                <ConnectedVehicleInformation
                                                    vehicles={selectedFuelCard?.cars || []}
                                                />
                                            </div>
                                        </ComboboxMenu>
                                    </ComboboxList>

                                </Combobox>

                                <FormMessage message={error?.message}/>

                                <FormDescription>
                                    {labels?.fleetWizard?.form?.selectFuelCardDescriptionNew}
                                </FormDescription>

                            </FormItem>)}
                    />
                )}


                {(!createFuelCardForm.watch("existingFuelCard") || (createFuelCardForm.watch("existingFuelCard") && selectedFuelCard)) && (<>
                    <FormField
                        control={createFuelCardForm.control}
                        name="cardNumber"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.fuelCardNumber} *
                            </FormLabel>
                            {createFuelCardForm.watch("existingFuelCard") ? (
                                <FormValue>
                                    {selectedFuelCard?.cardNumber || "-"}
                                </FormValue>
                            ) : (
                                <Input
                                    placeholder="1234 1234 1234 1234"
                                    {...field} {...error}
                                    disabled={createFuelCardForm.watch("existingFuelCard")}
                                />
                            )}

                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormField
                        control={createFuelCardForm.control}
                        name="provider.customerId"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.fuelCardProvider} *
                            </FormLabel>

                            {createFuelCardForm.watch("existingFuelCard") ? (
                                <FormValue>
                                    {selectedFuelCard?.provider?.companyName || "-"}
                                </FormValue>
                            ) : (
                                <Select
                                    options={energyProviders}
                                    {...field}
                                    {...error}
                                    disabled={createFuelCardForm.watch("existingFuelCard")}
                                />
                            )}


                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />


                    <FormField
                        control={createFuelCardForm.control}
                        name="cardName"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.fuelCardName} *
                            </FormLabel>
                            {createFuelCardForm.watch("existingFuelCard") ? (
                                <FormValue>
                                    {selectedFuelCard.cardName || "-"}
                                </FormValue>
                            ) : (
                                <Input
                                    placeholder=""
                                    {...field}
                                    {...error}
                                    disabled={createFuelCardForm.watch("existingFuelCard")}
                                />
                            )}

                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />


                    <FormGroup>
                        <FormField
                            control={createFuelCardForm.control}
                            name="expirationDate"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.fleetWizard?.form?.expirationDate}
                                </FormLabel>

                                {
                                    createFuelCardForm.watch("existingFuelCard") ? (
                                        <FormValue>
                                            {formatDateIfNotEmpty(selectedFuelCard?.expirationDate) || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            {...field} {...error} type="date"
                                            disabled={createFuelCardForm.watch("existingFuelCard")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createFuelCardForm.control}
                            name="pin"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.fleetWizard?.form?.cardPin}
                                </FormLabel>

                                {
                                    createFuelCardForm.watch("existingFuelCard") ? (
                                        <FormValue>
                                            {selectedFuelCard?.pin || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder=""
                                            {...field}
                                            {...error}
                                            disabled={createFuelCardForm.watch("existingFuelCard")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />
                    </FormGroup>

                    <FormField
                        control={createFuelCardForm.control}
                        name="note"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.note}
                            </FormLabel>
                            {
                                createFuelCardForm.watch("existingFuelCard") ? (
                                    <FormValue>
                                        {selectedFuelCard?.note || "-"}
                                    </FormValue>
                                ) : (
                                    <Input
                                        placeholder=""
                                        type='textArea'
                                        {...field} {...error}
                                        disabled={createFuelCardForm.watch("existingFuelCard")}
                                    />
                                )
                            }

                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormItem hidden={!createFuelCardForm.watch("existingFuelCard")}>
                        <FormLabel>
                            {labels?.fleetWizard?.form?.assignedToCarSince.replace("%", selectedFuelCard?.cars[0]?.brand)}
                        </FormLabel>
                        <FormValue>
                            {formatDateIfNotEmpty(selectedFuelCard?.cars[0]?.startDate) || "-"}
                        </FormValue>
                    </FormItem>


                </>)}

                <div className="assign-card-section">
                    <h3 className="title">
                        {labels?.fleetWizard?.form?.assignFuelCardTitle}
                    </h3>
                    <p className="description">
                        {labels?.fleetWizard?.form?.assignFuelCardDescription}
                    </p>
                    <p className="description">
                        {labels?.fleetWizard?.form?.assignFuelCardDescriptionTwo}
                    </p>
                </div>

                <FormField
                    control={createFuelCardForm.control}
                    name="carId"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.fleetWizard?.form?.selectCarTitle} *
                        </FormLabel>

                        <Combobox
                            selected={selectedCar?.additionalCarInformation?.baseData?.carId}
                            onSelect={onSelectVehicle}
                        >
                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                           selectedValue={`${selectedCar?.brand} ${(selectedCar?.type || "")} (${selectedCar?.additionalCarInformation?.baseData?.vin})`}
                                           data-invalid={!!error?.message}
                            />
                            <ComboboxList
                                noResults={<ComboboxItem
                                    disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                            >
                                {cars?.map(car => (
                                    <ComboboxItem key={car?.additionalCarInformation?.baseData?.carId}
                                                  value={car?.additionalCarInformation?.baseData?.carId}>
                                        {`${car?.brand} ${(car?.type || "")} (${car?.additionalCarInformation?.baseData?.vin})`}
                                    </ComboboxItem>))}
                            </ComboboxList>
                        </Combobox>

                        <FormMessage message={error?.message}/>

                        <FormDescription>
                            {labels?.fleetWizard?.form?.selectCarForFuelCardDescriptionNew}
                        </FormDescription>

                    </FormItem>)}
                />

                <FormGroup style={{flexDirection:createFuelCardForm.watch("existingFuelCard") ? "column" : "row"}}>

                    <FormField
                        control={createFuelCardForm.control}
                        name="startDate"
                        render={({field, error}) => (<FormItem hidden={createFuelCardForm.watch("existingFuelCard") && !selectedFuelCard}>
                            <FormLabel {...error} >
                                {
                                    createFuelCardForm.watch("existingFuelCard") ?
                                        labels?.fleetWizard?.form?.terminateFuelCardConnectionStartDate.replace("%", selectedFuelCard?.cars[0]?.brand) + " *" :
                                        labels?.fleetWizard?.form?.assignedFrom
                                }
                            </FormLabel>

                            <Input {...field} {...error} type="date"/>

                            <FormMessage message={error?.message}/>

                            <FormDescription style={{display: "flex", justifyContent:"space-between"}} hidden={!createFuelCardForm.watch("existingFuelCard")}>
                                <p style={{margin:0}}>
                                    {labels?.carsPage?.carInfo?.plateNumber}: {selectedFuelCard?.cars[0]?.licencePlate || "-"}
                                </p>
                                <p style={{margin: 0}}>
                                    {labels?.carsPage?.carInfo?.VIN}: {selectedFuelCard?.cars[0]?.vin || "-"}
                                </p>
                            </FormDescription>
                        </FormItem>)}
                    />


                    <FormField
                        control={createFuelCardForm.control}
                        name="endDate"
                        render={({field, error}) => (<FormItem hidden={createFuelCardForm.watch("existingFuelCard") && !selectedCar}>
                            <FormLabel {...error} >
                                {
                                    createFuelCardForm.watch("existingFuelCard") ?
                                        labels?.fleetWizard?.form?.terminateFuelCardConnectionEndDate.replace("%", selectedCar?.brand) :
                                        labels?.fleetWizard?.form?.assignedUntil
                                }
                            </FormLabel>

                            <Input {...field} {...error} type="date"/>

                            <FormMessage message={error?.message}/>

                            <FormDescription style={{display: "flex", justifyContent:"space-between"}} hidden={!createFuelCardForm.watch("existingFuelCard")}>
                                <p style={{margin:0}}>
                                    {labels?.carsPage?.carInfo?.plateNumber}: {selectedCar?.licencePlate || "-"}
                                </p>
                                <p style={{margin:0}}>
                                    {labels?.carsPage?.carInfo?.VIN}: {selectedCar?.vin || "-"}
                                </p>
                            </FormDescription>

                        </FormItem>)}
                    />
                </FormGroup>

            </div>
            {/*Form Buttons*/}
            {children[1]}
        </form>
    )

}

export default FuelCardTab;
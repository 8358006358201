import {createSlice} from "@reduxjs/toolkit";
import {STORAGE_CONST} from "../../actions/constants/storage.constants";
import {determineLanguage} from "../../actions/language.actions"

// load user's selection from local storage
const userLanguage = determineLanguage()



export const labelsSlice = createSlice({
    name: "labels",
    initialState: {
        currentLanguage: userLanguage,
        currentLabels: null,
    },
    reducers: {
        setCurrentLanguage: (state, action) => {
            // save the new language choice to session storage and local storage
            if (action.payload.language) {
                state.currentLanguage = action.payload.language
            }
            localStorage.setItem(STORAGE_CONST.USER_LANGUAGE, state.currentLanguage)
            // persist the labels themselves
            if(action.payload.labels){
                state.currentLabels = action.payload.labels
            }
        }
    }
})


export const {setCurrentLanguage} = labelsSlice.actions;
export default labelsSlice.reducer;
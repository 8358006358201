// endpoint to get all car related information
import {apiSlice} from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const fuelCardApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFuelCards: builder.query({
            query: (params) => ({
                url: API_FAMILY_CONST.FUEL_CARDS,
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
                params: params
            }),
            providesTags: (result, error, arg) => result && result.length > 0
                ? [...result.map(({ id }) => ({ type: 'FuelCards', id })), 'FuelCards']
                : ['FuelCards']
        }),
        getFuelCardById: builder.query({
            query: (cardId) => ({
                url: API_FAMILY_CONST.FUEL_CARDS +"?id=" + cardId,
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
            }),
            providesTags: (result, error, arg) => [{ type: 'FuelCards', id: arg }]
        }),
        createFuelCard: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.FUEL_CARDS,
                    method: 'PUT',
                    body: data,
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : [{type:"FuelCards", id: result?.id}]
            }
        })
    })
})

export const {
    useGetFuelCardsQuery,useGetFuelCardByIdQuery, useCreateFuelCardMutation
} = fuelCardApiSlice
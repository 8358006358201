//import styling
import "./_style.scss"
import ButtonWithIcon from "../ButtonWithIcon";
import React, {useRef} from "react";
import {ReactComponent as DownloadIcon} from "../../assets/svg/icon-download.svg";
import {AgGridReact} from "ag-grid-react";
import { triggerEvent } from "../../actions/eventTracking.actions";

const GridDownloadButtons = (props) => {

    const {data, columnDefs, labels, disableExcel, disableCSV, fileName} = props;

    const gridRef = useRef();


    // ------  export data entries to Excel file
    const exportExcel = () => {
        // check whether all properties are available to prevent accessing undefined
        if (gridRef?.current?.api) {
            let allColumnIds = [];
            gridRef?.current?.columnApi?.getColumns().forEach((column) => {
                if (!column.getId().includes("icon")) {
                    allColumnIds.push(column.getId());
                }
            });

            gridRef?.current?.api?.exportDataAsExcel({
                fileName: fileName + ".xlsx",
                columnKeys: allColumnIds
            });

            // add ga 4
            triggerEvent('file_download', 'click', 'success', 'excel', 'download_button');
        }
    };

    //Before exporting CSV it checks for each cell if it starts with one of these and if so, removes them.
    const forbiddenSymbols = ['+', '-', '=', '@', "\t", "\r"]

    // ------  export data entries to CSV file
    const exportCsv = () => {
        // check whether all properties are available to prevent accessing undefined
        if (gridRef?.current?.api) {
            let allColumnIds = [];
            gridRef?.current?.columnApi?.getColumns().forEach((column) => {
                if (!column.getId().includes("icon")) {

                    allColumnIds.push(column.getId());
                }
            });
            gridRef?.current?.api?.exportDataAsCsv({
                fileName: fileName + ".csv",
                columnKeys: allColumnIds,
                processCellCallback(params) {
                    for (let i = 0; i < forbiddenSymbols.length; i++) {
                        if (String(params.value).startsWith(forbiddenSymbols[i])) {
                            return ""
                        }
                    }
                    return params.value;
                }
            });

            // add ga 4
            triggerEvent('file_download', 'click', 'success', 'csv', 'download_button');
        }
    };


    return (
        <div className="ag-grid-download-button-container">
            <div className="ag-grid-download-button-container">
                <ButtonWithIcon text={labels?.otherElements?.downloadExcel} buttonStyle={"btn-primary"} hidden={disableExcel}
                                clickFunc={exportExcel}>
                    <DownloadIcon className="inner-icon"></DownloadIcon>
                </ButtonWithIcon>
                <ButtonWithIcon text={labels?.otherElements?.downloadCsv} buttonStyle={"btn-primary"} hidden={disableCSV}
                                clickFunc={exportCsv} >
                    <DownloadIcon className="inner-icon"></DownloadIcon>
                </ButtonWithIcon>
            </div>

            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                gridOptions={{rowData: data}}
                excelStyles={props.excelStyles}
            >
            </AgGridReact>

        </div>
    )

}

export default GridDownloadButtons;
// import external
import React from "react";
import {useSelector} from "react-redux";
// import internal component
import DriversOverview from "../../components/DriversOverview";

const MyDriversPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <DriversOverview labels={currentLabels} title={currentLabels?.driverPage?.titles?.driversOverview}></DriversOverview>
            </div>
        </div>
    )
}

export default MyDriversPage
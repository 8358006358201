// import external
import React from "react";

// import internal
import CostInfoCard from "../../CostInfoCard";
import GridHandler from "../../GridHandler";
import GridTitle from "../../GridHandler/GridTitle";
import LoadingComponent from "../../LoadingComponent";
import DiagramPerCostType from "./DiagramPerCostType";
import {
    useGetFleetCostsByCostTypeQuery,
    useGetFleetCostsByCostTypeV2Query
} from "../../../redux/apiSlice/fleetCostSlice";

// import helper
import {formatNumber, thousandsSeparator} from "../../../actions/formateNumbers.actions";
import {createErrorMessage} from "../../AlertContainer/alert.utils";

// assets
import AlertContainer from "../../AlertContainer";
import {useLocation} from "react-router-dom";

const CostsByCostType = (props) => {

    // VIN is only defined, if we are on car details page
    const {startDate, endDate, labels, vin} = props;

    // Get entire path, include .search in case we are on car detail page
    const path = useLocation().pathname + useLocation().search

    // If path contains 'costs-v2', we use new api
    const useNewApi = path.includes("costs-v2")

    let data, isLoading, isSuccess, error, isFetching;
    const {
        data: dataV1,
        isLoading: isLoadingV1,
        isSuccess: isSuccessV1,
        error: errorV1,
        isFetching: isFetchingV1
    } = useGetFleetCostsByCostTypeQuery({startDt: startDate, endDt: endDate, VIN: vin}, {skip:useNewApi})

    const {
        data: dataV2,
        isLoading: isLoadingV2,
        isSuccess: isSuccessV2,
        error: errorV2,
        isFetching: isFetchingV2
    } = useGetFleetCostsByCostTypeV2Query({startDt: startDate, endDt: endDate, VIN: vin}, {skip:!useNewApi})
    
    if(useNewApi){
        data=dataV2
        isLoading=isLoadingV2
        isSuccess=isSuccessV2
        error=errorV2
        isFetching=isFetchingV2
    }else{
        data=dataV1
        isLoading=isLoadingV1
        isSuccess=isSuccessV1
        error=errorV1
        isFetching=isFetchingV1
    }
    const columnDefs = [
        {
            headerName: props.labels?.costPage?.costInfo?.mainCategory,
            colId:"mainCategory",
            valueGetter: (params) => params.data.description,
            cellStyle: {fontWeight: 500}
        },
        {
            headerName: "TCO",
            colId:"TCO",
            valueGetter: function (params) {
                // if null or undefined (or 0) return 0
                return params.data.totalTco || 0;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            sort: 'desc',

        },
        {
            headerName: props.labels?.costPage?.costInfo?.chargedByUPTO,
            colId:"chargedByUPTO",
            valueGetter: function (params) {
                // if null or undefined (or 0) return 0
                return params.data.totalFleet || 0;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.costPage?.costInfo?.difference,
            colId:"difference",
            valueGetter: function (params) {
                // Use 0 if totalTco/TotalCost are null, undefined or 0
                return ((params.data?.totalTco || 0) - (params.data?.totalFleet || 0));
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " CHF";
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',

        },
    ]


    const DiagramContent = ({data}) => {
        const diagramTitle = props.labels?.costPage?.titles?.totalCostsPerCostType;

        let content;

        if(isLoading){
            content = "Loading..."
        }
        else if(isSuccess && data?.length > 0){
            content = (
                <div className="chart-container">
                    <DiagramPerCostType data={data} labels={props.labels?.costPage}/>
                </div>
            )
        }
        else {
            content = createErrorMessage(error, "", "h1", props.labels);
        }
        return (
            <>
                {diagramTitle}
                {content}
            </>
        )
    }

    let content;


    if (isLoading || isFetching) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    }
    else if (isSuccess){
        // Filter out all cost types that have no costs
        const formattedData = data?.costTypes?.filter((costType) => {
            if(costType.totalTco > 0 || costType.totalFleet > 0) {
                return true;
            }
            else {
                return false;
            }
        }).map((costType) => {
            return {
                ...costType,
            }
        })
        // If there is no data for the selected time frame, and the selected time frame is the entire time frame, show different error
        if(data?.costTypes?.length === 0 && !startDate && !endDate){
            content = (
                <AlertContainer key="alertContainerNoData" show={true} alertStyle={"alert-warning"}>
                    {props.labels?.errorMessage?.dataNotAvailable}
                </AlertContainer>
            )
        }
        // If there is no data for the selected time frame, show an error message
        else if(data?.costTypes?.length === 0){
            content = (
                <AlertContainer key="alertContainerNoDataForTimeFrame" show={true} alertStyle={"alert-warning"}>
                    {props.labels?.errorMessage?.noDataForTimeFrame}
                </AlertContainer>
            )
        }
        else {
            content = (
                <div className="cost-overview-content">
                    <div className="cost-overview-top">
                        <div className="cost-info-card-container">
                            <CostInfoCard title={"TCO"}
                                          tooltipText={labels?.costPage?.costInfo?.TCOExplanation}
                                          number={thousandsSeparator((Math.round(data.totalTco * 100) / 100).toFixed(2))}
                                          unit={"CHF"}
                            >
                            </CostInfoCard>
                            <CostInfoCard title={props.labels?.costPage?.titles?.totalChargedByUPTO}
                                          tooltipText={labels?.costPage?.costInfo?.chargedByUPTOExplanation}
                                          number={thousandsSeparator((Math.round(data.totalFleet * 100) / 100).toFixed(2))}
                                          unit={"CHF"}
                            >
                            </CostInfoCard>
                        </div>
                        <div className="total-cost-diagram-container">
                            <div className="blank-card">
                                <DiagramContent data={formattedData} labels={labels}/>
                            </div>
                        </div>
                    </div>

                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.costPage?.titles?.costTypes}
                                subtitle={props.labels?.costPage?.titles?.costTypesGridSubtitle}
                                badgeText={data?.costTypes?.length + " " + props.labels?.costPage?.titles?.costTypes}
                            />}
                        downloadable={true}
                        data={data?.costTypes}
                        columnDefs={columnDefs}
                        gridName={`upto_fleetCostsByCostType_${props?.vin?props?.vin:"all"}`}
                        downloadTextCsv={props.labels?.otherElements?.downloadCsv}
                        downloadTextExcel={props.labels?.otherElements?.downloadExcel}
                        fileName={`upto_fleetCostsByCostType_${props?.vin?props?.vin:"all"}`}
                    />
                </div>
            )
        }

    }
    else {
        content = createErrorMessage(error, "", "h1", props.labels);
    }

    return content;

}

export default CostsByCostType;
// endpoint to get all car related information
import { apiSlice } from "./apiSlice"
import { API_FAMILY_CONST } from "../../actions/constants/api.constants";
//To test with imported data:
//import testData from "./testDataFile.json";


export const invoicesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInvoices: builder.query({
            // if the vin is undefined, we call the generic carInvoice/invoicesForCar api
            // if the vin is not undefined, a query param is added to the url
            query: (vin) => API_FAMILY_CONST.INVOICES + (vin ? `?vin=${vin}` : ""),
            method: 'GET',

            //Response is a list that contains various invoice objects
            transformResponse: (response, meta, arg) => {

                //Replaces response with the faulty data (FOR TESTING ONLY)!
                //response = testData;

                //Create a new list that contains the invoice objects where id is defined
                let validEntries = [];
                response.forEach((entry) => {
                    if (entry.id){
                        validEntries.push(entry)
                    }
                })

                //Return new list that doesn't contain invalid invoices
                return validEntries;
            },

            //caching for 10 min
            keepUnusedDataFor: 600
        }),
        getInvoice: builder.query({
            query: (invoiceId) => API_FAMILY_CONST.INVOICE + "/" + invoiceId,
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            // the response from this api also include base64 data of the invoice => invoiceDetails
            providesTags: (result,error, arg) => [{ type: 'InvoiceDetails', id: arg }],
        }),
        getCarInvoicesNoPos: builder.query({
            query: (arg) => {
                // if query empty use empty string
                const vin = arg?.vin ? arg?.vin : "";
                const fromDate = arg?.fromDate ? arg?.fromDate : "";
                const toDate = arg?.toDate ? arg?.toDate : "";
                return (
                    API_FAMILY_CONST.CAR_INVOICES_NO_POS + `?expanded=true&vin=${vin}&fromDate=${fromDate}&toDate=${toDate}`
                )},
            method: 'GET',
            //caching for 5 min
            keepUnusedDataFor: 600,
            providesTags: ["CarInvoicesNoPos"]
        })
    })
})


export const {
    useGetInvoicesQuery,
    useLazyGetInvoiceQuery,
    useGetInvoiceQuery,
    useGetCarInvoicesNoPosQuery,
} = invoicesApiSlice
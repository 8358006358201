import fallbackLabels from '../assets/i18n/German.json'

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
// filter out the labels we download that are empty
// because I cannot delete labels from aax
export function filterOutEmptyFields(body) {
    const filteredBody = JSON.parse(JSON.stringify(body));
    for (const key in filteredBody) {
        // console.log("key", key, localJson[key])
        if (isObject(filteredBody[key])) {
            body[key] = filterOutEmptyFields(filteredBody[key]);
        } else {
            // console.log("not object")
            if (filteredBody[key]) {
                if (!filteredBody[key]) {
                    delete filteredBody[key]
                }
            }
        }
    }
    return filteredBody;
}

export function mergeDeep(highPriorityObject = {}) {
    let lowPriorityObject = JSON.parse(JSON.stringify(fallbackLabels))
    return mergeDeepRec(lowPriorityObject, highPriorityObject);
}

/**
 * Deep merge two objects.
 */
export function mergeDeepRec(lowPriorityObject, highPriorityObject = {}) {
    for (const key in highPriorityObject) {
        if (isObject(highPriorityObject[key])) {
            // lowPriorityObject is imported from JSON file
            if (!lowPriorityObject[key]) Object.assign(lowPriorityObject, {[key]: {}});
            mergeDeepRec(lowPriorityObject[key], highPriorityObject[key]);
        } else {
            Object.assign(lowPriorityObject, {[key]: highPriorityObject[key]});
        }
    }

    return lowPriorityObject;
}
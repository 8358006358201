// import styling
import "./_style.scss"

// import other stuff
import {ReactComponent as ChevronBottom} from "../../../src/assets/svg/icon-chevron-bottom.svg"
import PropTypes from "prop-types";

const Dropdown = (props) => {
    // All other props are passed on to the select element
    Dropdown.propTypes = {
        options: PropTypes.array,
    }

    return (
            <div className="custom-dropdown">
                <select {...props}>
                    {props.options.map((option, index) => {
                        return <option key={index} value={option.value}>{option.label}</option>
                    })}
                </select>
                <ChevronBottom className="chevron"/>
            </div>
        )
}

export default Dropdown;
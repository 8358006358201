import "./_style.scss";



const MetricCard = ({children ,...props}) => {
    const {className, ...rest}= props

    return (
        <div className={`metric-card ${className || ""}`} {...rest}>
            {children}
        </div>
    )
}

const MetricCardHeader = ({children ,...props}) => {
    return (
        <div className="metric-card-header" {...props}>
            {children}
        </div>
    )
}

const MetricCardTitle = ({children ,...props}) => {
    return (
        <div className="metric-card-title" {...props}>
            {children}
        </div>
    )
}

const MetricCardIcon = ({children ,...props}) => {
    return (
        <div className="metric-card-icon" {...props}>
            {children}
        </div>
    )
}

const MetricCardBody = ({children ,...props}) => {
    return (
        <div className="metric-card-body" {...props}>
            {children}
        </div>
    )
}

export {MetricCard, MetricCardHeader, MetricCardTitle, MetricCardIcon, MetricCardBody};
// import external
import React from "react";
import { Link } from "react-router-dom";


// import internal
import GridHandler from "../GridHandler";
import GridTitle from "../GridHandler/GridTitle";


// import helper
import { dateComparator, dateNowComparator } from "../GridHandler/dates.actions";
import { formatDateIfNotEmpty } from "../../actions/date.actions";


// import assets
import { ReactComponent as MagnifierIcon } from "../../assets/svg/icon-magnifier.svg";


const DamageGrid = (props) => {
    const formatAddress = (locationData) => {
        if (!locationData?.zip && !locationData?.city) return "";
        return `${locationData?.zip || ''} ${locationData?.city || ''}`;
    };


    const columnDefs = [
        {
            cellRenderer: function (params) {
                return (
                    <Link to={"/damages/" + params.data.id}>
                        <MagnifierIcon className="grid-icon" />
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            width: 65,
            suppressMenu: true,
            colId: "icon",
            suppressMovable: true
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.referenceNumber,
            valueGetter: (params) => params.data?.referenceNumber,
            colId: "referenceNumber",
            cellStyle: { fontWeight: 500 }
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.insuranceCompanyName,
            valueGetter: (params) => params.data?.insuranceCompanyName,
            colId: "insuranceCompanyName",
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.insurancePolicyNumber,
            valueGetter: (params) => params.data?.insurancePolicyNumber,
            colId: "insurancePolicyNumber",
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.damageDate,
            sort: 'desc',
            colId: "damageDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.damageDate);
            },
            filter: 'agDateColumnFilter',
            comparator: dateComparator,
            filterParams: {
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.damageBranche,
            valueGetter: (params) => params.data?.damageBranche,
            colId: "damageBranche"
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.damageLocation,
            valueGetter: (params) => formatAddress(params.data?.damageLocation),
            colId: "damageLocation"
        },
        {
            headerName: props.labels?.damagesPage?.damageInfo?.contactPersonName,
            valueGetter: (params) => params.data?.contactPersonName,
            colId: "contactPersonName"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId: "licensePlate",
            valueGetter: (params) => params.data.vehicleAax?.licencePlate,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            valueGetter: (params) => params.data.vehicleAax?.vin,
            colId: "VIN",
            cellRenderer: (params) => {
                const vin = params.data.vehicleAax?.vin;
              
                if (vin) {
                    return (
                        <Link to={"/cars/" +vin+ "?tab=car-details"}>
                        <span className="link-with-icon">
                            {vin}
                        </span>
                    </Link>
                    );
                }
                return vin || '';
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.brand,
            valueGetter: (params) => params.data.vehicleAax?.brand,
            colId: "brand"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.type,
            valueGetter: (params) => params.data?.vehicleAax?.type,
            colId: "type"
        },
    ];


    const gridTitle = (
        <GridTitle
            title={props.labels?.damagesPage?.titles?.damagesTableTitle}
            subtitle={props.labels?.damagesPage?.titles?.damagesTableSubtitle}
            badgeText={`${props.damages.length} ${props.labels?.damagesPage?.titles?.damages}`}
        />
    );


    return (
        <GridHandler
            gridTitle={gridTitle}
            data={props.damages || []}
            columnDefs={columnDefs}
            gridName="upto_damages_overview"
            fileName="upto_damages_overview"
            downloadable
            downloadTextCsv={props.labels?.otherElements?.downloadCsv}
            downloadTextExcel={props.labels?.otherElements?.downloadExcel}
        />
    );
};


export default DamageGrid;
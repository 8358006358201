// import external
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import internal component
import GridHandler from "../../../../components/GridHandler";
import "./_style.scss";
import { determineContractStatusColor } from "../../../../components/CarOverview/index"
const KmStatusGridTable = (props) => {
    const labels = useSelector(state => state.labels.currentLabels)
    const navigate = useNavigate()
    const columnDefs = [
        {
            headerName: labels.carsPage.carInfo.VIN,
            valueGetter: function (params) {
                return params.data?.vin;
            },
            colId: "vin",
            cellRenderer: (params) => {
                if (!params.value) return null;
                const pageName = `/cars/${params.value}?tab=mileage-data`;
                return (
                    <span
                        className="link-with-icon"
                        onClick={() => {
                            // When user goes to the Costs page of a car, we change the car detail page costs Datepicker
                            // dates to the currently selected one
                            navigate(pageName)
                        }}>
                        {params.value}
                    </span>
                )
            },
        },
        {
            headerName: labels.carsPage.carInfo.brand,
            valueGetter: function (params) {
                return params.data?.brand;
            },
            colId: "brand"
        },
        {
            headerName: labels.carsPage.carInfo.type,
            valueGetter: function (params) {
                return params.data?.type;
            },
            colId: "type"
        },
        {
            headerName: labels.carsPage.carInfo.plateNumber,
            valueGetter: function (params) {
                return params.data?.licensePlate;
            },
            colId: "licenseplate"
        },
        {
            headerName: labels.carsPage.carInfo.fuelType,
            valueGetter: function (params) {
                return params.data?.fuelType;
            },
            colId: "fuelType"
        },
        {
            headerName: labels.carsPage.carInfo.vehicleType,
            valueGetter: function (params) {
                return params.data?.vehicleType;
            },
            colId: "vehicleType"
        },
        {
            headerName: labels.carsPage.carInfo.kmStatus,
            valueGetter: function (params) {
                return params.data?.km;
            },
            colId: "km",
            //sort: 'desc',
        },
        {
            headerName: labels.carsPage.carInfo.status,
            colId: "status",
            valueGetter: function (params) {
                return params.data.kmPerDay > 800 || params.data.km < 0
            },
            cellRenderer: (params) => {
                const isValid = params.data.kmPerDay > 800 || params.data.km < 0
                const statusId = isValid ? -1 : 1;
                const statusText = isValid ? labels.homePage.homeInfo.dataError : labels.homePage.homeInfo.dataOkay;
                return determineContractStatusColor(statusId, statusText)
            },
            sort: 'desc'
        }

    ]
    return (
        <div className="scrollable">
            <p style={{fontSize:"14px"}}>{labels?.homePage?.metrics?.kmDataExplaination}</p>
            <GridHandler
                data={props.data}
                columnDefs={columnDefs}
                downloadable={false}
                gridName={"homepage_km_status"}
            ></GridHandler>
        </div>

    )
}

export default KmStatusGridTable
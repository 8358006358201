import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const twoFactorApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // Requests for the 2FA code to be sent to user e-mail
        sendTwoFactorCode: builder.query({
            query: () => API_FAMILY_CONST.TWO_FACTOR,
            method: 'GET',
        }),

        // Verifies whether the 2FA code is correct
        verifyTwoFactorCode: builder.mutation({
            query(code) {
                if(code.length !== 6) {
                    throw new Error("Invalid code length")
                }
                return {
                    url: API_FAMILY_CONST.TWO_FACTOR_VERIFY,
                    method: 'POST',
                    params: {
                        code: code
                    },
                }
            }
        })

    })
})

export const {
    useSendTwoFactorCodeQuery,
    useLazySendTwoFactorCodeQuery,
    useVerifyTwoFactorCodeMutation,
    useLazyVerifyTwoFactorCodeMutation
} = twoFactorApiSlice
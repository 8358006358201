import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const documentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDocuments: builder.query({
            // get all bills/invoices for a customer (not car!)
            // includeInvoices= false meaning we dont load invoices. The ones we generate are of type report, so not
            // affected.
            query: () => API_FAMILY_CONST.DOCUMENTS + "?includeInvoices=false",
            method: 'GET',
            providesTags: ['Documents'],
            //caching for 5 min
            keepUnusedDataFor: 600,
        }),
        getDocumentFile: builder.query({
            query: (documentId) => API_FAMILY_CONST.DOCUMENTS + "/" + documentId,
            method: 'GET',
        }), 
        getDocument: builder.query({
            query: (serviceId) => API_FAMILY_CONST.DOCUMENTS + "/service/" + serviceId,
            method: 'GET',
            // caching für 10 min
            keepUnusedDataFor: 600,
            // the response from this api also include base64 data 
            providesTags: (result, error, arg) => [{ type: 'Document', id: arg }],
    }),
        uploadDocument: builder.mutation({
            query: (data) => ({
                url: API_FAMILY_CONST.UPLOAD_DOCUMENT,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Documents'],
        })
})
})
export const {
    useGetDocumentsQuery,
    useGetDocumentQuery,
    useLazyGetDocumentFileQuery,
    useUploadDocumentMutation,
    usePrefetch
} = documentsApiSlice

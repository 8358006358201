// import external
import {useEffect, useReducer, useRef} from "react";

// import styling
import "./_style.scss";

/**
 *
 * I copied most of the logic off of google (things such as controls that automatically send you to next input field
 * once you type a symbol in one etc.) and added some stuff such as not needing to click a button to send api call + styling.
 *
 */

const TwoFactorInput = (props) => {
    function clampIndex(index) {
        if (index > 5) {
            return 5;
        } else if (index < 0) {
            return 0;
        } else {
            return index;
        }
    }
    function reducer(state, action) {
        switch (action.type) {
            case "INPUT":
                return {
                    inputValues: [
                        ...state.inputValues.slice(0, action.payload.index),
                        action.payload.value,
                        ...state.inputValues.slice(action.payload.index + 1)
                    ],
                    focusedIndex: clampIndex(state.focusedIndex + 1)
                };

            case "BACK":
                return {
                    inputValues: [
                        ...state.inputValues.slice(0, action.payload.index),
                        "",
                        ...state.inputValues.slice(action.payload.index + 1)
                    ],
                    focusedIndex: clampIndex(state.focusedIndex - 1)
                };

            case "PASTE":
                return {
                    inputValues: state.inputValues.map(
                        (_, index) => action.payload.pastedValue[index] || ""
                    ),
                    focusedIndex: clampIndex(state.focusedIndex + action.payload.pastedValue.length)
                };

            case "FOCUS":
                return {
                    ...state,
                    focusedIndex: clampIndex(action.payload.focusedIndex)
                };

            case "VERIFY":
                return {
                    ...state
                };

            case "VERIFY_SUCCESS":
                return {
                    ...state
                };

            default:
                throw new Error("unknown action");
        }
    }

    // This holds the values of the input fields in an array, by default: ["", "", "", "", "", ""]
    const initialState = {
        inputValues: Array(6).fill(""),
        focusedIndex: 0
    };
    const [{inputValues, focusedIndex}, dispatch] = useReducer(
        reducer,
        initialState
    );

    // This is called once user has filled out all fields or pasted in a 6 digit code
    function doSubmit(submittedValues) {
        const formattedCode = submittedValues.join("");
        // The code below clears values (otherwise a bit confusing when input has been processed)
        // now we no longer remove wrong verification code when wrong (#AMS-321), therefore code below commented out
        /*
        for (let i = 0; i < inputValues.length; i++) {
            inputValues[i] = "";
        }
        
         */
        props.handleSubmit(formattedCode);
    }


    function handleInput(index, value) {
        dispatch({type: "INPUT", payload: {index, value}})
    }

    function handleBack(index, value) {
        dispatch({type: "BACK", payload: {index, value}});
    }

    function handlePaste(index, pastedValue) {
        dispatch({type: "PASTE", payload: {index, pastedValue}});
        if (pastedValue.length === 6) {
            dispatch({type: "VERIFY"});
            // The following is actually no longer needed because we automatically submit the form once all fields are filled
            // in using useEffect, this was causing the API to be called twice
            /*
            doSubmit(pastedValue.split("")).then(() =>
                dispatch({type: "VERIFY_SUCCESS"})
            );
             */
        }
    }

    function handleFocus(focusedIndex) {
        dispatch({type: "FOCUS", payload: {focusedIndex}});
    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch({type: "VERIFY"});
        doSubmit(inputValues).then(() => dispatch({type: "VERIFY_SUCCESS"}));
    }

    // Checks if all fields have been filled in and submits the form (this is so we don't need a submit button)
    useEffect(() => {
        if (inputValues.every(value => value !== "")) {
            doSubmit(inputValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues])

    return (
        <form onSubmit={handleSubmit} style={{width: "100%"}}>
            <div className="two-factor-inputs">
                {inputValues.map((value, index) => {
                    return (
                        <Input
                            key={index}
                            index={index}
                            value={value}
                            onChange={handleInput}
                            onBackspace={handleBack}
                            onPaste={handlePaste}
                            isFocused={index === focusedIndex}
                            onFocus={handleFocus}
                            isDisabled={props.isValidating}
                            className={props.hasFailed ? "two-factor-inputs-input error" : "two-factor-inputs-input"}
                        />
                    );
                })}
            </div>

        </form>
    );
}

// We build the input fields using 6 of these:
function Input({
                   index,
                   value,
                   onChange,
                   onPaste,
                   onBackspace,
                   isFocused,
                   onFocus,
                   isDisabled,
                   className
               }) {
    const ref = useRef();
    useEffect(() => {
        requestAnimationFrame(() => {
            if (ref.current !== document.activeElement && isFocused) {
                ref.current.focus();
            }
        }
        );
    }, [isFocused]);
    function handleChange(e) {
        // If user presses space, don't treat it as input of " " but instead of arrow right
        if(e.target.value === " "){
            onFocus(index + 1);
            return;
        }
        // Only do onChange if user types input, not if they paste it (when pasting e.nativeEvent.data is undefined)
        if (e.nativeEvent.data){
            onChange(index, e.target.value);
        }
    }
    function handlePaste(e) {
        onPaste(index, e.clipboardData.getData("text"));
    }

    function handleKeyDown(e) {
        if (e.key === "Backspace") {
            onBackspace(index, e.target.value);
        }
        if (e.key === "ArrowLeft" && index > 0) {
            onFocus(index - 1);
        }
        if (e.key === "ArrowRight" && index < 6) {
            onFocus(index + 1);
        }
    }

    function handleFocus(e) {
        e.target.setSelectionRange(0, 1);
        onFocus(index);
    }

    return (
        <input
            ref={ref}
            type="text"
            value={value}
            // here it is important to use onInput instead of onChange
            onInput={handleChange}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            maxLength="1"
            onFocus={handleFocus}
            disabled={isDisabled}
            className={className}
        />
    );
}

export default TwoFactorInput;
/**
 * Used for agentLogin, encryption and decryption methods
 */
// Character dictionary, character is key and index is value, used for encryption (can use any arbitrary character dictionary but each char needs to be unique)
const charMap = {
    'è':0, 'a': 1, 'b': 2, 'c': 3, 'd': 4, 'e': 5,
    'f': 6, 'g': 7, 'h': 8, 'i': 9, 'j': 10,
    'k': 11, 'l': 12, 'm': 13, 'n': 14, 'o': 15,
    'p': 16, 'q': 17, 'r': 18, 's': 19, 't': 20,
    'u': 21, 'v': 22, 'w': 23, 'x': 24, 'y': 25,
    'z': 26, 'A': 27, 'B': 28, 'C': 29, 'D': 30,
    'E': 31, 'F': 32, 'G': 33, 'H': 34, 'I': 35,
    'J': 36, 'K': 37, 'L': 38, 'M': 39, 'N': 40,
    'O': 41, 'P': 42, 'Q': 43, 'R': 44, 'S': 45,
    'T': 46, 'U': 47, 'V': 48, 'W': 49, 'X': 50,
    'Y': 51, 'Z': 52, '0': 53, '1': 54, '2': 55,
    '3': 56, '4': 57, '5': 58, '6': 59, '7': 60,
    '8': 61, '9': 62, '!': 63, '@': 64, '#': 65,
    '$': 66, '%': 67, '^': 68, '&': 69, '*': 70,
    '(': 71, ')': 72, '-': 73, '+': 74, '=': 75,
    '{': 76, '}': 77, '[': 78, ']': 79, '|': 80,
    ':': 81, ';': 82, 'ä': 83, '"': 84, '<': 85,
    '>': 86, ',': 87, '.': 88, '?': 89, '/': 90,
    ' ': 91, '_': 92, '~': 93, 'é': 94, '§': 95,
    '€': 96, '¥': 97, '£': 98, '¢': 99, 'ü': 100
};

const CHAR_SUM = 1000;

// Generate an array of numbers that sum up to CHAR_SUM (1000 on default)
function generateNumberArray() {
    let array = [];
    let sum = 0;

    while (sum < CHAR_SUM) {
        // Generate a random number between 0 and number of characters in charMap
        let number = Math.floor(Math.random() * Object.keys(charMap).length);

        // Check if adding this number exceeds 1000
        if (sum + number > CHAR_SUM) {
            // Adjust the number so that the sum is exactly 1000
            number = CHAR_SUM - sum;
        }

        // Add the number to the array and update the sum
        array.push(number);
        sum += number;
    }

    return array;
}

/**
 * Generates an encrypted string by first using generateNumberArray() to generate an array of indexes summing up to CHAR_SUM,
 * then we use charMap to convert the index array into a character string and then we add random characters until its length is 40,
 * finally we add the original length of the string, encrypted as a character using charMap, as a final character to the string (to decrypt later)
 **/
export function generateEncryptedString() {
    // Get array of numbers that sum up to CHAR_SUM
    const array = generateNumberArray();

    // Init empty string
    let string = "";

    // For each number in array, find the character in charMap that has that index and add it to the string
    for (let i = 0; i < array.length; i++) {
        let char = Object.keys(charMap).find(key => charMap[key] === array[i]);
        string += char;
    }
    // Remember the current length of the string, since the string only holds our relevant information up to this point and we will be adding random characters to it
    const encryptedLength = string.length;

    // Add random characters until the string is 40 characters long
    while(string.length < 40){
        let randomKey = Math.floor(Math.random() * Object.keys(charMap).length);
        string += Object.keys(charMap).find(key => charMap[key] === randomKey);
    }

    // Add the original length of the string as a character to the end of the string (basically a checkSum to decrypt later)
    string += Object.keys(charMap).find(key => charMap[key] === encryptedLength);

    // Return the encrypted string, which is 41 characters long
    return string;
}

// Accepts a string and returns the sum of the characters' indexes in charMap as an integer
function getArraySum(string) {
    // Initialize sum at 0
    let sum = 0;
    for (let i = 0; i < string.length; i++) {
        // For each character in string, add its value in charMap to the sum
        const character = string[i];
        sum += charMap[character];
    }
    // Return the sum
    return sum;
}


/**
 * Accepts a string, converts the final character to an integer (its integer in charMap) and checks if the ArraySum of
 * the string up until that integer/checkSumIndex is equal to CHAR_SUM.
 * In theory (unless encryption was broken), if this method evaluates to TRUE, then the string was created using our encryption method
 */
export function checkEncryptedString(string){
    // The final character of string should be our checksum
    const checkSumCharacter = string[string.length - 1];

    // Turn the character into an integer (its index), which gives us length of encrypted string at which the sum starting from 0 should be 1000
    const checkSumIndex = charMap[checkSumCharacter];

    // Get the sum of the indexes of the characters in the string
    const encryptedStringSum = getArraySum(string.slice(0, checkSumIndex));

    // Check if the sum is equal to CHAR_SUM
    return encryptedStringSum === CHAR_SUM;
}
import "./_style.scss";


export const Skeleton = ({height, width,...props}) => {


    return (
        <span className="loading-skeleton"
              style={{height: height, width: width}}
              {...props}
        />
    )
}
/*
Purpose: render each driver in the drivers array
*/

// import external library
import React from "react";
// import assets
import {ReactComponent as DriverAvatar} from "../../assets/svg/circle-user-regular.svg";
// import styling
import "./_style.scss"
import {formatDateIfNotEmpty} from "../../actions/date.actions";

const DriverInfoCard = (props) => {
    let labels=props.labels
    return (
        <div className="driver-info-card-container">
            <div className="driver-avatar-container">
                <DriverAvatar className="driver-avatar"/>
            </div>
            <div className="driver-info-container">
                <b>{props.driver?.firstName + " " + props.driver?.lastName}</b>
                {props.driver?.fromDate ? <span>{`${labels.startDate}: ${formatDateIfNotEmpty(props.driver.fromDate)}`}</span> : <></>}
                {props.driver?.endDate ? <span>{ `${labels.endDate}: ${formatDateIfNotEmpty(props.driver.endDate)}`} </span> : <></>}
            </div>
        </div>
    )
}

export default DriverInfoCard
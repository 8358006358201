import React from 'react';

export const statusCellRenderer =(props) => {
  const cellValueFormatted = props.valueFormatted ? props.valueFormatted : props.value;
  const cellValue = props.value ? props.value : '';
  const negativeText = props.negativeText ? props.negativeText : '';
  const positiveText = props.positiveText ? props.positiveText : '';
  let content;
  if (cellValue === negativeText) {
    content=
      <div>
        <span className={(props.c_name ? props.c_name : 'status-tag') + '-negative'}>{cellValueFormatted}</span>
      </div>
  
  } else if (cellValue === positiveText) {
    content=
      <div>
        <span className={(props.c_name ? props.c_name : 'status-tag') + '-positive'}>{cellValueFormatted}</span>
      </div>
  }else{
    content=
    <div>
        <span className={(props.c_name ? props.c_name : 'status-tag') + '-neutral'}>{cellValueFormatted}</span>
      </div>
  }

  return content;
  
};
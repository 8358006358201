//import styling
import "./_style.scss";

//import internal
import FormModal from "../../../../components/FormModal";
import DatePickerHandler from "../../../../components/DatePickerHandler";
import FeatureIcon from "../../../../components/FeatureIcon";

// import assets
import {ReactComponent as PlusIcon} from "../../../../assets/svg/icon-plus.svg";
import {ReactComponent as CarIcon} from "../../../../assets/svg/icon-car.svg";

//import external
import React, {useState} from "react";
import Moment from "moment";

//Handles the license plate modal (button that opens modal and modal itself)
const LicensePlateModal = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [newLicensePlateData, setNewLicensePlateData] = useState({
        licencePlate: "",
        fromDate: "",
        endDate: "",
        isCurrent: false, //Checkbox where the user can confirm whether this is the current license plate
    });

    //Handles changes for string input fields
    const handleChange = (e) => {
        const [name, value] = [e.target.name, e.target.value];
        setNewLicensePlateData({...newLicensePlateData, [name]: value})
    }

    //Handles changes for checkbox/boolean input fields
    const handleCheckboxChange = (e) => {
        const [name, value] = [e.target.name, e.target.checked];
        setNewLicensePlateData({...newLicensePlateData, [name]: value})
    }


    const handleFromDateChange = (date) => {
        if(!date) {
            setNewLicensePlateData({...newLicensePlateData, fromDate: ""});
            return;
        }
        const formattedDate = Moment(date).format("YYYY-MM-DD");
        setNewLicensePlateData({...newLicensePlateData, fromDate: formattedDate});
    }

    const handleEndDateChange = (date) => {
        if(!date) {
            setNewLicensePlateData({...newLicensePlateData, endDate: ""});
            return;
        }
        const formattedDate = Moment(date).format("YYYY-MM-DD");
        setNewLicensePlateData({...newLicensePlateData, endDate: formattedDate});
    }

    return (
        <div hidden={props.hidden}>
            {props.isLoading ?
                <button className="btn btn-primary btn-disabled" >
                    <div className="dot-falling-container">
                        <div className="dot-falling"/>
                    </div>
                </button>
                :
            <button  className="btn btn-primary sm"  onClick={() => setShowModal(true)}>
                <PlusIcon style={{stroke:"white"}}/>
                {props.labels?.otherElements?.addEntry}
            </button>}

            <FormModal show={showModal}
                       onOutsideClick={() => setShowModal(false)}
                       onClose={() => {
                       setShowModal(false)
                       setNewLicensePlateData({licencePlate: "", fromDate: "", endDate: "",isCurrent: false})
                       }}
                       title={props.labels?.carsPage?.titles?.addLicensePlate}
                       featuredIcon={
                       <FeatureIcon>
                           <CarIcon style={{stroke:"#344054",margin:"0"}}/>
                       </FeatureIcon>
                   }
            >
                {/* Modal Content (Form) */}
                <div className="form-modal-content">
                    <div className="form-modal-input-container">
                        <div className="form-modal-input-title">
                            {props.labels?.costPage?.costInfo?.plateNumber}
                            <span className="required-label">
                                        ({props.labels?.otherElements?.required})
                                    </span>
                        </div>
                        <div className="form-modal-input-container-field">
                            <input className="input-field-primary" type="text" name="licencePlate" value={newLicensePlateData.licencePlate} onChange={e => handleChange(e)} style={{width:"100%"}}/>
                        </div>
                    </div>

                    <div className="form-modal-input-container">
                        <div className="form-modal-input-title">
                            {props.labels?.carsPage?.carInfo?.startDate}
                            <span className="required-label">
                                        ({props.labels?.otherElements?.required})
                            </span>
                        </div>
                        <div className="form-modal-input-container-field">
                            <DatePickerHandler onChange={handleFromDateChange}/>
                        </div>
                    </div>

                    <div className="form-modal-input-container">
                        <div className="form-modal-input-title">
                            {props.labels?.carsPage?.carInfo?.endDate}
                        </div>
                        <div className="form-modal-input-container-field">
                            <DatePickerHandler onChange={handleEndDateChange}/>
                        </div>
                    </div>

                    <div className="form-modal-input-container with-checkbox">
                        <label htmlFor="endCurrentPlate">
                        {props.labels?.carsPage?.carInfo?.setAsCurrentLicensePlate}
                        </label>
                        <input id="endCurrentPlate" className="check-box-primary" type="checkbox" name="isCurrent" checked={newLicensePlateData.isCurrent} onChange={e => handleCheckboxChange(e)}/>

                    </div>
                </div>

                {/* Modal Footer */}
                <div className="form-modal-footer">
                    <button
                        onClick={() => {
                            setShowModal(false)
                            setNewLicensePlateData({licencePlate: "", fromDate: "", endDate: "",isCurrent: false})
                            }}
                        className="btn btn-light"
                        style={{width: "45%"}}
                    >
                        {props.labels?.otherElements?.cancel}
                    </button>
                    <button
                        onClick={() => {
                            setShowModal(false);
                            props.onSubmit(newLicensePlateData);
                        }}
                        disabled={newLicensePlateData.licencePlate === "" || newLicensePlateData.fromDate === ""}
                        className="btn btn-primary"
                        style={{width: "45%"}}
                    >
                        {props.labels?.otherElements?.save}
                    </button>
                </div>
            </FormModal>
        </div>
    )
}

export default LicensePlateModal;
import {AgCharts} from "ag-charts-react";
import {sanitizeValues} from "../../../actions/sanitize";


const ActiveVehiclesChart = ({ data, labels }) => {
    if (!data) return null

    const parsedData = [...data].map((item) => {
        if (item.fuel === "null") {
            return {
                ...item,
                fuel: labels?.homePage?.homeInfo?.unknown
            }
        }
        return item
    })



    const totalCars = data.reduce((acc, curr) => acc + curr.cars, 0)

    const options = {
        data: parsedData,
        padding: {
            top: 0,
            bottom:0,
            //left:0,
            right: 0,
        },
        height: 125,
        series: [
            {
                type: 'donut',
                calloutLabelKey: 'fuel',
                fillOpacity: 1,
                strokeWidth: 0,
                angleKey: 'cars',
                sectorLabelKey: 'fuel',
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    enabled: false,
                },
                fills: [
                    '#00006D',
                    '#5C5CB7',
                    '#015A66',
                    '#41949F',
                    '#2f4b7c',
                    '#665191',
                    '#f95d6a',
                    '#ffa600',
                    '#ffabb3',
                    '#ffe0ff',
                    '#caf75d'
                ],
                innerRadiusRatio: 0.5,
                innerLabels: [
                    {
                        text: String(totalCars),
                        fontSize: 16,
                        color: 'black',
                        fontWeight: 'bold',
                        fontFamily: 'Space Grotesk'

                    },
                ],
                highlightStyle: {
                    item: {
                        fillOpacity: 0,
                        stroke: '#ffffff',
                        strokeWidth: 1
                    },
                },
                tooltip: {
                    renderer: function (params) {
                        params = sanitizeValues(params);
                        return {
                            content: `
                            <div class="type-tooltip fw-light" > 
                            <span class="fw-semibold">
                                ${params?.datum?.fuel}
                            </span>
                                 ${params?.datum?.cars} ${labels?.carsPage?.titles?.vehicles}
                                 
                            </div>`
                        }
                    }
                }
            }
        ],
        legend: {
            position: 'right',
            orientation: 'horizontal',
            spacing:10,
            item: {
                marker: {
                    shape: 'circle',
                    strokeWidth: 0,
                    size: 5
                },
                label: {
                    color: '#475467',
                    fontFamily: 'Space Grotesk',
                    formatter: function (params) {
                        const fuel = params.value;
                        const amount = parsedData.find((item) => item.fuel === fuel).cars

                        return `${fuel}: ${amount}`
                    }
                }
            }
        }
    }



    return (
        <AgCharts options={options} />

    )
}

export default ActiveVehiclesChart;
/*
This file takes out the api calling and error handling logic out of InvoiceOverview and CostOverview,
and handles them centrally here.
InvoiceOverview and CostOverview will only define the columns of AG grid.
*/

// import external library
import React from "react";
// import styling
import "./_style.scss"
// import internal helper functions
import {dateNowComparator, dateComparator} from "../GridHandler/dates.actions";

// import api slices
import {useGetInvoicesQuery} from "../../redux/apiSlice/invoiceApiSlice";
import {createErrorMessage} from "../AlertContainer/alert.utils";
import LoadingComponent from "../LoadingComponent";
import SupplierInvoicesOverview from "../SupplierInvoicesOverview";
import InvoicePositionsOverview from "../InvoicePositionsOverview";

/**
 *
 * @param {labels, titleSize} props
 * contains 2 components that further create AG grid: one for invoices positions, one for invoices
 * Now only needed for car details page
 * @returns
 */
const CarLevelInvoiceAndPositions = (props) => {
    // this vin is undefined or a string.
    const {vin} = props
    // load invoices from endpoint
    const {
        data: invoices,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetInvoicesQuery(vin);
    let content;
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>
    } else if (isSuccess) { // handle successful data loading
        // if property is available
        if (invoices && invoices.length > 0) {
            // show grid with the data
            content = (
                <>
                    <div className="section-card">
                        <div className="section-container-content">
                            <SupplierInvoicesOverview
                                vin={props.vin}
                                carId={props.carId}
                                gridSortingFunc={dateComparator}
                                gridFilteringFunc={dateNowComparator}
                                labels={props.labels}
                                title={props.labels?.supplierInvoicesPage?.titles?.supplierInvoicesOverview}
                            >
                            </SupplierInvoicesOverview>
                        </div>
                    </div>
                     {/*below should be the table with positions for a car*/}
                    <div className="section-card">
                        <div className="section-container-content">
                            <InvoicePositionsOverview
                                vin={props.vin}
                                carId={props.carId}
                                gridSortingFunc={dateComparator}
                                gridFilteringFunc={dateNowComparator}
                                titleSize = {props.titleSize}
                                labels={props.labels}
                                title={props.labels?.costPage?.titles?.invoicePositionsOverview}
                                invoiceRawData={invoices}></InvoicePositionsOverview>
                        </div>
                    </div>
                </>
            )
        } else {
            // if no invoice was loaded
            if (invoices) {
                content = (
                    <div className="section-card">
                        <div className="section-container-content">
                            {createErrorMessage(error, "", props.titleSize,props.labels)}
                        </div>
                    </div>
                )
            } else {
                content = (
                    <div className="section-card">
                        <div className="section-container-content">
                            {createErrorMessage(error, "", props.titleSize,props.labels)}
                        </div>
                    </div>
                )
            }

        }
    } else if (isError) {
        // show different error messages based on status code
        content = (
            <div className="section-card">
                <div className="section-container-content">
                    {createErrorMessage(error, props.labels.costPage.titles.invoicesOverview, props.titleSize,props.labels)}
                </div>
            </div>
        )
    }

    return (
        <>
            {content}
        </>
    )
}

export default CarLevelInvoiceAndPositions

// external library
import {Route, Routes, useLocation} from "react-router-dom";
import {useVariableValue, withDevCycleProvider} from '@devcycle/react-client-sdk'
import TagManager from 'react-gtm-module'

// styling
import './app.scss';

// react components
import LoginPage from "./pages/LoginPage";
import MyCarsPage from "./pages/MyCarsPage";
import MyBillsPage from "./pages/MyBillsPage";
import PrivateRoutes from "./routing/privateRoutes";
import {lazy, Suspense, useEffect} from "react";
import {useSelector} from "react-redux";
import CarDetailPage from "./pages/CarDetailsPage";
import MyDriversPage from "./pages/MyDriversPage";
import CostOverviewPage from "./pages/MyCostOverviewPage";
import DriverDetailsPage from "./pages/DriverDetailsPage";
import InvoiceDetailPage from "./pages/InvoiceDetailsPage";
import NotFoundPage from "./pages/NotFoundPage";
import FallbackPage from "./pages/FallbackPage";
import AgentLogin from "./routing/agentLogin";
import MyFuelCardsPage from "./pages/MyFuelCardsPage";
import FuelCardDetailsPage from "./pages/FuelCardDetailsPage";
import MyDamagesPage from "./pages/MyDamagesPage";
import DamageDetailsPage from "./pages/DamageDetailsPage";
import SupplierInvoicesPage from "./pages/MySupplierInvoicesPage";
import BillDetailsPage from "./pages/BillDetailsPage";
import MyDocumentsPage from "./pages/MyDocumentsPage";
import HomePage from "./pages/HomePage";
import {getPageName, getUserData} from "./actions/eventTracking.actions";
import {determineLanguage} from "./actions/language.actions";

const tagManagerArgs = {
    gtmId: 'GTM-WB6KZQLC',
}

TagManager.initialize(tagManagerArgs)


// import MyAccountPage from "./pages/MyAccountPage";
// lazy import MyAccountPage because it is using a big password strength checking library
const MyAccountPage = lazy(() => import("./pages/MyAccountPage"))

function App() {
    const location = useLocation()

    const access_token = useSelector(state => state.auth.access_token)
    const isAgentLogin = useSelector(state => state.auth.isAgentLogin)
    // Whenever location changes, we run this (for GA pageLoad event tracking)
    useEffect(() => {
        // This should technically never be the case, but if it is we handle it here so it doesn't crash page
        if (!window.dataLayer) return;

        // Get user data (customer/user ID), is null if user logged out
        const userData = getUserData(access_token);

        const pageName = getPageName()

        const is404 = pageName === "/404"

        window.dataLayer.push({
            event: 'pageLoad',
            page_name: pageName,
            page_lang: determineLanguage().toLowerCase(),
            hrco: is404 ? 404 : 200,
            vlst: !!userData,
            user_id: userData?.user_id, // User ID
            user_pers_id: userData?.user_pers_id, // Customer ID
            int_visit: isAgentLogin
        });
        // eslint-disable-next-line
    }, [location])


    const hasHomePageFlag = useVariableValue("home-page", false)


    return (
        <div className="App">
            <Suspense fallback={<FallbackPage></FallbackPage>}>
                <Routes>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/agentLogin" element={<AgentLogin/>}/>
                    <Route element={<PrivateRoutes/>}>
                        <Route
                            path="/"
                            element={hasHomePageFlag ? <HomePage/> : <MyCarsPage/>}
                        />
                        <Route path="/cars" element={<MyCarsPage/>}/>
                        <Route path="/cars/:vin" element={<CarDetailPage/>}/>
                        <Route path="/drivers" element={<MyDriversPage/>}/>
                        <Route path="/drivers/:id" element={<DriverDetailsPage/>}/>
                        <Route path="/costs" element={<CostOverviewPage/>}/>
                        <Route path="/costs-v2" element={<CostOverviewPage/>}/>
                        <Route path="/supplierInvoices" element={<SupplierInvoicesPage/>}/>
                        <Route path="/supplierInvoices/:id" element={<InvoiceDetailPage/>}/>
                        <Route path="/account" element={<MyAccountPage/>}/>
                        <Route path="/fuelcards" element={<MyFuelCardsPage/>}/>
                        <Route path="/fuelcards/:id" element={<FuelCardDetailsPage/>}/>
                        <Route path="/bills" element={<MyBillsPage/>}/>
                        <Route path="/bills/:billNumber" element={<BillDetailsPage/>}/>
                        <Route path="/damages" element={<MyDamagesPage/>}/>
                        <Route path="/damages/:id" element={<DamageDetailsPage/>}/>
                        <Route path="/documents" element={<MyDocumentsPage/>}/>
                        <Route path="/*" element={<NotFoundPage/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </div>
    );
}

export default withDevCycleProvider({
        sdkKey: `${process.env.REACT_APP_DEVCYCLE_CLIENT_KEY}`,
        options: {
            deferInitialization: true
        }
    },
)(App)

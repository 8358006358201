// import styling
import "./_style.scss"

// Import rest
import { useGetCarFinancingQuery } from "../../../redux/apiSlice/dashboardApiSlice";
import { formatDateIfNotEmpty } from "../../../actions/date.actions";
import { dateComparator, dateNowComparator } from "../../../components/GridHandler/dates.actions";
import React from "react";
import GridHandler from "../../../components/GridHandler";
import { createErrorMessage } from "../../../components/AlertContainer/alert.utils";
import LoadingComponent from "../../../components/LoadingComponent";
import { formatNumber } from "../../../actions/formateNumbers.actions";
import GridTitle from "../../../components/GridHandler/GridTitle";
import { CurrentFinancingCard } from "./CurrentFinancingCard";
import { DocumentListCard } from "./DocumentListCard";


export const CarFinancingTab = ({ car, labels }) => {

    const {
        data: originalData,
        isLoading,
        error,
    } = useGetCarFinancingQuery({
        vin: car?.vin,
        includeDocuments: true,
    });


    const columnDefs = [
        {
            headerName: labels?.carsPage?.carInfo.financingType,
            colId: "financingType",
            valueGetter: (params) => params.data.financingType,
        },
        {
            headerName: labels?.carsPage?.carInfo.financingContractStart,
            colId: "startDate",
            valueGetter: (params) => params.data.startDt,
            // Format Date
            valueFormatter: (params) => formatDateIfNotEmpty(params.value),
            filter: 'agDateColumnFilter',
            comparator: dateComparator,
            // Required for date filtering
            filterParams: {
                comparator: dateNowComparator
            },
            sort: 'desc',
        },
        {
            headerName: labels?.carsPage?.carInfo.financingContractEnd,
            colId: "endDate",
            valueGetter: (params) => params.data.endDt,
            // Format Date
            valueFormatter: (params) => formatDateIfNotEmpty(params.value),
            filter: 'agDateColumnFilter',
            comparator: dateComparator,
            // Required for date filtering
            filterParams: {
                comparator: dateNowComparator
            },
        },
        {
            headerName: labels?.carsPage?.carInfo.financingProvider,
            colId: "financingProviderName",
            valueGetter: (params) => params.data.financingProvider?.companyName,
        },
        {
            headerName: labels?.carsPage?.carInfo.contractPartner,
            valueGetter: (params) => params.data.contractPartner?.companyName,
            colId: "contractPartner",
        },
        {
            headerName: labels?.carsPage?.carInfo.contractDuration + " " + labels?.carsPage?.carInfo.monthsUnit,
            valueGetter: (params) => params.data.contractDuration,
            colId: "contractDuration"
        },
        /*
        {
            headerName: labels?.carsPage?.carInfo.amountMonthly,
            valueGetter: (params) => params.data.amountMonthly,
            colId: "amountMonthly",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.priceGross,
            valueGetter: (params) => params.data.priceGross,
            colId: "priceGross",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.financedAmount,
            valueGetter: (params) => params.data.financedAmount,
            colId: "financedAmount",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
         */
        {
            headerName: labels?.carsPage?.carInfo.priceNet,
            valueGetter: (params) => params.data.priceNet,
            colId: "priceNet",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.deposit,
            valueGetter: (params) => params.data.deposit,
            colId: "deposit",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.remainingValue,
            valueGetter: (params) => params.data.remainingValue,
            colId: "remainingValue",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.mileagePerYear,
            valueGetter: (params) => params.data.mileagePerYear,
            colId: "mileagePerYear",
            valueFormatter: (params) => formatNumber(params.value),
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: labels?.carsPage?.carInfo.notes,
            valueGetter: (params) => params.data.notes,
            colId: "notes"
        },
    ]



    // If Loading show spinner
    if (isLoading) return <LoadingComponent show={true} />
    // If an error occurred or the Data is empty, show error message
    else if (error || !originalData.length) return createErrorMessage(error, "", "h2", labels);

    // Function to get rid of duplicate financing entries (based off of ID)
    function getUniqueFinancingData(data) {
        const key = it => it.id;
        return [
            // creating a Map object with the id: key(x) as the key and the content of x (x) as value
            // Map is like a normal object except there is no duplicate keys allowed
            // when duplicated, the latter one is kept
            ...new Map(
                data.map(x => [key(x), x])
            ).values()
        ]
    }

    function getCurrentContract(contracts) {
        /**
         * Select contract with the newest start date, unless it has ended
        **/
        function isContractOngoing(contract) {
            // Returns true if contact has no endDate or endDate is in future
            return !contract.endDt || (new Date(contract.endDt) > new Date())
        }
        // Sort the contracts by start Date
        const sorted = contracts.sort((a, b) => new Date(b.startDt) - new Date(a.startDt))

        // Find most recent contract which is still ongoing
        for (const contract of sorted) {
            if (isContractOngoing(contract)) return contract
        }
        // No contract is ongoing
        return null
    }

    // Filter out duplicates in data
    const data = getUniqueFinancingData(originalData)

    // Get the current contract using the function above
    const currentContract = getCurrentContract(data)

    // Select the remaining contracts
    const remainingContracts = data.filter((financingEntry) => financingEntry?.id !== currentContract?.id);


    return (
        <>
            {   // Only show if we were able to determine an active contract
                currentContract && (
                    <div className="primary-content">
                        <div className="card-wrapper" style={{ maxWidth: "700px" }}>
                            <GridTitle
                                title={labels?.carsPage?.titles?.ongoingFinancing}
                                subtitle={labels?.carsPage?.carInfo?.ongoingFinancingSubtitle}
                            />

                            <CurrentFinancingCard data={currentContract} labels={labels} />
                        </div>

                        <div className="card-wrapper"
                            hidden={!currentContract?.documents || !currentContract?.documents?.length}>
                            <GridTitle
                                title={labels?.carsPage?.carInfo?.availableDocuments}
                                subtitle={labels?.carsPage?.carInfo?.carFinancingDocumentsSubtitle}
                            />

                            <DocumentListCard documents={currentContract?.documents} labels={labels} />
                        </div>

                    </div>
                )
            }

            {
                // Hide if there are no other contracts besides active one
                remainingContracts?.length ?(
                    <GridHandler
                        gridTitle={<GridTitle
                            title={labels?.carsPage?.titles?.completedFinancing}
                            subtitle={labels?.carsPage?.carInfo?.completedFinancingSubtitle}
                            badgeText={remainingContracts?.length + " " + labels?.carsPage?.carInfo?.contracts}
                        />}
                        downloadable={false}
                        gridName={`upto_car_financing_completed_${car?.vin}`}
                        data={remainingContracts}
                        columnDefs={columnDefs}
                        fileName={`upto_car_financing_completed_${car?.vin}`}
                    />
                ):<></>
            }
        </>
    )
}
// import styling
import "./_style.scss";


const StatusMessage = ({variant, ...props}) => {
    if (props?.children?.find((child) => child?.type?.name === "StatusMessageIcon")) {
        const icon = props?.children?.filter((child) => child?.type?.name === "StatusMessageIcon");
        const otherChildren = props?.children?.filter((child) => child?.type?.name !== "StatusMessageIcon");

        return (
            <div
                className={"status-message with-icon " + variant}
                {...props}
            >
                {icon}
                <div className={"status-message-content"}>
                    {otherChildren}
                </div>
            </div>
        )
    }


    return (
        <div
            className={"status-message " + variant}
            {...props}
        >
            {props.children}
        </div>
    )
}


const StatusMessageTitle = (props) => {
    return (
        <div
            className="title"
        >
            {props.children}
        </div>
    )
}

const StatusMessageContent = (props) => {
    return (
        <div
            className="content"
        >
            {props.children}
        </div>
    )
}

const StatusMessageIcon = (props) => {
    return (
        <div
            className="status-icon"
        >
            {props.children}
        </div>
    )
}



export {StatusMessage, StatusMessageContent, StatusMessageTitle, StatusMessageIcon}
import {z} from 'zod';
import {zodDateInputPipe, zodNumberInputPipe, zodStringPipe} from "./FormPipes";
import Moment from "moment";
import {isAfter} from "date-fns";


export const createCarSchema = (labels) => z.object({
    organisation: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number())),
    registrationCountry: zodStringPipe(z.string().or(z.null()).or(z.undefined())),
    vin: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    licencePlate:  zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    licencePlateStartDate: zodDateInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    brand: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})), // Nissan
    type: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})), // Micra
    model: zodStringPipe(z.string().or(z.null())),

    serialNumber: zodStringPipe(z.string().or(z.null())),
    typeCertificationNumber: zodStringPipe(z.string().or(z.null())),

    vehicleType: zodStringPipe(z.string().or(z.null())),
    fuelType: zodStringPipe(z.string().or(z.null())),
    mileage: zodNumberInputPipe(z.string().or(z.null()).or(z.number())),
    // Mileage date is required if mileage is provided
    mileageDate: zodDateInputPipe(z.string().or(z.null())),
    registrationDate: zodDateInputPipe(z.string().or(z.null())),
    vehicleRegistrationFile: z.object({
        name: z.string().or(z.undefined().or(z.null())),
        size: z.number().or(z.undefined().or(z.null())),
        type: z.string().or(z.undefined().or(z.null())).refine(data => {
            if (data !== 'application/pdf' && data !== 'image/jpeg' && data !== 'image/png') {
                return false
            }
            return true
        }, {message: labels?.fileTypeNotSupported + ' (pdf, jpeg, png)'}),
    }).or(z.string()).optional(),
}).refine(data => {
    if (data.mileage !== null && !data.mileageDate) {
        return false
    }
    return true
}, {message: labels?.mileageDateRequiredIfMileageProvided, path: ['mileageDate']})

export const editCarDataSchema = (labels) => z.object({
    // Allgemein
    brand: z.string().optional(),// Nissan
    type: z.string().optional(),// Micra
    model: z.string().optional(), // ...
    color:z.string().optional(), // ...,
    status: z.string().optional(),
    engineDisplacement:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),//1888
    powerInKw:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(), // number, unit is kw
    transmissionType:z.string().optional(),
    emissionStandard:z.string().optional(),
    co2GramPerKm:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    plateColor:z.string().optional(),
    // Permission
    registrationCountry: z.string().optional(),
    vehicleType: z.string().optional(),
    stammnummer: z.string().optional(),
    yearBuild:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    licencePlateFirstDate:  zodDateInputPipe(z.string().or(z.null())).optional(),
    lastMotorVehicleInspectionDate:zodDateInputPipe(z.string().or(z.null())).optional(),
    // fuel
    fuelType: z.string().optional(),
    tankSize: zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    combinedFuelConsumptionLitrePer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    cityFuelConsumptionLitrePer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    highwayFuelConsumptionLitrePer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    batteryCapaNet:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    combinedEnergyConsumptionKwhPer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    cityEnergyConsumptionKwhPer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    highwayEnergyConsumptionKwhPer100Km :zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    // payload
    emptyWight:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    payloadCapa:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    totalWeight: zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    towingCapa:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
    numberOfSeats:zodNumberInputPipe(z.string().or(z.null()).or(z.number())).optional(),
})


export const createDriverSchema = (labels) => z.object({
    carId: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number({errorMap: () => ({message: labels?.fieldIsMandatory})}))),
    existingDriver: z.boolean(),
    endPreviousConnection: z.boolean(),
    endPreviousConnectionDate: zodDateInputPipe(z.string().or(z.null())),
    parentCustomer: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number({errorMap: () => ({message: labels?.fieldIsMandatory})}))),
    startDt: zodDateInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    childCustomer: z.object({
        firstName: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
        lastName: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
        email: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
        birthday: zodDateInputPipe(z.string().or(z.null())),
        status: z.object({
            id: zodNumberInputPipe(z.string().or(z.null()).or(z.number())),
            //description: zodStringPipe(z.string().or(z.null())),
        }),
        salutation: z.object({
            id: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number({errorMap: () => ({message: labels?.fieldIsMandatory})}))),
            //description: zodStringPipe(z.string().or(z.null())),
        }),
        phone: z.object({
            countryCode: zodStringPipe(z.string().or(z.null().or(z.undefined()))),
            cli: zodStringPipe(z.string().or(z.null().or(z.undefined()))),
            areaCode: zodStringPipe(z.string().or(z.null().or(z.undefined()))),
            phoneType: z.object({
                id: zodNumberInputPipe(z.string().or(z.null()).or(z.number())),
            }),
            //phone: zodStringPipe(z.string().or(z.null())),
        }),
        mainAddress: z.object({
            street: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
            zip: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
            city: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
            houseNumber: zodStringPipe(z.string().or(z.null())),
            //country: z.object({id: zodNumberInputPipe(z.string().or(z.null()).or(z.number())), description: zodStringPipe(z.string().or(z.null())),}),
        }),
        language: z.object({
            id: zodNumberInputPipe(z.string().or(z.null()).or(z.number())),
            //description: zodStringPipe(z.string().or(z.null())),
        })
    }),
}).refine(data => {
    if (data.endPreviousConnection && !data.endPreviousConnectionDate) {
        // If termination of previous connection is ticked, then a date needs to be provided
        return false
    }
    return true
}, {message: labels?.fieldIsMandatory, path:["endPreviousConnectionDate"]})


export const createFuelCardSchema = (labels) => z.object({
    id: z.any(),
    carId: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number({errorMap: () => ({message: labels?.fieldIsMandatory})}))),
    existingFuelCard: z.boolean(),
    cardNumber: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    cardName: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    expirationDate: zodDateInputPipe(z.string().or(z.null())).or(z.undefined()),
    pin: zodStringPipe(z.string().or(z.null().or(z.undefined()))),
    note: zodStringPipe(z.string().or(z.null().or(z.undefined()))),
    provider: z.object({
        id: z.any(),
        companyName: z.any(),
        customerId: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    }),
    companyData: z.object({
        id: z.any(),
        customerId: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    }, {errorMap: () => ({message: labels?.fieldIsMandatory})}),
    startDate: zodDateInputPipe(z.string().or(z.null())),
    endDate: zodDateInputPipe(z.string().or(z.null())),
}).refine(data => {
    if (data.existingFuelCard && !data.startDate) {
        // If we are adding existing fuel card connection, startDate is required
        return false
    }
    return true
}, {message: labels?.fieldIsMandatory, path:["startDate"]})

export const createDamageSchema = (labels) => z.object({
    vin: zodStringPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    damageBranche: z.string().optional(),
    insuranceCompanyName: z.string().optional(),
    insurancePolicyNumber: z.string().optional(),
    damageDate: zodDateInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    description: z.string().optional(),
    carId: z.number().optional(),
})

export const createChangeDriverCarLinkSchema = (labels) => z.object({
    // default is false
    makeDriverInactive: z.boolean(),
    linkStartDate: zodDateInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    // linkEndDate is required if the user wants to make driverInactive (makeDriverInactive==true)
    linkEndDate: zodDateInputPipe(z.string().or(z.null()))
}).refine(data => {
    if (data.makeDriverInactive && !data.linkEndDate) {
        // false means that the form is invalid
        return false
    }
    return true
}, {message: labels?.endDateRequiredIfSetInactive, path: ['linkEndDate']}).refine(data => {
    if (data.linkStartDate && data.linkEndDate) {
        if(Moment(data.linkEndDate)<Moment(data.linkStartDate)){
            return false
        }
    }
    return true
}, {message: labels?.endDateAfterStartDate, path: ['linkEndDate']})


export const addDriverCarRelationSchema = (labels) => z.object({
    startDt: zodDateInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})})),
    endDt: zodDateInputPipe(z.string().or(z.null())),
    carId: zodNumberInputPipe(z.string({errorMap: () => ({message: labels?.fieldIsMandatory})}).or(z.number({errorMap: () => ({message: labels?.fieldIsMandatory})}))),
    //organisationCustomerId: zodNumberInputPipe(z.string().or(z.number())),
    //driver: zodNumberInputPipe(z.string().or(z.number())),
    //car: zodNumberInputPipe(z.string().or(z.number())),
    endLastConnection: z.boolean(),
}).refine(data => {
    if (data?.endDt && isAfter(new Date(data?.startDt), new Date(data?.endDt))) {
        // End date must be after start date
        return false
    }
    return true
}, {message: labels?.startDateBeforeEndDate, path: ['endDt']})
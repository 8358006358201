// import external
import {useState} from "react";
import {AgCharts} from "ag-charts-react";
import {convertAndFormat} from "../../../actions/formateNumbers.actions";
import {sanitizeValues} from "../../../actions/sanitize";
import {chartNumberFormatter} from "../../../actions/magicNumber.actions";

// Returns an ag grid column chart for the fleet costs per cost type data
const DiagramPerCostType = (props) => {
    const theme = {
        palette: {
            fills: [
                '#A1A1FF',
                '#00008F',
            ],
            strokes: ['transparent'],
        },
        overrides : {
            column: {
                legend: {
                    enabled: true,
                    position: 'top',
                    item: {
                        marker: {
                            size: 8,
                            shape: 'circle'
                        }

                    }
                },
                series: {
                    highlightStyle: {
                        item: {
                            fill: '#4bbfaa'
                        }
                    }
                },
            }
        }
    }


    function costTypeTooltipRenderer (params, type) {
        params = sanitizeValues(params);
        const number = type === "TCO" ? params.datum?.totalTco : params.datum?.totalFleet

        return {
            title: "",
            content:
                `<div class="type-tooltip">
                     <div class="type-tooltip-title">
                        <span class="marker" style="background-color:${params?.color}"></span>
                        ${type}
                     </div> 
                    <div class="type-tooltip-item">
                        ${params.datum?.description}
                    </div>
                    <div class="type-tooltip-item">
                        <strong>CHF ${convertAndFormat(number)}</strong>
                    </div>
             
            </div>`
        }
    }

    const [options] = useState({
        theme: theme,
        data: props.data,
        series: [
            {
                type: 'bar',
                xKey: 'description',
                yKey: 'totalTco',
                yName: 'TCO',
                tooltip: {
                    renderer: function (params) {
                        return costTypeTooltipRenderer(params, "TCO")
                    }
                }
            },
            {
                type: 'bar',
                xKey: 'description',
                yKey: 'totalFleet',
                yName: props.labels?.costInfo?.byUPTO,
                tooltip: {
                    renderer: function (params) {
                        return costTypeTooltipRenderer(params, "UPTO")
                    }
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                gridLine: {
                    enabled: false,
                },
                line: {
                    width: 0,
                },
                label: {
                    //rotation: 0,
                    avoidCollisions: true,
                    fontFamily: 'Space Grotesk',
                    color: '#475467'
                }
            },
            {
                type: 'number',
                position: 'left',
                line: {
                    width: 0,
                },
                label : {
                    formatter: chartNumberFormatter,
                    fontFamily: 'Space Grotesk',
                    color: '#475467'
                },
                gridLine: {
                    style: [{
                        stroke: '#F2F4F7',
                        lineDash: [0],
                    }]
                },
            }
        ],
        legend: {
            position: 'top',
            item: {
                marker: {
                    shape: 'circle',
                    size: 9,
                },
                label: {
                    fontFamily: 'Space Grotesk',
                    color: '#333c49'
                }
            }
        }
    });

    return (
        <AgCharts options={options} />
    )

}

export default DiagramPerCostType;
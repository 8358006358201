// endpoint to get all car related information
import { apiSlice } from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.query({
            query: () => API_FAMILY_CONST.USER,
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600
        }),
        updatePassword: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.USER+"change-password" ,
                    method: 'POST',
                    body: data,
                }
            }
        })
    })
})

export const {
    useGetUserQuery, useUpdatePasswordMutation, useLazyGetUserQuery
} = userApiSlice
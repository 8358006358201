// import external
import React from "react";

// import internal styling
import "./_style.scss"

// import internal react component
import FuelCardsOverview from "../../components/FuelCardsOverview";
import {useSelector} from "react-redux";


const MyFuelCardsPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <FuelCardsOverview labels={currentLabels} title={currentLabels?.fuelCardPage?.titles?.fuelCardsOverview}></FuelCardsOverview>
            </div>
        </div>
    )
}

export default MyFuelCardsPage
// import external
import React from "react";
//import internal styling
import "./_style.scss"

const ButtonWithIcon = ({...props}) => {
    const {className, buttonStyle, endDate,clickFunc, ...rest} ={...props}
    return (
        <div className="btn-wrapper">
            {/*the onClickFunc prop should not be required. On driverPersonalInfoForm the click function is attached to the */}
            {/*parents of the button */}
            <button {...rest} className={props.size=== "sm" ? "btn sm " + props.buttonStyle : "btn "+props.buttonStyle} onClick={props.clickFunc?()=>props.clickFunc():undefined } >
                {props.children}
                {props.text}
            </button>
        </div>
    )
}

export default ButtonWithIcon
import {StatusMessage, StatusMessageContent, StatusMessageIcon, StatusMessageTitle} from "../../StatusMessage";
import {ReactComponent as ErrorIcon} from "../../../assets/svg/icon-warning.svg";
import React from "react";

export const ErrorMessage = (props) => {
    const {labels, isHidden}=props
    return (<StatusMessage variant="warning" style={{marginBottom: "1em"}} hidden={isHidden}>
        <StatusMessageIcon>
            <ErrorIcon/>
        </StatusMessageIcon>
        <StatusMessageTitle>
            {labels?.fleetWizard?.messages?.error?.title}
        </StatusMessageTitle>
        <StatusMessageContent>
            {labels?.carsPage?.messages?.carInfoNotSaved}
        </StatusMessageContent>
    </StatusMessage>)
}
export const SuccessMessage = (props) => {
    const {labels, isHidden}=props
    return (
        <>
            <StatusMessage variant="success" hidden={isHidden} style={{marginBottom: "1em"}}>
                <StatusMessageIcon>
                    <ErrorIcon/>
                </StatusMessageIcon>
                <StatusMessageTitle>
                    {labels?.fleetWizard?.messages?.success?.title}
                </StatusMessageTitle>
                <StatusMessageContent>
                    {labels?.carsPage?.messages?.carInfoSaved}
                </StatusMessageContent>
            </StatusMessage>
        </>
    )
}
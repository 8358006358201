import { useEffect, useMemo, useState } from "react";
import { thousandsSeparator } from "../../../actions/formateNumbers.actions";
import { sanitizeValues } from "../../../actions/sanitize";
import { fuelTypeFromCo2Api } from "../../../actions/mapCo2.actions";
import {AgCharts} from "ag-charts-react";
import {chartNumberFormatter} from "../../../actions/magicNumber.actions";


export const EmissionsChart = ({dataBasedOnWLTP, dataBasedOnFuel, labels}) => {

    // I encountered a bug where if you selected a date with no Data, and then selected a range with data again,
    // it would still show the AG Grid/Charts message of 'no data to display', this appears to fix it
    const parsedData = useMemo(() => {
        let tempData = {}

        for (const fuelTypeId of Object.keys(dataBasedOnFuel)) {
            tempData[fuelTypeId]={
                fuelType: labels?.fleetWizard?.form?.fuelTypes[fuelTypeFromCo2Api[fuelTypeId]],
                fuelTypeId: fuelTypeId,
                valueBasedOnFuel: dataBasedOnFuel[fuelTypeId],
            }
        }
        for (const fuelTypeId of Object.keys(dataBasedOnWLTP)) {
            if(fuelTypeId in tempData){
                tempData[fuelTypeId].valueBasedOnWLTP = dataBasedOnWLTP[fuelTypeId]
            } else {
                tempData[fuelTypeId] = {
                    fuelType: labels?.fleetWizard?.form?.fuelTypes[fuelTypeFromCo2Api[fuelTypeId]],
                    fuelTypeId: fuelTypeId,
                    valueBasedOnWLTP: dataBasedOnWLTP[fuelTypeId]
                }
            }
        }
        let arrayForm=[...Object.values(tempData)]
        return arrayForm
    }, [dataBasedOnWLTP, dataBasedOnFuel,labels.fleetWizard.form.fuelTypes])



    const [chartOptions, setChartOptions] = useState({
        data: parsedData,
        height: 200,
        theme: {
            palette: {
                // fills: ['#00008F'], //['#A1A1FF']
                strokes: ['white']
            },
            overrides: {
                common: {
                    axes: {
                        number: {
                            label: {
                                fontFamily: 'Space Grotesk',
                                formatter: chartNumberFormatter,
                            }
                        },
                        category: {
                            label: {
                                fontFamily: 'Space Grotesk'
                            }
                        }
                    },
                },
            }
        },
        padding: {
            right: 0,
            left: 0,
        },
        series: [
            {
                type: 'bar',
                xKey: 'fuelType',
                yKey: 'valueBasedOnFuel',
                yName: labels?.homePage?.metrics?.tankedFuel,
                cornerRadius: 8,
                fill: '#00008F',
                tooltip: {
                    renderer: (params) => {
                        params = sanitizeValues(params);
                        return {
                            title: "",
                            content:
                                `<div class="type-tooltip">
                            <div class="type-tooltip-title">
                       
                        ${params?.datum?.fuelType}
                     </div> 
                    <div class="type-tooltip-item">
                        ${thousandsSeparator(Math.round((params?.datum?.valueBasedOnFuel || 0) * 100) / 100)} kg
                    </div>
                    </div>`
                        }
                    }
                }
            },
            {
                type: 'bar',
                xKey: 'fuelType',
                yKey: 'valueBasedOnWLTP',
                yName: 'WLTP',
                cornerRadius: 8,
                fill: '#9fc5e8',
                tooltip: {
                    renderer: (params) => {
                        params = sanitizeValues(params);
                        return {
                            title: "",
                            content:
                                `<div class="type-tooltip">
                            <div class="type-tooltip-title">
                       
                        ${params?.datum?.fuelType}
                     </div> 
                    <div class="type-tooltip-item">
                        ${thousandsSeparator(Math.round((params?.datum?.valueBasedOnWLTP || 0) * 100) / 100)} kg
                    </div>
                    </div>`
                        }
                    }
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                line: {
                    width: 0,
                },
                label: {
                    //rotation: 0,
                    avoidCollisions: true,
                }
            },
            {
                type: 'number',
                position: 'left',
                line: {
                    width: 0
                }

            }
        ],
        legend: {
            position: "right",
            item: {
                marker: {
                    shape: 'circle'
                },
                label: {
                    fontFamily: 'Space Grotesk',
                }
            }
        },
    })

    useEffect(() => {
        setChartOptions({
            ...chartOptions,
            data: parsedData
        })
        // eslint-disable-next-line
    }, [dataBasedOnFuel, dataBasedOnWLTP])


    return (
        <AgCharts options={chartOptions}/>
    )

}
import { z } from 'zod';
// Zod Pipelines that we can reuse when creating FormSchemas

// Accepts strings and turns them into null if empty
export const zodStringPipe = (zodPipe) =>
    z
        .string()
        .transform((value) => (value === '' ? null : value))
        .nullable()
        .pipe(zodPipe);

// Turns input into number, if empty, returns null
export const zodNumberInputPipe = (zodPipe) =>
    z
        .string()
        .or(z.number())
        .transform((value) => (value === '' ? null : value))
        .nullable()
        .refine((value) => value === null || !isNaN(Number(value)), {
            message: 'Number only',
        })
        .transform((value) => (value === null ? null : Number(value)))
        .pipe(zodPipe);

export function getDateTimeString(date) {
    // If date is date object, and has time, return it as string
    if (date instanceof Date && date.toISOString().includes('T')) {
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
        return utcDate.toISOString();
    }
    // If date is string, and has time, return it as is
    if (typeof date === 'string' && date.includes('T')) {
        return date;
    }
    // If date is date object, and has no time, return it as string with time
    if (date instanceof Date) {
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return utcDate.toISOString() + 'T00:00:00';
    }
    // If date is string, and has no time, return it as is with time
    if (typeof date === 'string') {
        return date + 'T00:00:00';
    }
}

// Accepts dates in format "yyyy-mm-dd" or if empty string, returns null, also if no time is provided, it will default to 00:00:00
export const zodDateInputPipe = (zodPipe) =>
    z
        .string()
        .or(z.date())
        .transform((value) => (value === '' ? null : value))
        .nullable()
        .refine((value) => value === null || !isNaN(Date.parse(value)), {
            message: 'Invalid input',
        })
        //.transform((value) => (value === null ? null : new Date(value)))
        .transform((value) => (value === null ? null :
            getDateTimeString(value))
        )
        .pipe(zodPipe);
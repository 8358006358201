// import styling
import "./_style.scss"

// import assets
import {ReactComponent as AlertIcon} from "../../assets/svg/icon-alert-triangle.svg";

const WarningContainer = ({ children, ...props }) => {
    WarningContainer.defaultProps = {
        title: "Attention",
    }
    return (
        <div className="warning-container" {...props}>
            <div className="warning-title">
                {props.icon || <AlertIcon/>} {props.title}
            </div>
            <div className="warning-body">
                {children}
            </div>
        </div>
    );
}

export default WarningContainer;
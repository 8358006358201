import {createContext, useReducer} from "react";


export const FleetWizardContext = createContext();

const initialState = {
    selectedObjects: {
        selectedOrganisation: null,
        selectedDriver: null,
        selectedFuelCard: null,
        selectedCar: null,
        selectedCarCurrentDriver:null
    },
    objects: {
        fuelCards: null,
        cars: null,
        drivers: null,
        organisations: null,
        user: null,
    },
    selectEntries: {
        languages: null,
        salutations: null,
        phoneTypes: null,
        energyProviders: null,
        vehicleTypes: null,
        fuelTypes: null,
        countries: null,
    }


};


const reducer = (state, action) => {
    switch (action.type) {

        // Selected Objects
        case 'UPDATE_SELECTED_ORGANISATION':
            return {
                ...state,
                selectedObjects: {...state.selectedObjects, selectedOrganisation: action.payload}
            };
        case 'UPDATE_SELECTED_DRIVER':
            return {
                ...state, selectedObjects: {...state.selectedObjects, selectedDriver: action.payload}
            };
        case 'UPDATE_SELECTED_FUEL_CARD':
            return {...state, selectedObjects: {...state.selectedObjects, selectedFuelCard: action.payload}};
        case 'UPDATE_SELECTED_CAR':
            return {...state, selectedObjects: {...state.selectedObjects, selectedCar: action.payload}};
        case 'UPDATE_SELECTED_CAR_CURRENT_DRIVER':
            return {...state, selectedObjects: {...state.selectedObjects, selectedCarCurrentDriver: action.payload}};
        // Objects
        case 'UPDATE_FUEL_CARDS':
            return {
                ...state,
                objects: {
                    ...state.objects,
                    fuelCards: action.payload
                }
            };
        case 'UPDATE_CARS':
            return {
                ...state,
                objects: {
                    ...state.objects,
                    cars: action.payload
                }
            };
        case 'UPDATE_DRIVERS':
            return {
                ...state,
                objects: {
                    ...state.objects,
                    drivers: action.payload
                }
            };
        case 'UPDATE_ORGANISATIONS':
            return {
                ...state,
                objects: {
                    ...state.objects,
                    organisations: action.payload
                }
            };
        case 'UPDATE_USER':
            return {
                ...state,
                objects: {
                    ...state.objects,
                    user: action.payload
                }
            };

        // Select Entries
        case 'UPDATE_LANGUAGES':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    languages: action.payload
                }
            };
        case 'UPDATE_SALUTATIONS':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    salutations: action.payload
                }
            };
        case 'UPDATE_PHONE_TYPES':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    phoneTypes: action.payload
                }
            };
        case 'UPDATE_ENERGY_PROVIDERS':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    energyProviders: action.payload
                }
            };
        case 'UPDATE_VEHICLE_TYPES':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    vehicleTypes: action.payload
                }
            };
        case 'UPDATE_FUEL_TYPES':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    fuelTypes: action.payload
                }
            };
        case 'UPDATE_COUNTRIES':
            return {
                ...state,
                selectEntries: {
                    ...state.selectEntries,
                    countries: action.payload
                }
            };
        default:
            return state;
    }
};

export const FleetWizardProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <FleetWizardContext.Provider value={{ state, dispatch }}>
            {children}
        </FleetWizardContext.Provider>
    );
};
import React from "react";
import PropTypes from "prop-types";

// import styling
import "./_style.scss"


/**
 * @param {title, number, (unit), cardStyle:string} props
 * a round-cornered card to show key info such as total cost.
 * used in all overview pages
 * @returns
 */



const KeyInfoCard=(props )=>{

    KeyInfoCard.propTypes = {
        title: PropTypes.string,
        number: PropTypes.any,
        unit: PropTypes.string,
        cardStyle: PropTypes.string,
        interactiveIcon: PropTypes.object, //an (interactive) icon that is displayed on top right
    }
    const {children, unit, title, cardStyle, number, interactiveIcon, ...other} = props;
    return(
        <div className={cardStyle}>
            <span className="card-interactive-icon">{interactiveIcon}</span>
            {children?
                <div className="icon-wrapper">
                    {children}
            </div>:<></>}
            <div>
                <div className="card-title">{title}</div>
                <span className="card-number" {...other}>{number}</span>
                <span {...other}>{unit}</span>
            </div>
        </div>
    )
}

export default KeyInfoCard

export function getCountryName(countryId, labels) {
    const countries = labels?.otherElements?.countries;

    switch (Number(countryId)) {
        case 1:
            return countries?.germany;
        case 2:
            return countries?.belgium;
        case 200:
            return countries?.switzerland;
        case 68:
            return countries?.france;
        case 100:
            return countries?.italy;
        case 184:
            return countries?.poland;
        default:
            return countries?.unknown;
    }
}

export function chartNumberFormatter(params) {
    //If value is not 0, return something like "10K"
    if(Math.abs(params.value)>=1000) {
        return (params.value / 1000).toFixed(1) + "K"
    }else if(Math.abs(params.value)>0){
        return params.value;
    }
    // otherwise return 0, so it's not "0K"
    return "0"
}

export function getDocumentTypeId(documentType) {
    if(!documentType) return -1;
    //No guarantee that documentType will always be capitalized like in table so I'll just use lowercase values
    switch (documentType.toLowerCase()) {
        case "vertragsdokument":
            return 203;
        case "identificationdocument":
            return 33;
        case "attachments":
            return 999;
        case "handoverprotocol":
            return 500;
        case "licenseathandover":
            return 501;
        case "documentathandover":
            return 502;
        case "documentattakeover":
            return 503;
        case "photosattakeover":
            return 504;
        case "shippingreceipt":
            return 505;
        case "carregistrationcertificate":
            return 506;
        case "cardocuments":
            return 507;
        case "invoices":
            return 508;
        case "miscellaneous":
            return 509;
        case "transportorder":
            return 510;
        case "tickets":
            return 511;
        case "coc":
            return 512;
        case "finanzierung":
            return 513;
        case "bill":
            return -2;
        case "other":
            return -3;
        case "report":
            return -4;
        case "offerte":
            return -5;
        case "offer":
            return -5;
        default:
            // When documentType couldn't be found return -1, could also be null or something
            return -1;
    }
}

export function getDocumentType(id, labels) {
    switch(id) {
        case 203:
            return labels?.documentPage?.documentTypes?.contractDocument;
        case 33:
            return labels?.documentPage?.documentTypes?.identificationDocument;
        case 999:
            return labels?.documentPage?.documentTypes?.attachments;
        case 500:
            return labels?.documentPage?.documentTypes?.handoverProtocol;
        case 501:
            return labels?.documentPage?.documentTypes?.licenseAtHandover;
        case 502:
            return labels?.documentPage?.documentTypes?.documentAtHandover;
        case 503:
            return labels?.documentPage?.documentTypes?.documentAtTakeover;
        case 504:
            return labels?.documentPage?.documentTypes?.photosAtTakeover;
        case 505:
            return labels?.documentPage?.documentTypes?.shippingReceipt;
        case 506:
            return labels?.documentPage?.documentTypes?.carRegistrationCertificate;
        case 507:
            return labels?.documentPage?.documentTypes?.carDocuments;
        case 508:
            return labels?.documentPage?.documentTypes?.invoices;
        case 509:
            return labels?.documentPage?.documentTypes?.miscellaneous;
        case 510:
            return labels?.documentPage?.documentTypes?.transportOrder;
        case 511:
            return labels?.documentPage?.documentTypes?.tickets;
        case 512:
            return labels?.documentPage?.documentTypes?.coc;
        case 513:
            return labels?.documentPage?.documentTypes?.financing;
        case -2:
            return labels?.documentPage?.documentTypes?.bill;
        case -3:
            return labels?.documentPage?.documentTypes?.other;
        case -4:
            return labels?.documentPage?.documentTypes?.report;
        case -5:
            return labels?.documentPage?.documentTypes?.offer;
        default:
            return labels?.documentPage?.documentTypes?.unknown;
    }
}

export const damageMap = {
    "10100": "collision",
    "10200": "theft",
    "10300": "naturalDisaster",
    "10301": "naturalDisaster-landslide",
    "10302": "naturalDisaster-rockfall",
    "10303": "naturalDisaster-stonefall",
    "10304": "naturalDisaster-flood",
    "10305": "naturalDisaster-flooding",
    "10306": "naturalDisaster-whirlwind",
    "10307": "naturalDisaster-hail",
    "10308": "naturalDisaster-avalanche",
    "10309": "naturalDisaster-snowPressure",
    "10400": "glassBreakage",
    "10401": "glassBreakagePlus",
    "10500": "fire",
    "10600": "snowslide",
    "10700": "animalDamage",
    "10701": "damageByMartens",
    "10800": "maliciousDamage",
    "10900": "personalBelongings",
    "10901": "personalBelongingsPlus",
    "11000": "parkingDamage",
    "11001": "parkingDamagePlus",
    "11100": "lossOfUse",
    "11200": "towingAfterBreakdown",
    "11300": "deductible",
    "99999": "otherReason"
};

export function getDamageType(damageId, labels) {
    const damageLabels = labels?.damagesPage?.damageCause;
    let damageType = damageMap[`${damageId}`]
    if(!damageType){
        return "N/A"
    }
    if (damageType&&damageType.includes("-")){
        const textArray = damageType.split("-")
        return `${damageLabels[textArray[0]]} - ${damageLabels[textArray[1]]}`
    }
    return damageLabels[damageType]
}

export const carStatusLabels = {
    "de": {
        "lager/parkplatz": "Lager/Parkplatz",
        "eingang": "Eingang",
        "inaufbereitung": "in Aufbereitung",
        "schadenreparatur": "Schadenreparatur",
        "ausgeliefert": "ausgeliefert",
        "inaktiv": "inaktiv",
        "verfügbar": "verfügbar",
        "vermietet": "vermietet",
        "inrückführung": "in Rückführung",
        "verfügbarfürclosedusergroup": "verfügbar für Closed User Group",
        "offerte": "Offerte",
        "aktiv": "aktiv",
        "reserviert": "Reserviert",
        "schadenreparaturkvbeikunde": "Schadenreparatur KV bei Kunde",
        "schadenreparaturkvgenehmigt": "Schadenreparatur KV genehmigt",
    },
    "en": {
        "lager/parkplatz": "Storage/parking lot",
        "eingang": "Way in",
        "inaufbereitung": "In preparation",
        "schadenreparatur": "Damage repair",
        "auslieferbereit": "Ready for delivery",
        "ausgeliefert": "Delivered",
        "inaktiv": "Inactive",
        "verfügbar": "available",
        "vermietet": "rented",
        "inrückführung": "in return",
        "verfügbarfürclosedusergroup": "available for Closed User Group",
        "offerte": "offer",
        "aktiv": "active",
        "reserviert": "Reserved",
        "schadenreparaturkvbeikunde": "Damage repair estimate approved by customer",
        "schadenreparaturkvgenehmigt": "Damage repair cost estimate approved",
    },
    "fr": {
        "lager/parkplatz": "Entrepôt/parking",
        "eingang": "Entrée du site",
        "inaufbereitung": "En cours de remise en état",
        "schadenreparatur": "Réparation des dommages",
        "auslieferbereit": "Prêt à être livré",
        "ausgeliefert": "Livré",
        "inaktiv": "Inactif",
        "verfügbar": "disponible",
        "vermietet": "loué",
        "inrückführung": "en cours de rapatriement",
        "verfügbarfürclosedusergroup": "disponible pour Closed User Group",
        "offerte": "Offre",
        "aktiv": "actif",
        "reserviert": "Réservé",
        "schadenreparaturkvbeikunde": "Réparation des dommages Devis chez le client",
        "schadenreparaturkvgenehmigt": "Réparation des dommages Devis approuvé",
    },
    "it": {
        "lager/parkplatz": "Magazzino/parcheggio",
        "eingang": "Ingresso",
        "inaufbereitung": "In preparazione",
        "schadenreparatur": "Riparazione dei danni",
        "auslieferbereit": "Pronto per la consegna",
        "ausgeliefert": "Consegnato",
        "inaktiv": "Inattivo",
        "verfügbar": "disponibile",
        "vermietet": "affittato",
        "inrückführung": "in cambio",
        "verfügbarfürclosedusergroup": "disponibile per gruppo chiuso di utenti",
        "offerte": "offerta",
        "aktiv": "attivo",
        "reserviert": "Riservato",
        "schadenreparaturkvbeikunde": "Preventivo di riparazione del danno approvato dal cliente",
        "schadenreparaturkvgenehmigt": "Preventivo di riparazione del danno approvato",
    }
}

export function getCarStatus(status, language) {
    // Get rid of spaces and make it lower case
    const parsedStatus = status.replace(/\s/g, '').toLowerCase()
    return carStatusLabels[language.toLowerCase()][parsedStatus]
}

export function getContractStatus(statusId, labels) {
    const statusLabels = labels?.statusCode;
    switch (statusId){
        case 1:
            return statusLabels?.active;
        case 0:
            return statusLabels?.ordered;
        /*
        I'm confused, should there be as many labelled statuses or should they be generalized if possible? I'll comment this out so it just shows "Inactive" for any non-active/ordered status
        case 6:
            return statusLabels?.terminated;
        case 9:
            return statusLabels?.terminated;
         */
        // If the contractInformation is undefined, it means there is no contract yet
        case undefined:
            return statusLabels?.noContract;
        // For any other statusId, we show "Inactive"
        default:
            return statusLabels?.inactive;
    }
}

export function getLanguageName(languageId, labels) {
    const languages = labels?.otherElements?.languages;

    switch (languageId) {
        case 1:
            return languages?.german;
        case 2:
            return languages?.english;
        case 3:
            return languages?.french;
        case 4:
            return languages?.italian;
        default:
            return null
    }
}
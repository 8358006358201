import AlertContainer from "../AlertContainer";
import React from "react";
import fallbackLabels from '../../assets/i18n/German.json'

/**
 *
 * @param {error, page, titleSize, labels} props
 * based on 1. error code such as 403 2. which page show different error 3. business logic error
 * messages in 1. different languages 2. in different font sized title
 * @returns
 */


export const createErrorMessage = (error, title="", titleSize, labels=fallbackLabels, businessLogicErrorDes="") => {
    let content;
    // if user has no access to costs show a different error msg
    if (error === undefined && businessLogicErrorDes==="") {
        content = (
            <div>
                {title?<h1 className={titleSize}>{title}</h1>:<></>}
                <AlertContainer key="alertContainerNotFleetManager" show={true} alertStyle={"alert-warning"}>
                    {labels?.errorMessage?.dataNotAvailable}
                </AlertContainer>
            </div>
        )
    } else if (error === undefined && businessLogicErrorDes==="invalidItemNumber"){
        content = (
            <div>
                {title?<h1 className={titleSize}>{title}</h1>:<></>}
                <AlertContainer key="alertContainerNotFleetManager" show={true} alertStyle={"alert-warning"}>
                    {labels?.errorMessage?.invalidItemNumber}
                </AlertContainer>
            </div>
        )
    }else if (error?.status === 403) {
        content = (
            <div>
                {title?<h1 className={titleSize}>{title}</h1>:<></>}
                <AlertContainer key="alertContainerNoAccess" show={true} alertStyle={"alert-warning"}>
                    {labels?.errorMessage?.noRole}
                </AlertContainer>
            </div>
        )
    } else if(businessLogicErrorDes==="canNotLoad"){
        content = (
            <div>
                {title?<h1 className={titleSize}>{title}</h1>:<></>}
                <AlertContainer key="alertContainerErrorLoading" show={true} alertStyle={"alert-warning"}>
                    {labels?.errorMessage?.canNotLoad}
                </AlertContainer>
            </div>
        )
    }
    else {
        content = (
            <div>
                {title?<h1 className={titleSize}>{title}</h1>:<></>}
                <AlertContainer key="alertContainerErrorLoading" show={true} alertStyle={"alert-warning"}>
                    {labels?.errorMessage?.canNotLoad}
                </AlertContainer>
            </div>
        )
    }

    return content;
};
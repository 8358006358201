
// Handle error messages when sending post/put requests to backend
export function handleErrorMessage(errorObject, labels, customErrorMessage){
    //Instead of sending an api error Object, we can also send a custom error message
    if(customErrorMessage){
        return customErrorMessage;
    }
    if(errorObject?.originalStatus === 403){
        return labels?.errorMessage?.noPermission;
    }
    else if(errorObject?.originalStatus === 400){
        return labels?.errorMessage?.invalidInput;
    }
    else {
        return labels?.errorMessage?.unknownError;
    }
}
//import external
import Moment from 'moment';

// import styling
import "./_style.scss"

// import internal slices
import GridHandler from "../GridHandler";
import { thousandsSeparator } from '../../actions/formateNumbers.actions';
import {Link} from "react-router-dom";
import React from "react";


/**
 *
 * @param {*} props (not used)
 * loads data for the cost overview and defines what to show
 * @returns
 */


const InvoiceDetailTable = (props) => {

    // each column definition results in one column.
    const columnDefs = [
        {
            headerName: props.labels.costPage.costInfo.positionDate,
            valueGetter: function (params) {
                return Moment(params.data.posDate).format('DD.MM.YYYY');
            },
            filter: 'agDateColumnFilter',
            // sorting related
            sort: 'desc',
            comparator: props.gridSortingFunc,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: props.gridFilteringFunc
            }
        },
        {
            headerName: props.labels.costPage.costInfo.VIN,
            colId:"VIN",
            valueGetter: function (params) {
                return params.data.car ? params.data.car.vin : "";
            },
            cellRenderer:(params)=>{
                if (!params.value) return null;
                return <Link to={"/cars/" + params.value}>
                    <span className="link-with-icon">
                        {params.value}
                    </span>
                </Link>
            },
        },
        {
            headerName: props.labels.costPage.costInfo.plateNumber,
            colId:"plateNumber",
            valueGetter: function (params) {
                return params.data.car ? params.data.car.licensePlate : "";
            }
        },
        {
            headerName: props.labels.carsPage.carInfo.brand,
            colId:"brand",
            valueGetter: function (params) {
                return params.data.car ? params.data.car.brand : "";
            }
        },
        {
            headerName: props.labels.carsPage.carInfo.type,
            colId:"type",
            valueGetter: function (params) {
                return params.data.car ? params.data.car.type : "";
            }
        },
        {
            headerName: props.labels.costPage.costInfo.description,
            colId:"description",
            valueGetter: function (params) {
                return params.data.description ? params.data.description : "";
            }
        },
        {
            headerName: props.labels.costPage.costInfo.entireLineText,
            colId:"entireLineText",
            valueGetter: function (params) {
                return params.data.entireLineText;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.mainCategory,
            colId:"mainCategory",
            valueGetter: function (params) {
                return params.data.mainCostTypeDescription;
            }
        },
        {
            headerName: props.labels.costPage.costInfo.category,
            colId:"category",
            valueGetter: function (params) {
                return params.data.costTypeDescription;
            }
        },

        {
            headerName: props.labels.costPage.costInfo.unitPriceNoVat,
            colId:"unitPriceNoVat",
            valueGetter: function (params) {
                return params.data.pricePerUnitExclTax;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2))
            }
        },
        
        {
            headerName: props.labels.costPage.costInfo.unitPriceWithVat,
            colId:"unitPriceWithVat",
            valueGetter: function (params) {
                return params.data.pricePerUnitInclTax;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2))
            }
        },
        {
            headerName: props.labels.costPage.costInfo.amount,
            colId:"amount",
            valueGetter: function (params) {
                return params.data.quantity;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2))
            }
        },
        {
            headerName: props.labels.costPage.costInfo.totalPriceNoVat,
            colId:"totalPriceNoVat",
            valueGetter: function (params) {
                return params.data.priceExclTax;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2))
            }
        },
        {
            headerName: props.labels.costPage.costInfo.VAT,
            colId:"VAT",
            valueGetter: function (params) {
                return params.data.taxAmount;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2));
            }

        },
        {
            headerName: props.labels.costPage.costInfo.totalPriceWithVat,
            colId:"totalPriceWithVat",
            valueGetter: function (params) {
                return params.data.priceInclTax;
            },
            valueFormatter: function (params) {
                if(params.value === undefined || params.value === null) {
                    return "";
                }
                return thousandsSeparator((Math.round(params.value * 100 ) / 100).toFixed(2));
            }
        }

    ];

    let content;
    content = (
        <div className="section-card">
            <div className="section-container-content">
                <h2>{props.section_name}</h2>
                <GridHandler
                    data={props.invoices_data}
                    columnDefs={columnDefs}
                    downloadable={true}
                    gridName={`upto_incoming_invoices_details_${props.invoice_id}`}
                    fileName={`upto_${props.section_name.toLowerCase()}_${props.invoice_id}`}
                    downloadTextCsv={props.labels.otherElements.downloadCsv}
                    downloadTextExcel={props.labels.otherElements.downloadExcel}
                ></GridHandler>
            </div>
        </div>
    )
    return (
        <div className="section-container-content">
            {content}
        </div>
    )
}


export default InvoiceDetailTable;




import {combineReducers, configureStore} from "@reduxjs/toolkit";
import authSliceReducer from "./authSlice/authSlice";
import labelsSliceReducer from "./labelsSlice/labelsSlice"
import gridSliceReducer from "./stateSlice/gridSlice"
import dateSliceReducer from "./stateSlice/dateSlice"

import {apiSlice} from "./apiSlice/apiSlice";

const allReducers = combineReducers({
    grid:gridSliceReducer,
    auth:authSliceReducer,
    labels:labelsSliceReducer,
    date: dateSliceReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
})

const rootReducer = (state, action) => {
    // the logOut reducer in auth slice takes care of deleting token from local storage
    // here we try to reset all states
    if (action.type === 'auth/logOut') {
        state = undefined;
    }
    return allReducers(state, action);
};

const Store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
})

export default Store
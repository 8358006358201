// import external
import React from "react";
import {useSelector} from "react-redux";

// import internal
import DocumentOverview from "../../components/DocumentOverview";

// internal styling
import "./_style.scss";

const MyDamagesPage = () => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <DocumentOverview labels={currentLabels} title={currentLabels?.documentPage?.titles?.documentsOverview}></DocumentOverview>
            </div>
        </div>
    )
}

export default MyDamagesPage
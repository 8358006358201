// import external
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import moment from "moment";

// import styling
import "./_style.scss"
import FormModal from "../../../components/FormModal";
import FeatureIcon from "../../../components/FeatureIcon";
import {FormField, FormItem, FormLabel, FormMessage, Input} from "../../../components/Form/Form";
import {createChangeDriverCarLinkSchema} from "../../../components/Form/FormSchemas";
import {useAddDriverToCarMutation} from "../../../redux/apiSlice/driverApiSlice";
import {
    StatusMessage,
    StatusMessageContent,
    StatusMessageIcon,
    StatusMessageTitle
} from "../../../components/StatusMessage";
import {ReactComponent as ErrorIcon} from "../../../assets/svg/icon-warning.svg";
import {ReactComponent as TimeIcon} from "../../../assets/svg/icon-clock.svg";
import {FormControlButtons} from "../../../components/Form/FormControlButtons";


const DriverTimeSpanModal = (props) => {
    const {driverName, currentLabels, modalState, setShowDriverTimeModal} = props
    // state for showing modal
    // const [isMakeDriverInactive, setMakeDriverInactive] = useState(false);

    const createDriverCarLinkForm = useForm({
        resolver: zodResolver(createChangeDriverCarLinkSchema(currentLabels?.fleetWizard?.form)),
        defaultValues: {
            makeDriverInactive: false,
            // populated by useEffect
            linkStartDate: null,
            // linkEndDate is required if the user wants to make driverInactive (makeDriverInactive==true)
            linkEndDate: null,
        }
    })

    useEffect(() => {
        createDriverCarLinkForm.reset({
            makeDriverInactive: false,
            linkStartDate: modalState.startDate ?? null,
            linkEndDate: modalState.endDate ?? null
        })

        // eslint-disable-next-line
    },[modalState.show])

    const [addDriver, {
        isLoading: isAddingDriver, isError: driverIsError, isSuccess: driverIsSuccess, reset: resetDriver
    }] = useAddDriverToCarMutation();

    function resetModal() {
        resetDriver();
    }

    const ErrorMessage = () => {
        return (<StatusMessage variant="warning" style={{marginBottom: "1em"}} hidden={!driverIsError}>
            <StatusMessageIcon>
                <ErrorIcon/>
            </StatusMessageIcon>
            <StatusMessageTitle>
                {currentLabels?.fleetWizard?.messages?.error?.title}
            </StatusMessageTitle>
            <StatusMessageContent>
                {currentLabels?.fleetWizard?.messages?.error?.driverToCarSpanNotUpdated}
            </StatusMessageContent>
        </StatusMessage>)
    }
    const SuccessMessage = () => {
        return (
            <>
                <StatusMessage variant="success" style={{marginBottom: "1em"}}>
                    <StatusMessageIcon>
                        <ErrorIcon/>
                    </StatusMessageIcon>
                    <StatusMessageTitle>
                        {currentLabels?.fleetWizard?.messages?.success?.title}
                    </StatusMessageTitle>
                    <StatusMessageContent>
                        {currentLabels?.fleetWizard?.messages?.success?.driverToCarSpanUpdated}
                    </StatusMessageContent>
                </StatusMessage>

                <button className="btn btn-primary" type="button" style={{width: "100%"}}
                        onClick={() => {
                            resetModal() // reset the form and states
                            setShowDriverTimeModal({type: 'RESET_MODAL'}) // Close modal
                        }}>
                    {currentLabels?.otherElements?.close}
                </button>
            </>
        )
    }
    const handleCancelButton=()=>{
        resetModal() // reset the form and states
        setShowDriverTimeModal({type: 'RESET_MODAL'}) // Close modal
    }
    const handleCloseModal=()=>{
        props.setShowDriverTimeModal({type: 'CLOSE_MODAL'})
        if(driverIsSuccess||driverIsError){
            resetDriver();
        }
    }
    const onSubmitDriverCarLinkForm = (data) => {
        let body ={
            "id":modalState.relationshipId,
            "startDt": data.linkStartDate,
            "endDt": data.linkEndDate,
            "driver": modalState.driverId,
            "car": modalState.carId

        }

        const requestData = {
            organisationCustomerId: props.client_id,
            carId: modalState.carId,
            body: body
        }
        addDriver(requestData);

    }

    return (
        <FormModal
            cssId={"driver-modal"}
            show={modalState.show}
            onClose={handleCloseModal}
            title={<div>
                <div>{`${currentLabels?.driverPage?.driverInfo?.changeDriverCarLink}`}</div>
                <div style={{fontSize:"20px", color:"grey"}}>{driverName} - {modalState.vin}</div>
            </div>}

            featuredIcon={
                <FeatureIcon>
                    <TimeIcon style={{stroke: "#344054"}}/>
                </FeatureIcon>
            }
        >{driverIsSuccess ? (<SuccessMessage/>) : (
            <form id="driverTimeSpanModal" onSubmit={createDriverCarLinkForm.handleSubmit(onSubmitDriverCarLinkForm)}
                  className="form">
                <div className="modal-tab-content">
                    <ErrorMessage/>
                    {/*comment out because this part will be its own story*/}
                    {/*<FormField*/}
                    {/*    control={createDriverCarLinkForm.control}*/}
                    {/*    name="makeDriverInactive"*/}
                    {/*    render={({field, error}) => (<FormItem>*/}
                    {/*        <FormLabel {...error} onClick={() => {*/}
                    {/*            createDriverCarLinkForm.setValue("makeDriverInactive", !isMakeDriverInactive);*/}
                    {/*            setMakeDriverInactive(!isMakeDriverInactive)*/}
                    {/*            createDriverCarLinkForm.trigger(["makeDriverInactive"])*/}
                    {/*        }}>*/}
                    {/*            {currentLabels?.driverPage?.driverInfo?.isMakeDriverInactive}*/}
                    {/*        </FormLabel>*/}
                    {/*        <Input {...field} {...error}*/}
                    {/*               checked={createDriverCarLinkForm.watch("makeDriverInactive")}*/}
                    {/*               type="checkbox"*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   createDriverCarLinkForm.setValue("makeDriverInactive", e.target.checked);*/}
                    {/*                   createDriverCarLinkForm.trigger(["makeDriverInactive"])*/}
                    {/*                   // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)*/}
                    {/*                   setMakeDriverInactive(e.target.checked)*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </FormItem>)}*/}
                    {/*/>*/}
                    <FormField
                        control={createDriverCarLinkForm.control}
                        name="linkStartDate"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {currentLabels?.driverPage?.driverInfo?.driverCarLinkStart} *
                            </FormLabel>
                            <Input {...field} {...error} popperPlacement={"bottom-end"}
                                   value={modalState.startDate ? moment(modalState.startDate, 'YYYY-MM-DD').toDate() : undefined}
                                   type="date"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormField
                        control={createDriverCarLinkForm.control}
                        name="linkEndDate"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {currentLabels?.driverPage?.driverInfo?.driverCarLinkEnd}
                            </FormLabel>
                            <Input {...field} {...error} popperPlacement={"bottom"}
                                   value={modalState.endDate ? moment(modalState.endDate, 'YYYY-MM-DD').toDate() : undefined}
                                   type="date"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                </div>
                <FormControlButtons labels={currentLabels} isLoading={isAddingDriver} onCancel={handleCancelButton} />
            </form>
        )}
        </FormModal>
    )
}
export default DriverTimeSpanModal;

// import styling
import './_style.scss';

// import assets
import {ReactComponent as ReportIcon} from "../../../assets/svg/icon-report.svg";
import {ReactComponent as CheckIcon} from "../../../assets/svg/icon-check.svg";

// import internal
import ButtonWithIcon from "../../ButtonWithIcon";
import FormModal from "../../FormModal";
import DatePickerHandler from "../../DatePickerHandler";
import {useGetUserQuery} from "../../../redux/apiSlice/userApiSlice";
import {useRequestReportMutation} from "../../../redux/apiSlice/reportApiSlice";
import WarningContainer from "../../WarningContainer";

// import external
import React, {useState} from "react";
import Moment from "moment/moment";
import {useNavigate} from "react-router-dom";

// Handles the report generation (contains Button, Modal and Report Generation Success/Error Message)
const ReportGenerationHandler = (props) => {
    const labels = props.labels;

    // Used to navigate to documents page after report generation
    const navigate = useNavigate();

    // Controls modal visibility
    const [showModal, setShowModal] = useState(false);

    // Get user data for organization id
    const {data: userData} = useGetUserQuery();

    // Store date range and date filter application type
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [reportType, setReportType] = useState('invoice');


    // Send api request
    const [requestReport, {data: reportResponse, error:reportError, isLoading:reportIsLoading}] = useRequestReportMutation();

    // Executed when "Generate" button is clicked, send API request and close modal
    const generateReport = () => {
        const data = {
            reportType: "carInvoice_invoicePositions",
            organisationId: userData?.customer,
            visibleInPortal:true,
            startDate: startDate,
            endDate: endDate,
            filterColumn: reportType
        }
        // send api request
        requestReport(data).then((response) => {
            // store workflow id in session storage (so we can check on document page if this one has finished)
            if(response?.data?.workflowId){
                // Yue: add the if condition: only store the workflow Id when the request is succusful
                // before adding the logic, if the request fails, we store undefined
                // no point storing undefined, because this would be reused to check the status of the workflow
                // it does not make sense to check "undefined"
                sessionStorage.setItem("mostRecentWorkflow", response?.data?.workflowId)
            }
        })

        // close modal and reset states
        handleCloseModal();

    }

    const handleCloseModal = () => {
        setShowModal(false);
        setStartDate(null);
        setEndDate(null);
        setReportType('invoice');
    }

    const handleRangeChange = (start, end) => {
        //As soon as the second date is selected, handle the change and fetch new data
        if(start && end){
            setStartDate(Moment(start).format("YYYY-MM-DD"))
            setEndDate(Moment(end).format("YYYY-MM-DD"))
        }
        //This is executed when the user resets the dates
        else if (!start && !end){
            setStartDate(null);
            setEndDate(null);
        }
    }

    // If user generates report successfully, show success message
    if(reportResponse?.workflowStatus === "In Progress"){
        return (
            <div className="report-generation-handler">
                <WarningContainer
                    title={labels.success}
                    className="warning-container success"
                    icon={<CheckIcon style={{stroke:"green",height:"18px",width:"18px"}}/>}
                    data-cy="report-generation-success-message"
                >
                    <div className="success-message-container">
                        {labels.reportGenerationSuccessMessage}
                        <button className="btn btn-success sm" style={{width:"fit-content",height:"38px"}}  onClick={() => navigate("/documents")}>
                            {labels.toTheDocumentOverview}
                        </button>
                    </div>

                </WarningContainer>
            </div>
        )
    }
    // If there was an error on report generation, show error message
    else if(reportError){
        return (
            <div className="report-generation-handler">
            <WarningContainer
                title={labels.error}
                data-cy="report-generation-error-message"
            >
                {labels.reportGenerationErrorMessage}
            </WarningContainer>
            </div>
        )
    }
    // Otherwise show this, which is the modal where the user can create the report
    else {
        return (
            <div className="report-generation-handler">
                {reportIsLoading ?
                    <button className="btn btn-primary btn-disabled" style={{width:"200px"}}>
                        <div className="dot-falling-container">
                            <div className="dot-falling"/>
                        </div>
                    </button>
                    :
                    <ButtonWithIcon
                        size="sm"
                        buttonStyle="btn-primary"
                        clickFunc={() => setShowModal(true)}
                        data-cy="generate-report-button"
                    >
                        <ReportIcon/>
                        {labels?.generateInvoicePositionReport}
                    </ButtonWithIcon>
                }
                <FormModal
                    title={labels?.generateReport}
                    show={showModal}
                    onClose={() => handleCloseModal()}
                    onOutsideClick={() => setShowModal(false)}

                >
                    <div className="report-generation-modal">
                        <div className="report-generation-modal-field" style={{marginTop:"1em"}}>
                            <label>{labels?.applyDateFilterFor}</label>
                            <select
                                name="report-generation-field"
                                id="report-generation-field"
                                data-cy="report-generation-field-report-type"
                                onChange={(e) => {
                                    setReportType(e.target.value)
                                }}
                            >
                                <option value="invoice">{labels?.invoiceDate}</option>
                                <option value="position">{labels?.invoicePositions}</option>
                            </select>
                        </div>

                        <div className="report-generation-modal-field">
                            <label>{labels?.timeFrame}</label>
                            <div className="date-picker-wrapper">
                                <DatePickerHandler
                                    isRange
                                    onChange={handleRangeChange}
                                />
                            </div>
                        </div>

                        <div className="report-generation-modal-buttons">
                            <button className="btn btn-light" onClick={() => handleCloseModal()}>
                                {labels?.cancel}
                            </button>

                            <button className="btn btn-primary" onClick={() => generateReport()} data-cy="confirm-report-generation-button">
                                {labels?.generate}
                            </button>


                        </div>

                    </div>

                </FormModal>
            </div>
        )
    }

}


export default ReportGenerationHandler;
import React from 'react';
import {useController} from "react-hook-form";
import "./_style.scss"
import {ReactComponent as UploadIcon} from "../../../src/assets/svg/icon-upload.svg";
import DatePickerHandler from "../DatePickerHandler";
import {ReactComponent as TrashIcon} from "../../../src/assets/svg/icon-trash.svg";


const FormField = ({ control, name, render }) => {
    const { field, fieldState: { error } } = useController({
        name,
        control,
    });
    return render({ field, error });
};

const FormGroup = ({ children, ...props }) => {
    return <div className="form-group" {...props}>{children}</div>;
}


const FormItem = ({children, ...props}) => {
    const inputRef = React.useRef(null);

    // Inject the ref to input fields
    const injectProps = (child) => {
        if (child.type.displayName === 'Input' || child.type.displayName === 'Select' || child.type.displayName === 'DatePickerHandler' || child.type.displayName === 'Checkbox' || child.type.displayName === 'FileInput') {
            return React.cloneElement(child, {
                ref: inputRef
            });
        }
        return child;
    }

    // If the form item has a checkbox, then display label next to it instead of above
    for (const child of children){
        if (child?.props?.type === "checkbox"){
            return (
                <div className="form-item column" {...props}>
                    {React.Children.map(children, injectProps)}
                </div>
            )
        }
    }

    // Otherwise row (label above input field)
    return (
        <div className="form-item" {...props}>
            {React.Children.map(children, injectProps)}
        </div>
    )
}

// Used if user is not in edit mode, to display value of field but not as Input Field
const FormValue = ({children, ...props}) => {

    return (
        <label className={"form-value"} {...props}>
            {children}
        </label>
    )
}


const FormLabel = ({children, message,linkedCheckBoxId, ...props}) => {

    // Holds the label of form, is red if the field is invalid
    const className = "form-label" + (!message ? "" : " invalid")
    return (
        <label className={className} htmlFor={linkedCheckBoxId} {...props}>
            {children}
        </label>
    )
}

/* // Not sure what this is used for (many Form UI components use this), but currently unused, maybe in future might need
const FormControl = ({ children }) => {
    return <div className="form-control">{children}</div>;
};

 */

const FormDescription = ({children, ...props}) => {
    return (
        <p className="form-description" {...props}>
            {children}
        </p>
    )
}

// Contains error message for form fields
const FormMessage = ({ message }) => {
    if (!message) return null;
    return <div className="form-message">{message}</div>;
};

// Input field for file upload
const FileInput = React.forwardRef((props, ref) => {

    const {name, onChange, onFileChange} = props;

    // Holds the file that is uploaded, used to display file name, and clear button visibility
    const [file, setFile] = React.useState(null);

    // True if user is dragging file over the input field
    const [isDragOver, setIsDragOver] = React.useState(false);

    // When user selects a file, set the file and call the onChange function
    const handleFileChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        setFile(file);
        if (onChange) {
            onChange(file);
        }
        if (onFileChange) {
            onFileChange(file);
        }
    }

    // When user drags a file over the input field, set the file and call the onChange function
    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        setFile(file);
        if (onChange) {
            onChange(file);
        }
        if (onFileChange) {
            onFileChange(file);
        }
    }

    // When user clicks the clear button, set the file to null and call the onChange function
    const handleClear = () => {
        setFile(null);
        if (onChange) {
            onChange(null);
        }
        if (onFileChange) {
            onFileChange(null);
        }
    }


    // When user clicks the input field, click the hidden input field
    const handleClick = (event) => {
        event.preventDefault();
        if (ref.current) {
            ref.current.click();
        }
    };

    return (
        <div style={{position:"relative"}}>
            <button
                className="input-file-form"
                // the custome attribute data-filedrag is needed for the css stying
                data-filedrag={isDragOver}
                onClick={handleClick}
                onDrop={handleDrop}
                onDragOver={(event) => {
                    event.preventDefault();
                    setIsDragOver(true);
                }}
                onDragLeave={() => setIsDragOver(false)}
                type="button"
                data-invalid={!!props?.message}
            >
                <UploadIcon style={{width:"3em", height:"3em"}}/>
                {file?.name || props?.placeholder }
            </button>


            {file && (
                    <div className="clear-file-button" onClick={handleClear}>
                        <TrashIcon style={{width:"1.5em", height:"1.5em"}}/>
                    </div>
                )}


            <input
                data-invalid={!!props?.message}
                name={name}
                type="file"
                onChange={handleFileChange}
                ref={ref}
                style={{display:"none"}}
            />
        </div>
    )
})

const Input = React.forwardRef((props, ref) => {
    // If input type is file, then show a button to upload file
    if (props.type === "file"){

        const { style, disabled, checked, maxLength, value, ...restForFile}=props
        // the usefulones should be name, onBlur, onChange, onFileChange, placeholder, type and value
        return <FileInput {...restForFile} value={value||""} ref={ref} />;
    }
    // If input type is date, then show our custom date picker
    else if (props.type === "date"){
        // Handle different inputs so it doesn't crash
        let date = props.value;
        if (date === undefined || date === ""){
            date = null;
        }
        else if (typeof date === "string"){
            date = new Date(date);
        }

        return (
            <DatePickerHandler popperPlacement={props.popperPlacement} isRange={false} startDate={date} onChange={props.onChange} className="date-input-field form" data-invalid={!!props?.message} />
        )
    }
    // If input type is checkbox, then show our custom checkbox
    else if (props.type === "checkbox"){
        const { style, disabled, onFileChange, checked, placeholder, maxLength, value, ...restForCheckBox}=props
        // the useful ones should be name, onBlur, onChange, type and value
        return (
            <input {...restForCheckBox} style={style} data-invalid={!!props?.message} ref={ref} className="input-checkbox-form" checked={value||false}/>
        )
    }

    else if (props.type === "disabled"){
        const {defaultValue}=props
        return (
            <span ref={ref} className="form-item-text" >{defaultValue}</span>
        )
    }
    else if(props.type==="textArea"){
        const { placeholder}=props
        return (
            <textarea
                {...props}
                className="text-area-primary"
                style={{ resize: "none" }}
                placeholder={placeholder}
        />
        )
    }
    // Otherwise show normal input field
    else {
        const { style,checked,onFileChange,value, ...restForOther}=props
        // the useful ones should be disabled, name, onBlur, onChange, value, placeholder, maxLength
        return <input {...restForOther} value={value||""} data-invalid={!!props?.message} ref={ref} className="input-field-form" />;
    }

});
Input.displayName = 'Input';


const Select = React.forwardRef((props, ref) => {
    const {options, onChange, placeholder, onBlur, name, value, disabled} = props;

    // If the value is empty, then the placeholder is selected
    const isUnselected = value === "" || value === undefined || value === null;
    return (
        <select data-invalid={!!props.message} name={name} ref={ref} onChange={onChange} onBlur={onBlur} value={value||""} disabled={disabled} className="select-form" >
            <option value="" selected={isUnselected} style={{color:"gray"}}>
                {placeholder}
            </option>
            {options?.map((option) => {
                return <option key={option.value} value={option.value}>
                    {option.label}
                </option>

            })}
        </select>
    )
});

Select.displayName = 'Select'


export {FormItem,FormGroup, FormValue, FormMessage, FormDescription, FormField, FormLabel, Input, Select}
// import styling
import "./_style.scss"

// import internal slices
import {useGetBillsQuery, useLazyGetBillQuery} from "../../redux/apiSlice/billsApiSlice";

// import internal components
import LoadingComponent from "../LoadingComponent";
import KeyInfoCard from "../KeyInfoCard";
import GridHandler from "../GridHandler";
import {createErrorMessage} from "../AlertContainer/alert.utils";

// import external
import React from "react";
import Moment from "moment";
import {Link} from "react-router-dom";

// import helper functions
import {formatNumber, thousandsSeparator} from "../../actions/formateNumbers.actions";
import {formatDateIfNotEmpty} from "../../actions/date.actions";
import {defaultCellRenderer} from "../GridHandler/CellRenderers/defaultCellRenderer";
import {statusCellRenderer} from "../GridHandler/CellRenderers/statusCellRenderer";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import { triggerEvent } from "../../actions/eventTracking.actions";

// import assets
import {ReactComponent as MagnifierIcon} from "../../assets/svg/icon-magnifier.svg";
import {ReactComponent as DownloadIcon} from "../../assets/svg/icon-download.svg";
//import {ReactComponent as DocumentIcon} from "../../assets/svg/icon-document.svg";
//import {ReactComponent as WalletIcon} from "../../assets/svg/icon-wallet2.svg";


import {FilterButtonItem} from "../FilterButtonGroup";
import FilterTypes from "../GridHandler/FilterUtil/FilterTypes";
import GridTitle from "../GridHandler/GridTitle";


const BillsOverview = (props) => {
    let [triggerBill] = useLazyGetBillQuery();

    //When user clicks on a download button, this function is called to trigger the api call for that bill id
    const downloadDocument = async (billId) => {
        let result = await triggerBill(billId, {preferCacheValue:true});
        if(result.isSuccess) {
            openDownloadLink(result.data.billFile.file, result.data.billFile.documentName);
            // push event for ga4
            triggerEvent('file_download', 'click', 'success', 'pdf', 'grid_row_download_button');
        } else {
            // push event for ga4
            triggerEvent('file_download', 'click', 'error', 'pdf', 'grid_row_download_button');
        }
    };

    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = (file, documentName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        a.download = documentName;
        a.click();
    }


    const {
        data: bills,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetBillsQuery();

    //Display the correct label ("Fällig" or "Bezahlt")
    let statusIdToText= (params) => {
        if (params.data.statusId === 0) {
            return props.labels?.billsPage?.billsInfo?.outstanding
        } else {
            return ('✓ ' + props.labels?.billsPage?.billsInfo?.closed)
        }
    }
    const columnDefs = [
        {
            //Download Icon
            cellRenderer: function (params) {
                return (
                    <span onClick={() => downloadDocument(params.data.id)} style={{cursor:"pointer"}} id={params.data.billNumber} data-cy={'bill-download-icon'}>
                        <DownloadIcon className="grid-icon"/>
                    </span>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            suppressMenu: true,
            colId: "icon",
            suppressMovable: true
        },

        {
            //Magnifier Icon
            cellRenderer: function (params) {
                return (
                    <Link to={"/bills/" + params.data?.billNumber + "?id=" + params.data?.id }>
                        <MagnifierIcon className="grid-icon"/>
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            suppressMenu: true,
            colId: "icon2",
            suppressMovable: true
        },
        {
            headerName: props.labels?.driverPage?.driverInfo?.companyName,
            colId:"companyName",
            valueGetter: (params) => params.data.companyName,
            cellStyle: {fontWeight: 500}
        },
        {
            headerName: props.labels?.costPage?.costInfo?.invoiceNumber,
            colId:"invoiceNumber",
            valueGetter: (params) => params.data.billNumber
        },
        {
            headerName: props.labels?.billsPage?.billsInfo?.billPeriod,
            colId:"billPeriod",
            valueGetter: function (params) {
                return `${params.data.billName} \n (${formatDateIfNotEmpty(params.data.billPeriodeFrom)} - ${formatDateIfNotEmpty(params.data.billPeriodeTo)})`;
            },
            comparator: (valueA, valueB, nodeA, nodeB) => {
                return Moment(nodeA.data.billPeriodeFrom).diff(Moment(nodeB.data.billPeriodeFrom))
            },
            width:220,
            autoHeight: true,
            cellRenderer: defaultCellRenderer,
            cellRendererParams:{
                multiLine: true
            }
        },
        {
            headerName: props.labels?.costPage?.costInfo?.invoiceDate,
            colId:"invoiceDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.invoiceDate);
            },
            filter: 'agDateColumnFilter',
            // for sorting
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.billsPage?.billsInfo?.dueDate,
            colId:"dueDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.dueDate);
            },
            filter: 'agDateColumnFilter',
            // for sorting
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.costPage?.costInfo?.totalPriceWithVat,
            colId:"totalPriceWithVat",
            valueGetter: function (params) {
                return params.data.amount;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " " + params.data?.currency?.description;
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.billsPage?.billsInfo?.openAmount,
            colId:"openAmount",
            valueGetter: function (params) {
                return params.data.openAmount;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value) + " " + params.data?.currency?.description;
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.billsPage?.billsInfo?.status,
            colId:"status",
            valueGetter: (params) => params.data.statusId,
            valueFormatter:(params) =>statusIdToText(params),
            filterValueGetter: (params) =>statusIdToText(params),
            cellRenderer:statusCellRenderer,
            cellRendererParams: {
                positiveText:1,
                negativeText:0,
                c_name: "badge"
            }
        },

    ]
    let totalOpenAmount = 0;
    let totalClosedAmount = 0;
    let content;

    // show loading text (spinner) while loading
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    }
    else if (isSuccess) { // handle successful data loading

        if(bills && bills.length > 0) {
            // Calculate total open amount
            bills.forEach((bill) => {
                totalOpenAmount += bill.openAmount;
                totalClosedAmount += bill.amount - bill.openAmount;
            })

            // show page content
            content =
                (
                    <>
                        <h1>{props.title}</h1>
                        <div className="bills-overview-content">
                            <div className="number-cards-container">
                                <KeyInfoCard title={props.labels?.billsPage?.titles?.openBillSum}
                                             number={thousandsSeparator(totalOpenAmount.toFixed(2))}
                                             cardStyle={"card-neutral-light"}
                                             unit={"CHF"}
                                             style={{color:"#F04438"}}
                                ></KeyInfoCard>
                                <KeyInfoCard title={props.labels?.billsPage?.titles?.closedBillSum}
                                             number={thousandsSeparator(totalClosedAmount.toFixed(2))}
                                             cardStyle={"card-neutral-light"}
                                             unit={"CHF"}
                                ></KeyInfoCard>
                            </div>
                            <GridHandler
                                gridTitle={
                                    <GridTitle
                                        title={props.labels?.billsPage?.titles?.billsTableTitle}
                                        subtitle={props.labels?.billsPage?.titles?.billsTableSubtitle}
                                        badgeText= {bills.length + " " + props.labels?.billsPage?.titles?.bills}
                                    />}
                                downloadable={false}
                                gridName="upto_bills_overview"
                                data={bills}
                                columnDefs={columnDefs}
                                fileName={`upto_${props.labels?.billsPage?.titles?.billsOverview}`}
                                filterItems={[
                                    <FilterButtonItem id={0} text={props.labels.billsPage?.filter?.all}
                                                      filterType={FilterTypes.ALL}/>,
                                    <FilterButtonItem id={1} text={props.labels.billsPage?.filter?.openOnly}
                                                      filterType={FilterTypes.BILLS_ONLY_OPEN}/>,
                                    <FilterButtonItem id={2} text={props.labels.billsPage?.filter?.closedOnly}
                                                      filterType={FilterTypes.BILLS_ONLY_CLOSED}/>,
                                ]}
                                defaultFilterType={FilterTypes.ALL}
                            ></GridHandler>
                        </div>
                    </>
                )
        }
        else {
            content = createErrorMessage(error, props.title, "h1", props.labels);
        }


    }
    else if (isError) {
        // show different error messages based on status code
        content = createErrorMessage(error, props.title, "h1", props.labels);
    }

    return (
        <>
            {content}
        </>
    )
}

export default BillsOverview;
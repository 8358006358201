import React from "react";
// styling
import "./_style.scss"
import PropTypes from "prop-types";

const AlertContainer = (props) => {
    AlertContainer.propTypes = {
        show: PropTypes.bool.isRequired,
        alertStyle: PropTypes.string.isRequired,
        children: PropTypes.object,
        readMore:props.bool,
        readMoreText:props.string,
        readMoreOnClick:props.func
    }
    const {show, alertStyle, children} = props;

    return (
        show ?
            <div data-cy="alert-container" className={"basic-" + alertStyle}>
                {children}
                {props.readMore&& <button className={"read-more btn btn-light"} onClick={props.readMoreOnClick}>{props.readMoreText}</button>}

            </div>
            : <></>
    )
}

export default AlertContainer
// import styling
import "./_style.scss"

// import external
import React from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"

// import internal
import DatePickerHandler from "../DatePickerHandler";
import CostsByCar from "./CostsByCar";
import CostsByCostType from "./CostsByCostType";
import FilterButtonGroup, { FilterButtonItem } from "../FilterButtonGroup";
import { setCarCostDates, setFleetCostDates } from "../../redux/stateSlice/dateSlice";
import { convertToDate } from "../GridHandler/dates.actions";


//Get correct default start date based on cached data or use first date of year
export const getDefaultStartDate = (dateStoredInRedux) => {
    // if there is a saved date in redux, use that
    if (dateStoredInRedux?.startDate) {
        return convertToDate(dateStoredInRedux.startDate)
    }
    // if in redux it is saved that the user wants all time, use it
    else if (dateStoredInRedux?.allTime) {
        return null
    }
    // otherwise the default start date is the first date of the year
    else {
        return Moment().startOf('year').toDate(); // First day of year
    }
}

// Get correct default end date based on cached data or current date
export const getDefaultEndDate = (dateStoredInRedux) => {
    if (dateStoredInRedux?.endDate) {
        return convertToDate(dateStoredInRedux.endDate)
    }
    else if (dateStoredInRedux?.allTime) {
        return null
    }
    else {
        return Moment().toDate(); // Today
    }
}
const CostOverview = (props) => {

    // True if we are on carDetailsPage, only affects visuals (hides ButtonGroup to make impossible to switch to costtype per car
    // and also hides title)
    const isCarDetailsPage = !!props?.vin;

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const selectedDates = useSelector(state => state.date)
    
    // Depending on whether we are on car details page or fleetcosts, the dates are saved under
    // one of these keys in the redux date state
    const reduxDateKey = isCarDetailsPage ? "carCostDates" : "fleetCostDates";


    const defaultStartDate = getDefaultStartDate(selectedDates[reduxDateKey]);
    const defaultEndDate = getDefaultEndDate(selectedDates[reduxDateKey]);
    const allTime = selectedDates[reduxDateKey].allTime;
    // The start and end date that is sent to the api, when set as empty string the api will return all data
    // AMS-502 Yue: comment the useState out because I do not see a reason why we need both useState and redux. I changed it to a normal variale that does not 
    // trigger rerender
    // const [startDate, setStartDate] = useState(defaultStartDate ?  Moment(convertToDate(defaultStartDate)).format("YYYY-MM-DD") : null)
    // const [endDate, setEndDate] = useState(defaultEndDate ?  Moment(convertToDate(defaultEndDate)).format("YYYY-MM-DD") : null)
    const startDate = defaultStartDate ? Moment(convertToDate(defaultStartDate)).format("YYYY-MM-DD") : null
    const endDate = defaultEndDate ? Moment(convertToDate(defaultEndDate)).format("YYYY-MM-DD") : null

    const validateAndCorrectView = () => {
        const currentView = searchParams.get('view');
        if (currentView !== 'car' && currentView !== 'costtype') {
            searchParams.set('view', 'costtype');
            navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
            return 'costtype';
        }
        return currentView;
    }

    const selectedView = searchParams.get('view') === 'car' ? 1 : 0;
    const validatedView = validateAndCorrectView();

    const handleButtonClick = (item) => {
        const newView = item?.props?.id === 1 ? 'car' : 'costtype';
        if (newView !== validatedView) {
            searchParams.set('view', newView);
            navigate(`${location.pathname}?${searchParams.toString()}`);
        }
    }

    const handleRangeChange = (start, end) => {
        //As soon as the second date is selected, handle the change and fetch new data
        if (start && end) {
            // AMS-502 Yue: comment the useState out because I do not see a reason why we need both useState and redux. 
            // setStartDate(Moment(start).format("YYYY-MM-DD"))
            // setEndDate(Moment(end).format("YYYY-MM-DD"))

            // Update either the redux cost dates for car detail pages or fleet cost page
            if (isCarDetailsPage) dispatch(setCarCostDates({ startDate: Moment(start).format("YYYY-MM-DD"), endDate: Moment(end).format("YYYY-MM-DD"), allTime: false }))
            else dispatch(setFleetCostDates({ startDate: Moment(start).format("YYYY-MM-DD"), endDate: Moment(end).format("YYYY-MM-DD"), allTime: false }))
        }
        //This is executed when the user resets the dates
        else if (!start && !end) {
            // AMS-502 Yue: comment the useState out because I do not see a reason why we need both useState and redux. 
            // setStartDate(null);
            // setEndDate(null);

            // Update either the redux cost dates for car detail pages or fleet cost page
            if (isCarDetailsPage) dispatch(setCarCostDates({ startDate: null, endDate: null, allTime: true }))
            else dispatch(setFleetCostDates({ startDate: null, endDate: null, allTime: true }))
        }
    }


    let content;

    if (selectedView && !isCarDetailsPage) {
        content = <CostsByCar startDate={startDate} endDate={endDate} labels={props.labels} />
    }
    else {
        // Pass a VIN if available (only if this component is used on Car details page)
        content = <CostsByCostType startDate={startDate} endDate={endDate} labels={props.labels} vin={props?.vin} />
    }
    const filterItems = [
        <FilterButtonItem text={props.labels?.costPage?.costInfo?.viewPerCostType} id={0} />,
        <FilterButtonItem text={props.labels?.costPage?.costInfo?.viewPerVehicle} id={1} />
    ]


    return (
        <div className="section-container-content" style={{ minWidth: "640px" }}>

            <div className="cost-overview-header">
                {props.title && <h1>{props.title}</h1>}
                <div className="cost-overview-controls highlight-button-when-load">
                    <span style={{ width: "275px" }}>
                        <DatePickerHandler isRange={true} onChange={handleRangeChange} startDate={convertToDate(defaultStartDate)} endDate={convertToDate(defaultEndDate)} allTime={allTime} />

                    </span>
                    <span style={{ whiteSpace: "nowrap", lineHeight: 2 }} hidden={isCarDetailsPage}>
                        <FilterButtonGroup items={filterItems} onFilterChange={handleButtonClick} selectedItem={filterItems[selectedView]} />
                    </span>
                </div>
            </div>
            {content}
        </div>
    )
}

export default CostOverview
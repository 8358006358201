const API_FAMILY_CONST = {
    AUTH: "api-auth-server/oauth/token",
    DASHBOARD: "frontend-api-v2/dashboard",
    TWO_FACTOR:"frontend-api-v2/user/2Fa",
    TWO_FACTOR_VERIFY: "frontend-api-v2/user/2Fa/validate",
    CAR: "frontend-api-v2/abo",
    CAR_BY_VALUES: "frontend-api-v2/cars/findByValues",
    CAR_MILEAGES: "frontend-api-v2/cars/mileages",
    CAR_BY_VALUES_V2: "frontend-api-v2/cars/findByValuesV2",
    DRIVERS: "frontend-api-v2/organisation",
    INVOICES: "frontend-api-v2/carInvoice/invoicesForCar/",
    INVOICE: "frontend-api-v2/carInvoice",
    BILLS: "frontend-api-v2/bill",
    USER: "frontend-api-v2/user/",
    LABEL:"frontend-api-v2/labels/1?prefix=fleetCockpit",
    AGENT_LOGIN:"api-auth-server/user/agentlogin",
    FUEL_CARDS:"frontend-api-v2/fuelCards/fuelCards",
    DAMAGES: "frontend-api-v2/damage",
    FLEET_COST_COST_TYPE:"frontend-api-v2/fleetCost/costType",
    FLEET_COST_CAR:"frontend-api-v2/fleetCost/car",
    UPDATE_CAR:"frontend-api-v2/repair/createOrUpdateCar",
    UPLOAD_DOCUMENT:"frontend-api-v2/repair/uploadDocument",
    REPORTS: "frontend-api-v2/reports",
    REPORTS_STATUS: "frontend-api-v2/reports/status",
    DOCUMENTS:"frontend-api-v2/document",
    CAR_INVOICES_NO_POS: "frontend-api-v2/carInvoice/invoicesForCarNoPos",
    TELEMATIC:"frontend-api-v2/telematic",
}


const API_AUTH_GRANT_TYPE = {
    CLIENT_CREDENTIALS: "client_credentials",
    PASSWORD:"password"
}

// here we use the old-style export because it is needed for the compareLabels.js file
module.exports = {
    API_FAMILY_CONST,
    API_AUTH_GRANT_TYPE
}
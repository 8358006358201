// import external
import React, { useCallback } from "react";
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux";

// import styling
import "./_style.scss"

// import assets
import { ReactComponent as DownloadIcon } from "../../assets/svg/icon-download.svg";
// import internal components
import LoadingComponent from "../../components/LoadingComponent";
import { createErrorMessage } from "../../components/AlertContainer/alert.utils";
import InvoiceDetailTable from "../../components/InvoiceDetailTable";
import GenericInfoTable from "../../components/GenericInfoTable";
import { useGetInvoiceQuery } from "../../redux/apiSlice/invoiceApiSlice";
import { useLazyGetDocumentFileQuery } from "../../redux/apiSlice/documentsApiSlice";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import { triggerEvent } from "../../actions/eventTracking.actions";

/**
 *
 * @param {*} props (not used)
 * loads details of an invoice and allow user to download the pdf
 * @returns
 */
const InvoiceDetailPage = (props) => {

    let [triggerDocumentDownload] = useLazyGetDocumentFileQuery();

    // get current labels
    const currentLabels = useSelector(state => state.labels.currentLabels)
    const param = useParams()
    const invoice_id = param.id

    const invoiceInfoPath = {

        "invoicingPartyText": (data) => data?.invoicingPartyText,
        "invoiceNumber": (data) => data?.invoiceNumber,
        "invoiceDate": (data) => data?.invoiceDate,
        "dueDate": (data) => data?.dueDate,
        "singleVehicleInvoice": (data) => data?.singleVehicleInvoice ? currentLabels.otherElements.no : currentLabels.otherElements.yes,
        "totalPriceNoVat": (data) => data?.totalAmountExclTax,
        "taxAmount": (data) => data?.taxAmount,
        "totalPriceWithVat": (data) => data?.totalAmountInclTax,
    }

    // api handling logic starts here
    const {
        data: invoice,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetInvoiceQuery(invoice_id)

    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = useCallback((file, documentName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        a.download = documentName;
        a.click();
    }, [])

    const downloadDocument = useCallback(async (documentId, documentName) => {
        let result = await triggerDocumentDownload(documentId, { preferCacheValue: true });
        if (result.isSuccess && result.data?.documentFile) {
            // Check if there are documents available before attempting to download
            openDownloadLink(result.data.documentFile.file, documentName);
            // add ga 4
            triggerEvent('file_download', 'click', 'success', 'pdf', 'download_button');
        }else {
            // push event for ga4
            triggerEvent('file_download', 'click', 'error', 'pdf', 'grid_row_download_button');
        }
    }, [openDownloadLink, triggerDocumentDownload]);

    // Show the download button if the invoiceDocuments array isn't empty and the id is available
    const showDownloadButton = invoice?.invoiceDocuments?.length > 0 && invoice?.invoiceDocuments[0]?.id;

    // start with the api loading logic
    let content;
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) {
        if (!invoice) {
            content = createErrorMessage("No invoices found", "costs");
        } else {
            content = (
                <>
                    {/*Infos zur Rechnung */}
                    <GenericInfoTable labels={currentLabels?.costPage?.costInfo}
                        section_name={currentLabels?.costPage?.titles?.billingInformation}
                        infoPath={invoiceInfoPath}
                        data={invoice}>
                    </GenericInfoTable>

                    {/*Rechnung Downloaden */}
                    <div className="download-button-container" hidden={!showDownloadButton}>
                        <button className="btn btn-secondary" onClick={() => {
                            downloadDocument(invoice?.invoiceDocuments[0]?.id, invoice?.invoiceNumber + "_" + invoice?.invoicingPartyText + ".pdf");
                        }}>
                            <DownloadIcon className="button-icon" />
                            <span>{currentLabels?.otherElements?.downloadPDF}</span>
                        </button>
                    </div>

                    {/*Rechnungspositionen AG-Grid */}
                    <InvoiceDetailTable labels={currentLabels}
                        invoice_id={invoice_id}
                        section_name={currentLabels.costPage.titles.invoicePosition}
                        invoices_data={invoice?.invoicePositions}></InvoiceDetailTable>
                </>
            )
        }
    } else if (isError) {
        content = createErrorMessage(error.message, "costs");
    }
    return (
        <div className="content-side content-side-padding">
            <BreadcrumbNav />
            {content}
        </div>
    )
}


export default InvoiceDetailPage;



//import styling
import "./_style.scss"

//import external
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

function getTab(tabParam, labels, hidden) {
    let tab = 0
    if(!labels ||labels.length<=0) {
        // this is a special case. When this happens, we need to stop trying to render the car details page
        // otherwise we will be stucked in a loop.
        return 999
    }
    try {
        tab = labels.indexOf(tabParam)
        // tab is -1 if the tabParam does not exist in labels
        if (tab < 0) {
            tab = 99
        }
        if (hidden.includes(tab)) {
            tab = 99
        }
    } catch (e) {
        tab = 99
    }
    return tab
}

//ContentTab is a component that stores content (props.views) in tabs (props.labels)
const ContentTab = (props) => {
    // Views are the different screens you can access through tabs, number of screens = number of tabs
    ContentTab.propTypes = {
        views: PropTypes.arrayOf(PropTypes.element).isRequired,
        labels: PropTypes.arrayOf(PropTypes.string).isRequired, //Need to be in same order as views for correct display
        hidden: PropTypes.arrayOf(PropTypes.number), //Array of indexes of tabs to hide
        urlLabels: PropTypes.arrayOf(PropTypes.string), //Array of labels to use in URL
    }
    const navigate = useNavigate()

    // Get tab index from URL, if not found, default to 0
    const urlParams = new URLSearchParams(window.location.search)
    // The tab name user is trying to access
    let tabName = urlParams.get("tab")

    const tabIndex = getTab(tabName, props.urlLabels, props.hidden)

    const [index, setIndex] = useState(tabIndex)

    // the useEffect here is a must. navigate need the component to rerender to work.
    useEffect(()=>{
        if(tabIndex===999){
            navigate("/error")
        }
        if(tabIndex===99){
            navigate("?tab=" + props.urlLabels[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    // Ensure that tab index is synced with URL
    if (index !== tabIndex) {

        setIndex(tabIndex)
        //navigate("?tab=" + tabName)
    }

    //Holds the content of each tab
    const TabPanel = () => {
        return (
            <>
                {props?.views[index]}
            </>
        )
    }

    const Tab = (props) => {
        const c_name = props.idx === index ? "tab active" : "tab";
        return (
            <div className={c_name} onClick={props.onClick}>
                {props.label}
            </div>
        )
    }
    return (
        <div className="content">
            <div className="tab-group">
                {props.labels.map((label, idx) => {

                    //If the index is in the "hidden" array, don't display the tab
                    if (props.hidden?.includes(idx)){
                        return <></>
                    }
                    return (
                        <Tab label={label}  idx={idx} onClick={() => {
                            navigate("?tab=" + props.urlLabels[idx])
                            setIndex(idx)
                        }}/>
                    )
                })
                }
            </div>

            <TabPanel/>

        </div>

    )


}
export default ContentTab;
// import styling
import "./_style.scss";

// import external
import React, {useRef} from "react";
import Moment from "moment/moment";

// import internal
import GridTitle from "../GridHandler/GridTitle";
import GridHandler from "../GridHandler";
import LoadingComponent from "../LoadingComponent";
import {FilterButtonItem} from "../FilterButtonGroup";
import FilterTypes from "../GridHandler/FilterUtil/FilterTypes";
import KeyInfoCard from "../KeyInfoCard";
import DocumentGenerationNotification from "./DocumentGenerationNotification";
import Dropdown from "../Dropdown";

// import helper
import {formatDateIfNotEmpty} from "../../actions/date.actions";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import {createErrorMessage} from "../AlertContainer/alert.utils";
import {
    useGetDocumentQuery,
    useGetDocumentsQuery,
    useLazyGetDocumentFileQuery
} from "../../redux/apiSlice/documentsApiSlice";
import {getDocumentType, getDocumentTypeId} from "../../actions/magicNumber.actions";

// import assets
import {ReactComponent as DownloadIcon} from "../../assets/svg/icon-download.svg";
import {ReactComponent as CalendarIcon} from "../../assets/svg/icon-calendar.svg";
import {ReactComponent as FileIcon} from "../../assets/svg/icon-file.svg";
import { triggerEvent } from "../../actions/eventTracking.actions";


/**
 * If props.serviceId is provided this component assumes you are accessing it from the document tab on the car details page.
 * This means that it will call a different api to get the documents for a specific serviceId and additionally will not display the following:
 *  - DocumentGenerationNotification (the banner that tells you that a document is being generated, because might not be for that specific car)
 *  - FilterButtonGroup for "All" or "Without Car Only"
 */
const DocumentOverview = (props) => {
    let [triggerDocumentDownload] = useLazyGetDocumentFileQuery();

    const gridRef = useRef(null);


    //When user clicks on a download button, this function is called to trigger the api call for that document id
    const downloadDocument = async (documentId, backupName) => {
        // Yue: add preferCacheValue true because no need to re-download the file data each time user download the same
        // file
        let result = await triggerDocumentDownload(documentId, {preferCacheValue: true});
        if (result.isSuccess) {
            openDownloadLink(result.data?.documentFile?.file, result.data?.documentFile?.documentName, backupName);
            // push event for ga4
            triggerEvent('file_download', 'click', 'success', 'pdf', 'grid_row_download_button');
        } else {
            // push event for ga4
            triggerEvent('file_download', 'click', 'error', 'pdf', 'grid_row_download_button');
        }
    };

    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = (file, documentName, backupName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        // If the file document has an undefined name, use the one displayed in grid, if that empty too, display "document"
        a.download = documentName || (backupName || "document");
        a.click();
    }

    function getTranslatedDocumentType(documentType) {
        const documentTypeId = getDocumentTypeId(documentType);
        return getDocumentType(documentTypeId, props.labels);
    }

    // Get all documents for a customer (only if props.serviceId is null, so if we are on /documents and not /cars/{id}  )
    const {
        data: documents,
        isLoading: isDocumentsLoading,
        isSuccess: isDocumentsSuccess,
        refetch: refetchDocuments,
        error: documentsError,
        // the serviceId is actually car id
        // undefined is needed, otherwise rtk does not know that this is the same query we prefetched in navbar
    } = useGetDocumentsQuery(undefined,{skip: props.serviceId});

    // If we are on the documents tab for car details page, use the document id specific api
    const {
        data: carDocuments,
        isLoading: isCarDocumentsLoading,
        isSuccess: isCarDocumentsSuccess,
        refetch: refetchCarDocuments,
        error: carDocumentsError,
    } = useGetDocumentQuery(props.serviceId, {skip: !props.serviceId})

    // switch between 2 api calls
    let data, isLoading, isSuccess, error, refetch;
    if (props.serviceId) {
        data = carDocuments;
        isLoading = isCarDocumentsLoading;
        isSuccess = isCarDocumentsSuccess;
        error = carDocumentsError;
        refetch = refetchCarDocuments;
    } else {
        data = documents;
        isLoading = isDocumentsLoading;
        isSuccess = isDocumentsSuccess;
        error = documentsError;
        refetch = refetchDocuments;
    }

    const columnDefs = [
        {
            //Download Icon
            cellRenderer: function (params) {
                return (
                    <span onClick={() => downloadDocument(params.data.id, params.data.documentName)}
                          style={{cursor: "pointer"}} title={params.data.documentName || 'document.pdf'}
                          id={params.data.id} data-cy={'file-download-icon'}>
                        <DownloadIcon className="grid-icon"/>
                    </span>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            suppressMenu: true,
            colId: "icon",
            suppressMovable: true
        },
        {
            headerName: props.labels?.documentPage?.documentInfo?.date,
            colId: "date",
            // Use YYYY-MM-DD:HH:MM:SS format for sorting, so time is also taken into account
            valueGetter: function (params) {
                return params.data.date;
            },
            // Use DD.MM.YYYY format for displaying
            valueFormatter: function (params) {
                return formatDateIfNotEmpty(params.value)
            },
            filter: 'agDateColumnFilter',
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            },
            sort: 'desc',
        },
        {
            headerName: props.labels?.documentPage?.documentInfo?.documentName,
            colId: "documentName",
            valueGetter: (params) => params.data.documentName,
            cellStyle: {fontWeight: 500},
            // Custom property so this column will be skipped in the AutoSizeColumns function in GridHandler
            disableAutoResizing: true,
            minWidth:300,
        },
        {
            headerName: props.labels?.documentPage?.documentInfo?.documentType,
            colId: "documentType",
            valueGetter: (params) => getTranslatedDocumentType(params.data.documentType),
            suppressMenu: true,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.brand,
            valueGetter: function (params) {
                return params.data.carData?.brand;
            },
            colId: "brand"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.type,
            valueGetter: function (params) {
                return params.data.carData?.type;
            },
            colId: "type"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId: "plateNumber",
            valueGetter: function (params) {
                return params.data.carData?.licencePlate;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            colId: "VIN",
            valueGetter: function (params) {
                return params.data.carData?.vin;
            }
        },
    ]

    // Handle the change of document type filter (in dropdown) so in agGrid the filter is applied
    function handleDocumentTypeChange(e) {
        let value = e.target.value;

        const reportFilterComponent = gridRef.current?.api?.getFilterInstance("documentType");

        // If the user selects "All", remove the filter
        if (value === "0") {
            reportFilterComponent.setModel(null);
        } else {
            reportFilterComponent.setModel({
                ...reportFilterComponent.getModel(),
                values: [getDocumentType(Number(value), props.labels)]
            })
        }
        gridRef.current?.api?.onFilterChanged();
    }
    let content;
    // show loading text (spinner) while loading
    if (isLoading) {
        content = <LoadingComponent show={true}/>;
    } else if (isSuccess) {
        let unpackedDocuments;
        if (props.serviceId) {
            unpackedDocuments = data;
        } else {
            // Unpack the document array so each index contains a document
            unpackedDocuments = data.reduce((acc, document) => {
                acc.push(...document.documents)
                return acc
            }, [])
        }


        // If there are no documents, display error message
        if (unpackedDocuments.length === 0) {
            content = createErrorMessage(error, null, "h1", props.labels);
        } else {
            // Find most recent date of documents to display it in the key info card
            const mostRecentDocument = unpackedDocuments.reduce((prev, current) => (Moment(prev.date).isAfter(current.date)) ? prev : current).date

            // Save all document types in an object (key = documentTypeId, value = count)
            const documentTypes = unpackedDocuments.reduce((acc, document) => {
                const documentTypeId = getDocumentTypeId(document.documentType);
                // Yue: we also get the counts of each type, but they are no longer used
                if (Object.keys(acc).includes(String(documentTypeId)) === false) {
                    acc[documentTypeId] = 0;
                }
                acc[documentTypeId] += 1;
                return acc
            }, {})
            // Contains the title & select element for filtering by document type
            const documentTypeSelector = (
                <div className="document-type-selection">
                        <span className="title">
                            {props.labels?.documentPage?.documentInfo?.filterByDocumentType}
                        </span>
                    <Dropdown
                        onChange={(event) => handleDocumentTypeChange(event)}
                        options={[
                            {label: props.labels?.documentPage?.documentInfo?.all, value: 0},
                            ...Object.keys(documentTypes).map((documentTypeId) => {
                                return (
                                    {
                                        label: getDocumentType(Number(documentTypeId), props.labels),
                                        value: documentTypeId
                                    }
                                )
                            })
                        ]}
                    />
                </div>
            )
            content = (
                <div className="document-overview-content">
                    <div className="card-container">
                        <KeyInfoCard
                            title={props.labels?.documentPage?.titles?.mostRecentDocument}
                            number={formatDateIfNotEmpty(mostRecentDocument)}
                            cardStyle="card-positive"
                            unit={""}
                        >
                            <CalendarIcon className={"card-icon"}></CalendarIcon>
                        </KeyInfoCard>

                        <KeyInfoCard
                            title={props.labels?.documentPage?.titles?.numberOfDocuments}
                            number={unpackedDocuments.length}
                            cardStyle="card-grey"
                            unit={""}
                        >
                            <FileIcon className={"card-icon"}></FileIcon>
                        </KeyInfoCard>
                    </div>

                    {(!props.serviceId) ? <DocumentGenerationNotification
                        labels={props.labels}
                        refetchData={refetch}
                    /> :null}

                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.documentPage?.titles?.documentsTableTitle}
                                subtitle={props.labels?.documentPage?.titles?.documentsTableSubtitle}
                                badgeText={unpackedDocuments.length + " " + props.labels?.documentPage?.titles?.documents}
                            />
                        }
                        forwardedRef={gridRef}
                        headerSideContent={documentTypeSelector}
                        downloadable={false}
                        onCellValueChanged={true}
                        // it is important that the grid name is unique, because it is used to save filter settings etc
                        gridName={`upto_documents_${props.serviceId ? props.serviceId : "overview"}`}
                        data={unpackedDocuments}
                        columnDefs={columnDefs}
                        fileName={`upto_${props.labels?.documentPage?.titles?.documentsOverview}`}
                        filterItems={props.serviceId ? undefined : [
                            <FilterButtonItem id={0} text={props.labels?.documentPage?.filter?.all}
                                              filterType={FilterTypes.ALL}/>,
                            <FilterButtonItem id={1} text={props.labels?.documentPage?.filter?.withoutCarOnly}
                                              filterType={FilterTypes.DOCUMENTS_NO_CAR_ONLY}/>,
                        ]}
                    ></GridHandler>
                </div>
            )
        }
    }
    // If api call was not successful, display error message
    else {
        content = createErrorMessage(error, null, "h1", props.labels);
    }

    return (
        <>
            <h1>{props.title}</h1>
            {content}
        </>
    )
}

export default DocumentOverview;
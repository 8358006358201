// import styling
import "./_style.scss";

//import external
import PropTypes from "prop-types";

//import assets
import {ReactComponent as CloseIcon} from "../../assets/svg/icon-close.svg";
import {useEffect} from "react";

//Modal that consists of a header and a body (props.children)
const FormModal = (props) => {
    FormModal.propTypes = {
        show : PropTypes.bool.isRequired, //Whether the modal should be shown or not (is controlled by parent component)
        onClose : PropTypes.func.isRequired, //Function that is called when the modal is closed
        onOutsideClick: PropTypes.func, //Function that is called when the modal is clicked outside
        title: PropTypes.string, //Title of modal
        featuredIcon: PropTypes.element //a featuredIcon that is displayed above the title
    }
    let {cssId,setShow,featuredIcon,title, ...rest}=props

    const cockpit = document.getElementsByClassName("content-side")[0];

    useEffect(() => {
        if(props.show && cockpit){
            //cockpit.scrollTop = 0;
            cockpit.setAttribute("style", `overflow: hidden`);
        }
        else if(cockpit) {
            cockpit.setAttribute("style", `overflow: auto`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])


    //If showModal is false, don't show anything
    if(!props.show) {
        return null
    }

    return (
        <>
            <div className="modal-container" id={props.cssId}  {...rest}>
                {/* Close Icon on Top Right*/}
                <CloseIcon
                    onClick={props.onClose}
                    className="modal-close-icon"
                    // 18px is the padding of the content to modal border
                    style={{position:"absolute",zIndex:1, right: "0.9rem", top: "18px"}}
                />

                <div className="blank-card" style={{background:'white'}}>
                    {/* Modal Header */}
                    <div className="modal-header">
                        {props.featuredIcon}
                        {title}
                    </div>

                    {/* Modal Body */}
                    {props.children}
                </div>
            </div>
            {/* Modal Overlay (Makes it possible to darken background etc.)*/}
            <div className="modal-overlay" onClick={() => props.onOutsideClick ? props.onOutsideClick() : props.onClose()}></div>
        </>
    )



}

export default FormModal;
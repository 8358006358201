export const calculateKmDriven = (kmData) => {
    let kmDataValid = true;

    const kmDriven = kmData?.reduce((acc, obj) => {
        if (obj?.km > 0 && obj?.kmPerDay < 800) return acc + obj.km
        else {
            kmDataValid = false;
            return acc;
        }
    }, 0);
    return {kmDriven,kmDataValid}

}
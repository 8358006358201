// import react/redux component
import {Navigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {setToken} from "../redux/authSlice/authSlice";
import {loadToken} from "../actions/token.actions";
// import internal styling
import "./_style.scss"
// import react internal components
import LoadLanguage from "../components/LoadLanguage";

const PrivateRoutes = () => {
    const dispatch = useDispatch()
    const access_token = useSelector(state => state.access_token)
    if (access_token) {
        return (
            <LoadLanguage></LoadLanguage>
            )
    }
    try {
        const upto_token = loadToken();
        dispatch(setToken(upto_token));
        return (
            upto_token?.access_token ?
                <LoadLanguage></LoadLanguage>
                : <Navigate to='/login'/>
        )
    } catch (error) {
        return <Navigate to='/login'/>
    }
}

export default PrivateRoutes
import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const damagesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDamages: builder.query({
            // get all damages for a customer
            query: () => API_FAMILY_CONST.DAMAGES + "/damagesForCustomer",
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            providesTags:["Damages"]
        }),
        getDamage: builder.query({
            query: (damageId) => API_FAMILY_CONST.DAMAGES + "/" + damageId,
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            // the response from this api also include base64 data of the invoice => invoiceDetails
            providesTags: (result, error, arg) => [{type: 'DamageDetail', id: arg}],
        }),
        updateDamage: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.DAMAGES,
                    method: 'PUT',
                    body: data,
                }
            },
            // the tag of the cache is the id in the request body arg (which is the data)
            // only invalidate if the update is successful
            invalidatesTags: (result, error, arg) =>  error?[]: [{type: 'DamageDetail', id: arg.id},{type:"Damages"}]
        })
    })
})
export const {
    useGetDamagesQuery,
    useGetDamageQuery,
    usePrefetch,
    useUpdateDamageMutation
} = damagesApiSlice
// Checks if a text was sanitized using sanitizeHTML (used to avoid multiplication when component is re-rendered)
const alreadySanitized = new RegExp(/&amp;|&lt;|&gt;|&quot;|&#39;/g);

// Sanitizes text possibly containing HTML tags (e.g. <b> or <s>), by wrapping them into a div
export function sanitizeHTML(text) {
    if (!alreadySanitized.test(text)) {
        const element = document.createElement('div');
        element.innerText = text;
        return element.innerHTML;
    } else {
        return text;
    }
}

// Sanitizes by removing any html tags from the actual text, probably shouldn't be used in most cases
export function hardSanitize(text) {
    if (!text || typeof(text) !== "string") return text;
    return text.replace(/<[^>]*>/g, '');
}

// Sanitizes the "datum" object used in the Ag Charts params object
export function sanitizeValues(params, hard=false) {
    for (const key of Object.keys(params.datum)) {
        let transformer = String
        const sanitizer = hard ? hardSanitize : sanitizeHTML;
        const originalType = typeof(params.datum[key])
        if (originalType === "number") {
            transformer = Number
        }
        params.datum[key] = transformer(sanitizer(params.datum[key]))
    }
    return params;
}
// Yue: data source: the cost type in power apps and jira story AAX-1005
// the value on the right hand side, need to map keys in the German.json file fleetWizard.form.fuelTypes
export const fuelTypeFromCo2Api = {
    "403655": "Diesel",
    "403656": "Benzin",
    "403657": "Elektro",
    "403658": "Autogas (LPG)",
    "4036581": "Flüssiggas (LNG)",
    "4036582": "Verdichtetes Erdgas (CNG)",
    //Yue: this mapping below is added by me
    "99999999":"Hybrid"

}
// Yue: data source: the drop down memu in AAX
export const fuelTypeFromKmApi = [
    "Benzin",
    "Diesel",
    "Elektro",
    "Hybrid",
    "Plug-In Hybrid",
    "Hybrid (Diesel + Elektro)",
    "Hybrid (Diesel + LPG)",
    "Hybrid (Diesel + CNG)",
    "Hybrid (Diesel + LNG)",
    "Hybrid (Benzin + Elektro)",
    "Hybrid (Benzin + LPG)",
    "Hybrid (Benzin + CNG)",
    "Hybrid (Benzin + LNG)",
    "Autogas (LPG)",
    "Flüssiggas (LNG)",
    "Verdichtetes Erdgas (CNG)",
    "Andere"
]
export const fuelTypeMapping = {
    "Benzin": "403656",
    "Diesel": "403655",
    "Elektro": "403657",
    "Plug-In Hybrid": "99999999",
    "Hybrid (Diesel + Elektro)":  "99999999",
    "Hybrid (Diesel + LPG)":  "99999999",
    "Hybrid (Diesel + CNG)":  "99999999",
    "Hybrid (Diesel + LNG)":  "99999999",
    "Hybrid (Benzin + Elektro)":  "99999999",
    "Hybrid (Benzin + LPG)": "99999999",
    "Hybrid (Benzin + CNG)":  "99999999",
    "Hybrid (Benzin + LNG)":  "99999999",
    "Autogas (LPG)": "403658",
    "Flüssiggas (LNG)": "436581",
    "Verdichtetes Erdgas (CNG)": "436582",
    "Andere":null
}
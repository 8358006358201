// import styling
import "./_style.scss"

const FeatureIcon = (props) => {
    const {children} = props;

    FeatureIcon.defaultProps = {
        shape: "square",
        backgroundColor: "#FFFFFF"
    }

    // Used to have a darker inner circle in the feature icon of the circular version
    const darkenColor = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };
    if(props.shape === "circle") {
        return (
            <div className="feature-icon-circular-outer"
                 style={{backgroundColor:props.backgroundColorOuter || darkenColor(props.backgroundColor, 10)}}
            >
                <div
                    className="feature-icon-circular-inner"
                    style={{backgroundColor:props.backgroundColor}}
                >
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className="feature-icon" style={{backgroundColor:props.backgroundColor}}>
            {children}
        </div>
    )
}

export default FeatureIcon;
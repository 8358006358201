//import styling
import "./_style.scss"

const TextTooltip = (props) => {
    //Used to identify the individual cells
    const rowId = props.node.rowIndex;
    const columnId = props.column.instanceId;

    //Try to find the cell and check if cell is overflowing (displaying dots), otherwise ignore
    try {
        const element = document.getElementById(`(${rowId} ${columnId})`);
        if(element.scrollWidth > element.offsetWidth){
            return (
                <p>
                    <span className="text-tooltip">
                        {props.valueFormatted ? props.valueFormatted : props.value}
                    </span>
                </p>
            )
        }
    }
    //Catch and ignore errors (for example if the Grid uses different CellRenderer)
    catch (e) {
    }

};

export default TextTooltip
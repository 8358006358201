import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

// import styling
import "./_style.scss"


/**
 * @param {title, number, (unit), cardStyle:string} props
 * Include a countdown timer and text to tell users to wait
 * @returns
 */



const ResendCountDown = (props) => {

    ResendCountDown.propTypes = {
        labels: PropTypes.object.isRequired, //
        resendCode: PropTypes.func, //
    }


    const defaultWaitTime = 50;

    // If there is a previous request, get the time difference between now and then and subtract it from the defaultWaitTime
    const getInitialWaitTime = () => {
        // Count seconds
        let inheritedWaitTime = -1;
        if (JSON.parse(localStorage.getItem('two_factor_request'))?.time) {
            const lastRequest = JSON.parse(localStorage.getItem('two_factor_request')).time;
            const now = new Date();
            const diff = Math.floor(Math.abs(now - lastRequest) / 1000);
            inheritedWaitTime = defaultWaitTime - diff;
        }
        return inheritedWaitTime<0?defaultWaitTime:inheritedWaitTime

    }


    // Set seconds to defaultWaitTime or inheritedWaitTime (if there is one)
    const [secondsLeft, setSecondsLeft] = useState(getInitialWaitTime);
    // Increment seconds after each second
    useEffect(() => {
        if (secondsLeft > 0) {
            setTimeout(() => {
                setSecondsLeft(secondsLeft - 1)
            }, 1000)
        }
    }, [secondsLeft])

    function getCountdown() {
        if (secondsLeft < 0) {
            return 0;
        } else {
            return secondsLeft;
        }
    }

    function getResendText() {
        if (secondsLeft > defaultWaitTime) {
            return props.labels?.twoFactor?.emailSent;
        } else if (secondsLeft > 0) {
            return props.labels?.twoFactor?.sendAgainSoon;
        } else {
            return props.labels?.twoFactor?.sendAgain;
        }
    }

    function resendTwoFactorCode() {
        if (getCountdown() === 0) {
            // Set it to defaultWaitTime +5 instead of defaultWaitTime so we can use the extra 5 seconds to display a "Sent!" text
            setSecondsLeft(defaultWaitTime + 5);
            props.resendCode();
        }
    }

    return (
        <>
            <p style={{whiteSpace: "nowrap"}}>{props.labels?.twoFactor?.emailNotReceived}
                <span className={getCountdown() ? "highlighted" : "highlighted clickable"}
                      onClick={() => resendTwoFactorCode()}>
                            {getResendText()}
                        </span>
                {getCountdown() !== 0 &&
                    <span className="timer">
                                ({getCountdown()}s)
                            </span>
                }
            </p>
        </>
    )
}

export default ResendCountDown
// styling
import "./_style.scss";

// import external
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

//assets
import {ReactComponent as Logo} from "../../assets/svg/logo.svg";
import Fleetmanager from "../../assets/image/fleet.svg"
import loginLabels from '../../assets/i18n/LoginLabels.json'

// internal
import PageFooter from "../../components/PageFooter";
import AlertContainer from "../../components/AlertContainer";
import {setTokenAndSave} from "../../redux/authSlice/authSlice";
import TwoFactorHandler from "../../components/TwoFactorHandler";
import {useLazySendTwoFactorCodeQuery} from "../../redux/apiSlice/twoFactorApiSlice";
import {useLazyGetUserQuery} from "../../redux/apiSlice/userApiSlice";
import {apiCallGetToken} from "../../actions/apicall.actions";
import {API_AUTH_GRANT_TYPE} from "../../actions/constants/api.constants";
import {triggerLoginEvent} from "../../actions/eventTracking.actions";

const LoginPage = () => {
    // the reason why we have ErrorMessageType and getErrorMessage:
    // when the error occurs, we want to store in the state which error occurred, so that when the language changes
    // it gets rerendered with the new language.
    const ErrorMessageType = {
        NONE: 0,
        BAD_CREDENTIALS: 1,
        OTHER_ERROR: 2,
        TWO_FACTOR_ERROR: 3
    }
    const getErrorMessage = (errorMsgType) => {
        switch (errorMsgType) {
            case ErrorMessageType.NONE:
                return "";
            case ErrorMessageType.BAD_CREDENTIALS:
                return currentLoginLabels?.errorMessage?.badCredentials;
            case ErrorMessageType.OTHER_ERROR:
                return currentLoginLabels?.errorMessage?.otherError;
            case ErrorMessageType.TWO_FACTOR_ERROR:
                return currentLoginLabels?.errorMessage?.twoFactorError;
            default:
                return "";
        }
    }
    // hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsgType, setErrorMsgType] = useState(ErrorMessageType.NONE);
    const [showButtonLoading, setButtonLoading] = useState(false);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
    // Checks if user is allowed to request a new 2fa token already
    function canRequest2faToken() {
        try {
            const twoFactorRequestTime = JSON.parse(localStorage.getItem("two_factor_request")).time;
            const currentTime = Date.now()
            const timeDifference = currentTime - twoFactorRequestTime
            // If last request was less than 50 seconds ago, and user still trying to log into same account, don't send another 2fa token
            if (timeDifference < 50000 && JSON.parse(localStorage.getItem("two_factor_request")).user === username) {
                return false
            }
            localStorage.removeItem("two_factor_request")
        }
            // if time to last request was more than 50 seconds ago, or if there was no previous request, 2fa token can be sent
        catch (e) {
            return true
        }
        return true
    }

    // send 2fa token to user e-mail
    const [triggerSend2faToken, {isLoading: isSending}] = useLazySendTwoFactorCodeQuery();
    let [triggerGetUser, {isLoading: isLoadingUser}] = useLazyGetUserQuery()

    // get language labels
    const currentLanguage = useSelector(state => state.labels.currentLanguage)
    let currentLoginLabels = JSON.parse(JSON.stringify(loginLabels))[currentLanguage]
    // actions
    const errorTextsFromApi = ["Bad credentials", "no user found"]

    const trigger2faTokenWrapper=()=>{
        // send 2fa token to user e-mail
        triggerSend2faToken().then((response) => {
            // if there was an error sending the 2fa token, show error message
            if (response.error) {
                setErrorMsgType(ErrorMessageType.TWO_FACTOR_ERROR)
                setShowErrorMsg(true)
                setShowTwoFactorModal(false);
            }
            // otherwise show the modal where user needs to enter token
            else {
                // if the 2fa code was sent successfully, update/remember time when 2fa token was last sent
                localStorage.setItem("two_factor_request", JSON.stringify({time: Date.now().toString(), user: username}))
                setShowTwoFactorModal(true);
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setButtonLoading(true);
        // in case the autofill did not work with email(username) field, we access the value via ref
        let currentEmail = username
        if (!username) {
            setUsername(emailInputRef.current.value)
            currentEmail = emailInputRef.current.value
        }
        apiCallGetToken({
            "username": currentEmail,
            "password": password
        }, API_AUTH_GRANT_TYPE.PASSWORD).then((response) => {
            setErrorMsgType(ErrorMessageType.NONE)
            setShowErrorMsg(false)
            dispatch(setTokenAndSave({access_token: response.access_token, refresh_token: response.refresh_token}));
            setButtonLoading(false);
            return response
        }).then(async (response) => {
            let result = await triggerGetUser()
            // If the user api returns a 403, it means the user needs to go through 2fa process
            if (result.error?.status === 403) {

                // If user has already requested a 2fa token less than 50 seconds ago, don't send another one and just open modal instead
                if (!canRequest2faToken()) {
                    setShowTwoFactorModal(true);
                    return;
                }

                // send 2fa token to user e-mail
                trigger2faTokenWrapper()
            } else {
                // If user has 2fa role, send them to homepage
                dispatch(setTokenAndSave({access_token: response.access_token, refresh_token: response.refresh_token}));
                // Trigger GTM Event: User logged in without 2FA using login screen
                triggerLoginEvent('email_no2fa', response.access_token, true)
                navigate("/");
            }
        }).catch(
            (error) => {
                // Trigger GTM Event: User failed to login without 2FA
                triggerLoginEvent('email_no2fa', null, false)

                setButtonLoading(false);
                if (errorTextsFromApi.some(knownErrorText => error.message.toString().includes(knownErrorText))) {
                    setErrorMsgType(ErrorMessageType.BAD_CREDENTIALS);
                } else {
                    // the error msg from the server does not match any error msg we know (saved in errorTextsFromApi)
                    setErrorMsgType(ErrorMessageType.OTHER_ERROR);
                }
                setShowErrorMsg(true);
            }
        );
    };
    // fix the bug that autofill sometimes does not trigger onChange with the email field
    const emailInputRef = useRef(undefined);

    return (
        <div className="page portal-login">
            <div className="header">
                <Logo height="54px" className="logo" alt="logo of upto"/>
                <h2>Fleet Management</h2>
            </div>
            <div className="login-content">
                <div className="welcome-image">
                    <img src={Fleetmanager} className="fm" alt="pic of fleetmanager"/>
                </div>
                <div className="form-container">
                    <AlertContainer show={showErrorMsg}
                                    alertStyle={"alert-warning"}>{getErrorMessage(errorMsgType)}</AlertContainer>

                    <form onSubmit={handleSubmit} name="login-form">
                        <label>{currentLoginLabels?.logInForm?.emailAddress} / Username</label>
                        <input name="email" className="form-control" data-cy="email-input-field" ref={emailInputRef}
                               required onChange={(e) => setUsername(e.target.value)}/>
                        <label>{currentLoginLabels?.logInForm?.password}</label>
                        <input type='password' name="password" className="form-control" data-cy="password-input-field"
                               required onChange={(e) => setPassword(e.target.value)}/>
                        {showButtonLoading || isSending || isLoadingUser ?
                            <button className="btn btn-primary btn-disabled" type='submit' name="submit-login">
                                <div className="dot-falling-container">
                                    <div className="dot-falling"/>
                                </div>
                            </button>
                            : <button className="btn btn-primary" type='submit' data-cy="login-submit-button" name="submit-login">
                                <span>{currentLoginLabels?.logInForm?.signIn}</span>
                            </button>}
                        <div className="user-help">
                            <p><a
                                href="https://cockpit.upto.ch/static/portal.html#/en/login/reset-password">{currentLoginLabels?.logInForm?.forgotPassword}</a>
                            </p>
                        </div>
                    </form>
                </div>
                {/* So the Modal states are reset when it is closed (including attempts, if you don't want this remove the "showTwoFactorModal &&") */}
                {showTwoFactorModal &&
                    <TwoFactorHandler
                        labels={currentLoginLabels}
                        show={showTwoFactorModal}
                        handleCancel={() => {
                            setShowTwoFactorModal(false)
                            setButtonLoading(false)
                        }}
                        resendCode={trigger2faTokenWrapper}
                    />
                }
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default LoginPage
// endpoint to get all car related information
import {apiSlice} from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";
import {addBackLicensePlatesEmptyArr, dataCrossTabTransform} from "../../actions/crossTab.actions";
import Cookies from "js-cookie";
import {STORAGE_CONST} from "../../actions/constants/storage.constants";

export const carsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCars: builder.query({
            query: () => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES,
                method: 'GET',
                params: {
                    loadContractData: true,
                },
                //caching for 5 min
                keepUnusedDataFor: 600,
            }),
            providesTags: ['Cars'],
            transformResponse: res => {
                return dataCrossTabTransform(res);
            },
        }),
        getCar: builder.query({
            query: (vinNr) => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES,
                method: 'GET',
                params: {
                    vin: vinNr,
                    loadContractData: true
                }
            }),
            providesTags: (result, error, arg) =>{
                return result
                    ? [{type:"Car", id:arg}]
                    : []
            },
            transformResponse: res => {
                return dataCrossTabTransform(res);
            },
        }),
        getCarsV2: builder.query({
            query: () => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES_V2,
                method: 'GET',
                params: {
                    loadContractData: true,
                },
                //caching for 5 min
                keepUnusedDataFor: 600,
            }),
            providesTags: ['Cars'],
            transformResponse: res => {
                   // Yue: in v2, if there is no licence plates, the field is dropped from the res. In comparison, the old version would return an empty array
                // like data.additionalCarInformation.licensePlates=[]. to simplify things, we transform the response to fit the old style
                return dataCrossTabTransform(addBackLicensePlatesEmptyArr(res));
            },
        }),
        getCarV2: builder.query({
            query: (vinNr) => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES_V2,
                method: 'GET',
                params: {
                    vin: vinNr,
                    loadContractData: true
                }
            }),
            providesTags: (result, error, arg) =>{
                return result
                    ? [{type:"Car", id:arg}]
                    : []
            },
            transformResponse: res => {
                   // Yue: in v2, if there is no licence plates, the field is dropped from the res. In comparison, the old version would return an empty array
                // like data.additionalCarInformation.licensePlates=[]. to simplify things, we transform the response to fit the old style
                return dataCrossTabTransform(addBackLicensePlatesEmptyArr(res));
            },
        }),
        updateCar: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.UPDATE_CAR,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : [{type:"Car", id: result.vin},{type:"Mileage", id: result.vin}, {type:"Cars", id: result.vin}]
            }
        }),
        createCar: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.UPDATE_CAR,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : ['Cars']
            }
        }),
        getMileage: builder.query({
            query(vin) {
                return {
                    url: API_FAMILY_CONST.CAR_MILEAGES,
                    method: 'GET',
                    params: {
                        vin: vin
                    }
                }
            },
            providesTags: (result, error, arg) =>{
                return result
                    // Not possible to do this Vin specific since each car has different
                    ? [{type: 'Mileage', id: arg.vin}]
                    : []
            }
        }),
        deleteTelematic: builder.mutation({
            query(data) {
                let agentLoginData;

                try {
                    agentLoginData =  JSON.parse(Cookies.get(STORAGE_CONST.AGENT_LOGIN_DATA));
                } catch {
                    agentLoginData = {};
                }

                return {
                    url: API_FAMILY_CONST.TELEMATIC + `/${data?.id}` + (
                        (agentLoginData?.user && agentLoginData?.hash) ? `?agentUser=${agentLoginData?.user}&hash=${agentLoginData?.hash}` : ""
                    ),
                    method: 'DELETE',
                    // This is not actually used by AAX it's just so I can invalidate tag
                    body: {
                        vin: data?.vin
                    }
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : [{type: 'Mileage', id: result.vin}]
            }
        })
    })
})

export const {
    // useGetCarsQuery,
    // useGetCarQuery,
    useGetCarsV2Query,
    useGetCarV2Query,
    useLazyGetCarQuery,
    useUpdateCarMutation,
    useCreateCarMutation,
    useDeleteTelematicMutation,
    useGetMileageQuery,
    usePrefetch
} = carsApiSlice

// The hook below is no longer needed. Nevertheless, it works and is left as an example.
//export const useGetCarQueryState = apiSlice.endpoints.getCar.useQueryState;
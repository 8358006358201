// import external
import React, {useState} from "react";
// import styling
import "./_style.scss";
import {ReactComponent as Edit} from "../../assets/svg/icon-edit.svg";
import {ReactComponent as Cancel} from "../../assets/svg/cross-icon.svg";
import {ReactComponent as Save} from "../../assets/svg/icon-save.svg";
import Moment from "moment";

// import api slice
import{useUpdateDriversMutation, useGetDriversQuery} from "../../redux/apiSlice/driverApiSlice";
import LoadingComponent from "../LoadingComponent";
import ButtonWithIcon from "../ButtonWithIcon";
import {getCountryName} from "../../actions/magicNumber.actions";



const FormItem = (props) => {
    const {title, contentIsText, children} = props;
    return (
        <div className="form-item-container">
            <div className="form-item-title">
                {title}
            </div>
            <div className={contentIsText ? "form-item-text" : "form-item-content"}>
                {children ? children : "-"}
            </div>
        </div>
    )
}
const DriverPersonalInfoForm = (props) => {
    //api
    const [updateDrivers,{isLoading}] = useUpdateDriversMutation()
    const {isFetching}= useGetDriversQuery()
    const [isEditing, setIsEditing] = useState(false);
    const [disableEdit, setDisableEdit] = useState(true)
    const [editedDriver, setEditedDriver]= useState(props.driver_data)

    const formLabels = props.labels?.driverPage?.driverInfo;


    const setLastName=(lastName)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.lastName=lastName
        setEditedDriver(editedDriverCopy)
    }
    const setFirstName=(firstName)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.firstName=firstName
        setEditedDriver(editedDriverCopy)
    }
    const setCompanyName=(companyName)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.companyName=companyName
        setEditedDriver(editedDriverCopy)
    }
    const setLanguage=(languageID)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.language){
            editedDriverCopy.childCustomer.language={}
        }
        editedDriverCopy.childCustomer.language.id= Number(languageID)
        setEditedDriver(editedDriverCopy)
    }

    const setEmail=(email)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.email=email
        setEditedDriver(editedDriverCopy)
    }

    const setNationality=(nationality)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.nationality=nationality
        setEditedDriver(editedDriverCopy)
    }

    const setAreaCode=(phone)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.phone){
            editedDriverCopy.childCustomer.phone={}
        }
        editedDriverCopy.childCustomer.phone.areaCode=phone
        setEditedDriver(editedDriverCopy)
    }

    const setCli=(phone)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.phone){
            editedDriverCopy.childCustomer.phone={}
        }
        editedDriverCopy.childCustomer.phone.cli=phone
        setEditedDriver(editedDriverCopy)
    }
    const setPhoneType=(phoneTypeId)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        // It is not ok with javascript object trying to add a new field to undefined.
        // therefore in case that we want to insert value into
        // editedDriverCopy.childCustomer.phone.phoneType.id
        // we first need to make sure the upper levels are initiated as empty object
        if (!editedDriverCopy.childCustomer.phone){
            editedDriverCopy.childCustomer.phone={}
        }
        if(!editedDriverCopy.childCustomer.phone.phoneType){
            editedDriverCopy.childCustomer.phone.phoneType={}
        }
        editedDriverCopy.childCustomer.phone.phoneType.id=phoneTypeId
        setEditedDriver(editedDriverCopy)
    }

    const setCountryCode=(countryCode)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.phone){
            editedDriverCopy.childCustomer.phone={}
        }
        editedDriverCopy.childCustomer.phone.countryCode=countryCode
        setEditedDriver(editedDriverCopy)
    }

    const setBirthday=(birthday)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy.childCustomer.birthday=birthday
        setEditedDriver(editedDriverCopy)
    }

    const setStreet =(street)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.mainAddress){
            editedDriverCopy.childCustomer.mainAddress={}
        }
        editedDriverCopy.childCustomer.mainAddress.street=street
        setEditedDriver(editedDriverCopy)
    }

    const setHouseNumber =(houseNumber)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.mainAddress){
            editedDriverCopy.childCustomer.mainAddress={}
        }
        editedDriverCopy.childCustomer.mainAddress.houseNumber=houseNumber
        setEditedDriver(editedDriverCopy)
    }

    const setCity =(city)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.mainAddress){
            editedDriverCopy.childCustomer.mainAddress={}
        }
        editedDriverCopy.childCustomer.mainAddress.city=city
        setEditedDriver(editedDriverCopy)
    }
    const setPostcode =(zip)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.mainAddress){
            editedDriverCopy.childCustomer.mainAddress={}
        }
        editedDriverCopy.childCustomer.mainAddress.zip=zip
        setEditedDriver(editedDriverCopy)
    }
    const setAddressCountry =(countryCode)=>{
        const editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        if (!editedDriverCopy.childCustomer.mainAddress){
            editedDriverCopy.childCustomer.mainAddress={}
        }
        if (!editedDriverCopy.childCustomer.mainAddress.country){
            editedDriverCopy.childCustomer.mainAddress.country={}
        }
        editedDriverCopy.childCustomer.mainAddress.country.id=countryCode
        setEditedDriver(editedDriverCopy)
    }

    const editTable = () => {
        setIsEditing(true);
        setDisableEdit(!disableEdit)
    }

    const handleCancel = (e) => {
        e.preventDefault();
        setEditedDriver(props.driver_data)
        // change button back to edit button only
        setIsEditing(false)
        setDisableEdit(!disableEdit)
    };
    const initializeAddress=(driverObject)=>{
        if (!driverObject.childCustomer.mainAddress){
            driverObject.childCustomer.mainAddress={}
        }
        if (!driverObject.childCustomer.mainAddress.zip){
            driverObject.childCustomer.mainAddress.zip="0000"
        }
        if (!driverObject.childCustomer.mainAddress.street){
            driverObject.childCustomer.mainAddress.zip="NA"
        }
        if (!driverObject.childCustomer.mainAddress.country){
            driverObject.childCustomer.mainAddress.country= {}
        }
        if (!driverObject.childCustomer.mainAddress.country.id){
            driverObject.childCustomer.mainAddress.country.id=200
        }
        return driverObject
    }
    const handleSubmit = (e) => {
        let editedDriverCopy = JSON.parse(JSON.stringify(editedDriver));
        editedDriverCopy=initializeAddress(editedDriverCopy)
        // phone id is also a must
        // if(!editedDriverCopy.childCustomer?.phone){
        //     editedDriverCopy.childCustomer.phone={}
        //     editedDriverCopy.childCustomer.phone.id=1
        // }
        // if(!editedDriverCopy.childCustomer?.phone.id){
        //     editedDriverCopy.childCustomer.phone.id=1
        // }
        if(props.hasPermission){
            e.preventDefault()
            updateDrivers(editedDriverCopy)
        }
        // change button back to edit button only
        setIsEditing(false)
        setDisableEdit(!disableEdit)
    }
    let content;

    const phoneTypes = [undefined,"Telefon (Festnetz)","Telefon (Mobil)","Telefon (Weitere)",undefined,undefined,"Fax" ]

    const replaceUndefined = (wordList) => {
        let symbols = [...wordList];
        for(let i = 0; i < wordList.length; i++){
            if(wordList[i] === undefined){
                symbols[i] = "-"
                while(i < wordList.length-1 && wordList[i+1] === undefined){
                    i++;
                    symbols[i] = undefined;
                }
            }
            else {
                symbols[i] = wordList[i];
            }
        }
        return symbols;

    }

    if (isLoading||isFetching){
        content = <div className="loading-spinner-container">
            <LoadingComponent show={true}></LoadingComponent>
        </div>
    }else{
        let addressCountryId= editedDriver.childCustomer.mainAddress?.country?.id
        let renderedCountryName=getCountryName(addressCountryId,props.labels)
        content = (
            <form className="driver-form" onReset={e => handleCancel(e)} onSubmit={e => handleSubmit(e)}>
                <div className="driver-form-inputs-container">
                    <div className="header">
                            <span className="header-text">
                                {props.section_name}
                            </span>


                            <span className="header-buttons" hidden={!props.hasPermission}>
                                {/*Cancel Icon */}
                                {disableEdit ?
                                    null
                                    :
                                    <span  onClick={e => handleCancel(e)}>
                                        <ButtonWithIcon buttonStyle={"btn-light"}
                                                        text={props.labels?.otherElements?.cancel}
                                                        size="sm">
                                            <Cancel className="cancel-icon"/>
                                        </ButtonWithIcon>
                                    </span>
                                }
                                {/*Edit Icon */}
                                {disableEdit ?
                                    (<span onClick={e => editTable(e)}>
                                        <ButtonWithIcon buttonStyle={"btn-primary"}
                                                    text={props.labels?.otherElements?.edit}
                                                    size="sm">

                                            <Edit className="edit-icon"/>
                                        </ButtonWithIcon>
                                    </span>)
                                    : null}
                                {/*Save Icon */}
                                {!disableEdit ?
                                    (<span onClick={e => handleSubmit(e)}>
                                        <ButtonWithIcon buttonStyle={"btn-primary"}
                                                    text={props.labels?.otherElements?.save}
                                                    size="sm">
                                            <Save className="save-icon"/>
                                        </ButtonWithIcon>
                                    </span>) : null}
                            </span>
                    </div>
                    <div>
                        <div className="body">
                            {/*Family Name*/}
                            <FormItem title={formLabels?.familyName} contentIsText={!isEditing}>
                                {isEditing ?  <input className="input-field-primary"
                                                     value={editedDriver.childCustomer.lastName}
                                                     onChange={e => setLastName(e.target.value)}
                                                     disabled={true}/>
                                    :
                                    editedDriver.childCustomer.lastName
                                }
                            </FormItem>

                            {/*First Name*/}
                            <FormItem title={formLabels?.firstName} contentIsText={!isEditing}>
                                {isEditing ? <input className="input-field-primary"
                                                    value={editedDriver.childCustomer.firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    disabled={true}/>
                                    :
                                    editedDriver.childCustomer.firstName
                                }
                            </FormItem>

                            {/*Company Name*/}
                            <FormItem title={formLabels?.companyName} contentIsText={!isEditing}>
                                {isEditing ?  <input className="input-field-primary"
                                                     value={editedDriver.childCustomer.companyName}
                                                     onChange={e => setCompanyName(e.target.value)}
                                                     disabled={true}
                                                     placeholder={disableEdit ? "" : "Musterfirma AG"}
                                    />
                                    :
                                    editedDriver.childCustomer.companyName
                                }
                            </FormItem>

                            {/*Birthday*/}
                            <FormItem title={formLabels?.birthday} contentIsText={!isEditing}>
                                {isEditing ?  <input className="input-field-primary" disabled={true}
                                                     onChange={e => setBirthday(Moment(e.target.value).format("YYYY-MM-DD"))}
                                                     value={editedDriver.childCustomer.birthday ? Moment(editedDriver.childCustomer.birthday).format('YYYY-MM-DD') : undefined}
                                                     type="date"/>
                                    :
                                    editedDriver.childCustomer.birthday ? Moment(editedDriver.childCustomer.birthday).format('DD.MM.YYYY') : undefined
                                }
                            </FormItem>

                            {/*Language*/}
                            <FormItem title={formLabels?.Language} contentIsText={!isEditing}>
                                {isEditing ?  <select className="input-field-primary" value={editedDriver.childCustomer.language?.id} disabled={disableEdit}
                                                      name="Sprache" id="language" onChange={e => setLanguage(e.target.value)}>
                                                    <option value={1}>Deutsch</option>
                                                    <option value={2}>English</option>
                                                    <option value={3}>Français</option>
                                                    <option value={4}>Italiano</option>
                                                </select>
                                    : editedDriver.childCustomer.language?.id === 1 ? "Deutsch" : editedDriver.childCustomer.language?.id === 2 ? "English" : editedDriver.childCustomer.language?.id === 3 ? "Français" : editedDriver.childCustomer.language?.id === 4 ? "Italiano" : undefined
                                }
                            </FormItem>

                            {/*Nationality*/}
                            <FormItem title={formLabels?.nationality} contentIsText={!isEditing}>
                                {isEditing ?  <input className="input-field-primary"
                                                     value={editedDriver.childCustomer.nationality}
                                                     onChange={e => setNationality(e.target.value)}
                                                     disabled={true}
                                                     placeholder={disableEdit ? "" : "Musterland"}/>
                                    :
                                    editedDriver.childCustomer.nationality
                                }

                            </FormItem>

                            {/*E-Mail*/}
                            <FormItem title={formLabels?.email} contentIsText={!isEditing}>
                                {isEditing ? <input className="input-field-primary"
                                                   value={editedDriver.childCustomer.email}
                                                   onChange={e => setEmail(e.target.value)}
                                                   disabled={disableEdit}
                                                   placeholder={disableEdit ? "" : "mustermann@upto.ch"}/>
                                    :
                                    editedDriver.childCustomer.email
                                }
                            </FormItem>

                            {/*Phone*/}
                            <FormItem title={formLabels?.phone} contentIsText={!isEditing}>
                                {isEditing ? (
                                        <div className="address-inputs-container">
                                            <input className="input-field-primary" value={editedDriver.childCustomer?.phone?.countryCode} required={true} disabled={disableEdit}
                                                   onChange={e => setCountryCode(e.target.value)}
                                                   placeholder={disableEdit ? "" : "+41"} size="3"/>
                                            <input className="input-field-primary" value={editedDriver.childCustomer?.phone?.areaCode} required={true} disabled={disableEdit}
                                                   type="areaCode"
                                                   onChange={e => setAreaCode(e.target.value)}
                                                   placeholder={disableEdit ? "" : "00"} size="2"/>
                                            <input className="input-field-primary" value={editedDriver.childCustomer?.phone?.cli} required={true} disabled={disableEdit}
                                                   type="cli" size="7"
                                                   onChange={e => setCli(e.target.value)}
                                                   placeholder={disableEdit ? "" : "0000000"}/>
                                            <select className="input-field-primary" value={editedDriver.childCustomer?.phone?.phoneType?.id} required={true} disabled={disableEdit}
                                                    onChange={e => setPhoneType(e.target.value)} style={{width:"100%",paddingRight:"8px"}}>
                                                <option value=" " disabled={!disableEdit}> </option>
                                                <option value="1">Telefon (Festnetz)</option>
                                                <option value="2">Telefon (Mobil)</option>
                                                <option value="3">Telefon (Weitere)</option>
                                                <option value="6">Fax</option>
                                            </select>
                                        </div>
                                    )
                                    :
                                    replaceUndefined([editedDriver.childCustomer?.phone?.countryCode,editedDriver.childCustomer?.phone?.areaCode, editedDriver.childCustomer?.phone?.cli, phoneTypes[editedDriver.childCustomer?.phone?.phoneType?.id]]).map((value) => {
                                        if(value){
                                            return value + " "
                                        }
                                        return ""
                                    })
                                }
                            </FormItem>

                            {/*Address*/}
                            <FormItem title={formLabels?.address} contentIsText={!isEditing}>
                                {isEditing ? (
                                        <div className="address-inputs-container" >
                                            <input className="input-field-primary" size="20" type="text" disabled={disableEdit}
                                                   value={editedDriver.childCustomer.mainAddress?.street}
                                                   onChange={e => setStreet(e.target.value)}
                                                   placeholder={disableEdit ? "" : "Musterstrasse"}/>
                                            <input className="input-field-primary" size="5" type="text" disabled={disableEdit}
                                                   value={editedDriver.childCustomer.mainAddress?.houseNumber}
                                                   onChange={e => setHouseNumber(e.target.value)}
                                                   placeholder={disableEdit ? "" : "99"}/>
                                        </div>
                                    )
                                    :
                                    replaceUndefined([editedDriver.childCustomer.mainAddress?.street,editedDriver.childCustomer.mainAddress?.houseNumber]).map((value) => {
                                        if(value){
                                            return value + " "
                                        }
                                        return ""
                                    })
                                }
                            </FormItem>

                            <span className="city-zip-container">
                                    <FormItem title={formLabels?.city} contentIsText={!isEditing}>
                                        {isEditing ? <input className="input-field-primary" size="15" type="text" disabled={disableEdit}
                                                            value={editedDriver.childCustomer.mainAddress?.city}
                                                            onChange={e => setCity(e.target.value)}
                                                            placeholder={disableEdit ? "" : "Musterstadt"}/>
                                            :
                                            editedDriver.childCustomer.mainAddress?.city
                                        }
                                    </FormItem>
                                    <FormItem title={formLabels?.zip} contentIsText={!isEditing}>
                                        {isEditing ? <input className="input-field-primary" size="5" type="text" disabled={disableEdit}
                                                            value={editedDriver.childCustomer.mainAddress?.zip}
                                                            placeholder={disableEdit ? "" : "0000"}
                                                            onChange={e => setPostcode(e.target.value)}/>
                                            :
                                            editedDriver.childCustomer.mainAddress?.zip
                                        }
                                    </FormItem>
                                    <FormItem title={formLabels?.country} contentIsText={!isEditing}>
                                        {isEditing ?  <select className="input-field-primary" value={editedDriver.childCustomer.mainAddress?.country?.id} disabled={disableEdit}
                                                              name="Country" id="addressCountry" onChange={e => setAddressCountry(e.target.value)}>
                                                <option value="200">{props.labels.otherElements?.countries.switzerland}</option>
                                                <option value="1">{props.labels.otherElements?.countries.germany}</option>
                                                <option value="68">{props.labels.otherElements?.countries.france}</option>
                                                <option value="100">{props.labels.otherElements?.countries.italy}</option>
                                            </select>
                                            : renderedCountryName
                                        }
                                    </FormItem>
                                </span>
                        </div>
                    </div>
                </div>
            </form>

        )
    }
    return (
        <>
            <div className="section-card">
                <div className="info-container" >
                    {content}
                </div>
            </div>
        </>
    )
}

export default DriverPersonalInfoForm
// import external
import React from "react";
import {useSelector} from "react-redux";

// import internal
import DamageOverview from "../../components/DamageOverview";

// internal styling
import "./_style.scss";

const MyDamagesPage = () => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <DamageOverview labels={currentLabels} title={currentLabels?.damagesPage?.titles?.damagesOverview}></DamageOverview>
            </div>
        </div>
    )
}

export default MyDamagesPage
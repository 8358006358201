import {createCarSchema, createDriverSchema, createFuelCardSchema} from "../Form/FormSchemas";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import FormModal from "../FormModal";
import React, {useContext, useEffect, useMemo, useState} from "react";

// import styling
import "./_style.scss"

// import icons
import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as DriverIcon} from "../../assets/svg/icon-person.svg";
import {ReactComponent as CardIcon} from "../../assets/svg/icon-card.svg";
import {ReactComponent as SuccessIcon} from "../../assets/svg/icon-check-circle.svg";
import {ReactComponent as ErrorIcon} from "../../assets/svg/icon-warning.svg";
import {ReactComponent as PlusIcon} from "../../assets/svg/icon-plus.svg";
import {ReactComponent as CloseIcon} from "../../assets/svg/icon-close.svg";

import FeatureIcon from "../FeatureIcon";
import {
    useAddDriverToCarMutation, useCreateDriverMutation,
    useGetDriversQuery,
    useGetOrganisationsQuery
} from "../../redux/apiSlice/driverApiSlice";
import {useCreateFuelCardMutation, useGetFuelCardsQuery} from "../../redux/apiSlice/fuelCardApiSlice";

import {StatusMessage, StatusMessageContent, StatusMessageIcon, StatusMessageTitle} from "../StatusMessage";
import {useCreateCarMutation, useGetCarsV2Query} from "../../redux/apiSlice/carApiSlice";
import {useGetUserQuery} from "../../redux/apiSlice/userApiSlice";
import {useSelector} from "react-redux";
import {getLanguageName} from "../../actions/magicNumber.actions";
import {useUploadDocumentMutation} from "../../redux/apiSlice/documentsApiSlice";
import {useVariableValue} from "@devcycle/react-client-sdk";
import {FormControlButtons} from "../Form/FormControlButtons";
import {FleetWizardContext} from "./FleetWizardContext";
import FuelCardTab from "./Tabs/FuelCardTab";
import DriverTab, {getCurrentDriver} from "./Tabs/DriverTab";
import VehicleTab from "./Tabs/VehicleTab";

const FleetWizard = ({initialTab, labels, ...props}) => {
    const { state, dispatch } = useContext(FleetWizardContext);

    const { selectedObjects } = state;

    const { selectedOrganisation, selectedDriver, selectedFuelCard, selectedCar } = selectedObjects;

    // Visibility of modal (FleetWizard)
    const [showModal, setShowModal] = useState(false);

    // Loading state (buttons etc.), necessary because there can be multiple api calls happening at the same time
    const [isLoading, setIsLoading] = useState(false);

    // File state for vehicle registration file
    const [file, setFile] = useState(null);

    // API Calls
    const {
        data: carData, isFetching: isCarsFetching
    } = useGetCarsV2Query();

    const cars = useMemo(() => {
        return carData?.cars || [];
    }, [carData])


    const {
        data: drivers, isFetching: isDriversFetching
    } = useGetDriversQuery();

    const {
        data: fuelCards,
    } = useGetFuelCardsQuery();

    const {
        data: user,
    } = useGetUserQuery();

    const {
        data: organisations
    } = useGetOrganisationsQuery();

    const [createCar, {
        isError: carIsError, isSuccess: carIsSuccess, reset: resetCar
    }] = useCreateCarMutation();

    // For adding existing driver to a car
    const [addDriver, {
        isError: driverIsError, isSuccess: driverIsSuccess, reset: resetDriver
    }] = useAddDriverToCarMutation();

    // For disconnecting a previous driver
    const [updateDriver, {
        isError: disconnectLastDriverIsError, reset: resetDriverUpdate
    }] = useAddDriverToCarMutation();

    // For creating a new driver
    const [createDriver, {
        isError: driverCreationIsError, reset: resetDriverCreation
    }] = useCreateDriverMutation();

    const [uploadDocument, {
        data: documentData,
        isError: documentIsError, isSuccess: documentIsSuccess, reset: resetDocument
    }] = useUploadDocumentMutation();

    const [createFuelCard, {
        isError: fuelCardIsError, isSuccess: fuelCardIsSuccess, reset: resetFuelCard
    }] = useCreateFuelCardMutation();


    // Get isAgent and User Roles, check if FleetWizard should be visible
    const isAgent = useSelector(state => state.auth.isAgentLogin)

    const hasCarManagementRole = user?.roles?.find((role) => role.id === 184) !== undefined;
    // Yue: the repair role is needed for uploading documents. I tested, 184 does not help
    const hasRepairRole = user?.roles?.find((role) => role.id === 177) !== undefined;

    // Tab Names
    const tabs = {
        0: labels?.fleetWizard?.tabs?.vehicle,
        1: labels?.fleetWizard?.tabs?.driver,
        2: labels?.fleetWizard?.tabs?.fuelCard
    }

    // Get the starting tab (if you open the fleet wizard on /drivers, we'd want the driver tab to be open initially)
    const getInitialTabIndex = () => {
        if (!initialTab) return 0

        switch (initialTab.toLowerCase()) {
            case "vehicle":
                return 0
            case "driver":
                return 1
            case "fuelcard":
                return 2
            default:
                return 0
        }
    }

    // State for the current tab
    const [tab, setTab] = useState(getInitialTabIndex);

    // Function to read a file as base64, used when vehicle Registration file is uploaded
    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]); // Remove the data URL prefix
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }


    // Form Submit method/logic for the vehicle form (vehicle tab)
    const onSubmitVehicleForm = (data) => {
        let baseData = {...data};
        delete baseData.licencePlateStartDate;
        delete baseData.organisation;
        delete baseData.mileage;
        delete baseData.mileageDate;
        delete baseData.fuelType;
        delete baseData.registrationDate;
        delete baseData.registrationCountry;
        delete baseData.vehicleType;
        delete baseData.vehicleRegistrationFile;

        const body = {
            baseData: {
                ...baseData,
                status: "Aktiv",
            },
            fleetInformation: {
                customerId: data.organisation,
            },
            additionalCarInformation: {
                baseData: {
                    ...baseData,
                    registrationCountry: data.registrationCountry,
                    vehicleType: data.vehicleType,
                    registeredOn: "Kunde",
                },
                licensePlates: [
                    {
                        licencePlate: data.licencePlate, fromDate: data.licencePlateStartDate
                    }
                ]
            },
        }

        if (data?.fuelType !== null) {
            body.additionalCarInformation.fuelData = {fuelType: data.fuelType}
        }

        if (data?.registrationDate) {
            body.additionalCarInformation.baseData.registrationDate = data.registrationDate;
        }

        if (data?.mileageDate !== null && data?.mileage !== null) {
            // Remove milliseconds from date
            const formattedDate = new Date(data.mileageDate).toISOString().split(".")[0] + "Z";

            body.additionalCarInformation.telematicData = [{
                date: formattedDate, odometerValue: data.mileage
            }]
        }
        // Yue: I was confused why the setIsLoading works here, as useState is async and if we log isLoading afterwareds, isLoading
        // is not changed. The reason why it works here, is that, the api call is also async, and the setIsLoading will finish
        // first before the api call
        setIsLoading(true)
        createCar(body).then(async (result) => {
            if (result?.data?.carId) {
                createDriverForm.setValue("carId", result?.data?.carId);
                createDriverForm.trigger(["carId"])
                createFuelCardForm.setValue("carId", result?.data?.carId);
                createFuelCardForm.trigger(["carId"])
                if (data.vehicleRegistrationFile && file) {
                    const fileDataString = await readFileAsBase64(file);
                    const fileEnding = data.vehicleRegistrationFile.name.split('.')?.pop();
                    const body = {
                        carId: result?.data?.carId,
                        customerId: data.organisation,
                        document: {
                                label: labels?.fleetWizard?.form?.vehicleRegistrationLabel || "Fahrzeugausweis",
                                documentName: `${labels?.fleetWizard?.form?.vehicleRegistrationLabel || "Fahrzeugausweis"}_${data?.licencePlate || result?.data?.carId}.${fileEnding || 'pdf'}`,
                                notes: "Upload on vehicle creation",
                                mimeType: data.vehicleRegistrationFile?.type,
                                visibleInPortal: "true",
                                documentType: 506,
                                file: fileDataString // Wrap the file data in an array as required
                        }
                    }
                    uploadDocument(body).then(() => setIsLoading(false))
                }
            }
            setIsLoading(false)
        })

    }





    // Form Submit method/logic for the driver form (driver tab)
    const onSubmitDriverForm = (data) => {

        // Prepare request data for assigning driver to car
        const requestData = {
            organisationCustomerId: selectedDriver?.parentCustomer,
            carId: data.carId,
            body: {
                startDt: data.startDt,
                driver: selectedDriver?.childCustomer?.id,
                car: data.carId
            }
        }


        // If user didn't select an existing driver, create a new driver
        if (!data.existingDriver) {
            const body = {...data};
            delete body.existingDriver;
            delete body.carId;

            delete body.endPreviousConnectionDate;
            delete body.endPreviousConnection;

            if (data?.childCustomer?.phone?.phoneType?.id === null) {
                delete body.childCustomer.phone;
            }

            body.organisationId = data?.parentCustomer;
            body.roleId = 506;
            body.childCustomer.customerType = {
                id: 2,
                description: "Geschäftskunde"
            }

            //body.childCustomer.status.description = "Aktiv";
            body.childCustomer.mainAddress.country = {id: 200, description: "Schweiz"};
            body.childCustomer.language.description = getLanguageName(Number(data?.childCustomer?.language?.id), labels)

            body.childCustomer.mainAddress = {
                ...body.childCustomer.mainAddress,
                firstName: body.childCustomer.firstName,
                lastName: body.childCustomer.lastName,
            }


            body.childCustomer.companyName = selectedOrganisation?.companyName;
            setIsLoading(true)
            // Send API call to create driver, if successful, modify request data and add the driver to the car
            createDriver(body).then((result) => {
                // If driver creation was successful, set the driver in the request data and add driver to car
                if (result?.data) {
                    const newDriver = result?.data;           
                    requestData.body.driver = newDriver?.childCustomer?.id;
                    requestData.organisationCustomerId = newDriver?.parentCustomer;
                    addDriver(requestData).then(() => setIsLoading(false))
                }
                else {
                    setIsLoading(false)
                }
            })
            // If user is just assigning existing driver, add driver to car
        } else {
            setIsLoading(true)
            addDriver(requestData).then(async () => {
                // User also wants to terminate previous connection
                if (data?.endPreviousConnection) {
                    const currentDriver = getCurrentDriver(selectedCar?.additionalCarInformation?.drivers || [])

                    const updateData = {
                        body: {
                            id: currentDriver?.id,
                            endDt: data?.endPreviousConnectionDate,
                            startDt: currentDriver?.startDt,
                            driver: currentDriver?.driver,
                            car: data?.carId,
                        },
                        // For API Call only
                        carId: data?.carId,
                        organisationCustomerId: selectedDriver?.parentCustomer,
                    }
                    await updateDriver(updateData);
                }

                setIsLoading(false)
            })
        }
    }


    // Placeholder, will hold fuelcard logic
    const onSubmitFuelCardForm = (data) => {
        const requestData = { ...data}
        delete requestData?.startDate;
        delete requestData?.endDate;
        delete requestData?.carId;
        delete requestData.existingFuelCard;
        delete requestData.companyData

        requestData.customers = []


        // Only send fuel card id if we are assigning existing
        if (!data.existingFuelCard) {
            delete requestData.id
            requestData.cars = [
                {
                    id: data.carId,
                    startDate: data.startDate,
                    endDate: data.endDate,
                }
            ]
        } else {
            // If we are assigning existing fuel card to a car, the flow is different and startDate/endDate are used for diff fields
            let updatedCars = [...selectedFuelCard.cars];

            // Selected (and mandatory) 'startDate' is used to end previous connection
            updatedCars[0] = {
                ...updatedCars[0],
                endDate: data?.startDate
            }

            // Add new connection with non-mandatory 'endDate' field as startDate
            requestData.cars = updatedCars.concat({
                id: data.carId,
                startDate: data.endDate,
            })

            requestData.provider = selectedFuelCard.provider;

            requestData.customers = selectedFuelCard.customers;
        }

        requestData.customers = [
            {
                ...data.companyData,
                startDate: requestData.cars[0].startDate,
            },
            ...requestData.customers,
        ]

        setIsLoading(true);
        createFuelCard([requestData]).then(() => {
            setIsLoading(false);
        })

    }


    // Get labels for countries to display in select registrationCountry
    const countries = useMemo(() => {
        // Init countries, needed because the label loading is weird, without this the countries const below would crash
        const countryList = labels?.fleetWizard?.form?.countries || {};

        return  Object.keys(countryList)?.map((country) => (
            {
                value: country,
                label: labels?.fleetWizard?.form?.countries[country]
            }
        ))
    }, [labels])

    const languages = useMemo(() => {
        return [
            {id: 1, description: labels?.otherElements?.languages?.german},
            {id: 2, description: labels?.otherElements?.languages?.english},
            {id: 3, description: labels?.otherElements?.languages?.french},
            {id: 4, description: labels?.otherElements?.languages?.italian},
        ]
    }, [labels])

    const salutations = useMemo(() => {
        return [
            {id: 0, description: "-"},
            {id: 1, description: labels?.driverPage?.driverInfo?.salutationMale},
            {id: 2, description: labels?.driverPage?.driverInfo?.salutationFemale},
        ]
    }, [labels])


    // Get the labels for the fuel types and vehicle types, used in the select fields
    const fuelTypes = useMemo(() => {
        if (!labels) return null
        return Object.keys(labels?.fleetWizard?.form?.fuelTypes)?.map((fuelType) => ({
            value: fuelType,
            label: labels?.fleetWizard?.form?.fuelTypes[fuelType]
        }))
    }, [labels])


    // Get the labels for the vehicle types, used in the select fields
    const vehicleTypes = useMemo(() => {
        if (!labels) return null
        return Object.keys(labels?.fleetWizard?.form?.vehicleTypes)?.map((vehicleType) => ({
            value: vehicleType,
            label: labels?.fleetWizard?.form?.vehicleTypes[vehicleType]
        }))
    }, [labels])



    // Form logic for the vehicle form (resolver and defaultValues)
    const createCarForm = useForm({
        resolver: zodResolver(createCarSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Vehicle Info
            organisation: "",
            registrationCountry: "Schweiz",
            vin: "",
            licencePlate: "",
            licencePlateStartDate: null,
            brand: "",
            type: "",
            model: "",
            vehicleType: "",
            mileage: "",
            mileageDate: null,
            fuelType: "",
            registrationDate: null,
            vehicleRegistrationFile: "",
            serialNumber: "",
            typeCertificationNumber: "",
        },
    });

    // Form logic for the driver form (resolver and defaultValues)
    const createDriverForm = useForm({
        resolver: zodResolver(createDriverSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Driver Info
            carId: "", // Sent to API to add to car
            existingDriver: false, // Not sent in API
            endPreviousConnection: false,
            endPreviousConnectionDate: null,
            // Below is what we send to API to create a new driver
            parentCustomer: "",
            startDt: null,
            childCustomer: {
                firstName: "",
                lastName: "",
                email: "",
                birthday: null,
                status: {
                    id: 1
                },
                salutation: {
                    id: null
                },
                phone: {
                    countryCode: "",
                    cli: "",
                    areaCode: "",
                    phone: "",
                    phoneType: {
                        id: null
                    }
                },
                mainAddress: {
                    street: "",
                    zip: "",
                    city: "",
                    houseNumber: "",
                    //country: {id: "", description: ""}
                },
                language: {
                    id: 1,
                }
            }
        }
    })

    // Form logic for the fuel card form (resolver and defaultValues)
    const createFuelCardForm = useForm({
        resolver: zodResolver(createFuelCardSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Fuel card id
            id: null,
            // Car to assign the fuel card to
            carId: "",
            startDate: null,
            endDate: null,
            // True if user is assigning existing fuel card, false if creating a new one
            existingFuelCard: false,
            // Fuel card data
            cardNumber: "",
            cardName: "",
            provider: {
                id: null,
                companyName: "",
                customerId: null,
            },
            expirationDate: null,
            pin: null,
            note: null,
            companyData: {
                id: null,
                customerId: null
            }
        }
    })

    useEffect(() => {
        if (fuelCards) {
            dispatch({ type: 'UPDATE_FUEL_CARDS', payload: fuelCards });
        }
        if (cars) {
            dispatch({ type: 'UPDATE_CARS', payload: cars });
        }
        if (drivers) {
            dispatch({ type: 'UPDATE_DRIVERS', payload: drivers });
        }
        if (user) {
            dispatch({ type: 'UPDATE_USER', payload: user });
        }
        if (organisations) {
            dispatch({ type: 'UPDATE_ORGANISATIONS', payload: organisations });
        }
        if (languages) {
            dispatch({ type: 'UPDATE_LANGUAGES', payload: languages });
        }
        if (salutations) {
            dispatch({ type: 'UPDATE_SALUTATIONS', payload: salutations });
        }
        if (vehicleTypes) {
            dispatch({ type: 'UPDATE_COUNTRIES', payload: countries });
        }
        if (vehicleTypes) {
            dispatch({ type: 'UPDATE_VEHICLE_TYPES', payload: vehicleTypes });
        }
        if (fuelTypes) {
            dispatch({ type: 'UPDATE_FUEL_TYPES', payload: fuelTypes });
        }
        dispatch({ type: 'UPDATE_PHONE_TYPES', payload: [
                {value: 1, label: "Telefon (Festnetz)"},
                {value: 2, label: "Telefon (Mobil)"},
                {value: 3, label: "Telefon (weitere)"},
                {value: 6, label: "Fax"},
            ] });

        if (process.env.REACT_APP_NODE_ENV !== 'development') {
            dispatch({ type: 'UPDATE_ENERGY_PROVIDERS', payload: [
                    {value: 7300006801, label: 'Migrol AG', id: 2001156444},
                    {value: 7300006015, label: 'DKV', id: 2000910983},
                    {value: 7300006982, label: 'Heider AG', id: 2001182533},
                    {value: 7300007001, label: 'AVIA Osterwalder AG', id: 2001182503},
                    {value: 7300007384, label: 'Coop Mineraloel AG', id: 2001299008},
                    {value: 7300009504, label: 'Avia Schätzle AG', id: 2002038226},
                    {value: 7300008444, label: 'Shell Switzerland AG', id: 2001533377},
                    {value: 7300008461, label: 'Moveri AG', id: 2001537178},
                    {value: 7300008543, label: 'Jubin Frères SA', id: 2001548357},
                    {value: 7300008604, label: 'BP Schweiz', id: 2001577479},
                    {value: 7300008201, label: 'SOCAR Card Center', id: 2001524164},
                    {value: 7300009022, label: 'Ernst Hürlimann AG', id: 2001788529},
                ] });
        } else {
            dispatch({ type: 'UPDATE_ENERGY_PROVIDERS', payload: [
                    {value: 81002420, label: 'Migrol', id: 1800117640},
                    {value: 81003080, label: 'MIGROL', id: 1800146305},
                ] });
        }

    }, [dispatch, fuelCards, user, organisations, cars, drivers, languages, salutations, vehicleTypes, fuelTypes, countries]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_SELECTED_CAR', payload: selectedCar });
        dispatch({ type: 'UPDATE_SELECTED_DRIVER', payload: selectedDriver });
        dispatch({ type: 'UPDATE_SELECTED_FUEL_CARD', payload: selectedFuelCard });
        dispatch({ type: 'UPDATE_SELECTED_ORGANISATION', payload: selectedOrganisation });

    }, [dispatch, selectedDriver,selectedOrganisation, selectedCar, selectedFuelCard])

    // Reset the modal, reset the form and states (called when "Cancel" is clicked)
    function resetModal() {
        resetCar();
        resetDriver();
        resetDocument();
        resetDriverCreation();
        resetDriverUpdate();
        resetFuelCard();
        createCarForm.reset() // Reset fields
        createDriverForm.reset() // Reset fields
        createFuelCardForm.reset() // Reset fields
        setFile(null) // Reset file (if any was uploaded)

        dispatch({
            type: 'UPDATE_SELECTED_DRIVER',
            payload: null,
        });
        dispatch({
            type: 'UPDATE_SELECTED_FUEL_CARD',
            payload: null,
        });
        dispatch({
            type: 'UPDATE_SELECTED_CAR',
            payload: null,
        });
        dispatch({
            type: 'UPDATE_SELECTED_ORGANISATION',
            payload: null,
        });

    }


    // I initially wanted to make it more dynamic so we only had to place this component once but because the width would be weird I had to place it in each
    // part/tab of the FleetWizard, which technically means we could improve the logic a bit (e.g. make it possible to have error messages on 2 tabs at same time etc)
    const ErrorMessage = () => {
        // Find where error happened (vehicle creation, driver creation, fuelcard creation)
        let errorTab;
        // If car creation failed, set errorTab to 0
        if (carIsError) errorTab = 0;
        // If driver creation failed, set errorTab to 1
        if (driverIsError || driverCreationIsError || disconnectLastDriverIsError) errorTab = 1;

        if (fuelCardIsError) errorTab = 2;

        // Means there was no error, don't show error message
        if (!errorTab && errorTab !== 0) return null;

        // For the error message, we have 3 different messages, depending on which tab the error happened
        const errorMessageLabels = ["vehicleNotAdded", "driverNotAdded", "fuelCardNotAdded"]

        let message = labels?.fleetWizard?.messages?.error[errorMessageLabels[errorTab]];

        // If error was caused by driver creation, show driver creation error message
        if (driverCreationIsError) {
            message = labels?.fleetWizard?.messages?.error?.driverNotCreated;
        }

        if (disconnectLastDriverIsError) {
            message = labels?.fleetWizard?.messages?.error?.lastDriverNotDisconnected;
        }


        // Scroll to top of modal when error message is shown (id is modal-tab-0, modal-tab-1, modal-tab-2)
        if (errorTab !== undefined) {
            const modalTab = document.getElementById(`modal-tab-${errorTab}`);
            if (modalTab) modalTab.scrollTop = 0;
        }

        return (<StatusMessage variant="warning" style={{marginBottom: "0.5em"}} hidden={tab !== errorTab}>
                <StatusMessageIcon>
                    <ErrorIcon/>
                </StatusMessageIcon>
                <StatusMessageTitle>
                    {labels?.fleetWizard?.messages?.error?.title}
                </StatusMessageTitle>
                <StatusMessageContent>
                    {message}
                </StatusMessageContent>
            </StatusMessage>)
    }

    // Success message for the FleetWizard, shows when a car or driver has been successfully added
    const SuccessMessage = () => {
        let successTab, showAdditionalButton;
        if (carIsSuccess) {
            successTab = 0;
            showAdditionalButton = true;
        }
        if (driverIsSuccess) {
            successTab = 1;
            showAdditionalButton = true;
        }
        if (fuelCardIsSuccess) {
            successTab = 2;
            showAdditionalButton = false;
        }

        // If no RTK mutation has isSuccess, don't show anything
        if (!successTab && successTab !== 0) return null;

        const successMessageLabels = ["vehicleAdded", "driverAdded", "fuelCardAdded"]

        const AdditionalButton = () => {
            if (!showAdditionalButton) return null;
            let label;
            if (successTab === 0) label = labels?.fleetWizard?.titles?.addDriver;
            if (successTab === 1) label = labels?.fleetWizard?.titles?.addFuelCard;

            return (
                <button
                    className="btn btn-primary sm"
                    style={{width: "100%"}}
                    onClick={() => {
                        if (successTab === 0) {
                            const newCarId = createDriverForm.getValues("carId");
                            const newCar = cars.find(car => car?.additionalCarInformation?.baseData?.carId === newCarId);
                            dispatch({
                                type: 'UPDATE_SELECTED_CAR',
                                payload: newCar,
                            });
                            createCarForm.reset();
                            setTab(1)
                            resetCar();
                            resetDocument();
                        }
                        else if (successTab === 1) {
                            createDriverForm.reset();
                            setTab(2)
                            resetDriver();
                            resetDriverCreation();
                        }

                    }}
                >
                    <PlusIcon style={{marginRight: "4px", stroke: "white"}}/>
                    {label}
                </button>
            )
        }


        // Below the Success Message, we would also show a Warning Message, if a file (vehicleRegistration) was uploaded but the API call failed
        return (
            <>
                <StatusMessage variant="success" style={{marginBottom: "1em", marginTop: "1em"}}>
                    <StatusMessageIcon>
                        <SuccessIcon/>
                    </StatusMessageIcon>
                    <StatusMessageTitle>
                        {labels?.fleetWizard?.messages?.success?.title}
                    </StatusMessageTitle>
                    <StatusMessageContent>
                        {labels?.fleetWizard?.messages?.success[successMessageLabels[successTab]]}
                    </StatusMessageContent>
                </StatusMessage>

                {
                    // If the document upload failed (but the car was created successfully), show a warning message (note: documentId 0 also means failed)
                    documentIsError || (documentIsSuccess && !documentData.documentId) ? (
                        <StatusMessage variant="warning" style={{marginBottom: "1em"}}>
                            <StatusMessageIcon>
                                <ErrorIcon/>
                            </StatusMessageIcon>
                            <StatusMessageTitle>
                                {labels?.fleetWizard?.messages?.error?.title}
                            </StatusMessageTitle>
                            <StatusMessageContent>
                                {labels?.fleetWizard?.messages?.error?.documentNotUploaded}
                            </StatusMessageContent>
                        </StatusMessage>
                    ) : null
                }

                <div className="footer-buttons">
                    <button
                        className={showAdditionalButton ? "btn btn-light sm" : "btn btn-primary sm"}
                        style={{width: "100%"}}
                        onClick={() => {
                            resetModal();
                            setShowModal(false);
                        }}
                    >
                        <CloseIcon style={{marginRight: "4px", stroke: showAdditionalButton ? "gray" : "white"}}/>
                        {labels?.otherElements?.close}
                    </button>

                    <AdditionalButton/>
                </div>

            </>

        )
    }

    useEffect(() => {
        // If there is only one organisation, set it as default value for the organisation select field
        if (organisations && organisations.length === 1 && !selectedOrganisation) {

            createCarForm.setValue("organisation", organisations[0].id);
            createDriverForm.setValue("parentCustomer", organisations[0].id);
            createFuelCardForm.setValue("companyData", organisations[0] || {
                id: null,
                customerId: null,
            });
            if (!selectedOrganisation) {
                dispatch({
                    type: 'UPDATE_SELECTED_ORGANISATION',
                    payload: organisations[0]
                });
            }
        }

        // If there are no organisations (which shouldn't happen), set the user's customer as default value for the organisation select field
        if (organisations && organisations.length === 0 && !selectedOrganisation){
            createCarForm.setValue("organisation", user?.customer);
            createDriverForm.setValue("parentCustomer", user?.customer);
            createFuelCardForm.setValue("companyData", user?.customer || {
                id: null,
                customerId: null,
            });
            if (!selectedOrganisation) dispatch({
                type: 'UPDATE_SELECTED_ORGANISATION',
                payload: {
                    id: user?.customer,
                    companyName: "-"
                },
            });

        }
    }, [createCarForm, createDriverForm, createFuelCardForm, dispatch, organisations, selectedOrganisation, user?.customer])

    const isLoadingAnything = isLoading || isCarsFetching || isDriversFetching;
    const isLoadingAnythingCarTab = isLoading;

    // Keeps track of whether any part of the form is successful, used for showing success message
    const isSuccess = carIsSuccess || driverIsSuccess || fuelCardIsSuccess;

    // Checks for user flag controlling whether user can upload vehicle registration file
    const hasVehicleRegistrationUploadFlag =  useVariableValue("fleet-wizard-upload", false)


    // If user is not an agent or doesn't have car management role, don't show the FleetWizard
    const hasFleetWizardFeatureFlag =  useVariableValue("fleet-wizard", false)



    if (!labels || !((isAgent || hasFleetWizardFeatureFlag) && hasCarManagementRole)) return null

    // Get Modal title from current tab
    const getModalTitle = () => {
        switch(tab) {
            case 0:
                return labels?.fleetWizard?.titles?.addVehicle;
            case 1:
                return labels?.fleetWizard?.titles?.addDriver;
            case 2:
                return labels?.fleetWizard?.titles?.addFuelCard;
            default:
                return labels?.fleetWizard?.titles?.addVehicle;
        }
    }

    // Get Modal icon from current tab
    const getModalIcon = () => {
        switch(tab) {
            case 0:
                return <CarIcon style={{fill: "white", stroke: "black"}}/>
            case 1:
                return <DriverIcon style={{fill: "white", stroke: "black"}}/>
            case 2:
                return <CardIcon style={{fill: "white", stroke: "black"}}/>
            default:
                return <CarIcon style={{fill: "white", stroke: "black"}}/>
        }
    }


    

    const formControlButtons = (<FormControlButtons
            labels={labels}
            onCancel={() => {
                resetModal();
                setShowModal(false);
            }}
            // there are some isLoading status that are not relevant for the car tab
            isLoading={tab===0?isLoadingAnythingCarTab:isLoadingAnything}
        />
    )
    return (<>
            {showModal?
                    <FormModal title={getModalTitle()} show={showModal} setShow={setShowModal}
                            onClose={() =>{
                                resetModal();
                                setShowModal(false)
                            }}
                            onOutsideClick={() => setShowModal(false)}
                            featuredIcon={<FeatureIcon>{getModalIcon()}</FeatureIcon>}
                    >
                        <ErrorMessage/>
                        {isSuccess  ? (<SuccessMessage/>) : (
                            <>
                                {/* MODAL TABS  */}
                                <div className="modal-tabs">
                                    {Object.keys(tabs).map((key, index) => (
                                        <button key={index} type="button" className="modal-tab"
                                                data-selected={tab === index}
                                                onClick={() => setTab(index)}
                                        >
                                            {tabs[key]}
                                        </button>))}
                                </div>

                                {/* VEHICLE TAB  */}
                                <VehicleTab
                                    createCarForm={createCarForm}
                                    createDriverForm={createDriverForm}
                                    createFuelCardForm={createFuelCardForm}
                                    onSubmit={onSubmitVehicleForm}
                                    tab={tab}
                                    labels={labels}
                                    setFile={setFile}
                                    showVehicleRegistration={hasVehicleRegistrationUploadFlag && hasRepairRole}
                                >
                                    <ErrorMessage/>
                                    {formControlButtons}
                                </VehicleTab>

                                {/* DRIVER TAB  */}
                                <DriverTab
                                    createDriverForm={createDriverForm}
                                    createCarForm={createCarForm}
                                    createFuelCardForm={createFuelCardForm}
                                    onSubmit={onSubmitDriverForm}
                                    tab={tab}
                                    labels={labels}
                                >
                                    <ErrorMessage/>
                                    {formControlButtons}
                                </DriverTab>


                                {/* FUEL CARD TAB  */}
                                    <FuelCardTab
                                        createCarForm={createCarForm}
                                        createFuelCardForm={createFuelCardForm}
                                        createDriverForm={createDriverForm}
                                        onSubmit={onSubmitFuelCardForm}
                                        tab={tab}
                                        labels={labels}
                                    >

                                        <ErrorMessage/>
                                        {formControlButtons}
                                    </FuelCardTab>



                            </>
                        )}

                    </FormModal>
                    :
                    <></>
            }
            {/* FLEET WIZARD TRIGGER BUTTON (OPENS MODAL)  */}
            <button className="btn btn-primary sm" style={{width: "fit-content", justifySelf: "end"}}
                    onClick={() => setShowModal(true)} type="button"
                    {...props}
            >
                <PlusIcon style={{marginRight: "4px", stroke: "white"}}/>
                {props.children}
            </button>
        </>

    );
}

export default FleetWizard;
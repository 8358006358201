//import external library
import React from "react";
//import internal styling
import "./_style.scss"
//import assets
import {ReactComponent as Call} from "../../assets/svg/icon-call.svg"
import {ReactComponent as Company} from "../../assets/svg/icon-company.svg"
import {ReactComponent as Email} from "../../assets/svg/icon-email.svg"

const DriverInfoBanner = (props) => {
    const driver_object=props.data?.childCustomer
    let contract_status = driver_object?.status?.id
    // let salutation = driver_object?.salutation?.id;
    // let salutation_description;
    let dotStyleClass;
    let bannerColorStyleClass;
    switch (contract_status) {
        case 1:
            dotStyleClass = "dot green"
            bannerColorStyleClass = "info-banner-green"
            break;
        default:
            dotStyleClass = "dot other"
            bannerColorStyleClass = "info-banner-other"
            break;
    }

    //TODO: salutation is currently removed due to translation

    // switch (salutation) {
    //     case 1:
    //         salutation_description = "Herr"
    //         break;
    //     case 2:
    //         salutation_description = "Frau"
    //         break;
    //     default:
    //         salutation_description=""
    //         break;
    // }

    return (
        <div className="section-container-content">
            <div className={bannerColorStyleClass}>
                <div className="name-status-stoplights">
                    {/*{salutation_description?<h2 style={{display:"inline"}}>{salutation_description}</h2>:<></>}*/}
                    <h2 style={{display:"inline"}}>{driver_object?.firstName + " "+ driver_object?.lastName}</h2>
                    <span className={dotStyleClass}></span>
                </div>
                {driver_object?.companyName?
                    <div className="icon-info-container">
                        <Company></Company>
                        <span>{driver_object?.companyName}</span>
                    </div>:<></>}
                {driver_object?.phone?.phone?
                    <div className="icon-info-container">
                        <Call></Call>
                        <span>{driver_object?.phone?.phone}</span>
                    </div>:<></>}
                {driver_object?.email?
                    <div className="icon-info-container">
                        <Email></Email>
                        <a id="email-address" href={`mailto:" + ${driver_object?.email}`}>{driver_object?.email}</a>
                    </div>:<></>}
            </div>
        </div>
    )
}

export default DriverInfoBanner


import {isObjectNotObsolete} from "./date.actions";
/**
 * Checks whether endDate of the car is empty or in the future and car is not
 * already added to the drivers list.
 * @param {*} car this specific element
 * @param {*} cars  list of already attached cars
 * @returns true if car should be added to drivers list
 *          false if car shouldn't be added to drivers list
 */

export const isCarValidAndUnique = (car, cars, endDateFieldName="endDt") => {
    if (isObjectNotObsolete(car[endDateFieldName]) && !cars.includes(car.id)) {
        return true;
    }
    return false;
}

export const isRelationshipUnique = (relationshipId, allRelationshipIds) => {
    if (!allRelationshipIds.includes(relationshipId)) {
        return true;
    }
    return false;
}
// import external
import React from "react";

// import internal
import CostOverview from "../../components/CostOverview";

// import internal styling
import "./_style.scss"
import {useSelector} from "react-redux";



const MyCostOverviewPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)

    // structure the page with car overview and a second container
    return (
        <div className="content-side content-side-padding">
            <CostOverview labels={currentLabels} title={currentLabels?.costPage?.titles?.fleetCostOverview}></CostOverview>
        </div>
    )
}

export default MyCostOverviewPage

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet} from "react-router-dom";
// import styling
import "./_style.scss"
// import api slices
import {useGetLabelsQuery} from "../../redux/apiSlice/labelsApiSlice";
// import assets
import defaultLabels from "../../assets/i18n/German.json"
// import other react components
import NavBar from "../NavBar";
import PageFooter from "../PageFooter";
import FallbackPage from "../../pages/FallbackPage";
import {setCurrentLanguage} from "../../redux/labelsSlice/labelsSlice";



const LoadLanguage=(props)=>{
    const currentLanguage = useSelector(state => state.labels.currentLanguage)
    const {
        data: labels,
        isLoading,
        isSuccess,
        isError
    } = useGetLabelsQuery(currentLanguage);
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(setCurrentLanguage({language:currentLanguage, labels:labels}))
    },[isSuccess,currentLanguage, dispatch, labels])
    // the reason why both is Success and isError is returning the same is that, we have
    // transformed the error response.
    // in case of error with labels API, or JSON is downloaded with no content, a fallback german JSON file in the
    // assets folder will be displayed.
    if (isSuccess){
        return (
            <div className="cockpit-protected-page">
                <div className="cockpit-panel">
                    <NavBar labels={labels} currentLanguage={currentLanguage}></NavBar>
                    <Outlet/>
                </div>
                <PageFooter></PageFooter>
            </div>
        )
    }else if(isLoading){
        return (
            <FallbackPage></FallbackPage>
            )
    }else if(isError){
        return (
            <div className="cockpit-protected-page">
                <div className="cockpit-panel">
                    <NavBar labels={defaultLabels}></NavBar>
                    <Outlet/>
                </div>
                <PageFooter></PageFooter>
            </div>
        )
    }
}

export default LoadLanguage
import {useMemo} from "react";
import {fuelTypeFromCo2Api, fuelTypeMapping} from "../../../actions/mapCo2.actions";
import { ReactComponent as GasIcon } from "../../../assets/svg/icon-gas.svg";
import { ReactComponent as LightningIcon } from "../../../assets/svg/icon-lightning.svg";

export const UsageCard = ({co2Data, kmData, labels}) => {
    const parsedData = useMemo(() => {
        let tempData = []
        let consumptionPerFuelType = {}
        let kmPerFuelType = {}

        for (const vehicle of co2Data || []) {
            for (const fuelTypeEntry of vehicle?.fuelDataList || []) {
                consumptionPerFuelType[fuelTypeEntry?.fuelTypeId] = consumptionPerFuelType[fuelTypeEntry?.fuelTypeId] ?? 0;
                consumptionPerFuelType[fuelTypeEntry?.fuelTypeId] += fuelTypeEntry?.fuelConsumption;
            }
        }

        for (const vehicle of kmData || []) {
            if (!vehicle?.fuelType) continue;
            const fuelTypeId = fuelTypeMapping[vehicle?.fuelType]

            kmPerFuelType[fuelTypeId] = kmPerFuelType[fuelTypeId] ?? 0;
            kmPerFuelType[fuelTypeId] += vehicle?.km;
        }


        for (const fuelTypeId of Object.keys(consumptionPerFuelType)) {
            if (consumptionPerFuelType[fuelTypeId] && kmPerFuelType[fuelTypeId]) {
                const fuelType = labels?.fleetWizard?.form?.fuelTypes[fuelTypeFromCo2Api[fuelTypeId]];
                tempData.push({
                    fuelType: fuelType,
                    // Fuel consumption per 100 km
                    value: (consumptionPerFuelType[fuelTypeId] / (kmPerFuelType[fuelTypeId])) * 100,
                })
            }
        }
        return tempData?.sort((a,b) => b?.value - a?.value);

    }, [co2Data, kmData, labels?.fleetWizard?.form?.fuelTypes])
    function getIcon(fuelType) {
        const size = '14px';
        switch(fuelType) {
            case labels?.fleetWizard?.form?.fuelTypes.Elektro:
                return <LightningIcon width={size} height={size}/>;
            default:
                return <GasIcon width={size} height={size}/>;
        }
    }

    function getUnit(fuelType) {
        switch(fuelType) {
            case labels?.fleetWizard?.form?.fuelTypes.Elektro:
                return "kWh"
            default:
                return "l"
        }
    }


    // Split into two columns if more than 4 items
    return (
        <div className="usage-list" data-two-columns={parsedData?.length > 4}>
            {parsedData?.map((item, index) => {
                return (
                    <div className="usage-item" key={index}>
                        <span className="usage-icon">
                            {getIcon(item?.fuelType)}
                        </span>
                        <div className="usage-text">
                            <p className="fuel-type">
                                {item?.fuelType}:
                            </p>
                            <p className="value">
                                {Math.round(item?.value*100) / 100}
                            </p>
                            <p className="unit">
                                {getUnit(item?.fuelType)}
                            </p>
                        </div>


                    </div>
                )
            })}
        </div>
    )
}


// import styling
import "./_style.scss"

// import internal slices
import GridHandler from "../GridHandler";
import {findNewestValidEntry, formatDateIfNotEmpty} from "../../actions/date.actions";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import {useGetCarV2Query} from "../../redux/apiSlice/carApiSlice";

/**
 * Use AG grid to show the list of cars that is related to the fuel card
 */
const FuelCardCarsRelation = (props) => {
    const NewestDriver = (VIN) => {
        const {
            data: carData,
            isSuccess,
        } = useGetCarV2Query(String(VIN));
        if(isSuccess){
            try {
                const drivers = [...carData[0].additionalCarInformation.drivers];
                const idx = findNewestValidEntry(drivers, "fromDate", "endDate");
                //Driver with most recent fromDate and no endDate
                return drivers[idx].firstName + " " + drivers[idx].lastName;
            }
            catch (e) {
                return "";
            }
        }
    }

    const GridWrapper = (props) => {
        const newestDriver = NewestDriver(String(props.fuelCards_data[0]?.vin))

        const columnDefs = [
            {
                headerName: props.labels?.carsPage?.carInfo?.brand,
                colId:"brand",
                valueGetter: function (params) {
                    return params.data.brand;
                },
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.type,
                colId:"type",
                valueGetter: function (params) {
                    return params.data.type;
                },
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.plateNumber,
                colId:"plateNumber",
                valueGetter: function (params) {
                    return params.data.licencePlate;
                },
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.VIN,
                colId:"VIN",
                valueGetter: function (params) {
                    return params.data.vin;
                }
            },
            {
                headerName: props.labels?.carsPage?.carInfo?.driver,
                colId:"driver",
                valueGetter: function () {
                    return newestDriver;
                }
            },
            {
                headerName: props.labels?.fuelCardPage?.fuelCardInfo?.carCardLinkStart,
                colId:"carCardLinkStart",
                valueGetter: (params) => formatDateIfNotEmpty(params.data.startDate),
                filter: 'agDateColumnFilter',
                // sorting related
                comparator: dateComparator,
                // filter related
                filterParams: {
                    // provide comparator function
                    comparator: dateNowComparator
                }
            },
            {
                headerName: props.labels?.fuelCardPage?.fuelCardInfo?.carCardLinkEnd,
                colId:"carCardLinkEnd",
                valueGetter: (params) => formatDateIfNotEmpty(params.data.endDate),
                filter: 'agDateColumnFilter',
                // sorting related
                comparator: dateComparator,
                // filter related
                filterParams: {
                    // provide comparator function
                    comparator: dateNowComparator
                }
            },
        ];

        return (
            <GridHandler
                data={props.fuelCards_data}
                columnDefs={columnDefs}
                downloadable={true}
                gridName={`upto_fuelcard_cars_relationship_${props.fuelcard_id}`}
                fileName={`upto_${props.title.toLowerCase()}_${props.fuelcard_id}`}
                downloadTextCsv={props.labels.otherElements.downloadCsv}
                downloadTextExcel={props.labels.otherElements.downloadExcel}
            ></GridHandler>
        )
    }


    let content;
    content = (
        <div className="section-card">
            <div className="section-container-content">
                <GridWrapper labels={props.labels}
                          fuelCards_data={props.fuelCards_data}
                          title={props.title}
                          fuelcard_id={props.fuelcard_id}
                />

            </div>
        </div>
    )

    return (
        <>
            {content}

        </>
    )
}

export default FuelCardCarsRelation;




// import external
import React from "react";
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux";
// import styling
import "./_style.scss"
// import helper function
import {formatDateIfNotEmpty} from "../../actions/date.actions"
import {useVariableValue} from "@devcycle/react-client-sdk";
// import internal components
import DriverInfoCardBundle from "../../components/DriverInfoCardBundle";
import CarTelematicDataTable from "../../components/CarTelematicDataTable";
import CarFieldTimeLogTable from "../../components/CarFieldTimeLogTable";
import LoadingComponent from "../../components/LoadingComponent";
import CarLevelInvoiceAndPositions from "../../components/CarLevelInvoiceAndPositions";
import {createErrorMessage} from "../../components/AlertContainer/alert.utils";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import ContentTab from "../../components/ContentTab";
import GeneralCarDataCard from "./GeneralCarDataCard";
import PlateNumberOverview from "./PlateNumberOverview";
import {dateComparator, dateNowComparator} from "../../components/GridHandler/dates.actions";
import CustomerFieldsCard from "./CustomerFieldsCard";
import FuelCardCarousel from "../../components/FuelCardsCarousel";
import {useGetUserQuery} from "../../redux/apiSlice/userApiSlice";
import {useGetCarV2Query} from "../../redux/apiSlice/carApiSlice";
import {ROLE_CONST} from "../../actions/constants/role.constants";
import DocumentOverview from "../../components/DocumentOverview";
import CarMasterDataTab from "./CarMasterDataTab";
import CostOverview from "../../components/CostOverview";
import {costPageKey} from "../../components/NavBar";
import {CarFinancingTab} from "./CarFinancingTab";

const CarDetailPage = (props) => {
    // get current labels
    const currentLabels = useSelector(state => state.labels.currentLabels)
    const param = useParams()
    const carVin = param.vin
    // car info table
    const carInfoPath = {
        "VIN": (data) => data?.additionalCarInformation?.baseData?.vin,
        "brand": (data) => data?.additionalCarInformation?.baseData?.brand,
        "type": (data) => data?.additionalCarInformation?.baseData?.type,
        "performance": (data) => data?.additionalCarInformation?.baseData?.powerInKw,
        "plateColor": (data) => data?.additionalCarInformation?.baseData?.colorLicensePlate,
        "registrationCountry": (data) => data?.additionalCarInformation?.baseData?.registrationCountry,
        "carPurpose": (data) => data?.additionalCarInformation?.baseData?.vehicleType,
        "firstRegistration": (data) => formatDateIfNotEmpty(data?.additionalCarInformation?.baseData?.registrationDate),
        "contractStart": (data) => formatDateIfNotEmpty(data?.contractInformation?.contractStartDate),
        "contractEnd": (data) => formatDateIfNotEmpty(data?.contractInformation?.contractEndDate),
        "fuelType": (data) => data?.additionalCarInformation?.fuelData?.fuelType
    }
    // API handling logic starts here
    const {
        data: carsData,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCarV2Query(carVin)
    //Check if user is Agent
    const isAgent = useSelector(state => state.auth.isAgentLogin);
    const hasEditCarFeatureFlag =  useVariableValue("edit-car", false);
    //Check if user has role 178
    const {
        data: userData,
    } = useGetUserQuery();




    //Only shown for loading state or for error messages
    let content;

    const useNewCostApi = useVariableValue(costPageKey, false)

    // Page Title
    let pageTitle = currentLabels?.carsPage?.titles?.vehicleDetails;

    //Make up the content for the different tabs
    let vehicleDetailsContent, carMasterDataContent, locationContent, driverContent, mileageDataContent, invoiceOverviewContent, costDataContent, 
        documentOverviewContent;

    //Empty lists for tab content, tab labels and which labels should be hidden (due to no content)
    let tabContent, tabLabels, hiddenTabs = [];
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) {
        let {cars, uniqueFields, uniqueFieldDescriptions} = carsData
        if (cars.length === 1 && cars[0]?.additionalCarInformation) {
            //Check if user is allowed to edit (isAgent && has role && car is active)
            const hasRole = userData?.roles?.some(role => role.id === ROLE_CONST.SELF_MNG_CARS) || userData?.roles?.some(role => role.id === ROLE_CONST.REPAIR);
            const hasTeleRole = userData?.roles?.some(role => role.id === ROLE_CONST.TELE_STANDORT) || userData?.roles?.some(role => role.id === ROLE_CONST.TELE_NO_STANDORT);
            const allowEdit = (hasRole && cars[0].contractInformation?.contractStatusId === 1 && (isAgent || hasEditCarFeatureFlag));

            //Set page title
            // change to cars[0] so that the title is correctly displayed with the new api
            const brand = cars[0]?.additionalCarInformation?.baseData?.brand;
            const type = cars[0]?.additionalCarInformation?.baseData?.type;
            const licensePlate = cars[0]?.licencePlate;
            // Ford Transit (ZH 138293)
            if (brand && type && licensePlate) {
                pageTitle = brand + " " + type + " (" + licensePlate + ")"
            }
            // Ford Transit
            else if (brand && type) {
                pageTitle = brand + " " + type
            }
            // ZH 138293
            else if (licensePlate) {
                pageTitle = licensePlate
            }
            // Else keep default page title (vehicle details)

            vehicleDetailsContent = (
                <div className="car-details-content">
                    <GeneralCarDataCard labels={currentLabels} infoFields={carInfoPath}
                                        data={cars[0]}/>
                    <FuelCardCarousel vin={cars[0]?.additionalCarInformation?.baseData?.vin}></FuelCardCarousel>
                    <CustomerFieldsCard
                        labels={currentLabels}
                        data={cars[0]}
                        uniqueFields={uniqueFields}
                        uniqueFieldDescriptions={uniqueFieldDescriptions}
                        allowEdit={allowEdit}
                    >
                    </CustomerFieldsCard>
                    <PlateNumberOverview
                        gridSortingFunc={dateComparator}
                        gridFilteringFunc={dateNowComparator}
                        data={cars[0]}
                        labels={currentLabels}
                        allowEdit={allowEdit}
                    />
                </div>
            )
            carMasterDataContent=(
                <CarMasterDataTab labels={currentLabels} data={cars[0]} isAgent={isAgent} hasFeatureFlag={hasEditCarFeatureFlag} hasRole={hasRole} customerId={userData?.customer}></CarMasterDataTab>
            )
            /* Because some tabs (Content variables) only rely on a single component, we only define them
            *  if that component is defined at not empty */
            if (cars[0]?.additionalCarInformation?.locations?.length) {
                locationContent = (
                    <div className="blank-card" style={{width: "600px"}}>
                        {/*Section for Location (Standort) History*/}
                        <CarFieldTimeLogTable labels={currentLabels?.carsPage?.carInfo}
                                              fieldName={currentLabels?.carsPage?.carInfo.location}
                                              data={cars[0]?.additionalCarInformation?.locations}>
                        </CarFieldTimeLogTable>
                    </div>
                )
            }
            if (cars[0]?.contractInformation?.serviceId) {
                documentOverviewContent = (
                    <div>
                        <div className="car-details-cost-overview-container">
                            <DocumentOverview labels={currentLabels}
                                              serviceId={cars[0]?.contractInformation?.serviceId}
                            />
                        </div>
                    </div>
                )
            }

            if (cars[0]?.additionalCarInformation?.drivers?.length > 0) {
                driverContent = (
                    <div className="blank-card" style={{width: "400px"}}>
                        {/*Section for driver history*/}
                        <DriverInfoCardBundle labels={currentLabels?.carsPage?.carInfo}
                                              drivers={cars[0]?.additionalCarInformation?.drivers}>
                        </DriverInfoCardBundle>
                    </div>
                )
            }

            /*Section for Telematik data*/
            mileageDataContent = (
                <CarTelematicDataTable
                    labels={currentLabels}
                    data={cars[0]}
                    allowEdit={allowEdit && hasTeleRole}
                >
                </CarTelematicDataTable>
            )
            invoiceOverviewContent = (
                <div>
                    <div className="car-details-cost-overview-container">
                        <CarLevelInvoiceAndPositions
                            vin={carVin}
                            gridSortingFunc={dateComparator}
                            gridFilteringFunc={dateNowComparator}
                            labels={currentLabels}
                        >
                        </CarLevelInvoiceAndPositions>
                    </div>
                </div>
            )
            costDataContent = (
                <CostOverview vin={cars[0]?.vin} labels={currentLabels}/>
            )
            tabContent = [
                vehicleDetailsContent,
                carMasterDataContent,
                locationContent,
                driverContent,
                mileageDataContent,
                costDataContent,
                invoiceOverviewContent,
                documentOverviewContent,
                <CarFinancingTab labels={currentLabels} car={cars[0]}/>
            ]
            tabLabels = [
                currentLabels?.carsPage?.titles?.carDetails,
                currentLabels?.carsPage?.titles?.masterData,
                currentLabels?.carsPage?.carInfo?.location,
                currentLabels?.carsPage?.titles?.driverList,
                currentLabels?.carsPage?.titles?.mileageData,
                currentLabels?.navigation?.pageName?.cost,
                currentLabels?.costPage?.titles?.invoicesOverview,
                currentLabels?.documentPage?.titles?.documents,
                currentLabels?.carsPage?.titles?.financing
            ]
            for (let i = 0; i < tabContent.length; i++) {
                if (!tabContent[i]) {
                    hiddenTabs[hiddenTabs.length] = i
                }
            }
        } else {
            content = createErrorMessage(error, "", "h2", currentLabels, "invalidItemNumber");
        }
    } else if (isError) {
        content = createErrorMessage(error, "", "h2", currentLabels);
    }

    // These english labels are hardcoded on purpose since they reflect the URL and shouldn't vary with language
    const tabUrlLabels = [
        "car-details",
        "master-data",
        "location",
        "driver-list",
        "mileage-data",
        `costs${useNewCostApi ? "-v2" : ""}`,
        "invoice-overview",
        "documents",
        "financing",
    ]

    return (
        <div className="content-side content-side-padding">
            {content ? content :
                <>
                    <BreadcrumbNav/>
                    <h1>
                        {pageTitle}
                    </h1>
                    <ContentTab views={tabContent} labels={tabLabels} hidden={hiddenTabs} urlLabels={tabUrlLabels}/>
                </>
            }
        </div>
    )
}
export default CarDetailPage

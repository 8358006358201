// import external
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import internal component
import GridHandler from "../../../../components/GridHandler";
import { formatDateIfNotEmpty} from "../../../../actions/date.actions"
import {dateComparator, dateNowComparator} from "../../../../components/GridHandler/dates.actions";
const CarFinancingGridTable = (props) => {
    const labels = useSelector(state => state.labels.currentLabels)
    const navigate = useNavigate()
    const columnDefs = [
        {
            headerName: labels.carsPage.carInfo.VIN,
            valueGetter: function (params) {
                return params.data?.car?.vin;
            },
            colId: "vin",
            cellRenderer: (params) => {
                if (!params.value) return null;
                const pageName = `/cars/${params.value}?tab=financing`;
                return (
                    <span
                        className="link-with-icon"
                        onClick={() => {
                            // When user goes to the Costs page of a car, we change the car detail page costs Datepicker
                            // dates to the currently selected one
                            navigate(pageName)
                        }}>
                        {params.value}
                    </span>
                )
            },
        },
        {
            headerName: labels.carsPage.carInfo.brand,
            valueGetter: function (params) {
                return params.data?.car?.brand;
            },
            colId: "brand"
        },
        {
            headerName: labels.carsPage.carInfo.type,
            valueGetter: function (params) {
                return params.data?.car?.type;
            },
            colId: "type"
        },
        {
            headerName: labels.carsPage.carInfo.plateNumber,
            valueGetter: function (params) {
                return params.data?.car?.licensePlate;
            },
            colId: "licenseplate"
        },
        {
            headerName: labels.carsPage.carInfo.financingType,
            valueGetter: function (params) {
                return params.data?.financingType;
            },
            colId: "financingType",
            //sort: 'desc',
        },
        {
            headerName: labels.carsPage.carInfo.financingProvider,
            valueGetter: function (params) {
                return params.data?.financingProvider?.companyName;
            },
            colId: "financingProvider",
            //sort: 'desc',
        },
        {
            headerName: labels.carsPage.carInfo.financingContractStart,
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data?.startDt)
            },
            colId: "financingStartDt",
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: labels.carsPage.carInfo.contractDuration,
            valueGetter: function (params) {
                return params.data?.contractDuration;
            },
            colId: "contractDuration",
            //sort: 'desc',
        },
        {
            headerName: labels.carsPage.carInfo.financingContractEnd,
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data?.endDt)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            },
            colId:"financingEndDt",
            sort: 'asc',
        },
        {
            headerName: labels.carsPage.carInfo.isExtendable,
            valueGetter: function (params) {
                return params.data?.isExtendable;
            },
            colId: "isExtendable",
        },
        {
            headerName: labels.carsPage.carInfo.notes,
            valueGetter: function (params) {
                return params.data?.notes;
            },
            colId: "notes",
        },
        {
            headerName: labels.carsPage.carInfo.remainingValue,
            valueGetter: function (params) {
                return params.data?.remainingValue;
            },
            colId: "remainingValue",
        },
        {
            headerName: labels.carsPage.carInfo.mileagePerYear,
            valueGetter: function (params) {
                return params.data?.mileagePerYear;
            },
            colId: "mileagePerYear",
        }
       
    ]
    return (
        <div className="scrollable">
            <p style={{fontSize:"14px"}}></p>
            <GridHandler
                data={props.data}
                columnDefs={columnDefs}
                downloadable={false}
                gridName={"homepage_car_financing_info"}
            ></GridHandler>
        </div>

    )
}

export default CarFinancingGridTable
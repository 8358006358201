// import external
import React from "react";
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux";

// import styling
import "./_style.scss"

// import internal components
import LoadingComponent from "../../components/LoadingComponent";
import {createErrorMessage} from "../../components/AlertContainer/alert.utils";
import {useGetFuelCardByIdQuery} from "../../redux/apiSlice/fuelCardApiSlice";
import FuelCardCarsRelation from "../../components/FuelCardCarsRelation";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import ContentTab from "../../components/ContentTab";
import GeneralCreditCard from "./GeneralCreditCard";


const FuelCardDetailsPage = (props) => {


    // get current labels
    const currentLabels = useSelector(state => state.labels.currentLabels)
    const param = useParams()
    const fuelcard_id = param.id



    // api handling logic starts here
    const {
        data: fuelCards,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetFuelCardByIdQuery(fuelcard_id)
    const cardBadge=(params) => {
        if (params.status === 1) {
            return <span className={"status-tag-positive"}>{currentLabels?.statusCode?.active}</span>
        } else {
            return <span className={"status-tag-neutral"}>{currentLabels?.statusCode?.inactive}</span>
        }
    }

    // start with the api loading logic
    let content;
    
    // Fallback page title 
    let pageTitle = currentLabels?.fuelCardPage?.titles?.fuelCardsDetails;
    
    let tabContent, tabLabels, hiddenTabs = [];
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) {

        if (fuelCards.length > 0) {
            let cardDetailsContent, relatedCarsContent;
            let filtered_fuelCards = fuelCards.filter(fuelCards => fuelCards.id.toString() === fuelcard_id)

            if (filtered_fuelCards.length !== 1) {
                content = createErrorMessage(error, currentLabels.carsPage.titles.carDetails, "h2", currentLabels, "invalidItemNumber");

            } else {
                // True if the fuel card has a cardName
                const hasCardName = !!filtered_fuelCards[0].cardName;

                // Define the title of page; "CARDNUMBER - CARDNAME"
                pageTitle = `${filtered_fuelCards[0].cardNumber} ${hasCardName ? ` - ${filtered_fuelCards[0].cardName}` : ''}`

                cardDetailsContent = (
                    <>
                        {/*Infos zur Tankkarte */}
                        <div className={"info-cards-container"}>
                            <div className={"info-card"}>
                                <div className={"card-label"}>{currentLabels?.fuelCardPage?.fuelCardInfo.cardName}</div>
                                <div className={"text-status-wrapper"}>
                                    <div className={"card-text"}>{filtered_fuelCards[0].cardName}</div>
                                    {cardBadge(filtered_fuelCards[0])}
                                </div>
                            </div>
                            <div className={"info-card"}>
                                <div
                                    className={"card-label"}>{currentLabels?.fuelCardPage?.fuelCardInfo.cardNumber}</div>
                                <div className={"card-text"}>{filtered_fuelCards[0].cardNumber}</div>
                            </div>
                            <div className={"info-card"}>
                                <div
                                    className={"card-label"}>{currentLabels?.fuelCardPage?.fuelCardInfo.energyProvider}</div>
                                <div className={"card-text"}>{filtered_fuelCards[0].provider?.companyName}</div>
                            </div>
                        </div>
                        <div className={"info-card"} style={{width: "fit-content"}}>
                            <div className={"card-label"} style={{marginBottom: "8px"}}>{currentLabels?.fuelCardPage?.titles.yourCard}</div>
                            <GeneralCreditCard labels={currentLabels?.fuelCardPage} data={filtered_fuelCards[0]}>
                            </GeneralCreditCard>
                        </div>
                    </>
                )
                relatedCarsContent = (
                    <>
                        {/* AG Grid: Alle verbundenen Fahrzeuge zu einer Tankkarte*/}
                        <FuelCardCarsRelation labels={currentLabels}
                                              fuelcard_id={fuelcard_id}
                                              title={currentLabels?.fuelCardPage?.titles?.carsRelation}
                                              fuelCards_data={filtered_fuelCards[0]?.cars}
                        ></FuelCardCarsRelation>
                    </>
                )
            }
            tabContent = [
                cardDetailsContent,
                relatedCarsContent
            ]

            tabLabels = [
                currentLabels?.fuelCardPage?.titles?.fuelCardsOverview,
                currentLabels?.fuelCardPage?.titles?.carsRelation
            ]

            for (let i = 0; i < tabContent.length; i++) {
                if (!tabContent[i]) {
                    hiddenTabs[hiddenTabs.length] = i
                }
            }
        }
    } else if (isError) {
        content = createErrorMessage(error, props.title, "h1", props.labels);
    }

    // These english labels are hardcoded on purpose since they reflect the URL and shouldn't vary with language
    const tabUrlLabels = ["details", "cars-relations"]

    return (
        <div className="content-side content-side-padding">
            {content ? content :
                <div>
                    <BreadcrumbNav/>
                    <h1>
                        {pageTitle}
                    </h1>
                    <ContentTab views={tabContent} labels={tabLabels} hidden={hiddenTabs} urlLabels={tabUrlLabels}/>
                </div>
            }
        </div>
    )
}

export default FuelCardDetailsPage;



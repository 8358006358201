import React from 'react';

// import styling
import "./_style.scss"

export const defaultCellRenderer =(props) => {
    //Used to define a guaranteed unique ID for all rows
    const divId = `(${props.node.rowIndex} ${props.column.instanceId})`

    //Enters edit mode for the cell
    const enterEditMode = () => {
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.getId(),
        })
    }
    if (props.multiLine){
        return (
            <div id={divId}
                 style={{lineHeight:1.5,overflow:"hidden", whiteSpace:"nowrap", display:"inline-block", textOverflow:"ellipsis", width:"100%",}}
                 dangerouslySetInnerHTML={{ __html: props.value.replace('\n', '<br/>') }}/>
        )
    }
    return (
            <div
                id={divId}
                style={{overflow:"hidden", whiteSpace:"nowrap", display:"inline-block", textOverflow:"ellipsis", width:"100%",}}>
                <span
                    onClick={enterEditMode}
                    hidden={!props.colDef.editable}
                    className={"edit-button"}
                >✎</span>
                {props.valueFormatted ? props.valueFormatted : props.value}
            </div>
        )

};
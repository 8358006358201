import {useState} from "react";
import "./_style.scss";
import PropTypes from "prop-types";

export const FilterButtonItem = (props) => {
    return {
        id: props.id,
        text: props.text,
        filterType: props.filterType,
    }
}

const FilterButtonGroup = ({ items, onFilterChange, selectedItem, ...props }) => {
const [activeFilter, setActiveFilter] = useState(selectedItem?.props?.id);

    FilterButtonGroup.propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({FilterButtonItem})).isRequired, //Array of FilterButtonItem objects
        onFilterChange: PropTypes.func.isRequired, //Function to call when a button is clicked
        selectedItem: PropTypes.shape(FilterButtonItem), //The FilterButtonItem from the items array that is currently selected
    }

    // Set active button on click, and call onFilterChange function
    const handleFilterChange = (item) => {
        setActiveFilter(item.props?.id);
        // this is the case where the button group is used on the bill details page
        if(item.props?.filterType === undefined){
            onFilterChange(item);
            return;
        }
        onFilterChange(item.props?.filterType);
    };

    //If the activeFilter is not defined, set the button specified as defaultFilter to active
    //(if no button has been clicked yet, selectedItem will be undefined so this is required)
    if(activeFilter === undefined){
        if(items.find(item => item.isDefaultFilter === true) === undefined){
            setActiveFilter(items[0].props?.id);
        }
        else {
            setActiveFilter(items.find(item => item.isDefaultFilter === true).props?.id);
        }
    }

    return (
        <div className="filter-button-group" data-cy="filter-button-group">
            {items.map((item) => (
                <button
                    key={item.props?.id}
                    className={item.props.id === activeFilter ? "filter-button active" : "filter-button"}
                    onClick={() => handleFilterChange(item)}
                    data-cy={"filter-button-"+item.props?.id}
                >
                    {item.props?.text}
                </button>
            ))}
        </div>
    );
}

export default FilterButtonGroup;
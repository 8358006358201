export const ROLE_CONST = {
    SELF_MNG_CARS: 184,
    REPAIR: 177,
    TELE_STANDORT: 174,
    TELE_NO_STANDORT: 175,
    DAMAGE: 180,
    ADD_CAR_VIA_PORTAL: 178, // This role allow user to add mileage when the car does not belong to them
    FUEL_CARDS: 182,
    DRIVER: 172
}

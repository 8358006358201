// import styling
import "./_style.scss"

// import external
import {useState, useCallback} from "react";
import {AgCharts} from "ag-charts-react";
import {convertAndFormat} from "../../../actions/formateNumbers.actions";
import {sanitizeValues} from "../../../actions/sanitize";
import {useNavigate} from "react-router-dom";
// import helper function
import {useVariableValue} from "@devcycle/react-client-sdk";
// import internal components
import {costPageKey} from "../../../components/NavBar";
import {chartNumberFormatter} from "../../../actions/magicNumber.actions";


// Returns an ag grid column chart for the fleet costs per car data
const DiagramPerCar = (props) => {
    const useNewCostApi = useVariableValue(costPageKey, false)
    const navigate = useNavigate();
    
    // Define callback function, which handles clicks on chart bubbles
    // Is called by the AG charts listener when a bubble is clicked.
    const handleBubbleClick = useCallback((event) => {
        const vin = event.datum?.vin;
        if (vin) {
            navigate(`/cars/${vin}?tab=costs${useNewCostApi ? "-v2" : ""}`);
        }
    }, [navigate,useNewCostApi]);


    //If there are more than 50 cars, only show the 50 with the highest costs
    if(props.data?.length > 50) {
        props.data?.sort((a, b) => {
            return (b.totalCost + b.totalTco) - (a.totalCost + a.totalTco)
        })
        props.data.length = 50;
    }


    let data = [...props.data]

    // If either totalCost or totalTco is not defined, set it to 0
    data.forEach((car) => {
        if(!car.totalCost && car.totalCost !== 0) {
            car.totalCost = 0;
        }
        if(!car.totalTco && car.totalTco !== 0) {
            car.totalTco = 0;
        }
    })

    // a fake point at 0, 0 ensures that the trend line goes all the way back to the origin
    data.push({
        totalCost: 0,
        totalTco: 0,
    })


    const theme = {
        palette: {
            fills: [
                '#4D4DFF',
            ],
            strokes: ['transparent'],
        }
    }

    /*
    function getColor(number) {
        //Compare number to highest value and return color so that the highest value is the darkest color
        const colors = [
            '#A1A1FF','#6767D8','#4D4DFF','#3333CC','#00008F'
        ]

        // Ensure the number is within a valid range
        if (number < 0) {
            return colors[0]; // Return the lightest color for negative values
        } else if (number >= highestValue) {
            return colors[colors.length - 1]; // Return the darkest color for values greater than or equal to the highest value
        }

        // Calculate the color index based on linear interpolation
        const colorIndex = Math.floor((number / highestValue) * (colors.length - 1));

        return colors[colorIndex];
    }
     */

    // Used to render the content of the car bubble chart tooltip
    function carTooltipRenderer (params) {
        params = sanitizeValues(params, false);

        return {
            /*
            title: `
                        <div class="type-tooltip-title">
                            <span class="marker" style="background-color:${params?.color}"></span>
                            ${params.datum?.brand} ${params.datum?.type}
                        </div>`,

             */
            title: "",
            content: `
                             <div class="type-tooltip"> 
                                 <div class="type-tooltip-title" style="color:${params?.color}">
                                    <span class="marker" style="background-color:${params?.color}"></span>
                                    ${params.datum?.brand} ${params.datum?.type}
                                 </div> 
                                <div class="type-tooltip-cost"> 
                                    <strong>TCO:</strong> CHF ${convertAndFormat(params.datum?.totalTco)}
                                </div> 
                                <div class="type-tooltip-cost"> 
                                    <strong>${props.labels?.costPage?.costInfo?.chargedByUPTO}:</strong> CHF ${convertAndFormat(params.datum?.totalCost)}
                                </div> 
                                <div class="type-tooltip-cost"> 
                                    <strong>${props.labels?.costPage?.costInfo?.difference}:</strong> CHF ${convertAndFormat(params.datum?.totalTco - params.datum?.totalCost)}
                                </div>
                                <div class="line-1"></div>

                                <div class="type-tooltip-item"> 
                                    <strong>${props.labels?.costPage?.costInfo?.VIN}:</strong> ${params.datum?.vin || "-"}
                                </div>  
                                 <div class="type-tooltip-item"> 
                                    <strong>${props.labels?.costPage?.costInfo?.plateNumber}:</strong> ${params.datum?.licencePlate || "-"}
                                </div>  
                            </div>`
        }
    }

    const [options] = useState({
        theme: theme,
        data: data,
        series: [
            {
                type: 'scatter',
                xKey: 'totalTco',
                xName: props.labels?.costPage?.costInfo?.totalVehicleCostsInCHF,
                yKey: 'totalCost',
                yName: props.labels?.costPage?.costInfo?.chargedByUPTOInCHF,
                itemStyler: (params) => ({
                    size: (params.datum.totalCost + params.datum.totalTco === 0 ? 0 : 12)
                }),
                highlightStyle: {
                    item: {
                        fill: '#4bbfaa',
                    }
                },
                tooltip: {
                    renderer: function (params) {
                        if(!(params.datum.totalCost + params.datum.totalTco > 0)) {
                            //If the sum is 0, don't show tooltip
                            return {
                                enabled: false,
                                content: "",
                                title: "",
                            }
                        }
                        return carTooltipRenderer(params)
                    },
                },
                cursor: 'pointer',
                listeners:  {
                    nodeClick: handleBubbleClick
                }
            },
            
            {
                type: 'line',
                xKey: 'totalTco',
                yKey: 'totalTco',
                stroke: '#e3e4e7',
                strokeWidth: 1,
                strokeOpacity: 0.5,
                marker: {
                    enabled: false
                },
                tooltip: {
                    enabled: false,
                }
            }
        ],
        axes: [
            {
                type: 'number',
                position: 'bottom',
                title: {
                    text: props.labels?.costPage?.costInfo?.totalVehicleCostsInCHF,
                    fontWeight: 500,
                    color: '#475467',
                    fontFamily: 'Space Grotesk',
                },
                label: {
                    formatter: chartNumberFormatter,
                    color: '#475467',
                    fontFamily: 'Space Grotesk',
                },
                gridLine: {
                    enabled: false,
                },
                line: {
                    stroke: '#F2F4F7'
                },
                min: 0,
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: props.labels?.costPage?.costInfo?.chargedByUPTOInCHF,
                    fontWeight: 500,
                    fontFamily: 'Space Grotesk',
                    color: '#475467'
                },
                label: {
                    formatter:chartNumberFormatter,
                    color: '#475467'
                },
                gridLine: {
                    enabled: false,
                },
                line: {
                    stroke: '#F2F4F7'
                },
                min: 0,
            }
        ],
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            showArrow: false,
            range: 'exact',
            position: {
                xOffset: 0,
                yOffset: -5
            }
        },
    });

    return (
        <AgCharts options={options} />
    )
    
}

export default DiagramPerCar;
// import styling
import "./_style.scss";

// import assets
import {ReactComponent as LockIcon} from "../../assets/svg/icon-lock.svg";

// import internal
import FormModal from "../FormModal";
import TwoFactorInput from "./TwoFactorInput";
import FeatureIcon from "../FeatureIcon";
import {setTokenAndSave} from "../../redux/authSlice/authSlice";
import {useVerifyTwoFactorCodeMutation} from "../../redux/apiSlice/twoFactorApiSlice";
import ResendCountDown from "./ResendCountDown";

// import external
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {triggerLoginEvent} from "../../actions/eventTracking.actions";

const TwoFactorHandler = (props) => {
    const navigate = useNavigate();
    const dispatch= useDispatch();

    // Sends API call to verify input
    const [verifyTwoFactorCode, {isLoading: isVerifying}] = useVerifyTwoFactorCodeMutation();

    // Count the number of attempts the user has made (once this reaches 3, reload page and cancel process)
    const [attempts, setAttempts] = useState(0);



    // Sends API call to check 2fa code, if it fails, increment attempts, if successful set new access_token and send user to home page
    const checkTwoFactorCode = (code) => {
        verifyTwoFactorCode(code).then(response => {
            if(response.error){
                // Trigger GTM Event: User failed to login with 2FA
                triggerLoginEvent('email_2fa', null, false)

                setAttempts(attempts+1)
                // Close modal & remove access token if attempts exceed 3 (if this failed attempt was the third)
                if(attempts >= 2){
                    // Remove access token from redux and local storage
                    dispatch(setTokenAndSave({access_token:"", refresh_token:""}))
                    // Close modal
                    props.handleCancel();
                }
            }
            else {
                // If validation was successful, send to home page and set new access + refresh token
                dispatch(setTokenAndSave({access_token:response.data.access_token, refresh_token:response.data.refresh_token}))
                // Trigger GTM Event: User successfully logged in using 2fa, through login page
                triggerLoginEvent('email_2fa', response.access_token, true)
                navigate("/")
            }
        })
    }
    // Replaces the lock icon on top of modal with the falling dots loading animation
    const circleLoading = (
        <div className="dot-falling-container" style={{height:"42px", width:"42px", justifyContent:"center"}}>
            <div className="dot-falling"/>
        </div>
    )

    return (
        <FormModal
            show={props.show}
            onClose={props.handleCancel}
            title={props.labels?.twoFactor?.twoFactorAuthentication}
            featuredIcon={
                <FeatureIcon
                    shape="circle"
                    backgroundColor="#AAFFF1"
                    backgroundColorOuter="#DDFFFA"
                >
                    {isVerifying ? circleLoading : <LockIcon style={{stroke:"#11B298", height:"42px", width:"42px"}}/>}
                </FeatureIcon>
            }
        >
            <div className="two-factor-handler">
                <div className="two-factor-handler-content">
                    <p>{props.labels?.twoFactor?.requestCodeInput}</p>
                    <ResendCountDown labels={props.labels} resendCode={props.resendCode}></ResendCountDown>
                    <TwoFactorInput
                        handleSubmit={checkTwoFactorCode}
                        isValidating={isVerifying}
                        hasFailed={attempts > 0 && !isVerifying}
                    />

                    {/* Only show error messages if user has failed at least once and API isn't loading*/}
                    {(attempts > 0 && !isVerifying) &&
                        <p style={{color:"red", fontWeight:"500"}}>
                            {props.labels?.twoFactor?.invalidTwoFactorCode} {3-attempts}
                        </p>
                    }

                </div>
            </div>
        </FormModal>
    )
}

export default TwoFactorHandler;
// import external
import React from "react";

// import internal
import SupplierInvoicesOverview from "../../components/SupplierInvoicesOverview";

// import internal styling
import {useSelector} from "react-redux";


const SupplierInvoicesPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)

    // structure the page with car overview and a second container
    return (
        <div className="content-side content-side-padding">
            <SupplierInvoicesOverview labels={currentLabels} title={currentLabels?.supplierInvoicesPage?.titles?.supplierInvoicesOverview}/>
        </div>
    )
}

export default SupplierInvoicesPage
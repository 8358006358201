// import external
import React, {createContext, useReducer} from "react";
import {useParams} from "react-router-dom"
// import styling
import "./_style.scss"
// import internal components

import LoadingComponent from "../../components/LoadingComponent";
import {useGetDriversQuery} from "../../redux/apiSlice/driverApiSlice";
import {createErrorMessage} from "../../components/AlertContainer/alert.utils";
import DriverInfoBanner from "../../components/DriverInfoBanner";
import DriverCarListTable from "../../components/DriverCarListTable";
import DriverPersonalInfoForm from "../../components/DriverPersonalInfoForm";
import {useSelector} from "react-redux";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import DriverTimeSpanModal from "./DriverTimeSpanModal";
import {useVariableValue} from "@devcycle/react-client-sdk";

export const DriverContext = createContext({});

const DriverDetailsPage = (props) => {
    const param = useParams()
    const driver_id = param.id
    const currentLabels = useSelector(state => state.labels.currentLabels)
    // Used to check whether the user is an agent (used to hide edit button and check before sending post/put calls)
    const isAgentLogin =useSelector(state=> state.auth.isAgentLogin);
    const hasFeatureFlag = useVariableValue("driver-car-relation", false)
    const hasPermission = isAgentLogin || hasFeatureFlag;
    const initialDriverTimeModalState = {
        show: false,
        relationshipId:undefined,
        driverId:undefined,
        carId:undefined,
        vin:undefined,
        startDate:undefined,
        endDate:undefined
    }
    const driverTimeModalReducer = (state, action) => {
        switch (action.type) {
            case 'OPEN_MODAL':
                return { ...action.payload, show: true };
            case 'RESET_MODAL':
                return { ...initialDriverTimeModalState };
            case 'CLOSE_MODAL':
                return { ...state, show: false };
            default:
                return state;
        }
    };
    const [driverTimeModalState, dispatchDriverTimeModalState] = useReducer(
        driverTimeModalReducer, initialDriverTimeModalState);



    // api handling logic starts here
    const {
        data: drivers,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDriversQuery()


    let content;
    // start with the api loading logic
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) {
        if (drivers && drivers.length > 0) {
            // filter and only show car with this specific contract id
            let filtered_driver = drivers.filter(driver => driver.id.toString() === driver_id)
            let driverName = filtered_driver[0]?.childCustomer?.firstName+" "+filtered_driver[0]?.childCustomer?.lastName
            if (filtered_driver.length !== 1) {
                content =createErrorMessage(error, currentLabels?.driverPage?.titles?.driverData, "h2", currentLabels, "invalidItemNumber");
            } else {
                content = (
                    <>
                        {/*This component shows the info we want to highlight for a driver */}
                        {/*with UI of a color altering card*/}
                        <DriverInfoBanner data={filtered_driver[0]}></DriverInfoBanner>

                        {/*This component display and allow editing of driver information such as*/}
                        {/*addresses and phone number*/}
                        <DriverPersonalInfoForm hasPermission={hasPermission} labels={currentLabels} key="driver-details-table" section_name={currentLabels?.driverPage?.titles?.driverData}
                                          driver_data={filtered_driver[0]}></DriverPersonalInfoForm>

                        {/*This component display the list of cars that is associated with the driver*/}
                        {/*in form of AG grid*/}

                        {<DriverTimeSpanModal client_id={filtered_driver[0]?.parentCustomer} driverName={driverName} modalState={driverTimeModalState} currentLabels={currentLabels} setShowDriverTimeModal={dispatchDriverTimeModalState}></DriverTimeSpanModal>}
                        <DriverContext.Provider value={filtered_driver[0]}>
                            <DriverCarListTable hasPermission={hasPermission} setModalState={dispatchDriverTimeModalState} labels={currentLabels} driverId={filtered_driver[0]?.childCustomer?.id} section_name={currentLabels?.driverPage?.titles?.carList} cars_data={filtered_driver[0]?.carList}></DriverCarListTable>
                        </DriverContext.Provider>
                    </>
                )
            }
        } else {
            content = createErrorMessage(error, currentLabels?.driverPage?.titles?.driverData, "h1",currentLabels);
        }
    } else if (isError) {
        content = createErrorMessage(error, currentLabels?.driverPage?.titles?.driverData, "h1",currentLabels);
    }
    return (
        <div className="content-side content-side-padding">
            <BreadcrumbNav/>
            {content}
        </div>
    )
}

export default DriverDetailsPage
// This file is needed for displaying column filters as chips

// import external
import React from "react";
// import helper function
import {formatDateIfNotEmpty} from "../../actions/date.actions";
// import assets
import {ReactComponent as CloseIcon} from "../../assets/svg/icon-close.svg";

/**
 *
 * @param {*} props
 * According to the UX design, we would like to show a list of column filters (filter chips) that are in-use above all the column
 * names.
 * @returns
 */
const GridColumnFilterList = (props) => {
    const {filterList, gridName} = props;
    const numberFilterReformat = (singleFilterCondition) => {
        const relationship = singleFilterCondition.type
        let filter = singleFilterCondition.filter
        let filterTo = singleFilterCondition.filterTo
        switch (relationship) {
            case 'equals':
                return `= ${filter}`
            case 'notEqual':
                return `≠ ${filter}`
            case 'lessThan':
                return `< ${filter}`
            case 'lessThanOrEqual':
                return `<= ${filter}`
            case 'greaterThan':
                return `> ${filter}`
            case 'greaterThanOrEqual':
                return `>= ${filter}`
            case 'inRange':
                return `${filter} - ${filterTo}`
            case 'blank':
                return `empty`
            case 'notBlank':
                return `not empty`
            default:
                return ` `
        }
    }
    const dateFilterReformat = (singleFilterCondition) => {
        const relationship = singleFilterCondition.type
        let dateFrom = formatDateIfNotEmpty(singleFilterCondition.dateFrom)
        let dateTo = formatDateIfNotEmpty(singleFilterCondition.dateTo)
        switch (relationship) {
            case 'equals':
                return `= ${dateFrom}`
            case 'notEqual':
                return `≠ ${dateFrom}`
            case 'lessThan':
                return `< ${dateFrom}`
            case 'greaterThan':
                return `> ${dateFrom}`
            case 'inRange':
                return `${dateFrom} - ${dateTo}`
            case 'blank':
                return `empty`
            case 'notBlank':
                return `not empty`
            default:
                return ` `
        }
    }
    const textFilterFormatter = (singleFilterCondition) => {
        const values = singleFilterCondition.values
        let result;

        values.forEach((value, index) => {
            if (index === 0) {
                result = value
            } else if(index<2) {
                result = result + ', ' + value;
            } 
        })
        if(values.length>2){
            result = result + '...  +' + (values.length-2)
        }
        return result
    }
    const logicFormat = (filter, condition1Result, condition2Result) => {
        let operator = filter.operator
        if (operator === "AND") {
            return condition1Result + " & " + condition2Result
        } else if (operator === "OR") {
            return condition1Result + ", " + condition2Result
        } else {
            return condition1Result + " " + condition2Result
        }

    }
    const centralFormatter = (filter) => {
        let operator = filter.operator
        let filterType = filter.filterType
        switch (filterType) {
            case 'number':
                if (operator) {
                    return logicFormat(filter, numberFilterReformat(filter.condition1), numberFilterReformat(filter.condition2))
                } else {
                    return numberFilterReformat(filter)
                }
            case 'date':
                if (operator) {
                    return logicFormat(filter, dateFilterReformat(filter.condition1), dateFilterReformat(filter.condition2))
                } else {
                    return dateFilterReformat(filter)
                }
            default:
                return textFilterFormatter(filter)
        }
    }
    const removeFilter = (filterKey) => {
        let newFilterModel = JSON.parse(JSON.stringify(filterList));
        delete newFilterModel[filterKey]
        props.gridRef.current.api.setFilterModel(newFilterModel);

        props.gridRef.current.api.onFilterChanged();
    }
    return (
        <div className="ag-grid-column-filter-list">
            {Object.values(filterList).map((filter, index) => {
                let filterKey = Object.keys(filterList)[index]
                let result = centralFormatter(filter)
                if (result && (filterKey !== 'documentType' || gridName !== 'upto_documents_overview')) {
                    return (
                        <div key={`filter-chips-column-${index}`} className="ag-grid-column-filter-chip">
                            {centralFormatter(filter)}
                            <CloseIcon className="filter-chips-close-icon" onClick={() => removeFilter(filterKey)}></CloseIcon>
                        </div>
                    )
                } else {
                    return <></>
                }
            })}
        </div>
    )
}

export default GridColumnFilterList
import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";
import {dataCrossTabTransformBillPostionsQuery} from "../../actions/crossTab.actions";

export const billsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBills: builder.query({
            // get all bills/invoices for a customer (not car!)
            query: () => API_FAMILY_CONST.BILLS,
            method: 'GET',

            providesTags: ['Bills'],
            //caching for 10 min
            keepUnusedDataFor: 600
        }),
        getBill: builder.query({
            query: (billId) => API_FAMILY_CONST.BILLS + "/" + billId,
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            // the response from this api also include base64 data of the bill, if available
            providesTags: (result,error, arg) => [{ type: 'BillDetails', id: arg }],
        }),
        getBillPositions: builder.query({
            query: (billNumber) => API_FAMILY_CONST.BILLS + "/" + billNumber + "/positions",
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            providesTags: (result,error, arg) => [{ type: 'BillPositions', id: arg }],
            transformResponse: res => {
                return dataCrossTabTransformBillPostionsQuery(res);
            },
        })
    })
})
export const {
    useGetBillsQuery,
    useGetBillQuery,
    useLazyGetBillQuery,
    useGetBillPositionsQuery,
    usePrefetch
} = billsApiSlice
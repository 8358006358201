
import {createSlice} from "@reduxjs/toolkit";
import {clearSavedAuth, saveToken} from "../../actions/token.actions";
import {STORAGE_CONST} from "../../actions/constants/storage.constants";


export const authSlice = createSlice({
    name: "auth",
    initialState: {
        access_token:undefined,
        refresh_token:undefined,
        isAgentLogin: false,
        info42faEndpoint:undefined,
        isAPIAborted: {},
    },
    reducers: {
        setToken:(state,action)=>{
            const { refresh_token, access_token } = action.payload
            state.access_token=access_token;
            state.refresh_token=refresh_token
        },
        setTokenAndSave:(state,action)=>{
            const { refresh_token, access_token } = action.payload
            state.access_token=access_token;
            state.refresh_token=refresh_token
            // save the token to local storage
            saveToken(access_token,refresh_token)
        },
        logOut:(state, action)=>{
            state.access_token=undefined;
            state.refresh_token=undefined
            state.info42faEndpoint=undefined
            clearSavedAuth()
        },
        setIsAgentLogin:(state, action)=>{
            state.isAgentLogin = action.payload
        },
        setInfo42faEndpoint:(state, action)=>{
            state.info42faEndpoint = action.payload
            localStorage.setItem(STORAGE_CONST.INFO_4_REFRESH_ENDPOINT, JSON.stringify(action.payload));
        },
        addAbortedAPI:(state, action) => {
            state.isAPIAborted[action.payload] = true;
        }

    }
})

export const {setToken, addAbortedAPI, logOut, setTokenAndSave, setIsAgentLogin, setInfo42faEndpoint} = authSlice.actions;
export default authSlice.reducer;
import { useMemo, useRef, useState } from "react";
import moment from "moment";
import DatePickerHandler from "../../../components/DatePickerHandler";
import {useDispatch, useSelector} from "react-redux";
import { setHomePageDates } from "../../../redux/stateSlice/dateSlice";

import '../_style.scss';
import {
    MetricCard,
    MetricCardBody,
    MetricCardHeader,
    MetricCardIcon,
    MetricCardTitle
} from "../../../components/MetricCard";
import { ReactComponent as RoadIcon } from "../../../assets/svg/icon-road.svg";
import { ReactComponent as WindIcon } from "../../../assets/svg/icon-wind.svg";
import { ReactComponent as GasIcon } from "../../../assets/svg/icon-gas.svg";
import { ReactComponent as LightningIcon } from "../../../assets/svg/icon-lightning.svg";
import FormModal from "../../../components/FormModal";
import KmStatusGridTable from "./KmStatusGridTable";

import { DataStatusBadge } from "../index";
import { thousandsSeparator } from "../../../actions/formateNumbers.actions";
import { calculateKmDriven } from "../../../actions/kmDriven.action";
import { EmissionsChart } from "../Charts/EmissionsChart";
import { useGetCo2EmissionsQuery, useGetFleetKmQuery } from "../../../redux/apiSlice/dashboardApiSlice";
import { useGetUserQuery } from "../../../redux/apiSlice/userApiSlice";
import { Skeleton } from "../../../components/Skeleton";
// import UsageChart from "../Charts/UsageChart"; Yue: commented out because chart not in use
import { fuelTypeMapping } from "../../../actions/mapCo2.actions";
import {UsageCard} from "./UsageCard";

export const FleetPerformanceSection = ({ labels }) => {


    const dispatch = useDispatch();
    const selectedDates = useSelector(state => state.date.homePageDates);  // Redux-Store

    const { startDate: selectedStartDate, endDate: selectedEndDate, allTime } = selectedDates || {};
    // either use the date from redux, or call api with YTD
    const startDate = selectedStartDate? moment(selectedStartDate).toDate(): allTime? null:moment().startOf('year').toDate()
    const endDate =selectedEndDate ? moment(selectedEndDate).toDate() : allTime? null:moment(new Date()).toDate()

    // state for modal
    const [isKmModalVisible, setIsKmModalVisible] = useState(false)
    function handleRangeChange(start, end) {
        // Change the dates
        if (start && end) {
            dispatch(setHomePageDates({ startDate: start, endDate: end, allTime: false }));
        } else if (!start && !end) {
            dispatch(setHomePageDates({ startDate: null, endDate: null, allTime: true }));
        }
    }

    const {
        data: userData,
    } = useGetUserQuery()

    const customerId = userData?.customer;


    const {
        data: rawEmissionData,
        isFetching: emissionIsFetching,
        isError: emissionIsError,
        isSuccess: emissionIsSuccess,
    } = useGetCo2EmissionsQuery({
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
    });

    const {
        data: kmData,
        isFetching: kmDataIsFetching,
        isError: kmDataIsError,
        isSuccess: kmDataIsSuccess,
    } = useGetFleetKmQuery({
        customerId: customerId,
        startDt: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDt: endDate ? moment(endDate).format('YYYY-MM-DD') : null
    });

    const {kmDriven,kmDataValid} = calculateKmDriven(kmData)

    // This needs to be stored in a useRef because otherwise we can't set it to false inside the useMemo
    let fueldBasedEmissionDataValid = useRef(true)
    let fuelConsumption = useRef({})
    // This extracts the co2 emissions into a more structured object, useMemo because it's probably pretty computationally
    // expensive and we should avoid unnecessary re-computations.
    const co2EmissionsBasedOnFuel = useMemo(() => {
        if (!rawEmissionData || !rawEmissionData?.length) return {}
        // Reset the fuelConsumption object, otherwise it adds multiples
        fuelConsumption.current = {}
        return rawEmissionData?.map((vehicleEntry) => vehicleEntry?.fuelDataList)?.reduce((acc, obj) => {
            for (const fuelEntry of obj) {
                // If for some reason a fuelEntry has no fuelType, continue to not crash
                if (!fuelEntry.fuelTypeId) continue
                // This does the same as 'if the key does not exist yet, initialize it at 0'
                acc[fuelEntry.fuelTypeId] = acc[fuelEntry.fuelTypeId] ?? 0;
                fuelConsumption.current[fuelEntry.fuelType] = fuelConsumption.current[fuelEntry.fuelType] ?? 0;
                // Check if it has co2 emission value, if it does, add it to the new object, otherwise set valid bool to false
                if (fuelEntry?.co2Emissions) {
                    acc[fuelEntry.fuelTypeId] += fuelEntry.co2Emissions / 1000
                } else {
                    fueldBasedEmissionDataValid.current = false;
                }
                if (fuelEntry?.fuelConsumption) {
                    fuelConsumption.current[fuelEntry.fuelType] += fuelEntry.fuelConsumption;
                }
            }
            // Return the modified object after each reduction
            return acc;
        }, {})
    }, [rawEmissionData])
    const co2EmissionBasedOnWLTP =useMemo(()=>{
        if (!kmData || !kmData?.length) return {}
        let result = {}
        kmData.forEach(car => {
            if(car.fuelType){
                // convert fueltype from the api based on the fuelType mapping
                // Yue: I do not know where to put the "andere" (when fuelTypeMapping[car.fuelType] ==null), so I will
                // skip this element
             if(fuelTypeMapping.hasOwnProperty(car.fuelType) && fuelTypeMapping[car.fuelType]){
                if(fuelTypeMapping[car.fuelType] in result){
                    result[fuelTypeMapping[car.fuelType]]+= (car.co2Emission??0)/ 1000
                }
                else{
                    result[fuelTypeMapping[car.fuelType]]=(car.co2Emission??0)/ 1000
                }
             }
            }
        });
        return result
    },[kmData])
    const totalEmissions = Object.values(co2EmissionBasedOnWLTP).reduce((partialSum, a) => partialSum + a, 0);


    let hideEmissionsChart = emissionIsError || (emissionIsSuccess && (!rawEmissionData || !rawEmissionData.length));
    let hideUsageChart = hideEmissionsChart || kmDataIsError || (kmDataIsSuccess && (!kmData || !kmData.length));

    return (
        <div className="fleet-performance-content">
            <div style={{ width: "fit-content" }} className="highlight-button-when-load">
                <DatePickerHandler
                    isRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleRangeChange}
                    allTime={allTime}
                />
            </div>

            <div className="performance-metrics">
                <MetricCard onClick={() => setIsKmModalVisible(true)} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={kmDataIsError}>
                    <MetricCardHeader>
                        <MetricCardTitle>
                            {labels?.homePage?.metrics?.drivenKms}
                        </MetricCardTitle>
                    </MetricCardHeader>
                    <MetricCardBody>
                        <MetricCardIcon>
                            <RoadIcon width={19} height={19} />
                        </MetricCardIcon>
                        {kmDataIsFetching ? <Skeleton height="25px" width="100%" /> : (thousandsSeparator(Math.round(kmDriven)) || 0) + ' km'}
                    </MetricCardBody>
                    {
                        kmDataIsSuccess && !kmDataIsFetching && <DataStatusBadge labels={labels} isValid={kmDataValid} />
                    }
                </MetricCard>
            </div>

            <FormModal
                style={{ minWidth: "800px" }}
                show={isKmModalVisible}
                onClose={() => setIsKmModalVisible(false)}
                title={<div>
                    {/* Yue: atm there is no way to set date as empty. */}
                    {labels?.homePage?.metrics?.drivenKms} {moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
                </div>}
            >
                {/* Yue: atm there is no way to set them as empty. */}
                <p>{moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}</p>
                <KmStatusGridTable data={kmData}></KmStatusGridTable>
            </FormModal>

            <div className='emission-section'
                 data-chart-count={(hideUsageChart ? 0 : 1) + (hideEmissionsChart ? 0 : 1)}
            >

                <div className='performance-charts' hidden={hideEmissionsChart && hideUsageChart}>

                    <MetricCard
                        hidden={hideEmissionsChart}
                        style={{ width:hideUsageChart ? "100%" :"50%", height:"100%"}}>

                        {emissionIsFetching || kmDataIsFetching ? <Skeleton height="200px" width="100%" /> : <EmissionsChart dataBasedOnWLTP={co2EmissionBasedOnWLTP} dataBasedOnFuel={co2EmissionsBasedOnFuel} labels={labels} />}

                    <MetricCardHeader>
                        <MetricCardTitle style={{overflow:"wrap", wordBreak:"break-word", textWrap:"wrap"}}>
                            {labels?.homePage?.metrics?.totalCo2EmissionsBasedOnWLTP}
                        </MetricCardTitle>
                    </MetricCardHeader>

                        <MetricCardBody>
                            <MetricCardIcon>
                                <WindIcon width={19} height={19} />
                            </MetricCardIcon>
                            {thousandsSeparator(Math.round(totalEmissions * 100) / 100) || 0} kg
                        </MetricCardBody>

                        {
                            emissionIsSuccess && !emissionIsFetching && <DataStatusBadge labels={labels} isValid={fueldBasedEmissionDataValid.current} />
                        }

                    </MetricCard>

                    <MetricCard
                        hidden={hideUsageChart}
                        style={{
                            width: hideEmissionsChart ? "100%" : "50%",
                            height: "100%",
                            justifyContent: "flex-end",
                        }}>

                        {emissionIsFetching || kmDataIsFetching ? <Skeleton height="200px" width="100%"/> :
                            <UsageCard
                                co2Data={rawEmissionData}
                                kmData={kmData}
                                labels={labels}/>}

                        <MetricCardHeader>
                            <MetricCardTitle
                                style={{maxWidth: "50%", overflow: "wrap", wordBreak: "break-word", textWrap: "wrap"}}>
                                {labels?.homePage?.metrics?.usagePer100km}
                            </MetricCardTitle>
                        </MetricCardHeader>


                        {
                            (emissionIsSuccess && !emissionIsFetching && kmDataIsSuccess && !kmDataIsFetching) &&
                            <DataStatusBadge labels={labels} isValid={fueldBasedEmissionDataValid.current && kmDataValid}/>
                        }

                    </MetricCard>

                        {/* Yue: This metric card is not used at the moment, so I am commenting it out. The detailed reason can be found in JIRA AMS-506 */}
                    {/* <MetricCard
                        hidden={hideUsageChart || true}
                        style={{
                            width: hideEmissionsChart ? "100%" : "50%",
                            height: "100%",
                            justifyContent: "space-between"
                        }}>

                        {emissionIsFetching || kmDataIsFetching ? <Skeleton height="200px" width="100%"/> :
                            <UsageChart
                                co2Data={emissionData}
                                kmData={kmData}
                                labels={labels}/>}

                        <MetricCardHeader>
                            <MetricCardTitle
                                style={{maxWidth: "50%", overflow: "wrap", wordBreak: "break-word", textWrap: "wrap"}}>
                                {labels?.homePage?.metrics?.usagePer100km}
                            </MetricCardTitle>
                        </MetricCardHeader>

                        {
                            (emissionIsSuccess && !emissionIsFetching && kmDataIsSuccess && !kmDataIsFetching) &&
                            <DataStatusBadge labels={labels} isValid={fueldBasedEmissionDataValid.current && kmDataValid}/>
                        }

                        <p  className={'consumption-conversion'} hidden={!(emissionIsSuccess && !emissionIsFetching && kmDataIsSuccess && !kmDataIsFetching)}>
                            1 kWh = 0.1098901098901099 l {labels?.homePage?.metrics?.gasoline}
                        </p>
                    </MetricCard> */}

                </div>


                <div
                    className='emission-cards'
                    data-chart-count={(hideUsageChart ? 0 : 1) + (hideEmissionsChart ? 0 : 1)}
                >
                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle>
                                {labels?.fleetWizard?.form?.fuelTypes?.Benzin}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <GasIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.gasoline] ?? 0) + ' l'}
                        </MetricCardBody>
                    </MetricCard>


                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle>
                                {labels?.fleetWizard?.form?.fuelTypes?.Diesel}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <GasIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.diesel] ?? 0) + ' l'}
                        </MetricCardBody>
                    </MetricCard>

                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle> 
                                {labels?.fleetWizard?.form?.fuelTypes?.Elektro}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <LightningIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.electric] ?? 0) + ' kWh'}
                        </MetricCardBody>
                    </MetricCard>
                </div>

            </div>


        </div>

    )
}

// import external
import React from "react";
// import styling
import "./_style.scss"
import LoadingComponent from "../../components/LoadingComponent";


const FallbackPage = (props) => {
    return (
        <div className="content-side content-side-padding">
            <LoadingComponent show={true}></LoadingComponent>
        </div>
    )
}

export default FallbackPage
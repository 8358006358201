import { STORAGE_CONST } from "./constants/storage.constants";
import {checkEncryptedString} from "./encrypt.actions";
import Cookies from 'js-cookie'

//save the token to cookies
export const saveToken = (accessToken, refreshToken) => {
    Cookies.set(STORAGE_CONST.ACCESS_TOKEN, accessToken, { expires: 3 , path: '/' , secure:true})
    Cookies.set(STORAGE_CONST.REFRESH_TOKEN, refreshToken, { expires: 3, path: '/', secure:true })
}


export const saveAgentLoginToken = (value) => {
    Cookies.set(STORAGE_CONST.AGENT_LOGIN_TOKEN, value, { expires: 3, path: '/' , secure:true})
}


//Checks if the agent login token is valid or not (needs to be an encrypted value using access token and our secret key)
// comment out because the function below did not consider the situation with refresh token => new access token => actualDecryptedValue !== accessToken
// export const checkAgentLoginToken = () => {
//     try {
//         const agentLoginToken = localStorage.getItem(LOCALSTORAGE_CONST.AGENT_LOGIN_TOKEN);
//         const accessToken = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.LOCALSTORAGE_KEY))[LOCALSTORAGE_CONST.ACCESS_TOKEN];
//         const actualDecryptedValue = xorDecrypt(agentLoginToken);
//         if(actualDecryptedValue !== accessToken){
//             localStorage.removeItem(LOCALSTORAGE_CONST.AGENT_LOGIN_TOKEN);
//             return false;
//         }
//         return true;
//     }
//     catch (e){
//
//     }
//     return false;
// }

//Checks if the agent login token is valid or not
export const checkAgentLoginToken = () => {
    try {
        const agentLoginToken = Cookies.get(STORAGE_CONST.AGENT_LOGIN_TOKEN)
        if (agentLoginToken) {
            return checkEncryptedString(agentLoginToken);
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}



// Load token from cookies
export const loadToken = () => {
    let accessToken = Cookies.get(STORAGE_CONST.ACCESS_TOKEN)
    let refreshToken = Cookies.get(STORAGE_CONST.REFRESH_TOKEN)

    return { access_token: accessToken, refresh_token: refreshToken};

}


function eraseCookieFromAllPaths(name) {
    // This function will attempt to remove a cookie from all paths.
    var pathBits = window.location.pathname.split('/');
    var pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) !== '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}

export const clearSavedAuth = () => {
   
 
    Cookies.remove(STORAGE_CONST.ACCESS_TOKEN, { path: '/' , secure:true})
    Cookies.remove(STORAGE_CONST.REFRESH_TOKEN, { path: '/' , secure:true})
    Cookies.remove(STORAGE_CONST.AGENT_LOGIN_TOKEN, { path: '/' , secure:true})
    // Yue: why the remove need to be here twice? 
    // unlike localStorage, there is one central localStorage for the site
    // cookies matters where it was created. In previous version of code. There was a time, the set
    // Cookie was set to be path "", therefore, for users, it could be that their cookie is not on the root
    // level, rather /cars, /drivers or etc. Now all cookies should be created on root level, and can be removed
    // with path: "/", but the old ones that are located at "/cars" would not be removed
    // by adding the remove below. There is a chance user's cookies are cleared again
    Cookies.remove(STORAGE_CONST.ACCESS_TOKEN, {path: '', secure:true})
    Cookies.remove(STORAGE_CONST.REFRESH_TOKEN, { path: '', secure:true})
    Cookies.remove(STORAGE_CONST.AGENT_LOGIN_TOKEN, {path: '', secure:true})

    Cookies.remove(STORAGE_CONST.AGENT_LOGIN_DATA, { path: '/' , secure:true})
    Cookies.remove(STORAGE_CONST.AGENT_LOGIN_DATA, { path: '' , secure:true})


    // use a stackoverflow function as backup to clear all tokens
    eraseCookieFromAllPaths(STORAGE_CONST.ACCESS_TOKEN)
    eraseCookieFromAllPaths(STORAGE_CONST.REFRESH_TOKEN)
    eraseCookieFromAllPaths(STORAGE_CONST.AGENT_LOGIN_TOKEN)

    localStorage.removeItem(STORAGE_CONST.INFO_4_REFRESH_ENDPOINT)
}

// import internal styling
import "./_style.scss"
import PropTypes from "prop-types";

const GridTitle = (props) => {
    GridTitle.propTypes = {
        titleText: PropTypes.string,
        subtitleText: PropTypes.string,
        badgeText: PropTypes.string,
    }

    return (
        <div className="grid-title-container">
            <span className="grid-title">
                {props.title}
                <span className="badge-neutral" hidden={!props.badgeText} data-cy="grid-title-row-counter" style={{marginLeft:"1em"}}>
                    {props.badgeText}
                </span>
            </span>
            <span className="grid-subtitle">
                {props.subtitle}
            </span>
        </div>
    )

}

export default GridTitle;
import {API_AUTH_GRANT_TYPE, API_FAMILY_CONST} from "./constants/api.constants";
import {STORAGE_CONST} from "./constants/storage.constants";

export const apiCallGetToken = (reqData = {}, grantType) => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + API_FAMILY_CONST.AUTH
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic " + process.env.REACT_APP_SERVER_CREDENTIALS,
        "accept": "*/*",
    }
    let body = {}
    if (grantType === API_AUTH_GRANT_TYPE.PASSWORD) {
        body = {
            "grant_type": "password",
            "username": reqData["username"],
            "password": reqData["password"],
            "scope": "all"
        }
    } else if (grantType === API_AUTH_GRANT_TYPE.CLIENT_CREDENTIALS) {
        body = {
            "grant_type": "client_credentials",
            "scope": "all"
        }
    }
    let formBody = [];
    for (let property in body) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let requestConfig = {
        method: "POST",
        headers: headers,
        body: formBody
    }
    return fetch(url, requestConfig)
        .then(response => response.json())
        .then(data => {
                if ((data.access_token && data.refresh_token) || (data.access_token && grantType === API_AUTH_GRANT_TYPE.CLIENT_CREDENTIALS)) {
                    return data
                } else {
                    throw new Error(data.error_description)
                }
            }
        )
}

export const apiCallGetAgentToken = ({hash, customer, user}, access_token) => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + API_FAMILY_CONST.AGENT_LOGIN
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token,
        "accept": "*/*",
    }
    let body = {hash, customer, user}
    let body_json = JSON.stringify(body)
    let requestConfig = {
        method: "POST",
        headers: headers,
        body: body_json
    }
    return fetch(url, requestConfig)
        .then(response => response.json())
        .then(data => {
                if (data.access_token && data.refresh_token) {
                    return data
                } else {
                    throw new Error(data.error_description)
                }
            }
        )
}

export const apiCallAgentLogin = ({hash, customer, user}) => {
    // apiCallGetToken if successful, will allow us to call the auth related apis
    return apiCallGetToken({}, API_AUTH_GRANT_TYPE.CLIENT_CREDENTIALS)
        .then(data => {
            // the call below if successful will give us a token that can be used to call any api
            return apiCallGetAgentToken({hash, customer, user}, data.access_token)
        })
}

export const apiCallRefreshToken = (refreshToken) => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + "api-auth-server/oauth/token"
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic " + process.env.REACT_APP_SERVER_CREDENTIALS,
        "accept": "*/*",
    }
    let body = {
        "grant_type": "refresh_token",
        "scope": "all",
        "refresh_token": refreshToken,
    }
    let formBody = [];
    for (let property in body) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let requestConfig = {
        method: "POST",
        headers: headers,
        body: formBody
    }
    return fetch(url, requestConfig)
        .then(response => response.json())
        .then(data => {
                if (data.access_token && data.refresh_token) {
                    return data
                } else {
                    throw new Error(data.error_description)
                }
            }
        )
}
let isRefresh2faTokenInProgress = false;
export const apiCallRefresh2faToken = (refreshToken, userName) => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + "api-auth-server/oauth/refreshToken2Fa"
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic " + process.env.REACT_APP_SERVER_CREDENTIALS,
        "accept": "*/*",
    }
    const info42FA = localStorage.getItem(STORAGE_CONST.INFO_4_REFRESH_ENDPOINT);

    // if we do not have userName used in localStorage, it does not suffice the api requirement. No need to try
    if (info42FA) {
        let body = {
            "grant_type": "refresh_token",
            "refresh_token": refreshToken,
            "client_id": process.env.REACT_APP_CLIENT_ID,
            "client_secret": process.env.REACT_APP_CLIENT_SECRET,
            "user_name": userName
        }
        let formBody = [];
        for (let property in body) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(body[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        let requestConfig = {
            method: "POST",
            headers: headers,
            body: formBody
        }

        if (!isRefresh2faTokenInProgress) {
            isRefresh2faTokenInProgress = true;
            return fetch(url, requestConfig)
                .then(response => {
                        isRefresh2faTokenInProgress = false;
                        return response.json()
                    }
                ).then(data => {
                        if (data.access_token && data.refresh_token) {
                            return data
                        } else {
                            throw new Error(data.error_description)
                        }
                    }
                )
        }else{
            // if there is already one refresh token call in progress
            return "in progress"
        }
    }
    return undefined
}

// import external
import { useLocation, useNavigate } from "react-router-dom";
import Moment from "moment";
import {memo, useCallback, useEffect, useState} from "react";
import { useDispatch } from "react-redux";
// styling
import "./_style.scss"

//assets
import logo from "./../../assets/svg/logo.svg"
import {ReactComponent as PersonIcon} from "../../assets/svg/icon-person.svg";
import {ReactComponent as HomeIcon} from "../../assets/svg/icon-home.svg";
import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as CostIcon} from "../../assets/svg/icon-wallet.svg";
import {ReactComponent as CardIcon} from "../../assets/svg/icon-card.svg";
import {ReactComponent as PaperclipIcon} from "../../assets/svg/paperclip-icon.svg";
import {ReactComponent as ToolIcon} from "../../assets/svg/icon-tool.svg";
import {ReactComponent as TruckIcon} from "../../assets/svg/icon-truck.svg";
import {ReactComponent as FileIcon} from "../../assets/svg/icon-file.svg";


// api
import {setInfo42faEndpoint} from "../../redux/authSlice/authSlice";

import { usePrefetch } from "../../redux/apiSlice/carApiSlice";
import { useGetUserQuery } from "../../redux/apiSlice/userApiSlice";
import { checkAgentLoginToken } from "../../actions/token.actions";
import { setIsAgentLogin } from "../../redux/authSlice/authSlice";
import {useDevCycleClient, useVariableValue} from "@devcycle/react-client-sdk";
import UserAvatar from "./UserAvartar";
import {ROLE_CONST} from "../../actions/constants/role.constants";

//import dokumente from "./../../assets/Icons/Offertunterlagen.svg"
//import schaden from "./../../assets/Icons/Schadenmanagement.svg"

export const costPageKey='cost-api-switch'

const NavBar = memo((props) => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSwitchTab = (divName) => {

        navigate(divName)
    }

    const getActiveTab = () => {
        return location.pathname.split("/")[1].toLowerCase();
    }
    // check if user is using Agent Login


    //Navbar is re-rendered very often so we use this component to regularly check if token is still valid
    let isAgentLogin = checkAgentLoginToken();


    // prefetch all slow pages: fleet and driver

    const prefetchFleet = usePrefetch("getCarsV2")
    const prefetchDrivers = usePrefetch("getDrivers")
    const prefetchFuelCards = usePrefetch("getFuelCards")
    const prefetchBills=usePrefetch("getBills")
    const prefetchDamages=usePrefetch("getDamages")
    const prefetchFleetCosts = usePrefetch("getFleetCostsByCostType")
    const prefetchFleetCostsByCar = usePrefetch("getFleetCostsByCar")
    const prefetchFleetCostsV2 = usePrefetch("getFleetCostsByCostTypeV2")
    const prefetchFleetCostsByCarV2 = usePrefetch("getFleetCostsByCarV2")
    const prefetchDocuments = usePrefetch("getDocuments")
    const prefetchCarInvoicesNoPos = usePrefetch("getCarInvoicesNoPos")


    //Fetch Data for UserAccount Avatar Section 
    const {
        data: user,
        isSuccess,
        error
    } = useGetUserQuery()

    let userMail;
    let displayName;
    let userInitials;

    if (isSuccess) {
        userMail = user.preferred_username;
        // check if the user requires 2fa or not
        let withNo2faRole= user.roles?.some((role) => role.id===185 && role.description.includes("2FA"))
        dispatch(setInfo42faEndpoint({"username":user.preferred_username, "withNo2faRole":withNo2faRole}))
        if(user.given_name && user.family_name){
            displayName = user.given_name + " " + user.family_name;
            userInitials = user.given_name?.at(0).toUpperCase() + user.family_name?.at(0).toUpperCase();
        }
        else if(user.given_name){
            displayName = user.given_name;
            userInitials = user.given_name?.at(0).toUpperCase();
        }
        else if(user.family_name){
            displayName = user.family_name;
            userInitials = user.family_name?.at(0).toUpperCase();
        }
        else {
            displayName = user.email;
            userInitials = user.email?.at(0).toUpperCase();
            userMail = "";
        }

        //avatarData = user.given_name[0].toUpperCase() + user.family_name[0].toUpperCase();
        //userData = user.given_name + user.family_name;
    } else if(error?.status === 403){
        //If the user api returns a 403, it means the user needs to go through 2fa process (so they may not be anywhere other than /login)
        // This was falsely triggering and logging out users right after logging in and doesn't appear to be necessary anymore anyways
        //userLogout()
    }

    // featureFlag
    const [identified, setIdentified] = useState(false)
    const devcycleClient = useDevCycleClient()

    if (user && !identified) {
        const newUser = {
            "user_id": user.sub.toString(),
            customData: {
                'client_id': user.customer
            }
        }
        devcycleClient.identifyUser(newUser)
        setIdentified(true)
    }

    // devcycleClient.identifyUser(newUser).then((variables) => console.log('Updated Variables:', variables))



    const useNewCostApi = useVariableValue(costPageKey, false)
    const hasHomePageFlag = useVariableValue("home-page", false)

    let hasDamageRole= user?.roles?.some(role => role.id === ROLE_CONST.DAMAGE)
    let hasFuelCardRole= user?.roles?.some(role => role.id === ROLE_CONST.FUEL_CARDS)
    useEffect(() => {
        setTimeout(() => {
            prefetchFleet()
            prefetchDrivers()
            //prefetchInvoice()
            prefetchFuelCards()
            prefetchBills()
            // prefetch the cost data for YTD
            if(useNewCostApi){
                prefetchFleetCostsV2({"endDt":Moment().format("YYYY-MM-DD"),"startDt":Moment().startOf('year').format("YYYY-MM-DD")})
                prefetchFleetCostsByCarV2({"endDt":Moment().format("YYYY-MM-DD"),"startDt":Moment().startOf('year').format("YYYY-MM-DD")})
            }else{
                prefetchFleetCosts({"endDt":Moment().format("YYYY-MM-DD"),"startDt":Moment().startOf('year').format("YYYY-MM-DD")})
                prefetchFleetCostsByCar({"endDt":Moment().format("YYYY-MM-DD"),"startDt":Moment().startOf('year').format("YYYY-MM-DD")})
            }
            prefetchDocuments()
            prefetchCarInvoicesNoPos({"vin":"","toDate":Moment().format("YYYY-MM-DD"),"fromDate":Moment().startOf('year').format("YYYY-MM-DD")})

            // if we do not hide the prefetch behind the if check, everytime we click on the navbar, an api call will be made
            // as it failed last time due to no access => no cache => retry
            if (hasDamageRole){
                prefetchDamages()
            }
            if(hasFuelCardRole){
                prefetchFuelCards()
            }
        }, 500)
    })

    // with your root component wrapped in withDevCycleProvider, you can use the useDevCycleClient hook to access the DevCycle client from anywhere in your app

    useEffect(() => {
        if (isAgentLogin) {
            dispatch(setIsAgentLogin(true))
        } else {
            dispatch(setIsAgentLogin(false))
        }
    }, [isAgentLogin, dispatch])


    return (
        <div className="sidebar-component">
            <div className="sidebar-component-content">
                <div className="logo-section"
                     onClick={() => handleSwitchTab("")}
                >
                    <img src={logo} className="logo" alt="axa-upto logo" />
                </div>
                <div className="menu-items-section">
                    <div className={`menu-item-container ${getActiveTab() === "" ? "active" : ""}`}
                         onClick={() => handleSwitchTab("")}
                         hidden={!hasHomePageFlag}
                    >
                        <HomeIcon className="nav-icon" alt="home icon"></HomeIcon>
                        <span>Home</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "cars" || getActiveTab() ===  (hasHomePageFlag ? "cars" : "") ? "active"  : ""}`}
                         onClick={() => handleSwitchTab("cars")}>
                        <CarIcon className="nav-icon" alt="flotte icon"></CarIcon>
                        <span>{props.labels.navigation.pageName.fleet}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab().includes("costs") ? "active" : ""}`}
                                           onClick={() => {
                                               if(useNewCostApi){
                                                   handleSwitchTab("costs-v2?view=costtype")
                                               }else{
                                                   handleSwitchTab("costs?view=costtype")
                                               }
                                           }}>
                        <CostIcon className="nav-icon" alt="cost icon"></CostIcon>
                        <span>{props.labels.navigation.pageName.cost}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "supplierinvoices" ? "active" : ""}`}
                         onClick={() => handleSwitchTab("supplierInvoices")}>
                        <TruckIcon className="nav-icon" alt="truck icon"></TruckIcon>
                        <span style={{wordWrap:"break-word"}}>{props.labels?.navigation?.pageName?.supplierInvoices}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "drivers" ? "active" : ""}`}
                        onClick={() => handleSwitchTab("drivers")}>
                        <PersonIcon className="nav-icon" alt="driver icon"></PersonIcon>
                        <span>{props.labels.navigation.pageName.driver}</span>
                    </div>

                    <div className={`menu-item-container ${getActiveTab() === "fuelcards" ? "active" : ""}`}
                        onClick={() => handleSwitchTab("fuelcards")}>
                        <CardIcon className="nav-icon" alt="card icon"></CardIcon>
                        <span>{props.labels.navigation.pageName.fuelCards}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "bills" ? "active" : ""}`}
                         onClick={() => handleSwitchTab("bills")}>
                        <PaperclipIcon className="nav-icon" alt="paperclip icon"></PaperclipIcon>
                        <span>{props.labels.navigation.pageName.bills}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "documents" ? "active" : ""}`}
                         onClick={() => handleSwitchTab("documents")}
                    >
                        <FileIcon className="nav-icon" alt="file icon"></FileIcon>
                        <span>{props.labels?.navigation?.pageName?.documents}</span>
                    </div>
                    <div className={`menu-item-container ${getActiveTab() === "damages" ? "active" : ""}`}
                                             onClick={() => handleSwitchTab("damages")}
                                             hidden={!hasDamageRole}
                    >
                        <ToolIcon className="nav-icon" alt="tool icon"></ToolIcon>
                        <span>{props.labels?.navigation?.pageName?.damages}</span>
                    </div>

                    {checkAgentLoginToken() ?
                        <span style={{margin: "auto", marginBottom: 0, width: "fit-content", textAlign: "center"}}
                              className="status-tag-negative">AGENT LOGIN USER</span> : null}

                </div>

                {/* User Account Section */}
                {/*eslint-disable-next-line react-hooks/exhaustive-deps*/}
                <UserAvatar onClick={useCallback(() => navigate("account"), [])} userInitials={userInitials}
                            displayName={displayName} userMail={userMail}></UserAvatar>
            </div>
        </div>
    )
})


export default NavBar;



// import style
import './_style.scss'

// import external
import {AgCharts} from "ag-charts-react";
import {formatNumber} from "../../../actions/formateNumbers.actions";
import {sanitizeValues} from "../../../actions/sanitize";
import {chartNumberFormatter} from "../../../actions/magicNumber.actions";

const BillPositionsByCar = (props) => {
    const {data, positionsData} = props;


    let filteredData = [...data];
    //If there are more than 15 cars, sort them by cost, and then only show the 15 with the highest costs
    const keepNTopValue= 15

    // Sort by total amount, so the highest cost cars are at the top
    filteredData.sort((a, b) => {
        return (b.totalAmount) - (a.totalAmount)
    })

    // If there are more than N cars, only show top 15 and add the rest as aggregated "N Others" at bottom
    if(filteredData?.length > keepNTopValue) {
        //const restData = filteredData.slice(keepNTopValue, filteredData.length);
        filteredData = filteredData.slice(0, keepNTopValue);

        /*
        //For OTHERS Bar:

        const restTotal = restData.reduce((acc, curr) => acc + curr.totalAmount, 0);

        // Position Descriptions
        const positions = positionsData.map(position => position.description);

        // Create a new object with the rest of the data
        const restObject = {
            carName: `${restData.length} ${labels?.billsPage?.billsInfo?.others}`,
            licensePlate: "",
            vin: `${restData.length} ${labels?.billsPage?.billsInfo?.others}`,
            totalAmount: restTotal
        }
        // Add Keys for position descriptions with summed values
        positions.forEach(position => {
            restObject[position] = restObject[position] ?? 0

            restData.forEach(car => {
                restObject[position] += car[position] ?? 0
            })
        })
        // Add the rest object to the filtered data
        filteredData.push(restObject)

         */

    }

    const fillColors = [
        '#00006D',
        '#00008F',
        '#1F1F9C',
        '#3D3DAA',
        '#5C5CB7',
        '#241D0C',
        '#4B3E1B',
        '#6E5C28',
        '#8A7333',
        '#A79666',
        '#015A66',
        '#41949F',
        // only the top 12 should be needed, the ones below are just in case we have extra cost types
        '#2f4b7c',
        '#365973',
        '#665191',
        '#a24095',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#ffbe67',
        '#ffabb3',
        '#ffcef2',
        '#ffe0ff',
        '#ffe2b3',
        '#e7ef6d',
        '#caf75d'
    ]


    let series = []
    const highlightStyle = {
        item: {
            fill: '#9FD9B4'
        }
    };

    positionsData.forEach((position, index) => {
        series.push({
            type: 'bar',
            xKey: 'vin',
            xName: 'carName',
            yKey: position.description,
            yName: position.description,
            stacked: true,
            direction: 'horizontal',
            fill: fillColors[index],
            strokeWidth: 0,
            highlightStyle,
            tooltip: {
                renderer: function (params) {
                    params = sanitizeValues(params);
                    return {
                        title: `<div class="custom-tooltip-header">${params?.title}</div> `,
                        content: `
                            <div class="custom-tooltip"> 
                                <div class="custom-tooltip-item highlight">CHF ${formatNumber(params.datum[params.yKey])}</div>
                                <div class="custom-tooltip-item">${params?.datum?.carName ? params?.datum?.carName : ""}</div> 
                                <div class="custom-tooltip-item">${params?.datum?.licensePlate ? params?.datum?.licensePlate : ""}</div> 
                            </div>`
                    }
                }
            },
        })
    })


    const options = {
        data: filteredData,
        series: series,
        axes: [
            {
                type: 'category',
                position: 'left',
                gridLine: {
                    enabled: false,
                },
                line: {
                    width: 0,
                },
                label: {
                    fontFamily: 'Space Grotesk'
                }
            },
            {
                type: 'number',
                position: 'bottom',
                line: {
                    width: 0,
                },
                label : {
                    formatter: chartNumberFormatter,
                    fontFamily: 'Space Grotesk'
                },
                gridLine: {
                    style: [
                        {
                            stroke: '#F2F4F7',
                            lineDash: [0],
                        }
                    ]
                },
            }

        ],
        legend: {
            item: {
                marker: {
                    shape: 'circle',
                    strokeWidth: 0
                },
                label: {
                    color: '#2f3a4b',
                    fontFamily: 'Space Grotesk'
                }
            }
        },
    }





    return (
        <AgCharts options={options} />
    )

}

export default BillPositionsByCar;
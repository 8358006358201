// import external
import React from "react";

// import styling
import "./_style.scss"

//import {ReactComponent as NoContractIcon} from "../../assets/svg/icon-file-question-mark.svg";
// import internal components
import {newStatusCellRenderer} from "../GridHandler/CellRenderers/newStatusCellRenderer";
import FleetWizard from "../FleetWizard";
import {FleetWizardProvider} from "../FleetWizard/FleetWizardContext";
import CarOverviewContent from "./CarOverViewContent";
import {getCarStatus} from "../../actions/magicNumber.actions";

export function determineContractStatusColor(statusId, statusText){
    if (statusId === 1) {
        return newStatusCellRenderer({ value: statusText, color: "positive"});
    }
    // If statusId 0 or 5 (New or Ordered) then blue color
    else if (statusId === 0 || statusId === 5){
        return newStatusCellRenderer({ value: statusText, color: "neutral"});
    }
    // If no contract then grey color to match the Key number card
    else if (statusId ===undefined){
        return newStatusCellRenderer({ value: statusText, color: "grey"});
    }
    // Else red color
    else {
        return newStatusCellRenderer({ value: statusText, color: "negative"});
    }
}

export function determineCarStatusColor(status, languageCode){
    const statusValue = status.replace(/\s/g, '').toLowerCase()
    const statusText = getCarStatus(status, languageCode)
    switch (statusValue) {
        case "lager/parkplatz":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "eingang":
            return newStatusCellRenderer({ value: statusText, color: "yellow"})
        case "inaufbereitung":
            return newStatusCellRenderer({ value: statusText, color: "orange"});
        case "schadenreparatur":
            return newStatusCellRenderer({ value: statusText, color: "negative"});
        case "aktiv":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "verfügbar":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "vermietet":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "ausgeliefert":
            return newStatusCellRenderer({ value: statusText, color: "darkgreen"});
        case "inrücknahme":
            return newStatusCellRenderer({ value: statusText, color: "neutral"});
        case "inaktiv":
            return newStatusCellRenderer({ value: statusText, color: "darkred"});
        case "":
            return null;
        default:
            return newStatusCellRenderer({ value: statusText, color: "grey"});
    }

}


/**
 *
 * @param {labels} props
 * loads data for the car overview and defines what to show
 * @returns
 */
const CarOverview = (props) => {
 

    return (
        <>
            <div className="fleet-header">
                <h1>{props.title}</h1>
                <FleetWizardProvider>
                    <FleetWizard initialTab={"vehicle"} labels={props?.labels}>
                        {props?.labels?.fleetWizard?.titles?.addVehicle}
                    </FleetWizard>
                </FleetWizardProvider>

            </div>
            <CarOverviewContent labels={props?.labels}></CarOverviewContent>
        </>

    )
}

export default CarOverview
import {FormField, FormGroup, FormItem, FormLabel, FormMessage, Input, Select} from "../../Form/Form";
import {Combobox, ComboboxInput, ComboboxItem, ComboboxList} from "../../Form/Combobox/Combobox";
import React, {useContext} from "react";
import {FleetWizardContext} from "../FleetWizardContext";


const VehicleTab = ({createCarForm, createDriverForm, createFuelCardForm, onSubmit, tab, children, setFile, showVehicleRegistration, labels}) => {
    const { state, dispatch } = useContext(FleetWizardContext);

    const { selectedObjects, objects, selectEntries } = state;

    const { selectedOrganisation} = selectedObjects;
    const { organisations } = objects;

    const { vehicleTypes, fuelTypes, countries } = selectEntries;


    if (tab !== 0) return null;
    return (
        <form onSubmit={createCarForm.handleSubmit(onSubmit)} className="form"
              hidden={tab !== 0}>
            <div className="modal-tab-content" data-selected={tab === 0} id={"modal-tab-0"}>
                {/*Error Message*/}
                {children[0]}
                <FormField
                    control={createCarForm.control}
                    name="organisation"
                    render={({field, error}) => (
                        <FormItem hidden={organisations?.length === 1 || organisations?.length === 0}>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.organisation} *
                            </FormLabel>

                            <Combobox
                                selected={selectedOrganisation?.id}
                                onSelect={(id) => {
                                    const organisation = organisations.find(organisation => organisation?.id === id);
                                    dispatch({
                                        type: 'UPDATE_SELECTED_ORGANISATION',
                                        payload: organisation,
                                    });
                                    createCarForm.setValue("organisation", organisation?.id || null);
                                    createDriverForm.setValue("parentCustomer", organisation?.id || null)
                                    createFuelCardForm.setValue("companyData", organisation || {
                                        id: null,
                                        customerId: null,
                                    });
                                    // Yue: the UI does not automatically update after setting a value. So to make the red warning disappear, we need to trigger once
                                    createCarForm.trigger(["organisation"])
                                    createDriverForm.trigger(["parentCustomer"])
                                    createFuelCardForm.trigger(["companyData.customerId", "companyData"])

                                }}
                            >
                                <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                               selectedValue={`${selectedOrganisation?.companyName}`}
                                               disabled={organisations?.length === 1 || organisations?.length === 0}
                                               data-invalid={!!error?.message}
                                />
                                <ComboboxList
                                    noResults={<ComboboxItem
                                        disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                >
                                    {organisations?.map(organisation => (<ComboboxItem key={organisation?.id}
                                                                                       value={organisation?.id}>
                                        {`${organisation?.companyName}`}
                                    </ComboboxItem>))}
                                </ComboboxList>
                            </Combobox>

                            <FormMessage message={error?.message}/>

                        </FormItem>)}
                />

                <FormField
                    control={createCarForm.control}
                    name="registrationCountry"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.fleetWizard?.form?.approvedIn}
                        </FormLabel>
                        <Select
                            options={countries}
                            {...field}
                            {...error}
                        />
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />

                <FormField
                    control={createCarForm.control}
                    name="vin"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.carsPage?.carInfo.VIN} *
                        </FormLabel>
                        <Input placeholder="WBYK283920189393" {...field} {...error} maxLength={17} type="text"/>
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />


                <FormGroup>
                    <FormField
                        control={createCarForm.control}
                        name="licencePlate"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo.plateNumber} *
                            </FormLabel>
                            <Input placeholder="ZH 392021" {...field} {...error} type="text"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormField
                        control={createCarForm.control}
                        name="licencePlateStartDate"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.licensePlateStartDate} *
                            </FormLabel>
                            <Input {...field} {...error} type="date"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                </FormGroup>


                <FormGroup>

                    <FormField
                        control={createCarForm.control}
                        name="brand"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo.brand} *
                            </FormLabel>
                            <Input placeholder="BMW" {...field} {...error} type="text"/>
                            {/*<FormDescription>This is a form description for Brand</FormDescription>*/}
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                    <FormField
                        control={createCarForm.control}
                        name="type"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo.type} *
                            </FormLabel>
                            <Input placeholder="X5" {...field} {...error} type="text"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                </FormGroup>

                <FormField
                    control={createCarForm.control}
                    name="model"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.carsPage?.carInfo?.model}
                        </FormLabel>
                        <Input placeholder="M-Sport" {...field} {...error} type="text"/>
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />


                <FormField
                    control={createCarForm.control}
                    name="vehicleType"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.carsPage?.carInfo?.carPurpose}
                        </FormLabel>

                        <Select
                            options={vehicleTypes}
                            {...field}
                            {...error}
                        />

                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />

                <FormGroup>

                    <FormField
                        control={createCarForm.control}
                        name="serialNumber"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo?.serialNumber}
                            </FormLabel>
                            <Input placeholder="" {...field} {...error} type="text"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                    <FormField
                        control={createCarForm.control}
                        name="typeCertificationNumber"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.carsPage?.carInfo?.typeCertificationNumber}
                            </FormLabel>
                            <Input placeholder="" {...field} {...error} type="text"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                </FormGroup>


                <FormField
                    control={createCarForm.control}
                    name="fuelType"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.carsPage?.carInfo?.fuelType}
                        </FormLabel>
                        <Select
                            options={fuelTypes}
                            {...field}
                            {...error}
                        />
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />

                <FormGroup>

                    <FormField
                        control={createCarForm.control}
                        name="mileage"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.mileage}


                            </FormLabel>
                            <Input placeholder="20000" {...field} {...error} type="number"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormField
                        control={createCarForm.control}
                        name="mileageDate"
                        render={({field, error}) => {
                            const mileageValue = createCarForm.watch("mileage");
                            const mileageDateMandatory = mileageValue !== "" && mileageValue !== null;

                            let actualError;

                            if (mileageDateMandatory) actualError = error;
                            else actualError = {}

                            return (<FormItem>
                                <FormLabel {...actualError} >
                                    {labels?.fleetWizard?.form?.mileageDate} {mileageDateMandatory ? " *" : ""}

                                </FormLabel>
                                <Input {...field} {...actualError} type="date"/>
                                <FormMessage message={actualError?.message}/>
                            </FormItem>)
                        }
                        }
                    />

                </FormGroup>

                <FormField
                    control={createCarForm.control}
                    name="registrationDate"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.fleetWizard?.form?.registrationDate}
                        </FormLabel>
                        <Input {...field} {...error} type="date"/>
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />

                {
                    showVehicleRegistration && (
                        <FormField
                            control={createCarForm.control}
                            name="vehicleRegistrationFile"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error?.type} >
                                    {labels?.fleetWizard?.form?.vehicleRegistration}
                                </FormLabel>
                                <Input {...field} {...error?.type} type="file" onFileChange={(file) => setFile(file)}
                                       placeholder={labels?.otherElements?.dragAndDrop}/>
                                <FormMessage message={error?.type?.message}/>

                            </FormItem>)}
                        />
                    )
                }


            </div>
            {/*Form Buttons*/}
            {children[1]}

        </form>
    )
}

export default VehicleTab;
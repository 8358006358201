import { determineLanguage } from "./language.actions";
import { loadToken, checkAgentLoginToken } from "./token.actions";

function decodeJWT(token) {
    // Decodes access token, so that we can extract info such as user id from it
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// Returns GA Event relevant information from access token
export function getUserData() {

    let tokens = loadToken();

    if (!tokens || !tokens.access_token) return null;

    const tokenData = decodeJWT(tokens.access_token);

    return {
        user_id: Number(tokenData?.sub),
        user_pers_id: Number(tokenData.customer)
    }
}

export function triggerEvent(event_name, element_interaction, element_response, element_type, element_name) {
    const isAgentLogin = checkAgentLoginToken();

    if (!window.dataLayer) return;

    let user = getUserData();

    let baseEvent = 'generic_event';
    switch (event_name) {
        case 'login':
            baseEvent = 'login';
            break;
        case 'logout':
            baseEvent = 'logout';
            break;
        case 'file_download':
            baseEvent = 'file_download';
            break;
        default:
            break;
    };

    const baseBody = {
        event: baseEvent,
        event_name: event_name,
        page_name: getPageName(),
        page_lang: determineLanguage().toLowerCase(),
        hrco: 200, // Always 200 for login
        int_visit: isAgentLogin,
        user_id: user ? user.user_id : null,
        user_pers_id: user ? user.user_pers_id : null,
        element_interaction: element_interaction,
        element_response: element_response,
        element_type: element_type,
        element_name: element_name

    }

    window.dataLayer.push({ ...baseBody })
}

export function triggerLoginEvent(type, accessToken, isSuccess) {
    if (!window.dataLayer) return;

    let user = getUserData(accessToken);

    const baseBody = {
        event: 'login',
        event_name: 'login',
        login_type: type,
        page_name: getPageName(),
        page_lang: determineLanguage().toLowerCase(),
        hrco: 200, // Always 200 for login
        vlst: false,  // Always false since user was logged out
        int_visit: type === "agentLogin",
        user_id: user ? user.user_id : null,
        user_pers_id: user ? user.user_pers_id : null,
        element_interaction: 'submit',
        element_response: isSuccess ? 'success' : 'error',
        element_type: 'login-form',
        element_name: 'primary-action-button'

    }

    window.dataLayer.push({ ...baseBody })

}

// Used to check if user is on 404 page
export let allPages = ["", "cars", "drivers", "supplierinvoices", "fuelcards", "costs", "costs-v2", "documents", "bills", "damages", "account", "login", "agentlogin"]
allPages = allPages.map(page => {
    return page.toLocaleLowerCase()
})
export function getPageName() {
    let pathName = document.location.pathname;
    let search = document.location.search;
    let splitPath = pathName.split("/")
    splitPath = splitPath.map(trunk => {
        return trunk.toLocaleLowerCase()
    })
    // If we are on cars page
    if (splitPath[1] === "cars") {
        // If user is on car details page
        if (splitPath.length > 2) {
            // Replace VIN with literal "VIN"
            splitPath[2] = "VIN"
        }
    }
    else if (["drivers", "supplierinvoices", "fuelcards", "bills", "damages"].includes(splitPath[1])) {
        // If user is on a driver/fuelcard/bill/damages detail page
        if (splitPath.length > 2) {
            // Replace driver/fuelcard/bill/damage ID with literal "ID"
            splitPath[2] = "ID"
        }
        if (splitPath[1] === "bills") {
            // If we are on bill detail page, delete the search, since that's just used to carry over bill id
            search = ""
        }
    }
    else if (splitPath[1] === "agentlogin") {
        let splitSearch = search?.split("&")
        let user = splitSearch[2]?.split("=")[1]
        let ITusers = process.env.REACT_APP_IT_AAX_USERNAME?.split(",")
        if(ITusers?.includes(user)){
            search="?IT_User"
        }else{
            search ="?NONE_IT_User"
        }
    }
    // If user is on a page not known to us, we assume that its 404
    else if (!allPages.includes(splitPath[1])) {
        return "/404"
    }

    pathName = splitPath.join("/")
    return pathName + search;

}
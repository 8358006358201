//this file is created to formate number values

//Helper Function to add a thousand Separator:
export function thousandsSeparator(number) {
    if(number){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }else{
        return ""
    }
  }

export function formatNumber(number) {
    if(number || number === 0) {
        return thousandsSeparator(number.toFixed(2))
    }
    else {
        return ""
    }
  }

export function convertAndFormat(number){
    if (typeof number ==="string"){
        return formatNumber(number(number))
    }else if(typeof number ==="number"){
        return formatNumber(number)
    }
    return number
}

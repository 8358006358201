import React from "react";
import PropTypes from "prop-types";


export const FormControlButtons = (props) => {
    FormControlButtons.propTypes = {
        labels:PropTypes.object.isRequired,
        isLoading:PropTypes.bool,
        onCancel:PropTypes.func.isRequired,
        onSubmit:PropTypes.func,
    }
    let {isLoading, onCancel, onSubmit, labels, ...rest} = props
    return (<div className="form-buttons" {...rest}>
        <button className="btn btn-light" type="button" style={{width: "100%"}} disabled={isLoading}
                onClick={() => onCancel && onCancel()}
        >
            {labels?.otherElements?.cancel}
        </button>
        {isLoading ? (<button className="btn btn-primary btn-disabled" style={{width: "100%"}}>
            <div className="dot-falling-container">
                <div className="dot-falling"/>
            </div>
        </button>) : (
            <button
                className="btn btn-primary"
                type="submit"
                style={{width: "100%"}}
                onClick={() => onSubmit && onSubmit()}
            >
            {labels?.otherElements?.save}
        </button>)}
    </div>)
}
// import external
import React from "react";
import PropTypes from "prop-types";

// import styling
import "./_style.scss"

// import internal
import {ReactComponent as QuestionMarkIcon} from "../../assets/svg/icon-questionmark.svg";
import IconWithTooltip from "../IconWithTooltip";


const CostInfoCard=(props )=>{

    CostInfoCard.propTypes = {
        title: PropTypes.string, // Title shown at top
        number: PropTypes.any,
        unit: PropTypes.string,
        tooltipText: PropTypes.string, // If text is passed on to this prop, a question mark icon will be shown next to the title

        //Children is used to display the footer of the card, so all that is below the number
    }
    const {children, unit, title, number, interactiveIcon, ...other} = props;

    return(
        <div className="info-card" {...other} style={{whiteSpace:"nowrap",flexGrow:1,flexShrink:1}}>
            <div className="cost-info-card-header">
                <span className="title">{title}</span>
                {props.tooltipText ?
                    (<IconWithTooltip
                        icon={<QuestionMarkIcon className="tooltip-icon" alt="information icon"/>}
                        toolTipText={props.tooltipText}
                    />)

                    : null}
            </div>
            <div className="cost-info-card-content">
                {unit} {number}
            </div>
            <>
                {children}
            </>
        </div>
    )
}

export default CostInfoCard
// import external
import React from "react";
// import internal

// internal styling
import "./_style.scss";
import {useSelector} from "react-redux";
import BillsOverview from "../../components/BillsOverview";

/**
 * @param {*} props (not used)
 * renders the page to show all invoice positions and invoices for a fleet
 * @returns
 */
const MyBillsPage = (props) => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    return (
        <div className="content-side content-side-padding">
            <div className="section-card">
                <BillsOverview labels={currentLabels} title={currentLabels?.billsPage?.titles?.billsOverview}></BillsOverview>
            </div>
        </div>
    )
}

export default MyBillsPage
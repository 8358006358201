import {ReactComponent as LogoutIcon} from "../../../assets/svg/icon-logout.svg";
import {memo} from "react";
import {logOut} from "../../../redux/authSlice/authSlice";
import {apiSlice} from "../../../redux/apiSlice/apiSlice";
import {useDispatch} from "react-redux";
import {triggerEvent} from "../../../actions/eventTracking.actions";
import {useNavigate} from "react-router-dom";

const UserAvatar = memo((props)=>{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const userLogout = () => {
        triggerEvent('logout', 'click', 'success', 'logout-button', 'leave-button');
        dispatch(logOut());
        dispatch(apiSlice.util.resetApiState());
        navigate("/login")
    }
    return(
        <div className={"useraccount-container"}>
            <div className="avatar-label-group">
                <div className="roundavatar" onClick={props.onClick}>
                    {props.userInitials}
                </div>
                <div className="user-details" onClick={props.onClick}>
                    <div className="userName">{props.displayName}</div>
                    <div className="userMail">{props.userMail}</div>
                </div>
                <div className="logout-section" onClick={() => userLogout()}>
                    <LogoutIcon className="logout-icon" alt="logout icon" />
                </div>
            </div>
        </div>
        )
    })
export default UserAvatar;
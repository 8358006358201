import "./_style.scss"
import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as PersonIcon} from "../../assets/svg/icon-person.svg";
//import {ReactComponent as CostIcon} from "../../assets/svg/icon-wallet.svg";
import {ReactComponent as TruckIcon} from "../../assets/svg/icon-truck.svg";
import {ReactComponent as CardIcon} from "../../assets/svg/icon-card.svg";
import {ReactComponent as ToolIcon} from "../../assets/svg/icon-tool.svg";

import {ReactComponent as PaperclipIcon} from "../../assets/svg/paperclip-icon.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useSelector} from "react-redux";

const BreadcrumbNav = () => {
    const currentLabels = useSelector(state => state.labels.currentLabels)
    const location = useLocation();
    const navigate = useNavigate();
    const separatorSymbol = ">"; //Symbol that is used to separate breadcrumbs

    //Store path parts in an array to know which tabs to show, know which labels to use and handle navigation
    const [tabs] = useState(location.pathname.split("/")) //Example: ["", "cars", "123456"] when on Vehicle Detail page
    const handleSwitchTab = (index) => {
        let location = ""
        for (let i = 1; i <= index; i++) {
            location += "/" + tabs[i]
        }
        navigate(location)
    }

    //TODO: Some translations are inaccurate to mockups because translation doesn't exist
    //(for example uses "Kostenübersicht" instead of "Flottenkosten Übersicht")

    //Used to get the correct terms out of the url ("/cars" gives us "Fleet", "Fleet Overview" etc.)
    const BREADCRUMBS_TEXT = {
        cars: [
            currentLabels?.carsPage?.titles?.fleetOverview,
            currentLabels?.carsPage?.titles?.vehicleDetails,
        ],
        supplierInvoices: [
            currentLabels?.supplierInvoicesPage?.titles?.supplierInvoicesOverview,
            currentLabels?.supplierInvoicesPage?.titles?.supplierInvoicesDetails,

        ],
        drivers: [
            currentLabels?.driverPage?.titles?.driversOverview,
            currentLabels?.driverPage?.titles?.driverData,
        ],
        fuelcards: [
            currentLabels?.fuelCardPage?.titles?.fuelCardsOverview,
            currentLabels?.fuelCardPage?.titles?.fuelCardsDetails
        ],
        bills: [
            currentLabels?.billsPage?.titles?.billsOverviewShort,
            currentLabels?.billsPage?.titles?.billPositions
        ],
        damages: [
            currentLabels?.damagesPage?.titles?.damagesOverview,
            currentLabels?.damagesPage?.titles?.damageDetails
        ]
    }
    //Used to get correct icons out of url
    const BREADCRUMBS_ICONS = {
        cars: <CarIcon className="breadcrumb-icon" alt="fleet icon"/>,
        supplierInvoices: <TruckIcon className="breadcrumb-icon" alt="truck icon"/>,
        drivers: <PersonIcon className="breadcrumb-icon" alt="drivers icon"/>,
        fuelcards: <CardIcon className="breadcrumb-icon" alt="fuelcards icon"/>,
        bills: <PaperclipIcon className="breadcrumb-icon" alt="bills icon"/>,
        damages: <ToolIcon className="breadcrumb-icon no-margin" alt="tool icon"/>
    }

    let breadcrumbs = <></>;

    const Separator = () => {
        return (
            <span className="breadcrumb-separator"> {separatorSymbol}</span>
        )
    }
    breadcrumbs = tabs.map((tab, index) => {
        //For first tab we show icon and label
        if(index === 1) return (
            <>
                <span className={"text with-icon"} onClick={() => {handleSwitchTab(index)}}>
                    {BREADCRUMBS_ICONS[tabs[1]]}
                    {BREADCRUMBS_TEXT[tabs[1]][0]}
                </span>
                <Separator/>
            </>
        )
        //For intermediate tabs we only show label and change tab on click
        //For last tab (when index===tabs.length-1) we show highlighted label and reload page on click
        if(index > 1) return (
            <>
                <span className={index === tabs.length-1 ? "text active" : "text"}
                      onClick={() => {index === tabs.length-1 ? window.location.reload(false) : handleSwitchTab(index)}}>
                    {BREADCRUMBS_TEXT[tabs[1]][index-1]}
                </span>
                {index !== tabs.length-1 && <Separator/>}
            </>

        )
        return <></>
    })

    return (
        <div className="breadcrumb-wrapper">
            <div className="breadcrumb-container">
                {breadcrumbs}
            </div>
        </div>
    )
}

export default BreadcrumbNav
// import external
import React from "react";
import {useNavigate} from "react-router-dom";
// import styling
import "./_style.scss"
import {useIsDevCycleInitialized} from "@devcycle/react-client-sdk";
import FallbackPage from "../FallbackPage";
// import assets


const NotFoundPage = (props) => {
    const navigate = useNavigate()
    const dvcReady = useIsDevCycleInitialized()
    if (!dvcReady) return <FallbackPage></FallbackPage>
    return (
        <div className="content-side">
            <div className="not-found-page-content content-side-padding">
                <div className="content-wrapper">
                        <h1>Oops, Verfahren?</h1>
                        <h2>Diese Page existiert nicht</h2>
                        <button className={"btn btn-primary"} onClick={() => navigate("/")}>Zurück zur Startseite
                        </button>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage
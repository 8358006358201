//import styling
import "./_style.scss"

// import external
import React, {useState} from "react";
import { usePopper } from 'react-popper';
import PropTypes from "prop-types";

const IconWithTooltip = (props) => {

    IconWithTooltip.propTypes = {
        toolTipText: PropTypes.string.isRequired, //Text that will be shown in tooltip window
        icon: PropTypes.object.isRequired, //Icon that will be shown (where hovering over it will open tooltip)
    }

    // The reference object that the popper will attach to
    const [iconElement,setIconElement] = useState(null);
    // The popper element that will be shown
    const [tooltipElement,setTooltipElement] = useState(null);
    // The arrow element that will point to the reference object
    const [arrowElement, setArrowElement] = useState(null);


    // Connecting the popper to the reference/popper/arrow objects and applying settings
    const {styles, attributes} = usePopper(iconElement, tooltipElement, {
        placement: 'top',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'arrow',
                options: {
                    padding: 5,
                    element: arrowElement,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                }
            }
        ]
    })



    return (
        <>
            <div ref={setIconElement} onMouseEnter={() => tooltipElement.setAttribute('data-show', '')} onMouseLeave={() => tooltipElement.removeAttribute('data-show')} style={{width:"min-content"}} >
                {props.icon}
            </div>

            <div id="tooltip" className='tooltip-content' ref={setTooltipElement} style={styles.popper} {...attributes.popper}>
                {props.toolTipText}

                <div id="arrow"
                     data-popper-arrow={true} ref={setArrowElement} {...attributes.arrow} style={styles.arrow} ></div>
            </div>
        </>



    )

}

export default IconWithTooltip
// this file is created to use the Moment library to format date.
// the issue with directly use moment(dateString).format() is that, when dateString is null
// moment automatically return the date of today, which is not desired.


import Moment from 'moment';
import moment from "moment";


/**
 * reformat date strings to "YYYY-MM-DDTHH:MM:SS+02:00" or return "" if empty
 * @param dateString
 * @returns {string}
 */
export const formatDateToDatetimeIfNotEmpty = (dateString) => {
    if (dateString && typeof dateString==="string") {
        let date=Moment(dateString).format('YYYY-MM-DD')
        return `${date}T00:00:00+00:00`;
    } else {
        return ""
    }
}

/**
 * reformat date strings to "DD.MM.YYYY" or return "" if empty
 * @param dateString
 * @returns {string}
 */
export const formatDateIfNotEmpty = (dateString) => {
    if (dateString && typeof dateString==="string") {
        return Moment(dateString).format('DD.MM.YYYY');
    } else {
        return ""
    }
}
/**
 * reformat date strings to "YYYY-MM-DD" (required format for html input (type=date) value) or return "" if empty
 * @param dateString
 * @returns {string}
 */
export const formatDateHtmlFormat = (dateString) => {
    if (dateString && typeof dateString==="string") {
        // html input (type = date) element has a value attribute, which is a string representing
        // the date entered in the input. This string has to be of "YYYY-MM-DD" format.
        return Moment(dateString).format('YYYY-MM-DD');
    } else {
        return ""
    }
}

/**
 * check if the endDate property of an object (e.g. car/driver) invalidate the object.
 * The object is NOT obsolete if there is no endDate, or endDate in the future.
 * @param dateString
 * @returns {boolean}
 */
export const isObjectNotObsolete = (dateString) => {
    let endDate = dateString
    let diffToDay = moment().diff(endDate, 'days')

    if (!endDate || diffToDay <= 0) {
        return true
    } else {
        return false
    }
}

/**
 * Determine the newest (with the most recent start date) entry that is also valid (no endDate or in the future)
 * @param {Object[]} entries - an array of entries such as logs of car plates
 * @param {string} [fromDateFieldName="fromDate"] - in each entry within the array, a field should describe the start date of the entry
 * @param {string} [endDateFieldName="endDate"] - in each entry within the array, a field should describe the end date of the entry
 * @returns {number|null}  the index number of the newest valid entry. Null if there is no entry or no valid entry.
 */
export const findNewestValidEntry = (entries, fromDateFieldName="fromDate", endDateFieldName="endDate") => {
    // Entries is an array. We need to identify the newest one (most recent fromDate)
    let newestEntryId = 0;
    // check if the entries are an empty array, in this case, we return null
    if (entries){
        entries.forEach((entry, i) => {
            let currentNewestFromDate = moment(entries[newestEntryId][fromDateFieldName])
            let currentFromDate = moment(entry[fromDateFieldName])
            let diff = currentNewestFromDate.diff(currentFromDate, 'days')
            if (diff < 0) {
                newestEntryId = i
            }
        })
        // if there is the newest entry we test if it is valid
        if(entries[newestEntryId]){
            let endDateNewestEntry = entries[newestEntryId][endDateFieldName]
            return isObjectNotObsolete(endDateNewestEntry)?newestEntryId:null
        }
    }
    return null
}
// This file is needed for the EXTERNAL FILTER

//Used to define what value the GridHandler should filter by (necessary because the active status id is in different
//locations for the different data types)
export const FilterTypes = {
    ALL: 'ALL',
    VEHICLES_ONLY_ACTIVE: 'VEHICLES_ONLY_ACTIVE',
    DRIVERS_ONLY_ACTIVE: 'DRIVERS_ONLY_ACTIVE',
    FUELCARDS_ONLY_ACTIVE: 'FUELCARDS_ONLY_ACTIVE',
    BILLS_ONLY_OPEN: 'BILLS_ONLY_OPEN',
    BILLS_ONLY_CLOSED: 'BILLS_ONLY_CLOSED',
    DOCUMENTS_NO_CAR_ONLY: 'DOCUMENTS_NO_CAR_ONLY',
}

export const dataPassesFilter = (rowData, filterType) => {
    //Checks if the required condition is fulfilled for the given filter type
    //(e.g. for the fuel card row to be shown under the "only active" filter, data.status === 1 must be true)
    switch (filterType) {
        case FilterTypes.ALL:
            return true;
        case FilterTypes.VEHICLES_ONLY_ACTIVE:
            return rowData.contractInformation?.contractStatusId === 1
        case FilterTypes.DRIVERS_ONLY_ACTIVE:
            return rowData.childCustomer?.status?.id === 1
        case FilterTypes.FUELCARDS_ONLY_ACTIVE:
            return rowData.status === 1;
        case FilterTypes.BILLS_ONLY_OPEN:
            return rowData.statusId === 0;
        case FilterTypes.BILLS_ONLY_CLOSED:
            return rowData.statusId === 1;
        case FilterTypes.DOCUMENTS_NO_CAR_ONLY:
            return !rowData.carData;
        default:
            return true;
    }
}
export default FilterTypes;
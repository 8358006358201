import React from "react";
import PropTypes from "prop-types";
import "./_style.scss";
import CSSTransition from "react-transition-group/cjs/CSSTransition";


const LoadingComponent = (props) => {
    const { show } = props;
    return (
        <CSSTransition in={show} timeout={500} classNames="fade-loader" mountOnEnter={true} unmountOnExit={true}>
            <div className={"loading-component"}>
                <div className="loading-rotate">
                    <svg id="ulogo" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                        <g className="rotator rotate-group">
                            <path
                                className="rotator rotate-form"
                                d="M39,16V30a9,9,0,0,1-18,0V8H13V30a17,17,0,0,0,34,0V8A8,8,0,0,0,39,16Z"
                            />
                        </g>
                        <circle className="rotate-circle" cx="30" cy="30" r="5" />
                    </svg>
                </div>
            </div>
        </CSSTransition>
    );
};

LoadingComponent.defaultProps = {
    show: false,
};

LoadingComponent.propTypes = {
    show: PropTypes.bool,
};

export default LoadingComponent;
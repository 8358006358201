import PropTypes from "prop-types";
import React, {useState} from "react";
// import assets

// import styling
import "./_style.scss"
import {FormControlButtons} from "../../../components/Form/FormControlButtons";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {editCarDataSchema} from "../../../components/Form/FormSchemas";
import {ErrorMessage} from "../../../components/Form/FormMessages";
import {useUpdateCarMutation} from "../../../redux/apiSlice/carApiSlice";
import {FormField, FormItem, FormMessage, Input, Select} from "../../../components/Form/Form";
import {formatDateIfNotEmpty} from "../../../actions/date.actions";
import {useVariableValue} from "@devcycle/react-client-sdk";
import {carStatusLabels, getCarStatus} from "../../../actions/magicNumber.actions";
import {useSelector} from "react-redux";

// Get labels for countries to display in select registrationCountry
export const countriesChoices = (labels) => {
    let countryList = labels?.fleetWizard?.form?.countries
    return Object.keys(countryList)?.map((country) => (

        {
            value: country,
            label: labels?.fleetWizard?.form?.countries[country]
        }
    ))
}

export const vehicleTypesChoices = (labels) => {
    let vehicleTypesList = labels?.fleetWizard?.form?.vehicleTypes
    return Object.keys(vehicleTypesList)?.map((vehicleType) => (
        {
            value: vehicleType,
            label: labels?.fleetWizard?.form?.vehicleTypes[vehicleType]
        }
    ))
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const carStatusChoices = (languageCode) => {
    const carStatusList = Object.values(carStatusLabels["de"]);
    return carStatusList.map((status) => (
        {
            value: status,
            label: capitalizeFirstLetter(getCarStatus(status, languageCode))
        }
    ))
}


// Get the labels for the color and vehicle types, used in the select fields
export const colorLicensePlateChoices = (labels) => {
    let colorLicensePlate = labels?.fleetWizard?.form?.plateColor
    return Object.keys(colorLicensePlate)?.map((color) => (
        {
            value: color,
            label: labels?.fleetWizard?.form?.plateColor[color]
        }
    ))
}
// Get the labels for the transmission Type, used in the select fields
export const transmissionTypesChoices = (labels) => {
    let transmissionTypeList = labels?.fleetWizard?.form?.transmissionType
    return Object.keys(transmissionTypeList)?.map((transmission) => (
        {
            value: transmission,
            label: labels?.fleetWizard?.form?.transmissionType[transmission]
        }
    ))
}

// Get the labels for the fuel types and vehicle types, used in the select fields
export const fuelTypesChoices = (labels) => {
    let fuelTypesList = labels?.fleetWizard?.form?.fuelTypes
    return Object.keys(fuelTypesList)?.map((fuelType) => (
        {
            value: fuelType,
            label: labels?.fleetWizard?.form?.fuelTypes[fuelType]
        }
    ))
}

const SelectField = (props) => {
    const {formControl, options, isEditing, name} = props
    return (
        <>
            {(
                <FormField
                    disabled={!isEditing}
                    control={formControl}
                    name={name}
                    render={({field, error}) => (<FormItem>
                        <Select
                            disabled={!isEditing}
                            options={options}
                            {...field}
                            {...error}
                        />
                        <FormMessage message={error?.message}/>
                    </FormItem>)}
                />
            )
            }

        </>
    )
}

export const Section = (infoFields, data, labels, isEditing, formControl, dataTypeInfoMapping) => {
    let selectFieldsKeys = ["registrationCountry", "vehicleType", "plateColor", "transmissionType", "fuelType", "status"]

    const languageCode = useSelector(state => state.labels?.currentLanguage) || "DE"

    return (
        <>
            {Object.keys(infoFields).map((key, i) => {
                return <div className={"data-field form-item-container"}>
                    <span className="form-item-title">{labels?.carsPage?.carInfo[key]}</span>
                    {key === "registrationCountry" ? <SelectField formControl={formControl} labels={labels}
                                                                  isEditing={isEditing}
                                                                  name={key}
                                                                  options={countriesChoices(labels)}></SelectField> : <></>}
                    {key === "vehicleType" ? <SelectField formControl={formControl} labels={labels}
                                                          isEditing={isEditing}
                                                          name={key}
                                                          options={vehicleTypesChoices(labels)}></SelectField> : <></>}
                    {key === "plateColor" ? <SelectField formControl={formControl} labels={labels}
                                                         isEditing={isEditing}
                                                         name={key}
                                                         options={colorLicensePlateChoices(labels)}></SelectField> : <></>}
                    {key === "transmissionType" ? <SelectField formControl={formControl} labels={labels}
                                                               isEditing={isEditing}
                                                               name={key}
                                                               options={transmissionTypesChoices(labels)}></SelectField> : <></>}
                    {key === "fuelType" ? <SelectField formControl={formControl} labels={labels}
                                                       isEditing={isEditing}
                                                       name={key}
                                                       options={fuelTypesChoices(labels)}></SelectField> : <></>}
                    {key === "status" ? <SelectField formControl={formControl} labels={labels}
                                                       isEditing={isEditing}
                                                       name={key}
                                                       options={carStatusChoices(languageCode)}></SelectField> : <></>}
                    {!selectFieldsKeys.includes(key) ? (isEditing ?
                        <FormField
                            control={formControl}
                            name={key}
                            render={({field, error}) => (<FormItem>
                                <Input {...field} {...error} popperPlacement={"auto"}
                                       defaultValue={infoFields[key](data) ? infoFields[key](data) : undefined}
                                       type={dataTypeInfoMapping[key]}
                                />
                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />
                        :
                        <span className="form-item-text">
                            {dataTypeInfoMapping[key] === "date" ? (
                                    formatDateIfNotEmpty(infoFields[key](data)).length ? formatDateIfNotEmpty(infoFields[key](data)) : "-"
                                ) :
                                infoFields[key](data) ?? "-"}
                            </span>) : <></>}
                </div>
            })}
        </>
    )
}


const CarMasterDataTab = (props) => {
    CarMasterDataTab.propTypes = {
        data: PropTypes.object.isRequired,
        isAgent: PropTypes.bool.isRequired,
        hasFeatureFlag: PropTypes.bool.isRequired,
        labels: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
        customerId: PropTypes.number,
    }

    // Controls visibility of "status"
    const showSpecificCarStatus = useVariableValue("specific-car-status", false)

    let {data, isAgent, hasFeatureFlag, labels, hasRole, customerId} = props
    let allowEdit = hasRole && (isAgent || hasFeatureFlag)
    
    const [isEditing, setIsEditing] = useState(false)
    const [updateCar, {
        isLoading: isUpdatingCar, isError: carIsError, reset: resetCar
    }] = useUpdateCarMutation();
    const allgemeinCarInfoPath = {
        // Allgemein
        "VIN": (data) => data?.vin,
        "brand": (data) => data?.brand,
        "type": (data) => data?.type,
        "model": (data) => data?.model, // ...
        "color": (data) => data?.color, // ...,
        "status": (data) => data?.status,
        "engineDisplacement": (data) => data?.additionalCarInformation?.baseData?.engineDisplacement,//1888
        "powerInKw": (data) => data?.additionalCarInformation?.baseData?.powerInKw, // number, unit is kw
        "transmissionType": (data) => data?.additionalCarInformation?.baseData?.transmissionType,
        "emissionStandard": (data) => data?.additionalCarInformation?.baseData?.emissionStandard,
        "co2GramPerKm": (data) => data?.additionalCarInformation?.baseData?.co2emission,//
        "plateColor": (data) => data?.additionalCarInformation?.baseData?.colorLicensePlate
    }

    // If user does not have this feature flag, don't show that field
    if (!showSpecificCarStatus) {
        delete allgemeinCarInfoPath["status"]
    }


    const permissionCarInfoPath = {
        // Permission
        "registrationCountry": (data) => data?.additionalCarInformation?.baseData?.registrationCountry,
        "vehicleType": (data) => data?.additionalCarInformation?.baseData?.vehicleType,
        "serialNumber": (data) => data?.additionalCarInformation?.baseData?.serialNumber,
        "typeCertificationNumber": (data) => data?.additionalCarInformation?.baseData?.typeCertificationNumber,
        "yearBuild": (data) => data?.additionalCarInformation?.baseData?.yearBuilt,
        "licencePlateFirstDate": (data) => data?.additionalCarInformation?.baseData?.registrationDate,
        "lastMotorVehicleInspectionDate": (data) => data?.additionalCarInformation?.baseData?.lastMfk,
    }

    const fuelCarInfoPath = {
        // fuel
        "fuelType": (data) => data?.additionalCarInformation?.fuelData?.fuelType, // a select field,
        "tankSize": (data) => data?.additionalCarInformation?.fuelData?.fuelSize,
        "combinedFuelConsumptionLitrePer100Km": (data) => data?.additionalCarInformation?.fuelData?.fuelConsumptionCombined,
        "cityFuelConsumptionLitrePer100Km": (data) => data?.additionalCarInformation?.fuelData?.fuelConsumptionCity,
        "highwayFuelConsumptionLitrePer100Km": (data) => data?.additionalCarInformation?.fuelData?.fuelConsumptionCountry,
        "batteryCapaNet": (data) => data?.additionalCarInformation?.fuelData?.batteryCapacity,
        "combinedEnergyConsumptionKwhPer100Km": (data) => data?.additionalCarInformation?.fuelData?.electricityConsumptionCombined,
        "cityEnergyConsumptionKwhPer100Km": (data) => data?.additionalCarInformation?.fuelData?.electricityConsumptionCity,
        "highwayEnergyConsumptionKwhPer100Km": (data) => data?.additionalCarInformation?.fuelData?.electricityConsumptionCountry,
    }

    const payloadCarInfoPath = {
        // payload
        "emptyWight": (data) => data?.additionalCarInformation?.weightData?.curbWeight,
        "payloadCapa": (data) => data?.additionalCarInformation?.weightData?.payloadWeight,
        "totalWeight": (data) => data?.additionalCarInformation?.weightData?.totalWeight,
        "towingCapa": (data) => data?.additionalCarInformation?.weightData?.towingCapacity,
        "numberOfSeats": (data) => data?.additionalCarInformation?.weightData?.numberOfSeats,
    }
    const mergedInfoPath = {
        ...allgemeinCarInfoPath,
        ...permissionCarInfoPath,
        ...fuelCarInfoPath,
        ...payloadCarInfoPath
    }
    let defaultValue = {}
    const constructDefaultValueForForm = (data, infoPath) => {
        Object.keys(infoPath).forEach(key => {
            defaultValue[key] = infoPath[key](data)
        });

        return defaultValue
    }
    if (data) {
        defaultValue = constructDefaultValueForForm(data, mergedInfoPath)
    }

    const createCarDataForm = useForm({
        resolver: zodResolver(editCarDataSchema(labels?.fleetWizard?.form)),
        defaultValues: {
            ...defaultValue
        }
    })
    // a mapping for the input field that are not text, e.g. dates
    const dataTypeInfoMapping = {
        "licencePlateFirstDate": "date",
        "lastMotorVehicleInspectionDate": "date",
        "VIN": "disabled",
        "registrationCountry": "select",
        "vehicleType": "select",
        "plateColor": "select",
        "transmissionType": "select",
        "powerInKw":"number",
        "co2GramPerKm":"number",
        "yearBuild":"number",
        "tankSize":"number",
        "combinedFuelConsumptionLitrePer100Km":"number",
        "cityFuelConsumptionLitrePer100Km":"number",
        "highwayFuelConsumptionLitrePer100Km":"number",
        "batteryCapaNet":"number",
        "combinedEnergyConsumptionKwhPer100Km":"number",
        "cityEnergyConsumptionKwhPer100Km":"number",
        "highwayEnergyConsumptionKwhPer100Km":"number",
        "emptyWight":"number",
        "payloadCapa":"number",
        "totalWeight":"number",
        "towingCapa":"number",
        "numberOfSeats":"number",
    }
    const cancelEdit = () => {
        resetCar()
        setIsEditing(false)
        createCarDataForm.reset() // Reset fields
    }

    const handleEdit = () => {
        setIsEditing(true)
        resetCar()
    }

    const onSubmitCarDataForm = (formData) => {
        const body = {
            baseData: {
                carId: data.additionalCarInformation.baseData.carId,
                vin: data.additionalCarInformation.baseData.vin,
                status: formData?.status,
                type: formData.type,
                brand: formData.brand,
                model: formData.model,
                color: formData.color,
            },
            fleetInformation: {
                customerId: customerId,
            },
            additionalCarInformation: {
                baseData: {
                    carId: data.additionalCarInformation.baseData.carId,
                    vin: data.additionalCarInformation.baseData.vin,
                    status: formData?.status,
                    type: formData.type,
                    brand: formData.brand,
                    model: formData.model,
                    engineDisplacement: formData.engineDisplacement,
                    powerInKw: formData.powerInKw,
                    transmissionType: formData.transmissionType,
                    emissionStandard: formData.emissionStandard,
                    co2emission: formData.co2GramPerKm,
                    colorLicensePlate: formData.plateColor,
                    registrationCountry: formData.registrationCountry,
                    vehicleType: formData.vehicleType,
                    serialNumber: formData.stammnummer,
                    yearBuilt: formData.yearBuild,
                    registrationDate: formData.licencePlateFirstDate,
                    lastMfk: formData.lastMotorVehicleInspectionDate,
                },
                licensePlates: [...data.additionalCarInformation.licensePlates],
                fuelData: {
                    fuelType: formData.fuelType,
                    fuelSize: formData.tankSize,
                    fuelConsumptionCombined: formData.combinedFuelConsumptionLitrePer100Km,
                    fuelConsumptionCity: formData.cityFuelConsumptionLitrePer100Km,
                    fuelConsumptionCountry: formData.highwayFuelConsumptionLitrePer100Km,
                    batteryCapacity: formData.batteryCapaNet,
                    electricityConsumptionCombined: formData.combinedEnergyConsumptionKwhPer100Km,
                    electricityConsumptionCity: formData.cityEnergyConsumptionKwhPer100Km,
                    electricityConsumptionCountry: formData.highwayEnergyConsumptionKwhPer100Km,
                },
                weightData: {
                    curbWeight: formData.emptyWight,
                    payloadWeight: formData.payloadCapa,
                    totalWeight: formData.totalWeight,
                    towingCapacity: formData.towingCapa,
                    numberOfSeats: formData.numberOfSeats,
                },
            },
        }
        updateCar(body).then((response) => {
            if (response.data && response.data?.carId) {
                createCarDataForm.setValue("carId", data?.carId);
                createCarDataForm.trigger(["carId"])
                setIsEditing(false)
            }
        })
    }


    return (
        <div className="car-details-content">
            <div className="blank-card">
                <form className="car-all-fields-form" onSubmit={createCarDataForm.handleSubmit(onSubmitCarDataForm)}>
                    <ErrorMessage labels={labels} isHidden={!carIsError}/>
                    <fieldset readOnly>
                        <div className="form-sections">
                            {allowEdit && (isEditing ? <div className={"form-buttons-wrapper"}>
                                <FormControlButtons onCancel={cancelEdit} labels={labels}
                                                    isLoading={isUpdatingCar}></FormControlButtons>
                            </div> : <div className={"form-buttons-wrapper"}>
                                <div className={"form-buttons"}>
                                    <button className="btn btn-primary sm" type="button" style={{width: "100%"}}
                                            onClick={() => handleEdit()}>
                                        {props.labels?.otherElements?.edit}
                                    </button>
                                </div>
                            </div>)
                            }
                            {/*-----------base data----------*/}
                            {<>
                                <div className="header">
                                    <span className="header-text">
                                        {labels?.carsPage.titles?.baseData}
                                    </span>
                                </div>
                                <div className={"data-section-wrapper"}>
                                    {Section(allgemeinCarInfoPath, data, labels, isEditing, createCarDataForm.control, dataTypeInfoMapping)}
                                </div>
                            </>}
                            {/*-----------zulassung-----------*/}
                            {
                                <>
                                    <div className="header">
                                        <span className="header-text">
                                            {labels?.carsPage.titles.permit}
                                        </span>
                                    </div>
                                    <div className={"data-section-wrapper"}>
                                        {Section(permissionCarInfoPath, data, labels, isEditing, createCarDataForm.control, dataTypeInfoMapping)}
                                    </div>
                                </>
                            }
                            {/*fuel section starts here*/}
                            {
                                <>
                                    <div className="header">
                                        <span className="header-text">
                                            {labels?.carsPage.titles.fuelAndConsumption}
                                        </span>
                                    </div>
                                    <div>
                                        <div className={"data-section-wrapper"}>
                                            {Section(fuelCarInfoPath, data, labels, isEditing, createCarDataForm.control, dataTypeInfoMapping)}
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                <>
                                    <div className="header">
                                        <span className="header-text">
                                            {labels?.carsPage.titles.payload}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="data-section-wrapper">
                                            {Section(payloadCarInfoPath, data, labels, isEditing, createCarDataForm.control, dataTypeInfoMapping)}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )

}

export default CarMasterDataTab;
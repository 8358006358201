import {apiSlice} from "./apiSlice";
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

function getFinancingParams(args) {
    let params = {}
    params.includeDocuments = args?.includeDocuments || false
    if (args?.endEndDate) {
        params.endEndDate = args.endEndDate
    }
    if (args?.vin) {
        params.vin = args.vin
    }
    return params
}

// Delete any params with value 'null' from the object
function parseParams(args) {
    let params = structuredClone(args)

    for (const key of Object.keys(args)) {
        if (!params[key]) delete params[key]
    }
    return params;
}


export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFleetKm: builder.query({
            query: (arg) => ({
                url: API_FAMILY_CONST.DASHBOARD + "/fleetKm",
                method: 'GET',
                params: parseParams(arg),
                keepUnusedDataFor: 600
            })
        }),
        getCarFinancing: builder.query({
            // Get car financing information grouped by car
            query: (arg) => ({
                url: API_FAMILY_CONST.DASHBOARD + "/carFinancing",
                method: 'GET',
                params: getFinancingParams(arg),
                keepUnusedDataFor: 600
            })
        }),
        getActiveCars: builder.query({
            // Get the active cars
            query: () => API_FAMILY_CONST.DASHBOARD + "/activeCars",
            method: 'GET',

            keepUnusedDataFor: 600
        }),
        getCo2Emissions: builder.query({
            // Get the co2 emissions grouped by car and fuelType
            query: (arg) => ({
                url: API_FAMILY_CONST.DASHBOARD + "/co2Emissions",
                method: 'GET',
                params: parseParams(arg),
                keepUnusedDataFor: 600
            })
        }),
        getDriverCount: builder.query({
            // Get the current drivers and fleet managers
            query: () => API_FAMILY_CONST.DASHBOARD + "/driverCount",
            method: 'GET',

            keepUnusedDataFor: 600
        }),
    })
})

export const {
    useGetFleetKmQuery,
    useGetCarFinancingQuery,
    useGetActiveCarsQuery,
    useGetCo2EmissionsQuery,
    useGetDriverCountQuery,
    usePrefetch
} = dashboardApiSlice

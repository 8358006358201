import {
    FormDescription,
    FormField,
    FormGroup,
    FormItem,
    FormLabel,
    FormMessage,
    FormValue,
    Input, Select
} from "../../Form/Form";
import {Combobox, ComboboxInput, ComboboxItem, ComboboxList} from "../../Form/Combobox/Combobox";
import {formatDateIfNotEmpty} from "../../../actions/date.actions";
import {getLanguageName} from "../../../actions/magicNumber.actions";
import React, {useContext} from "react";
import {FleetWizardContext} from "../FleetWizardContext";
import {useLazyGetDriversOfCarQuery} from "../../../redux/apiSlice/driverApiSlice";


export function getCurrentDriver(drivers) {
    let currentDriver = null;
    let latestFromDate = new Date(0);

    drivers?.forEach(driver => {
        const fromDate = new Date(driver?.startDt);

        if (fromDate > latestFromDate && !driver?.endDt) {
            latestFromDate = fromDate;
            // set current driver to a driver id eg. 1800004700
            currentDriver = driver;
        }
    });

    return currentDriver;
}

const DriverTab = ({createDriverForm, createFuelCardForm, createCarForm, onSubmit, children, tab, labels}) => {
    const { state, dispatch } = useContext(FleetWizardContext);

    const { selectedObjects, objects, selectEntries } = state;

    const { selectedOrganisation, selectedDriver, selectedCar, selectedCarCurrentDriver } = selectedObjects;
    const {cars, drivers, organisations, user } = objects;

    const { languages, salutations, phoneTypes } = selectEntries;

    const [getDriversOfCar] = useLazyGetDriversOfCarQuery();
 
    // Yue: comment setCurrentDriver out because useEffect below is no longer needed 
    // useEffect is no longer needed because I think by saving selectedCarCurrentDriver in the state, we do not need useEffect
    // to re-populate the field anymore 
    // async function setCurrentDriver(car) {

    //     Get drivers, and then replace the simple driver array of a car, with the detailed one (containing ids)
    //     await getDriversOfCar({
    //             organisationCustomerId: car?.contractInformation?.contractOrganisation?.id || user?.customer,
    //             carId: car?.additionalCarInformation?.baseData?.carId,
    //         }
    //     ).then((res) => {
    //         if(res.data.length>0){
    //             dispatch({
    //                 type: 'UPDATE_SELECTED_CAR',
    //                 payload: {
    //                     ...car,
    //                     additionalCarInformation: {
    //                         ...car?.additionalCarInformation,
    //                         drivers: res?.data,
    //                     }
    //                 },
    //             });
    //             let currrentDriver= getCurrentDriver(res?.data)
    //             dispatch({
    //                 type: 'UPDATE_SELECTED_CAR_CURRENT_DRIVER',
    //                 payload: {currrentDriver}
    //             });
    //         }
          
    //     })

    // }

    // This is only used for the annoying bug where if user closes modal by clicking outside,
    // the currentDriverOfCar would be reset to Null, so this basically just sets it again
    // useEffect( () => {
    //     if (selectedCarCurrentDriver || !selectedCar) {
    //         return;
    //     }


    //     // Need "wrapper" function for the async function since useEffect causes crash otherwise
    //     async function handle() {
    //         await setCurrentDriver(selectedCar)
    //     }

    //     handle()

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedCar])


    async function onSelectCar(id) {
        const car = cars.find(car => car?.additionalCarInformation?.baseData?.carId === id);
        dispatch({
            type: 'UPDATE_SELECTED_CAR',
            payload: car,
        });
        createDriverForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "");
        createFuelCardForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "");
        createDriverForm.trigger(["carId"])
        createDriverForm.setValue("endPreviousConnection", false);
        createDriverForm.setValue("endPreviousConnectionDate", null);

        // If car is unselected, we don't need to try and get drivers
        if (!id) {
            let currrentDriver= null
            dispatch({
                type: 'UPDATE_SELECTED_CAR_CURRENT_DRIVER',
                payload: {currrentDriver}
            });
            return;
        }
        // Get drivers, and then replace the simple driver array of a car, with the detailed one (containing ids)
        await getDriversOfCar({
                organisationCustomerId: car?.contractInformation?.contractOrganisation?.id || user?.customer,
                carId: car?.additionalCarInformation?.baseData?.carId,
            }
        ).then((res) => {
            dispatch({
                type: 'UPDATE_SELECTED_CAR',
                payload: {
                    ...car,
                    additionalCarInformation: {
                        ...car?.additionalCarInformation,
                        drivers: res?.data,
                    }
                },
            });
            let currentDriver=getCurrentDriver(res?.data)
            dispatch({
                type: 'UPDATE_SELECTED_CAR_CURRENT_DRIVER',
                payload: {currentDriver}
            });
        })
    }


    function onSelectDriver(id) {
        const driver = drivers.find(driver => driver?.childCustomer?.id === id);
        dispatch({
            type: 'UPDATE_SELECTED_DRIVER',
            payload: driver || null,
        });
        createDriverForm.setValue("parentCustomer", driver?.parentCustomer || "");
        createDriverForm.setValue("childCustomer.firstName", driver?.childCustomer?.firstName || "");
        createDriverForm.setValue("childCustomer.lastName", driver?.childCustomer?.lastName || "");
        createDriverForm.setValue("childCustomer.email", driver?.childCustomer?.email || "");
        createDriverForm.setValue("childCustomer.birthday", driver?.childCustomer?.birthday || null);
        createDriverForm.setValue("childCustomer.salutation.id", driver?.childCustomer?.salutation.id ?? null);
        createDriverForm.setValue("childCustomer.phone.countryCode", driver?.childCustomer?.phone?.countryCode || "");
        createDriverForm.setValue("childCustomer.phone.cli", driver?.childCustomer?.phone?.cli || "");
        createDriverForm.setValue("childCustomer.phone.areaCode", driver?.childCustomer?.phone?.areaCode || "");
        createDriverForm.setValue("childCustomer.phone.phone", driver?.childCustomer?.phone?.phone || "");
        createDriverForm.setValue("childCustomer.phone.phoneType.id", driver?.childCustomer?.phone?.phoneType?.id || "");
        createDriverForm.setValue("childCustomer.mainAddress.street", driver?.childCustomer?.mainAddress?.street || "");
        createDriverForm.setValue("childCustomer.mainAddress.zip", driver?.childCustomer?.mainAddress?.zip || "");
        createDriverForm.setValue("childCustomer.mainAddress.city", driver?.childCustomer?.mainAddress?.city || "");
        createDriverForm.setValue("childCustomer.mainAddress.houseNumber", driver?.childCustomer?.mainAddress?.houseNumber || "");

        //createDriverForm.setValue("childCustomer.mainAddress.country.id", driver?.childCustomer?.mainAddress?.country?.id || null);

        // Trigger  all fields we changed above (to validate)
        createDriverForm.trigger(["parentCustomer", "childCustomer.firstName", "childCustomer.lastName", "childCustomer.email", "childCustomer.salutation.id", "childCustomer.birthday", "childCustomer.phone.phoneType.id", "childCustomer.phone.countryCode", "childCustomer.phone.cli", "childCustomer.phone.areaCode", "childCustomer.phone.phone", "childCustomer.mainAddress.street", "childCustomer.mainAddress.zip", "childCustomer.mainAddress.city", "childCustomer.mainAddress.houseNumber", "childCustomer.mainAddress.country.id"])
    }

    function handleExistingDriverCheckbox(e) {
        createDriverForm.setValue("existingDriver", e.target.checked);
        // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)
        if (!e.target.checked) {
            dispatch({
                type: 'UPDATE_SELECTED_DRIVER',
                payload: null,
            });

            createDriverForm.setValue("parentCustomer", null);
            createDriverForm.setValue("childCustomer.firstName", "");
            createDriverForm.setValue("childCustomer.lastName", "");
            createDriverForm.setValue("childCustomer.email", "");
            createDriverForm.setValue("childCustomer.birthday", null);
            createDriverForm.setValue("childCustomer.salutation.id", null);
            createDriverForm.setValue("childCustomer.phone.countryCode",  "");
            createDriverForm.setValue("childCustomer.phone.cli", "");
            createDriverForm.setValue("childCustomer.phone.areaCode",  "");
            createDriverForm.setValue("childCustomer.phone.phone", "");
            createDriverForm.setValue("childCustomer.phone.phoneType.id", "");
            createDriverForm.setValue("childCustomer.mainAddress.street", "");
            createDriverForm.setValue("childCustomer.mainAddress.zip",  "");
            createDriverForm.setValue("childCustomer.mainAddress.city", "");
            createDriverForm.setValue("childCustomer.mainAddress.houseNumber",  "");
        }
    }
     if (tab !== 1) return null
 

    return (
        <form onSubmit={createDriverForm.handleSubmit(onSubmit)} className="form">

            <div className="modal-tab-content" data-selected={tab === 1} id={"modal-tab-1"}>
                {/*Error Message*/}
                {children[0]}


                <FormField
                    control={createDriverForm.control}
                    name="carId"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.fleetWizard?.form?.selectCarTitle} *
                        </FormLabel>

                        <Combobox
                            selected={selectedCar?.additionalCarInformation?.baseData?.carId}
                            onSelect={onSelectCar}
                        >
                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                           selectedValue={`${selectedCar?.brand} ${(selectedCar?.type || "")} (${selectedCar?.additionalCarInformation?.baseData?.vin})`}
                                           data-invalid={!!error?.message}
                            />
                            <ComboboxList
                                noResults={<ComboboxItem
                                    disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                            >
                                {cars?.map(car => (<ComboboxItem key={car?.additionalCarInformation?.baseData?.carId}
                                                                 value={car?.additionalCarInformation?.baseData?.carId}>
                                    {`${car?.brand} ${(car?.type || "")} (${car?.additionalCarInformation?.baseData?.vin})`}
                                </ComboboxItem>))}
                            </ComboboxList>
                        </Combobox>

                        <FormMessage message={error?.message}/>

                        <FormDescription>
                            {labels?.fleetWizard?.form?.selectCarDescription}
                        </FormDescription>

                    </FormItem>)}
                />


                <FormField
                    control={createDriverForm.control}
                    name="existingDriver"
                    render={({field, error}) => (<FormItem>
                        <FormLabel linkedCheckBoxId="existingDriverCheckbox" {...error} >
                            {labels?.fleetWizard?.form?.existingDriver}
                        </FormLabel>
                        <Input id="existingDriverCheckbox" {...field} {...error}
                               checked={createDriverForm.watch("existingDriver")}
                               type="checkbox"
                               onChange={handleExistingDriverCheckbox}
                        />
                    </FormItem>)}
                />

                {createDriverForm.watch("existingDriver") && (
                    <FormField
                        control={createDriverForm.control}
                        name="parentCustomer"
                        render={({field, error}) => (
                            <FormItem>
                                <Combobox
                                    selected={selectedDriver?.childCustomer?.id}
                                    onSelect={onSelectDriver}
                                >
                                    <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                   selectedValue={selectedDriver?.childCustomer?.firstName + " " + selectedDriver?.childCustomer?.lastName}
                                                   data-invalid={!!error?.message}
                                    />
                                    <ComboboxList
                                        noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                    >
                                        {drivers?.map(driver => (<ComboboxItem key={driver?.childCustomer?.id}
                                                                               value={driver?.childCustomer?.id}>
                                            {driver?.childCustomer?.firstName} {driver?.childCustomer?.lastName}
                                        </ComboboxItem>))}
                                    </ComboboxList>
                                </Combobox>

                                <FormMessage message={error?.message}/>
                                <FormDescription>
                                    {labels?.fleetWizard?.form?.selectDriverDescription}
                                </FormDescription>

                            </FormItem>)}
                    />
                )}


                {(!createDriverForm.watch("existingDriver") || (createDriverForm.watch("existingDriver") && selectedDriver)) && (<>

                    <FormField
                        control={createDriverForm.control}
                        name="parentCustomer"
                        render={({field, error}) => (
                            <FormItem
                                hidden={(organisations?.length === 1 || organisations?.length === 0) && !createDriverForm.watch("existingDriver")}
                            >
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.companyName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.parentCompanyName}
                                        </FormValue>
                                    ) : (
                                        <Combobox
                                            selected={selectedOrganisation?.id}
                                            onSelect={(id) => {
                                                const organisation = organisations.find(organisation => organisation?.id === id);
                                                dispatch({
                                                    type: 'UPDATE_SELECTED_ORGANISATION',
                                                    payload: organisation,
                                                });
                                                createDriverForm.setValue("parentCustomer", organisation?.id || null);
                                                createCarForm.setValue("organisation", organisation?.id || null);
                                                createFuelCardForm.setValue("companyData", organisation || {
                                                    id: null,
                                                    customerId: null,
                                                });

                                                createFuelCardForm.trigger(["companyData.customerId", "companyData"])
                                                createDriverForm.trigger(["parentCustomer"])

                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={`${selectedOrganisation?.companyName}`}
                                                           disabled={organisations?.length === 1 || organisations?.length === 0}
                                                           data-invalid={!!error?.message}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem
                                                    disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {organisations?.map(organisation => (
                                                    <ComboboxItem key={organisation?.id}
                                                                  value={organisation?.id}>
                                                        {`${organisation?.companyName}`}
                                                    </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>
                                    )
                                }

                                <FormMessage message={error?.message}/>

                            </FormItem>)}
                    />


                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.firstName"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.firstName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.firstName || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="Max"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.lastName"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.familyName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.lastName || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="Muster"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                                <FormMessage message={error?.message}/>

                            </FormItem>)}
                        />
                    </FormGroup>

                    <FormGroup>

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.email"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.email} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.email || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="max.muster@mail.com"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.salutation.id"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.salutation} *

                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {salutations.find((salutation) => salutation.id === selectedDriver?.childCustomer?.salutation?.id)?.description || "-"}
                                        </FormValue>
                                    ) : (
                                        <Select
                                            options={salutations.filter((salutation) => salutation.id !== 0).map((salutation) => ({
                                                value: salutation.id,
                                                label: salutation.description
                                            }))}
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                    </FormGroup>


                    <FormGroup>

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.birthday"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.birthday}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {formatDateIfNotEmpty(selectedDriver?.childCustomer?.birthday) || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            {...field} {...error} type="date"
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.language.id"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.language}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {getLanguageName(selectedDriver?.childCustomer?.language?.id, labels) || "-"}
                                        </FormValue>
                                    ) : (
                                        <Select
                                            options={languages.map((language) => ({
                                                value: language.id,
                                                label: language.description
                                            }))}
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />
                    </FormGroup>


                    {
                        createDriverForm.watch("existingDriver") ? (
                            <FormItem>
                                <FormLabel>
                                    {labels?.driverPage?.driverInfo?.phone}
                                </FormLabel>
                                <FormValue>
                                    {selectedDriver?.childCustomer?.phone?.phone || "-"}
                                </FormValue>
                            </FormItem>

                        ) : (
                            <FormItem>
                                <FormLabel>
                                    {labels?.driverPage?.driverInfo?.phone}
                                </FormLabel>
                                <FormGroup>
                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.phoneType.id"
                                        render={({field, error}) => (<FormItem style={{width: "250px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Select options={phoneTypes} {...field} {...error}
                                                    disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />


                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.countryCode"
                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="+41"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.areaCode"
                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="79" type="number"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.cli"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="123 45 67" type="number"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                </FormGroup>
                            </FormItem>


                        )
                    }


                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.street"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.street} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.street || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                            <FormMessage message={error?.message}/>
                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.houseNumber"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.houseNumber}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.houseNumber || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />


                    </FormGroup>

                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.zip"

                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.zip} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.zip || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error} maxLength={5}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />

                                            <FormMessage message={error?.message}/>

                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.city"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.city2} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.city || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />

                                            <FormMessage message={error?.message}/>
                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                    </FormGroup>
                    
                    <FormField
                        control={createDriverForm.control}
                        name="startDt"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.driverStartDate} *
                            </FormLabel>
                            <Input {...field} {...error} type="date"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />

                    <FormField
                        control={createDriverForm.control}
                        name="endPreviousConnection"
                        render={({field, error}) => (
                            <FormItem hidden={!createDriverForm.watch("existingDriver") || !selectedCarCurrentDriver}>
                                <FormLabel linkedCheckBoxId="disconnectPreviousCheckbox" {...error} >
                                    {labels?.fleetWizard?.form?.endPreviousDriverConnection}
                                </FormLabel>
                                <Input id="disconnectPreviousCheckbox" {...field} {...error}
                                       checked={createDriverForm.watch("existingDriver")}
                                       type="checkbox"
                                       onChange={(e) => {
                                           createDriverForm.setValue("endPreviousConnection", e.target.checked)

                                           let startDt = createDriverForm.watch("startDt");
                                           // If startDt filled, use startDate otherwise today
                                           let endPreviousConnectionDate = startDt ? new Date(startDt) : new Date();
                                           // Set the end date to the date before
                                           endPreviousConnectionDate.setDate(endPreviousConnectionDate.getDate() - 1);
                                           createDriverForm.setValue("endPreviousConnectionDate", endPreviousConnectionDate);


                                       }}
                                />
                            </FormItem>
                        )}
                    />

                    {
                        createDriverForm.watch("existingDriver") && createDriverForm.watch("endPreviousConnection") &&selectedCarCurrentDriver?
                    <FormField
                        control={createDriverForm.control}
                        name="endPreviousConnectionDate"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.terminationDate} *
                            </FormLabel>
                            {/* Yue: the default date of the end driver connection date is 1. prio A: the day before the new relation starts 2. prio B: yesterday */}
                            <Input  {...field} {...error} type="date"  />
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />:<></>}


                </>)}
            </div>
            {/*Form Control Buttons*/}
            {children[1]}

        </form>
    )
}

export default DriverTab;
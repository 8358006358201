/*
Purpose: render telematic data into a table, if available.
otherwise the section is not shown at all
*/
// import external
import React from "react";

// import helper function
import {formatDateIfNotEmpty} from "../../actions/date.actions"

// import styling
import "./_style.scss"

const CarFieldTimeLogTable = (props) => {
    if (props.data?.length > 0) {
        return (
            <div className="section-card" style={{marginTop:"-1em"}}>
                <div className="section-container-content">
                    <h2>{props.section_name}</h2>
                    <table className="car-data-table">
                        <thead>
                        <tr>
                            <th style={{width:"30%"}}>{props?.fieldName}</th>
                            <th style={{width:"20%"}}>{props?.labels?.startDate}</th>
                            <th style={{width:"20%"}}>{props?.labels?.endDate}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.data.map(dataRow => {
                            return (
                                <tr>
                                    <td>{Object.values(dataRow)[0]}</td>
                                    <td>{formatDateIfNotEmpty(dataRow?.fromDate)}</td>
                                    <td>{formatDateIfNotEmpty(dataRow?.endDate)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else {
        return <></>
    }

}
export default CarFieldTimeLogTable
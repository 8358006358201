/*
Purpose: check if driver information is available, or it is an empty array
if available, render DriverInfoCard
*/
import React from "react";
// import styling
import "./_style.scss"
// import internal components
import DriverInfoCard from "../DriverInfoCard";

const DriverInfoCardBundle = (props) => {
    if (props.drivers?.length > 0) {
        return (
            <span className="driver-list">
                <h2>{props.section_name}</h2>
                {props.drivers.map(driver => <DriverInfoCard labels={props.labels} driver={driver}></DriverInfoCard>)}
            </span>
        )
    } else {
        return <></>
    }

}

export default DriverInfoCardBundle

/**
 * @param {string} key
 * @param {string} content
 */
export const saveToSessionStorage=(key, content)=>{
    sessionStorage.setItem(key, content);
}


/**
 * @param {string} key
 */
export const getFromSessionStorage=(key)=>{
    return sessionStorage.getItem(key);
}
export const AG_GRID_LOCALE_EN = {
    // Set Filter
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No matches',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    blank: 'Blank',
    notBlank: 'Not blank',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'In range',
    inRangeStart: 'from',
    inRangeEnd: 'to',

    // Text Filter
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Group Column Filter
    groupFilterSelect: 'Select field:',

    // Side Bar
    columns: 'Columns',
    filters: 'Filters',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Row Drag
    rowDragRow: 'row',
    rowDragRows:'rows',

    // Other
    loadingOoo: 'Loading...',
    loadingError: 'ERR',
    noRowsToShow: 'No Rows To Show',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    noAggregation: 'None',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    // eslint-disable-next-line no-template-curly-in-string
    addToValues: 'Add ${variable} to values',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromValues: 'Remove ${variable} from values',
    // eslint-disable-next-line no-template-curly-in-string
    addToLabels: 'Add ${variable} to labels',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromLabels: 'Remove ${variable} from labels',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copy With Headers',
    copyWithGroupHeaders: 'Copy with Group Headers',
    cut: 'Cut',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    first: 'First',
    last: 'Last',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',
    histogramFrequency: "Frequency",

    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    autoRotate: 'Auto Rotate',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    lineDash: 'Line Dash',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    combinationGroup: 'Combination',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    columnLineComboTooltip: 'Column & Line',
    areaColumnComboTooltip: 'Area & Column',
    customComboTooltip: 'Custom Combination',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',
    seriesChartType: 'Series Chart Type',
    seriesType: 'Series Type',
    secondaryAxis: 'Secondary Axis',

    // ARIA
    ariaChecked: 'checked',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaColumnList: 'Column List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'List',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterToValue: 'Filter to value',
    ariaFilterValue: 'Filter Value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaHidden: 'hidden',
    ariaIndeterminate:'indeterminate',
    ariaInputEditor: 'Input Editor',
    ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaSearch: 'Search',
    ariaSortableColumn: 'Press ENTER to sort',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaUnchecked: 'unchecked',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Search filter values',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Values',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
    ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'ascending',
    ariaDropZoneColumnComponentSortDescending: 'descending',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.'

}

export const AG_GRID_LOCALE_DE = {
// Set Filter
    selectAll: '(Alle auswählen)',
    selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
    searchOoo: 'Suchen...',
    blanks: '(Leer)',
    noMatches: 'Keine Übereinstimmungen',

// Number Filter & Text Filter
    filterOoo: 'Filtern...',
    equals: 'Gleich',
    notEqual: 'Nicht gleich',
    blank: 'Leer',
    notBlank: 'Nicht leer',
    empty: 'Wählen Sie eins',

// Number Filter
    lessThan: 'Kleiner als',
    greaterThan: 'Größer als',
    lessThanOrEqual: 'Kleiner oder gleich',
    greaterThanOrEqual: 'Größer oder gleich',
    inRange: 'Im Bereich',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

// Text Filter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',

// Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

// Filter Conditions
    andCondition: 'UND',
    orCondition: 'ODER',

// Filter Buttons
    applyFilter: 'Anwenden',
    resetFilter: 'Zurücksetzen',
    clearFilter: 'Leeren',
    cancelFilter: 'Abbrechen',

// Filter Titles
    textFilter: 'Textfilter',
    numberFilter: 'Zahlenfilter',
    dateFilter: 'Datumsfilter',
    setFilter: 'Filter einstellen',

// Group Column Filter
    groupFilterSelect: 'Feld auswählen:',

// Side Bar
    columns: 'Spalten',
    filters: 'Filter',

// columns tool panel
    pivotMode: 'Pivot-Modus',
    groups: 'Zeilen-Gruppierung',
    rowGroupColumnsEmptyMessage: 'Ziehen Sie hierher, um Zeilen zu gruppieren',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Ziehen Sie hierher, um zu aggregieren',
    pivots: 'Spaltenbeschriftungen',
    pivotColumnsEmptyMessage: 'Ziehen Sie hierher, um Spaltenbeschriftungen festzulegen',

// Header of the Default Group Column
    group: 'Gruppe',

// Row Drag
    rowDragRow: 'Zeile',
    rowDragRows: 'Zeilen',

// Other
    loadingOoo: 'Laden...',
    loadingError: 'ERR',
    noRowsToShow: 'Keine Zeilen zum Anzeigen',
    enabled: 'Aktiviert',

// Menu
    pinColumn: 'Spalte fixieren',
    pinLeft: 'Links fixieren',
    pinRight: 'Rechts fixieren',
    noPin: 'Nicht fixieren',
    valueAggregation: 'Wert-Aggregation',
    noAggregation: 'Keine',
    autosizeThiscolumn: 'Diese Spalte automatisch anpassen',
    autosizeAllColumns: 'Alle Spalten automatisch anpassen',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Gruppierung aufheben',
    // eslint-disable-next-line no-template-curly-in-string
    addToValues: '${variable} zu Werten hinzufügen',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromValues: '${variable} von Werten entfernen',
    // eslint-disable-next-line no-template-curly-in-string
    addToLabels: '${variable}: zu Beschriftungen hinzufügen',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromLabels: '${variable} von Beschriftungen entfernen',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alle erweitern',
    collapseAll: 'Alle schließen',
    copy: 'Kopieren',
    ctrlC: 'Strg+C',
    ctrlX: 'Strg+X',
    copyWithHeaders: 'Mit Überschriften kopieren',
    copyWithGroupHeaders: 'Mit Gruppen-Überschriften kopieren',
    cut: 'Ausschneiden',
    paste: 'Einfügen',
    ctrlV: 'Strg+V',
    export: 'Exportieren',
    csvExport: 'CSV-Export',
    excelExport: 'Excel-Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Summe',
    first: 'Erster',
    last: 'Letzter',
    min: 'Minimum',
    max: 'Maximum',
    none: 'Keine',
    count: 'Anzahl',
    avg: 'Durchschnitt',
    filteredRows: 'Gefiltert',
    selectedRows: 'Ausgewählt',
    totalRows: 'Gesamtanzahl Zeilen',
    totalAndFilteredRows: 'Zeilen',
    more: 'Mehr',
    to: 'bis',
    of: 'von',
    page: 'Seite',
    nextPage: 'Nächste Seite',
    lastPage: 'Letzte Seite',
    firstPage: 'Erste Seite',
    previousPage: 'Vorherige Seite',

    // Pivoting
    pivotColumnGroupTotals: 'Gesamt',

// Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart-Bereich',

    columnChart: 'Säulen',
    groupedColumn: 'Gruppiert',
    stackedColumn: 'Gestapelt',
    normalizedColumn: '100% gestapelt',

    barChart: 'Balken',
    groupedBar: 'Gruppiert',
    stackedBar: 'Gestapelt',
    normalizedBar: '100% gestapelt',

    pieChart: 'Kuchen',
    pie: 'Kuchen',
    doughnut: 'Donut',

    line: 'Linie',

    xyChart: 'X Y (Punkte)',
    scatter: 'Punkte',
    bubble: 'Blasen',

    areaChart: 'Flächen',
    area: 'Flächen',
    stackedArea: 'Gestapelt',
    normalizedArea: '100% gestapelt',

    histogramChart: 'Histogramm',
    histogramFrequency: 'Frequenz',

    combinationChart: 'Kombination',
    columnLineCombo: 'Säule & Linie',
    AreaColumnCombo: 'Fläche & Säule',

// Charts
    pivotChartTitle: 'Pivot-Chart',
    rangeChartTitle: 'Bereichs-Chart',
    settings: 'Einstellungen',
    data: 'Daten',
    format: 'Formatierung',
    categories: 'Kategorien',
    defaultCategory: '(Keine)',
    series: 'Serien',
    xyValues: 'X Y Werte',
    paired: 'Paarweise',
    axis: 'Achse',
    navigator: 'Navigator',
    color: 'Farbe',
    thickness: 'Dicke',
    xType: 'X-Achse Typ',
    automatic: 'Automatisch',
    category: 'Kategorie',
    number: 'Nummer',
    time: 'Zeit',
    autoRotate: 'Auto-Rotation',
    xRotation: 'X-Rotation',
    yRotation: 'Y-Rotation',
    ticks: 'Striche',
    width: 'Breite',
    height: 'Höhe',
    length: 'Länge',
    padding: 'Polsterung',
    spacing: 'Abstand',
    chart: 'Chart',
    title: 'Titel',
    titlePlaceholder: 'Chart-Titel - doppelklicken zum Bearbeiten',
    background: 'Hintergrund',
    font: 'Schriftart',
    top: 'Oben',
    right: 'Rechts',
    bottom: 'Unten',
    left: 'Links',
    labels: 'Bezeichnungen',
    size: 'Größe',
    minSize: 'Mindestgröße',
    maxSize: 'Maximalgröße',
    legend: 'Legende',
    position: 'Position',
    markerSize: 'Markergröße',
    markerStroke: 'Markerkontur',
    markerPadding: 'Marker-Polsterung',
    itemSpacing: 'Abstand zwischen Einträgen',
    itemPaddingX: 'X-Eintragspolsterung',
    itemPaddingY: 'Y-Eintragspolsterung',
    layoutHorizontalSpacing: 'Horizontaler Abstand',
    layoutVerticalSpacing: 'Vertikaler Abstand',
    strokeWidth: 'Konturstärke',
    lineDash: 'Strichart',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Marker',
    shadow: 'Schatten',
    blur: 'Unschärfe',
    xOffset: 'X-Versatz',
    yOffset: 'Y-Versatz',
    lineWidth: 'Linienbreite',
    normal: 'Normal',
    bold: 'Fett',
    italic: 'Kursiv',
    boldItalic: 'Fett und kursiv',
    predefined: 'Vordefiniert',
    fillOpacity: 'Füll-Transparenz',
    strokeOpacity: 'Kontur-Transparenz',
    histogramBinCount: 'Anzahl Bins',
    columnGroup: 'Säule',
    barGroup: 'Balken',
    pieGroup: 'Kuchen',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Punkte)',
    areaGroup: 'Fläche',
    histogramGroup: 'Histogramm',
    combinationGroup: 'Kombination',
    groupedColumnTooltip: 'Gruppiert',
    stackedColumnTooltip: 'Gestapelt',
    normalizedColumnTooltip: '100% gestapelt',
    groupedBarTooltip: 'Gruppiert',
    stackedBarTooltip: 'Gestapelt',
    normalizedBarTooltip: '100% gestapelt',
    pieTooltip: 'Kuchen',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Fläche',
    stackedAreaTooltip: 'Gestapelt',
    normalizedAreaTooltip: '100% gestapelt',
    scatterTooltip: 'Punkte',
    bubbleTooltip: 'Blasen',
    histogramTooltip: 'Histogramm',
    columnLineComboTooltip: 'Säule & Linie',
    areaColumnComboTooltip: 'Fläche & Säule',
    customComboTooltip: 'Benutzerdefinierte Kombination',
    noDataToChart: 'Keine Daten zum Erstellen eines Charts verfügbar.',
    pivotChartRequiresPivotMode: 'Für die Verwendung von Pivot-Charts ist der Pivot-Modus erforderlich.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Mit Raster verknüpft',
    chartUnlinkToolbarTooltip: 'Von Raster getrennt',
    chartDownloadToolbarTooltip: 'Chart herunterladen',
    seriesChartType: 'Diagrammtyp der Serie',
    seriesType: 'Serie-Typ',
    secondaryAxis: 'Sekundäre Achse',

    // ARIA
    ariaChecked: 'ausgewählt',
    ariaColumn: 'Spalte',
    ariaColumnGroup: 'Spaltengruppe',
    ariaColumnList: 'Spaltenliste',
    ariaColumnSelectAll: 'Alle Spalten auswählen',
    ariaDateFilterInput: 'Eingabe für Datumsfilter',
    ariaDefaultListName: 'Liste',
    ariaFilterColumnsInput: 'Eingabe für Spaltenfilter',
    ariaFilterFromValue: 'Filter von Wert',
    ariaFilterInput: 'Eingabe für Filter',
    ariaFilterList: 'Filterliste',
    ariaFilterToValue: 'Filter bis Wert',
    ariaFilterValue: 'Filterwert',
    ariaFilteringOperator: 'Filteroperator',
    ariaHidden: 'versteckt',
    ariaIndeterminate: 'unbestimmt',
    ariaInputEditor: 'Eingabe-Editor',
    ariaMenuColumn: 'Drücken Sie STRG EINGABE, um das Spaltenmenü zu öffnen.',
    ariaRowDeselect: 'Drücken Sie LEERTASTE, um diese Zeile abzuwählen',
    ariaRowSelectAll: 'Drücken Sie LEERTASTE, um alle Zeilen auszuwählen',
    ariaRowToggleSelection: 'Drücken Sie LEERTASTE, um die Zeilenauswahl zu wechseln',
    ariaRowSelect: 'Drücken Sie LEERTASTE, um diese Zeile auszuwählen',
    ariaSearch: 'Suche',
    ariaSortableColumn: 'Drücken Sie EINGABETASTE, um zu sortieren',
    ariaToggleVisibility: 'Drücken Sie LEERTASTE, um die Sichtbarkeit zu ändern',
    ariaUnchecked: 'nicht ausgewählt',
    ariaVisible: 'sichtbar',
    ariaSearchFilterValues: 'Filterwerte durchsuchen',
    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Zeilen-Gruppierung',
    ariaValuesDropZonePanelLabel: 'Werte',
    ariaPivotDropZonePanelLabel: 'Spalten-Beschriftungen',
    ariaDropZoneColumnComponentDescription: 'Drücken Sie ENTF, um zu entfernen',
    ariaDropZoneColumnValueItemDescription: 'Drücken Sie EINGABETASTE, um den Aggregationstyp zu ändern',
    ariaDropZoneColumnGroupItemDescription: 'Drücken Sie EINGABETASTE, um zu sortieren',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' von ',
    ariaDropZoneColumnComponentSortAscending: 'aufsteigend sortieren',
    ariaDropZoneColumnComponentSortDescending: 'absteigend sortieren',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Spaltenmenü',
    ariaLabelCellEditor: 'Zellen-Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Feld auswählen',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Kontextmenü',
    ariaLabelSubMenu: 'Untermenü',
    ariaLabelAggregationFunction: 'Aggregationsfunktion',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','
}


export const AG_GRID_LOCALE_FR = {
// Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats de recherche)',
    searchOoo: 'Rechercher...',
    blanks: '(Vides)',
    noMatches: 'Aucune correspondance',
// Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Égal',
    notEqual: 'Différent',
    blank: 'Vide',
    notBlank: 'Non vide',
    empty: 'Choisir une valeur',

// Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal à',
    greaterThanOrEqual: 'Supérieur ou égal à',
    inRange: 'Dans la plage',
    inRangeStart: 'De',
    inRangeEnd: 'À',

// Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Se termine par',

// Date Filter
    dateFormatOoo: 'aaaa-mm-jj',

// Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

// Filter Buttons
    applyFilter: 'Appliquer',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Effacer',
    cancelFilter: 'Annuler',

// Filter Titles
    textFilter: 'Filtre de texte',
    numberFilter: 'Filtre de nombres',
    dateFilter: 'Filtre de dates',
    setFilter: 'Configurer le filtre',

// Group Column Filter
    groupFilterSelect: 'Sélectionner un champ:',

// Side Bar
    columns: 'Colonnes',
    filters: 'Filtres',

// columns tool panel
    pivotMode: 'Mode pivot',
    groups: 'Groupes de lignes',
    rowGroupColumnsEmptyMessage: 'Glissez-déposez ici pour grouper des lignes',
    values: 'Valeurs',
    valueColumnsEmptyMessage: 'Glissez-déposez ici pour agréger',
    pivots: 'Étiquettes de colonnes',
    pivotColumnsEmptyMessage: 'Glissez-déposez ici pour définir les étiquettes de colonnes',

// Header of the Default Group Column
    group: 'Groupe',

// Row Drag
    rowDragRow: 'ligne',
    rowDragRows: 'lignes',

// Other
    loadingOoo: 'Chargement...',
    loadingError: 'Erreur',
    noRowsToShow: 'Aucune ligne à afficher',
    enabled: 'Activé',

// Menu
    pinColumn: 'Épingler la colonne',
    pinLeft: 'Épingler à gauche',
    pinRight: 'Épingler à droite',
    noPin: 'Ne pas épingler',
    valueAggregation: 'Aggrégation de valeurs',
    noAggregation: 'Aucune',
    autosizeThiscolumn: 'Ajuster automatiquement cette colonne',
    autosizeAllColumns: 'Ajuster automatiquement toutes les colonnes',
    groupBy: 'Grouper par',
    ungroupBy: 'Dégrouper par',
    // eslint-disable-next-line no-template-curly-in-string
    addToValues: 'Ajouter ${variable} aux valeurs',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromValues: 'Supprimer ${variable} des valeurs',
    // eslint-disable-next-line no-template-curly-in-string
    addToLabels: 'Ajouter ${variable} aux étiquettes',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromLabels: 'Supprimer ${variable} des étiquettes',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Tout développer',
    collapseAll: 'Tout réduire',
    copy: 'Copier',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copier avec les en-têtes',
    copyWithGroupHeaders: 'Copier avec les en-têtes de groupe',
    cut: 'Couper',
    paste: 'Coller',
    ctrlV: 'Ctrl+V',
    export: 'Exporter',
    csvExport: 'Exporter en CSV',
    excelExport: 'Exporter en Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Somme',
    first: 'Premier',
    last: 'Dernier',
    min: 'Min',
    max: 'Max',
    none: 'Aucun',
    count: 'Nombre',
    avg: 'Moyenne',
    filteredRows: 'Filtré',
    selectedRows: 'Sélectionné',
    totalRows: 'Total de lignes',
    totalAndFilteredRows: 'Lignes',
    more: 'Plus',
    to: 'à',
    of: 'de',
    page: 'Page',
    nextPage: 'Page suivante',
    lastPage: 'Dernière page',
    firstPage: 'Première page',
    previousPage: 'Page précédente',
    // Pivoting
    pivotColumnGroupTotals: 'Total',

// Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Chart croisé & Mode croisé',
    pivotChart: 'Chart croisé',
    chartRange: 'Plage de graphique',

    columnChart: 'Colonne',
    groupedColumn: 'Groupé',
    stackedColumn: 'Empilé',
    normalizedColumn: '100% empilé',

    barChart: 'Barre',
    groupedBar: 'Groupé',
    stackedBar: 'Empilé',
    normalizedBar: '100% empilé',

    pieChart: 'Camembert',
    pie: 'Camembert',
    doughnut: 'Donut',

    line: 'Ligne',

    xyChart: 'X Y (Dispersé)',
    scatter: 'Dispersé',
    bubble: 'Bulle',

    areaChart: 'Surface',
    area: 'Surface',
    stackedArea: 'Empilé',
    normalizedArea: '100% empilé',

    histogramChart: 'Histogramme',
    histogramFrequency: "Fréquence",

    combinationChart: 'Combinaison',
    columnLineCombo: 'Colonne & Ligne',
    AreaColumnCombo: 'Surface & Colonne',

// Charts
    pivotChartTitle: 'Chart croisé',
    rangeChartTitle: 'Plage de graphique',
    settings: 'Paramètres',
    data: 'Données',
    format: 'Format',
    categories: 'Catégories',
    defaultCategory: '(Aucune)',
    series: 'Séries',
    xyValues: 'Valeurs X Y',
    paired: 'Mode apparié',
    axis: 'Axe',
    navigator: 'Navigateur',
    color: 'Couleur',
    thickness: 'Épaisseur',
    xType: 'Type X',
    automatic: 'Automatique',
    category: 'Catégorie',
    number: 'Nombre',
    time: 'Temps',
    autoRotate: 'Rotation automatique',
    xRotation: 'Rotation X',
    yRotation: 'Rotation Y',
    ticks: 'Graduations',
    width: 'Largeur',
    height: 'Hauteur',
    length: 'Longueur',
    padding: 'Remplissage',
    spacing: 'Espacement',
    chart: 'Graphique',
    title: 'Titre',
    titlePlaceholder: 'Titre du graphique - double-cliquez pour modifier',
    background: 'Fond',
    font: 'Police',
    top: 'Haut',
    right: 'Droite',
    bottom: 'Bas',
    left: 'Gauche',
    labels: 'Étiquettes',
    size: 'Taille',
    minSize: 'Taille minimale',
    maxSize: "Taille maximale",
    legend: 'Légende',
    position: 'Position',
    markerSize: 'Taille du marqueur',
    markerStroke: 'Contour du marqueur',
    markerPadding: 'Remplissage du marqueur',
    itemSpacing: 'Espacement des éléments',
    itemPaddingX: 'Remplissage X des éléments',
    itemPaddingY: 'Remplissage Y des éléments',
    layoutHorizontalSpacing: 'Espacement horizontal',
    layoutVerticalSpacing: 'Espacement vertical',
    strokeWidth: 'Largeur de trait',
    lineDash: 'Pointillé',
    offset: 'Décalage',
    offsets: 'Décalages',
    tooltips: 'Info-bulles',
    callout: 'Annotation',
    markers: 'Marqueurs',
    shadow: 'Ombre',
    blur: 'Flou',
    xOffset: 'Décalage X',
    yOffset: 'Décalage Y',
    lineWidth: 'Largeur de la ligne',
    normal: 'Normal',
    bold: 'Gras',
    italic: 'Italique',
    boldItalic: 'Gras Italique',
    predefined: 'Prédéfini',
    fillOpacity: 'Opacité de remplissage',
    strokeOpacity: 'Opacité de la ligne',
    histogramBinCount: 'Nombre de bacs',
    columnGroup: 'Colonne',
    barGroup: 'Barre',
    pieGroup: 'Camembert',
    lineGroup: 'Ligne',
    scatterGroup: 'X Y (Dispersé)',
    areaGroup: 'Surface',
    histogramGroup: 'Histogramme',
    combinationGroup: 'Combinaison',
    groupedColumnTooltip: 'Groupé',
    stackedColumnTooltip: 'Empilé',
    normalizedColumnTooltip: '100% empilé',
    groupedBarTooltip: 'Groupé',
    stackedBarTooltip: 'Empilé',
    normalizedBarTooltip: '100% empilé',
    pieTooltip: 'Camembert',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Ligne',
    groupedAreaTooltip: 'Surface',
    stackedAreaTooltip: 'Empilé',
    normalizedAreaTooltip: '100% empilé',
    scatterTooltip: 'Dispersé',
    bubbleTooltip: 'Bulle',
    histogramTooltip: 'Histogramme',
    columnLineComboTooltip: 'Colonne & Ligne',
    areaColumnComboTooltip: 'Surface & Colonne',
    customComboTooltip: 'Combinaison personnalisée',
    noDataToChart: 'Aucune donnée disponible pour créer un graphique.',
    pivotChartRequiresPivotMode: 'Le graphique croisé nécessite le mode croisé activé.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Lié à la grille',
    chartUnlinkToolbarTooltip: 'Dissocier de la grille',
    chartDownloadToolbarTooltip: 'Télécharger le graphique',
    seriesChartType: 'Type de graphique de la série',
    seriesType: 'Type de série',
    secondaryAxis: 'Axe secondaire',
    //ARIA
    ariaChecked: 'sélectionné',
    ariaColumn: 'Colonne',
    ariaColumnGroup: 'Groupe de colonnes',
    ariaColumnList: 'Liste de colonnes',
    ariaColumnSelectAll: 'Sélectionner toutes les colonnes',
    ariaDateFilterInput: 'Saisie de filtre de date',
    ariaDefaultListName: 'Liste',
    ariaFilterColumnsInput: 'Saisie de filtre de colonnes',
    ariaFilterFromValue: 'Filtrer à partir de la valeur',
    ariaFilterInput: 'Saisie de filtre',
    ariaFilterList: 'Liste de filtres',
    ariaFilterToValue: 'Filtrer jusqu\'à la valeur',
    ariaFilterValue: 'Valeur de filtre',
    ariaFilteringOperator: 'Opérateur de filtrage',
    ariaHidden: 'caché',
    ariaIndeterminate:'indéterminé',
    ariaInputEditor: 'Éditeur de saisie',
    ariaMenuColumn: 'Appuyez sur CTRL ENTRÉE pour ouvrir le menu de colonnes.',
    ariaRowDeselect: 'Appuyez sur ESPACE pour désélectionner cette ligne',
    ariaRowSelectAll: 'Appuyez sur ESPACE pour basculer la sélection de toutes les lignes',
    ariaRowToggleSelection: 'Appuyez sur ESPACE pour basculer la sélection de la ligne',
    ariaRowSelect: 'Appuyez sur ESPACE pour sélectionner cette ligne',
    ariaSearch: 'Recherche',
    ariaSortableColumn: 'Appuyez sur ENTRÉE pour trier',
    ariaToggleVisibility: 'Appuyez sur ESPACE pour basculer la visibilité',
    ariaUnchecked: 'non sélectionné',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Rechercher des valeurs de filtre',
    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Groupes de lignes',
    ariaValuesDropZonePanelLabel: 'Valeurs',
    ariaPivotDropZonePanelLabel: 'Libellés de colonnes',
    ariaDropZoneColumnComponentDescription: 'Appuyez sur SUPPR pour supprimer',
    ariaDropZoneColumnValueItemDescription: 'Appuyez sur ENTRÉE pour modifier le type d\'agrégation',
    ariaDropZoneColumnGroupItemDescription: 'Appuyez sur ENTRÉE pour trier',
// used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' de ',
    ariaDropZoneColumnComponentSortAscending: 'tri croissant',
    ariaDropZoneColumnComponentSortDescending: 'tri décroissant',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Menu de colonnes',
    ariaLabelCellEditor: 'Éditeur de cellule',
    ariaLabelDialog: 'Dialogue',
    ariaLabelSelectField: 'Sélectionnez un champ',
    ariaLabelTooltip: 'Info-bulle',
    ariaLabelContextMenu: 'Menu contextuel',
    ariaLabelSubMenu: 'Sous-menu',
    ariaLabelAggregationFunction: 'Fonction d\'agrégation',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ' ',
    decimalSeparator: ',',

}



export const AG_GRID_LOCALE_IT = {
// Set Filter
    selectAll: '(Seleziona tutto)',
    selectAllSearchResults: '(Seleziona tutti i risultati)',
    searchOoo: 'Cerca...',
    blanks: '(Vuoto)',
    noMatches: 'Nessun risultato',
// Number Filter & Text Filter
    filterOoo: 'Filtra...',
    equals: 'Uguale',
    notEqual: 'Diverso',
    blank: 'Vuoto',
    notBlank: 'Non vuoto',
    empty: 'Seleziona...',

// Number Filter
    lessThan: 'Minore di',
    greaterThan: 'Maggiore di',
    lessThanOrEqual: 'Minore o uguale a',
    greaterThanOrEqual: 'Maggiore o uguale a',
    inRange: 'Nell\'intervallo',
    inRangeStart: 'Da',
    inRangeEnd: 'A',

// Text Filter
    contains: 'Contiene',
    notContains: 'Non contiene',
    startsWith: 'Inizia con',
    endsWith: 'Finisce con',

// Date Filter
    dateFormatOoo: 'yyyy-mm-gg',

// Filter Conditions
    andCondition: 'E',
    orCondition: 'O',

// Filter Buttons
    applyFilter: 'Applica',
    resetFilter: 'Reset',
    clearFilter: 'Pulisci',
    cancelFilter: 'Annulla',

// Filter Titles
    textFilter: 'Filtro testo',
    numberFilter: 'Filtro numeri',
    dateFilter: 'Filtro data',
    setFilter: 'Imposta filtro',

// Group Column Filter
    groupFilterSelect: 'Seleziona campo:',

// Side Bar
    columns: 'Colonne',
    filters: 'Filtri',

// columns tool panel
    pivotMode: 'Modalità pivot',
    groups: 'Gruppi di righe',
    rowGroupColumnsEmptyMessage: 'Trascina qui per raggruppare le righe',
    values: 'Valori',
    valueColumnsEmptyMessage: 'Trascina qui per aggregare',
    pivots: 'Etichette di colonna',
    pivotColumnsEmptyMessage: 'Trascina qui per impostare le etichette di colonna',

// Header of the Default Group Column
    group: 'Gruppo',

// Row Drag
    rowDragRow: 'riga',
    rowDragRows: 'righe',

// Other
    loadingOoo: 'Caricamento...',
    loadingError: 'Errore',
    noRowsToShow: 'Nessuna riga da mostrare',
    enabled: 'Abilitato',

// Menu
    pinColumn: 'Blocca colonna',
    pinLeft: 'Blocca a sinistra',
    pinRight: 'Blocca a destra',
    noPin: 'Non bloccare',
    valueAggregation: 'Aggregazione dei valori',
    noAggregation: 'Nessuna',
    autosizeThiscolumn: 'Adatta automaticamente questa colonna',
    autosizeAllColumns: 'Adatta automaticamente tutte le colonne',
    groupBy: 'Raggruppa per',
    ungroupBy: 'Annulla raggruppamento',
    // eslint-disable-next-line no-template-curly-in-string
    addToValues: 'Aggiungi ${variable} ai valori',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromValues: 'Rimuovi ${variable} dai valori',
    // eslint-disable-next-line no-template-curly-in-string
    addToLabels: 'Aggiungi ${variable} alle etichette',
    // eslint-disable-next-line no-template-curly-in-string
    removeFromLabels: 'Rimuovi ${variable} dalle etichette',
    resetColumns: 'Resetta colonne',
    expandAll: 'Espandi tutto',
    collapseAll: 'Comprimi tutto',
    copy: 'Copia',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copia con intestazioni',
    copyWithGroupHeaders: 'Copia con intestazioni di gruppo',
    cut: 'Taglia',
    paste: 'Incolla',
    ctrlV: 'Ctrl+V',
    export: 'Esporta',
    csvExport: 'Esporta in CSV',
    excelExport: 'Esporta in Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Somma',
    first: 'Primo',
    last: 'Ultimo',
    min: 'Minimo',
    max: 'Massimo',
    none: 'Nessuno',
    count: 'Conteggio',
    avg: 'Media',
    filteredRows: 'Filtrate',
    selectedRows: 'Selezionate',
    totalRows: 'Totale righe',
    totalAndFilteredRows: 'Righe',
    more: 'Altro',
    to: 'a',
    of: 'di',
    page: 'Pagina',
    nextPage: 'Pagina successiva',
    lastPage: 'Ultima pagina',
    firstPage: 'Prima pagina',
    previousPage: 'Pagina precedente',
    // Pivoting
    pivotColumnGroupTotals: 'Totali',

// Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Grafico pivot e Modalità pivot',
    pivotChart: 'Grafico pivot',
    chartRange: 'Intervallo grafico',

    columnChart: 'Colonna',
    groupedColumn: 'Raggruppato',
    stackedColumn: 'Impilato',
    normalizedColumn: '100% Impilato',

    barChart: 'Barra',
    groupedBar: 'Raggruppato',
    stackedBar: 'Impilato',
    normalizedBar: '100% Impilato',

    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Ciambella',

    line: 'Linea',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bolla',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Impilato',
    normalizedArea: '100% Impilato',

    histogramChart: 'Istogramma',
    histogramFrequency: "Frequenza",

    combinationChart: 'Combinazione',
    columnLineCombo: 'Colonna e Linea',
    AreaColumnCombo: 'Area e Colonna',

// Charts
    pivotChartTitle: 'Grafico pivot',
    rangeChartTitle: 'Intervallo grafico',
    settings: 'Impostazioni',
    data: 'Dati',
    format: 'Formato',
    categories: 'Categorie',
    defaultCategory: '(Nessuna)',
    series: 'Serie',
    xyValues: 'Valori X Y',
    paired: 'Modalità accoppiata',
    axis: 'Asse',
    navigator: 'Navigatore',
    color: 'Colore',
    thickness: 'Spessore',
    xType: 'Tipo X',
    automatic: 'Automatico',
    category: 'Categoria',
    number: 'Numero',
    time: 'Tempo',
    autoRotate: 'Rotazione automatica',
    xRotation: 'Rotazione X',
    yRotation: 'Rotazione Y',
    ticks: 'Ticks',
    width: 'Larghezza',
    height: 'Altezza',
    length: 'Lunghezza',
    padding: 'Padding',
    spacing: 'Spaziatura',
    chart: 'Grafico',
    title: 'Titolo',
    titlePlaceholder: 'Titolo del grafico - doppio clic per modificare',
    background: 'Sfondo',
    font: 'Carattere',
    top: 'In alto',
    right: 'Destra',
    bottom: 'In basso',
    left: 'Sinistra',

    labels: 'Etichette',
    size: 'Dimensione',
    minSize: 'Dimensione minima',
    maxSize: 'Dimensione massima',
    legend: 'Legenda',
    position: 'Posizione',
    markerSize: 'Dimensione marcatore',
    markerStroke: 'Contorno marcatore',
    markerPadding: 'Padding marcatore',
    itemSpacing: 'Spaziatura elemento',
    itemPaddingX: 'Padding X elemento',
    itemPaddingY: 'Padding Y elemento',
    layoutHorizontalSpacing: 'Spaziatura orizzontale',
    layoutVerticalSpacing: 'Spaziatura verticale',
    strokeWidth: 'Larghezza contorno',
    lineDash: 'Linea tratteggiata',
    offset: 'Offset',
    offsets: 'Offset',
    tooltips: 'Descrizioni',
    callout: 'Callout',
    markers: 'Marcatori',
    shadow: 'Ombra',
    blur: 'Sfocatura',
    xOffset: 'Offset X',
    yOffset: 'Offset Y',
    lineWidth: 'Spessore linea',
    normal: 'Normale',
    bold: 'Grassetto',
    italic: 'Corsivo',
    boldItalic: 'Grassetto corsivo',
    predefined: 'Predefinito',
    fillOpacity: 'Opacità riempimento',
    strokeOpacity: 'Opacità linea',
    histogramBinCount: 'Conteggio bin',
    columnGroup: 'Colonna',
    barGroup: 'Barra',
    pieGroup: 'Torta',
    lineGroup: 'Linea',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Istogramma',
    combinationGroup: 'Combinazione',
    groupedColumnTooltip: 'Raggruppato',
    stackedColumnTooltip: 'Impilato',
    normalizedColumnTooltip: '100% Impilato',
    groupedBarTooltip: 'Raggruppato',
    stackedBarTooltip: 'Impilato',
    normalizedBarTooltip: '100% Impilato',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Ciambella',
    lineTooltip: 'Linea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Impilato',
    normalizedAreaTooltip: '100% Impilato',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bolla',
    histogramTooltip: 'Istogramma',
    columnLineComboTooltip: 'Colonna e Linea',
    areaColumnComboTooltip: 'Area e Colonna',
    customComboTooltip: 'Combinazione personalizzata',
    noDataToChart: 'Nessun dato disponibile per il grafico.',
    pivotChartRequiresPivotMode: 'Il grafico pivot richiede la Modalità pivot abilitata.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Collegato alla griglia',
    chartUnlinkToolbarTooltip: 'Non collegato alla griglia',
    chartDownloadToolbarTooltip: 'Scarica il grafico',
    seriesChartType: 'Tipo grafico serie',
    seriesType: 'Tipo serie',
    secondaryAxis: 'Asse secondario',

    // ARIA
    ariaChecked: 'selezionato',
    ariaColumn: 'Colonna',
    ariaColumnGroup: 'Gruppo di colonne',
    ariaColumnList: 'Elenco colonne',
    ariaColumnSelectAll: 'Attiva/disattiva la selezione di tutte le colonne',
    ariaDateFilterInput: 'Input filtro data',
    ariaDefaultListName: 'Elenco',
    ariaFilterColumnsInput: 'Input filtro colonne',
    ariaFilterFromValue: 'Filtra da valore',
    ariaFilterInput: 'Input filtro',
    ariaFilterList: 'Elenco filtri',
    ariaFilterToValue: 'Filtra a valore',
    ariaFilterValue: 'Valore filtro',
    ariaFilteringOperator: 'Operatore di filtraggio',
    ariaHidden: 'nascosto',
    ariaIndeterminate:'indeterminato',
    ariaInputEditor: 'Editor di input',
    ariaMenuColumn: 'Premi CTRL INVIO per aprire il menu colonne.',
    ariaRowDeselect: 'Premi SPAZIO per deselezionare questa riga',
    ariaRowSelectAll: 'Premi SPAZIO per attivare/disattivare la selezione di tutte le righe',
    ariaRowToggleSelection: 'Premi SPAZIO per attivare/disattivare la selezione della riga',
    ariaRowSelect: 'Premi SPAZIO per selezionare questa riga',
    ariaSearch: 'Cerca',
    ariaSortableColumn: 'Premi INVIO per ordinare',
    ariaToggleVisibility: 'Premi SPAZIO per attivare/disattivare la visibilità',
    ariaUnchecked: 'non selezionato',
    ariaVisible: 'visibile',
    ariaSearchFilterValues: 'Cerca valori filtro',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Gruppi di righe',
    ariaValuesDropZonePanelLabel: 'Valori',
    ariaPivotDropZonePanelLabel: 'Etichette di colonna',
    ariaDropZoneColumnComponentDescription: 'Premi CANC per rimuovere',
    ariaDropZoneColumnValueItemDescription: 'Premi INVIO per modificare il tipo di aggregazione',
    ariaDropZoneColumnGroupItemDescription: 'Premi INVIO per ordinare',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' di ',
    ariaDropZoneColumnComponentSortAscending: 'ordinamento ascendente',
    ariaDropZoneColumnComponentSortDescending: 'ordinamento discendente',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Menu colonna',
    ariaLabelCellEditor: 'Editor cella',
    ariaLabelDialog: 'Dialogo',
    ariaLabelSelectField: 'Seleziona campo',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Menu contestuale',
    ariaLabelSubMenu: 'Sottomenu',
    ariaLabelAggregationFunction: 'Funzione di aggregazione',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','
}
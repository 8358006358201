import {useLazyGetDocumentFileQuery} from "../../../../redux/apiSlice/documentsApiSlice";
import React from "react";
import {ReactComponent as DownloadIcon} from "../../../../assets/svg/icon-download.svg";
import { triggerEvent } from "../../../../actions/eventTracking.actions";


export const DocumentListCard = ({documents, labels}) => {
    let [triggerDocumentDownload] = useLazyGetDocumentFileQuery();


    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = (file, documentName, backupName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        // If the file document has an undefined name, use the one displayed in grid, if that empty too, display "document"
        a.download = documentName || (backupName || "document");
        a.click();
    }

    //When user clicks on a download button, this function is called to trigger the api call for that document id
    const downloadDocument = async (documentId, backupName) => {
        let result = await triggerDocumentDownload(documentId, {preferCacheValue: true});
        if (result.isSuccess) {
            openDownloadLink(result.data?.documentFile?.file, result.data?.documentFile?.documentName, backupName);

            // track the download
            triggerEvent('file_download', 'click', 'success', 'pdf', 'download_button');
        } else {
            // track the download
            triggerEvent('file_download', 'click', 'error', 'pdf', 'download_button');
        }
    };

    const DownloadButton = ({document}) => {
        return (
            <span onClick={() => downloadDocument(document.id, document.label)}
                  style={{cursor: "pointer"}} title={document.label || 'document.pdf'}
                  id={document.id} data-cy={'file-download-icon'}>
                        <DownloadIcon className="grid-icon" width={24} height={24}/>
            </span>
        )
    }

    return (
        <div className="financing-card" style={{paddingLeft:0, paddingRight:0, height:"100%", display:"flex", flexDirection:"column"}}>
            {documents.map((document, index) => (
                <div className="document-download-item" key={index} >
                    <DownloadButton document={document}/>
                    {document.label}
                </div>
            ))}
        </div>
    )
}
import {createSlice} from "@reduxjs/toolkit";

// the session storage keyword here is filter_state or filter_external_state
// this function loop
const loadFilterFromStorage = (session_storage_keyword) => {
    let result = {}
    for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i)
        if (key.includes(session_storage_keyword)) {
            try{
                let content = JSON.parse(sessionStorage.getItem(key))
                if(Object.keys(content).length !== 0){
                    result[key] = content
                }
            }catch (e) {
            }
        }
    }

    return result
}

let initialFilterModel = loadFilterFromStorage("filter_state")
let initialExternalFilterModel = loadFilterFromStorage("filter_external_state")

export const gridSlice = createSlice({
    name: "grid",
    initialState: {
        //Filter is set to "Only Active" on default
        activeExtFilter: initialExternalFilterModel,
        columnFilterModel: initialFilterModel,
        currentPageNumber:{}
    },
    reducers: {
        setExternalActiveFilter: (state, action) => {
            state.activeExtFilter[`${action.payload.gridName}_filter_external_state`] = action.payload.filterType;
            sessionStorage.setItem(`${action.payload.gridName}_filter_external_state`, JSON.stringify(action.payload.filterType));
        },
        setColumnFilterModel: (state, action) => {
            state.columnFilterModel[`${action.payload.gridName}_filter_state`] = action.payload.model
            sessionStorage.setItem( `${action.payload.gridName}_filter_state`, JSON.stringify(action.payload.model));
        },
        saveCurrentPageToRedux:(state,action)=>{
            state.currentPageNumber[`${action.payload.gridName}`]=action.payload.pageNumber
        }
    }
})

export const {setExternalActiveFilter, setColumnFilterModel, saveCurrentPageToRedux} = gridSlice.actions;
export default gridSlice.reducer;
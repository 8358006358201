import {useSelector} from "react-redux";
import { useNavigate } from "react-router-dom"
import "./_style.scss";
import {useGetActiveCarsQuery, useGetDriverCountQuery} from "../../redux/apiSlice/dashboardApiSlice";
import {
    MetricCard,
    MetricCardBody,
    MetricCardHeader,
    MetricCardIcon,
    MetricCardTitle
} from "../../components/MetricCard";

import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as DriverIcon} from "../../assets/svg/icon-person.svg";
import ActiveVehiclesChart from "./Charts/ActiveVehiclesChart";
import {Skeleton} from "../../components/Skeleton";
import {FleetManagementSection} from "./FleetManagement";
import {FleetPerformanceSection} from "./FleetPerformance";

export const DataStatusBadge = ({isValid, labels}) => {
    const tagType = isValid ? 'positive' : 'negative'
    const text = isValid ? labels?.homePage?.homeInfo?.dataOkay : labels?.homePage?.homeInfo?.dataError;

    return (
        <div className={`status-tag-${tagType}`} style={{position:'absolute', top:'15px', right:'15px'}}>
            {text}
        </div>
    )
}

const HomePage = () => {
    const labels = useSelector(state => state.labels.currentLabels)
    const navigate = useNavigate();
    const handleSwitchPage = (divName) => {

        navigate(divName)
    }

    const {
        data: driverCountData,
        isFetching:driverCountIsFetching,
        isError:driverCountIsError,
    } = useGetDriverCountQuery();

    const {
        data: activeCarsData,
        isFetching:activeCarsIsFetching,
        isError:activeCarsIsError,
    } = useGetActiveCarsQuery();


    return (
        <div className="content-side content-side-padding">
            <div className="home-content">
                {/* secion 1 */}

                <div className="home-section">
                    <h1>
                        {labels?.homePage?.titles?.fleetPerformance}
                    </h1>
                    <div className="content">
                        <FleetPerformanceSection labels={labels} />
                    </div>
                </div>

                {/* secion 2 */}
                <div className="home-section">
                    <h1>
                        {labels?.homePage?.titles?.fleetManagement}
                    </h1>
                    <FleetManagementSection labels={labels}/>
                </div>

                {/* secion 3 */}
                
                <div className="home-section">
                    <h1>
                        {labels?.homePage?.titles?.fleetStructure}
                    </h1>
                    {/*
                       If only one of the two APIs for this section load, we change the CSS a bit because otherwise
                       the card alignment would look weird.
                    */}
                    <div className="fleet-structure-content" data-single={activeCarsIsError || driverCountIsError}>
                        <div className="double-cards" data-single={activeCarsIsError || driverCountIsError}>
                            <MetricCard onClick={() => {handleSwitchPage("/cars")}} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={activeCarsIsError}>
                                <MetricCardHeader>
                                    <MetricCardTitle>
                                        {labels?.homePage?.metrics?.activeVehicles}
                                    </MetricCardTitle>
                                </MetricCardHeader>
                                <MetricCardBody>
                                    <MetricCardIcon>
                                        <CarIcon width={19} height={19}/>
                                    </MetricCardIcon>
                                    {activeCarsIsFetching ? <Skeleton height="25px" width="100%"/> : activeCarsData?.reduce((acc, curr) => acc + curr.cars, 0)}
                                </MetricCardBody>
                            </MetricCard>

                            <MetricCard onClick={() => {handleSwitchPage("/drivers")}} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={driverCountIsError}>
                                <MetricCardHeader>
                                    <MetricCardTitle>
                                        {labels?.homePage?.metrics?.activeDrivers}
                                    </MetricCardTitle>
                                </MetricCardHeader>
                                <MetricCardBody>
                                    <MetricCardIcon>
                                        <DriverIcon width={19} height={19}/>
                                    </MetricCardIcon>
                                    {driverCountIsFetching ?  <Skeleton height="25px" width="100%"/> : driverCountData?.drivers}
                                </MetricCardBody>
                            </MetricCard>
                        </div>
                    
                        <MetricCard style={{flexDirection:"column", flexGrow:3, overflowX:"auto"}} hidden={activeCarsIsError}>
                            <MetricCardHeader>
                                <MetricCardTitle>
                                    {labels?.homePage?.metrics?.activeVehicles}
                                </MetricCardTitle>
                            </MetricCardHeader>
                            <MetricCardBody  style={{overflowX:"auto"}}>
                                {
                                activeCarsIsFetching ? (
                                    <Skeleton height="125px" width="100%"/>):(
                                <ActiveVehiclesChart data={activeCarsData} labels={labels} />)
                                }
                            </MetricCardBody>
                        </MetricCard>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default HomePage;
// endpoint to get all car related information
import {apiSlice} from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

const getParams = (arg) => {
    let params = {}
    if (arg?.startDt?.length > 0) {
        params.startDt = arg.startDt
    }
    if (arg?.endDt?.length > 0) {
        params.endDt = arg.endDt
    }
    if (arg?.VIN?.length > 0) {
        params.vin = arg.VIN
    }
    return params
}

export const fleetCostSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFleetCostsByCostType: builder.query({
            query: (arg ) => ({
                url: API_FAMILY_CONST.FLEET_COST_COST_TYPE,
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
                //Only send params if they are not null
                params: getParams(arg)
            }),
            providesTags: ['FleetCostsByCostType'],
        }),
        getFleetCostsByCar: builder.query({
            query: (arg) => ({
                url: API_FAMILY_CONST.FLEET_COST_CAR,
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
                //If params are null, they will not be sent

                params: getParams(arg)
            }),
            providesTags: ['FleetCostsByCar'],
        }),
        getFleetCostsByCostTypeV2: builder.query({
            query: (arg ) => ({
                url: API_FAMILY_CONST.FLEET_COST_COST_TYPE + "V2",
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
                //Only send params if they are not null
                params: getParams(arg)
            }),
            providesTags: ['FleetCostsByCostTypeV2'],
        }),
        getFleetCostsByCarV2: builder.query({
            query: (arg) => ({
                url: API_FAMILY_CONST.FLEET_COST_CAR+ "V2",
                method: 'GET',
                //caching for 10 min
                keepUnusedDataFor: 600,
                //If params are null, they will not be sent

                params: getParams(arg)
            }),
            providesTags: ['FleetCostsByCarV2'],
        }),
    })
})

export const {
    useGetFleetCostsByCostTypeQuery,
    useGetFleetCostsByCostTypeV2Query,
    useGetFleetCostsByCarV2Query,
    useGetFleetCostsByCarQuery,
    usePrefetch
} = fleetCostSlice